import {
    FilterObject,
    InputViewBox,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    Divider,
    Grid,
    HorizontalScroller,
    Stack,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import some from "lodash/some";
import { DashedChip } from "../../../common/components/DashedChip";
import { FilterCriteriaChip } from "../../../common/components/FilterCriteriaChip";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { getLinearGradient } from "../../../common/utils/gradient";
import ExportContainer from "../../containers/ExportContainer";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { MapContextFilter } from "../../mapbox/context";
import { DEFAULT_SHAPE_COLOR_OBJECT } from "../utils/utils";

interface IMapTopbar {
    readonly onAddFilter: () => void;
    readonly filters: MapContextFilter[];
    readonly clearFilters: () => void;
    readonly removeFilter: (id: string) => void;
    readonly editFilter: (id: string) => void;
    readonly viewFilterTable: (id: string, isViewed: boolean) => void;
    readonly toggleFilterVisibility: (id: string, visible: boolean) => void;
    readonly viewport?: InputViewBox;
    readonly multipolygon?: readonly InputPolygonWithId[];
    readonly multiFilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
}

const MapTopbar = ({
    onAddFilter,
    filters,
    clearFilters,
    removeFilter,
    editFilter,
    viewFilterTable,
    toggleFilterVisibility,
    responses,
    recentResponse,
    viewport,
    multipolygon,
    multiFilters,
}: IMapTopbar) => {
    const isSf = isAppRunningOnSF();
    const hasFilters =
        !isEmpty(filters) && every(filters, (f) => isEqual(f.preview, false));
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const isFilterGettingEdited = some(
        filters,
        (f) => isEqual(f.preview, true) && isEqual(f.selected, true)
    );

    return (
        <Grid
            container
            justifyContent="space-between"
            width="100%"
            sx={{
                padding: 2,
            }}
        >
            <Grid item xs minWidth={0}>
                <HorizontalScroller>
                    <Stack flexDirection="row" gap={2}>
                        {pipe(
                            filters,
                            A.filter((f) => {
                                const isNotPreview =
                                    isEqual(f.preview, false) &&
                                    !isEmpty(f.filterCriteria);
                                const isEdit =
                                    isEqual(f.preview, true) &&
                                    isEqual(f.selected, true);

                                return isNotPreview || isEdit;
                            }),
                            A.map((filter) => (
                                <FilterCriteriaChip
                                    key={filter.id}
                                    id={filter.id}
                                    label={filter.details.name}
                                    description={filter.details.description}
                                    color={
                                        filter.styles?.fill ||
                                        DEFAULT_SHAPE_COLOR_OBJECT
                                    }
                                    gradient={
                                        filter.styles.dataAggregation?.heatmap
                                            ? getLinearGradient(
                                                  filter.styles.dataAggregation
                                                      .heatmap
                                              )
                                            : undefined
                                    }
                                    borderColor={
                                        filter.styles?.stroke ||
                                        DEFAULT_SHAPE_COLOR_OBJECT
                                    }
                                    visible={filter.visible}
                                    disabled={filter.disabled}
                                    selected={filter.selected}
                                    onVisibilityClick={(id) => {
                                        toggleFilterVisibility(
                                            id,
                                            !filter.visible
                                        );
                                    }}
                                    onDeleteClick={(id) => {
                                        removeFilter(id);
                                    }}
                                    onEditClick={(id) => editFilter(id)}
                                    onViewTable={(id) =>
                                        viewFilterTable(
                                            id,
                                            filter.viewed || false
                                        )
                                    }
                                />
                            ))
                        )}
                    </Stack>
                </HorizontalScroller>
            </Grid>
            <Grid item>
                <Stack
                    alignItems="center"
                    gap={2}
                    width="100%"
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    {hasFilters && (
                        <Divider
                            orientation="vertical"
                            color={200}
                            sx={{
                                height: (theme) => theme.spacing(4),
                                marginBlockStart: isMobile
                                    ? undefined
                                    : "revert",
                                ...(isMobile && {
                                    display: "flex",
                                    alignSelf: "center",
                                }),
                            }}
                        />
                    )}
                    <Stack
                        alignItems="center"
                        gap={2}
                        flexDirection={isMobile ? "column-reverse" : "row"}
                    >
                        <Button
                            variant="ghost"
                            density="dense"
                            disabled={!hasFilters}
                            onClick={clearFilters}
                        >
                            Clear Filters
                        </Button>
                        <DashedChip
                            text="Add Filter"
                            onClick={onAddFilter}
                            disabled={isFilterGettingEdited}
                        />
                    </Stack>
                    {isSf && (
                        <Grid item flexShrink={0}>
                            <ExportContainer
                                responses={responses}
                                recentResponse={recentResponse}
                                viewport={viewport}
                                multipolygon={multipolygon}
                                multiFilters={multiFilters}
                            />
                        </Grid>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default MapTopbar;
