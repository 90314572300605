import {
    Checkbox,
    InfoCell,
    Slider,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Info } from "@biggeo/bg-ui/lab/icons";
import { IDatasetConfigurationView } from "./DatasetConfigurationView";

interface IDatasetConfigDetailsView
    extends Pick<
        IDatasetConfigurationView,
        "params" | "handleConfiguration" | "handleVisibility"
    > {
    readonly showLevelSets: boolean;
    readonly showPoints: boolean;
}

const DatasetConfigDetailsView = ({
    params,
    handleConfiguration,
    handleVisibility,
    showLevelSets,
    showPoints,
}: IDatasetConfigDetailsView) => {
    return (
        <Stack gap={4}>
            <Stack
                flexDirection="row"
                gap={1}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="title3" fontWeight="bold">
                    Points
                </Typography>
                <Tooltip
                    sx={{ maxWidth: 50 }}
                    title={
                        <Stack gap={2}>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Maximum Geometries
                                </Typography>
                                <Typography variant="body3">
                                    This parameter describes the maximum number
                                    of geometries before BG-Search returns an
                                    aggregate view for that cell.
                                </Typography>
                            </Stack>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Resolution Offset
                                </Typography>
                                <Typography variant="body3">
                                    This parameter helps control the resolution
                                    of the aggregated cells returned.
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                >
                    <span>
                        <Info color={"info"} size="sm" />
                    </span>
                </Tooltip>
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Maximum Geometries{" "}
                    {`(${params.points.maximumGeometries.value})`}
                </Typography>
                <Slider
                    color="surface"
                    rangeMin={1}
                    rangeMax={params.points.maximumGeometries.max}
                    value={params.points.maximumGeometries.value}
                    onDrag={(_e, v) => {
                        handleConfiguration({
                            pointMaxGeomsPerCell: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Resolution Offset{" "}
                    {`(${params.points.resolutionOffset.value})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={params.points.resolutionOffset.max}
                    value={params.points.resolutionOffset.value}
                    onDrag={(_e, v) => {
                        handleConfiguration({
                            pointResolutionOffset: v,
                        });
                    }}
                />
            </Stack>
            <Typography variant="title3" fontWeight="bold">
                Polygons
            </Typography>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Maximum Geometries{" "}
                    {`(${params.polygons.maximumGeometries.value})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={params.polygons.maximumGeometries.max}
                    value={params.polygons.maximumGeometries.value}
                    onDrag={(_e, v) => {
                        handleConfiguration({
                            polygonMaxGeomsPerCell: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Resolution Offset{" "}
                    {`(${params.polygons.resolutionOffset.value})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={params.polygons.resolutionOffset.max}
                    value={params.polygons.resolutionOffset.value}
                    onDrag={(_e, v) => {
                        handleConfiguration({
                            polygonResolutionOffset: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={4} sx={{ paddingTop: 2 }}>
                <InfoCell
                    title={"Show Level Sets"}
                    density="none"
                    startNode={
                        <Checkbox
                            checked={showLevelSets}
                            onClick={() => {
                                handleVisibility("levelSets", !showLevelSets);
                            }}
                        />
                    }
                />
                <InfoCell
                    title={"Show Points"}
                    density="none"
                    startNode={
                        <Checkbox
                            checked={showPoints}
                            onClick={() => {
                                handleVisibility("points", !showPoints);
                            }}
                        />
                    }
                />
            </Stack>
        </Stack>
    );
};

export default DatasetConfigDetailsView;
