import { LoadingBar } from "@biggeo/bg-ui/lab";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Consumers } from "../../common/redux/model";
import { getSavedViewIdFromParams } from "../../utils/utils";
import MapViewWrapper, { MapTabs } from "../map-wrappers/MapViewWrapper";
import OpenLayersWrapper from "../map-wrappers/OpenLayersWrapper";
import { useMapTemplateDatasets } from "../mapbox/context/hooks";

interface IFullmapContainer {
    readonly currentTab?: MapTabs;
    readonly isSavedAreaForm?: boolean;
}

const FullmapContainer = ({
    currentTab,
    isSavedAreaForm,
}: IFullmapContainer) => {
    const [consumption, setConsumption] = useState(Consumers.mapbox);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const { mapTemplateId } = useParams();
    const [searchParams] = useSearchParams();
    const paramSavedViewId = Number.parseInt(
        getSavedViewIdFromParams(searchParams)
    );
    const savedViewId = isEqual(paramSavedViewId, 0)
        ? undefined
        : paramSavedViewId;

    const { datasets, loading, error } = useMapTemplateDatasets(
        {
            fkMapTemplateId: Number(mapTemplateId),
        },
        { skip: !!savedViewId }
    );

    if (loading) {
        return <LoadingBar />;
    }

    if (error) {
        return <ErrorPage subtitle={error.message} />;
    }

    return (
        <>
            {consumption === Consumers.openLayers && (
                <OpenLayersWrapper
                    setConsumption={setConsumption}
                    activeConsumption={consumption}
                />
            )}
            {consumption === Consumers.mapbox && (
                <MapViewWrapper
                    currentTab={currentTab}
                    datasets={datasets}
                    setConsumption={setConsumption}
                    activeConsumption={consumption}
                    mapTemplateId={Number(mapTemplateId)}
                    isSavedAreaForm={isSavedAreaForm}
                    isExporting={isExporting}
                    setIsExporting={setIsExporting}
                />
            )}
        </>
    );
};

export default FullmapContainer;
