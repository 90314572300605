import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import startCase from "lodash/startCase";
import { useState } from "react";
import { match } from "ts-pattern";
import MapConfigurationSideNav from "../mapbox/views/MapConfigurationSideNav";
import MapAvailableAreasConfig from "./MapAvailableAreasConfig";
import MapTemplateDatasets from "./MapTemplateDatasets";

export enum MapConfigurationTabs {
    datasets = "datasets",
    availableBoundaries = "availableBoundaries",
    // TODO: talked to Jose and he said it's not needed yet
    // configuration = "configuration",
}

export interface IMapConfiguration {
    mapTemplateId: number;
    toPage: (path: string) => void;
    isSavedAreaForm?: boolean;
}

const MapConfiguration = ({
    mapTemplateId,
    toPage,
    isSavedAreaForm,
}: Readonly<IMapConfiguration>) => {
    const tabValue = isSavedAreaForm
        ? MapConfigurationTabs.availableBoundaries
        : MapConfigurationTabs.datasets;
    const [tab, setTab] = useState<MapConfigurationTabs>(tabValue);

    return (
        <DoubleColumnLayout
            slotProps={{
                left: {
                    sx: {
                        overflow: "auto",
                        backgroundColor: (theme) =>
                            theme.palette.background.container,
                        borderRight: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                        width: "100%",
                        breakpoints: {
                            cmd: {
                                width: 58.5,
                            },
                        },
                    },
                },
                main: {
                    sx: {
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        padding: 4,
                        gap: 4,
                    },
                },
            }}
            leftHeader={startCase(tab)}
            left={
                <MapConfigurationSideNav
                    currentTab={tab}
                    onChange={(t) => setTab(t)}
                />
            }
        >
            {match(tab)
                .with(MapConfigurationTabs.datasets, () => (
                    <MapTemplateDatasets
                        mapTemplateId={mapTemplateId}
                        toPage={toPage}
                    />
                ))
                .with(MapConfigurationTabs.availableBoundaries, () => (
                    <MapAvailableAreasConfig
                        mapTemplateId={mapTemplateId}
                        toPage={toPage}
                        isSavedAreaForm={isSavedAreaForm}
                    />
                ))
                .exhaustive()}
        </DoubleColumnLayout>
    );
};

export default MapConfiguration;
