import { Dialog } from "@biggeo/bg-ui";
import { useBelowBreakpoint } from "@biggeo/bg-ui/lab/system";

interface IDialogModal {
    readonly open: boolean;
    readonly closeModal: () => void;
    readonly children?: JSX.Element;
}

const DialogModal = ({ open, closeModal, children }: IDialogModal) => {
    const fullScreen = useBelowBreakpoint("md");

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            fullScreen={fullScreen}
            sx={{
                height: "100%",
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                },
                "& .MuiDialog-container": {
                    alignItems: { xs: "flex-end", md: "center" },
                },
                "& .MuiPaper-root": {
                    display: "flex",
                    backgroundColor: (theme) => theme.palette.background.main,
                    color: (theme) => theme.palette.background.onMain,
                    borderRadius: (theme) => ({
                        xs: theme.spacing(2, 2, 0, 0),
                        md: 2,
                    }),
                    height: { xs: "98%", md: "80%" },
                    width: "100%",
                    maxWidth: {
                        xs: "100%",
                        md: "80%",
                    },
                },
            }}
        >
            {children}
        </Dialog>
    );
};

export default DialogModal;
