import {
    CompanyAvatar,
    Grid,
    IconAvatar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CheckCircle } from "@biggeo/bg-ui/lab/icons";

export const MarketplaceDatasetCardHeader = ({
    datasetId,
    bgIndex,
    datasetName,
    datasetPrice,
    url,
    hover,
}: {
    readonly datasetId: string;
    readonly bgIndex: boolean;
    readonly datasetName: string;
    readonly datasetPrice: string;
    readonly url?: string;
    readonly hover: boolean;
}) => {
    return (
        <Grid container gap={2} alignItems="center">
            <IconAvatar size="sm" color="surface" square>
                <CompanyAvatar
                    square
                    size="xs"
                    src={url}
                    alt={datasetId.toString()}
                />
            </IconAvatar>

            <Grid item xs minWidth={0}>
                <Typography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{
                        color: (theme) => theme.palette.background.onMain,
                    }}
                    align="left"
                    truncate
                >
                    {datasetName}
                </Typography>
            </Grid>
            <Grid item>
                <Stack gap={1}>
                    {/* * @UiDiscrepancy */}
                    <Typography
                        variant="body4"
                        fontWeight="semibold"
                        sx={{
                            color: (theme) => theme.palette.background.onMain,
                        }}
                        align="right"
                    >
                        ${datasetPrice}/Mo
                    </Typography>
                    {bgIndex && hover && (
                        <Grid container alignItems="center">
                            <Grid item>
                                {/* * @UiDiscrepancy */}
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                >
                                    BigGeo indexed
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CheckCircle size="xxs" color="success" />
                            </Grid>
                        </Grid>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};
