import {
    AutoComplete,
    Button,
    CompanyAvatar,
    Divider,
    Grid,
    Radio,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CompareArrowsOutline, SyncOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import split from "lodash/split";
import React from "react";
import { Link } from "react-router-dom";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { Routes } from "../../navigation/redux/model";

export type ManageDatasetMappedColumnsProps = {
    readonly onLearnMore: () => void;
    readonly onRefresh: () => void;
    readonly geospatialColumns: string;
    readonly tableId: string;
    readonly displayImage?: string | null;
};

export const ManageDatasetMappedColumns = ({
    onRefresh,
    geospatialColumns,
    tableId,
    displayImage,
}: ManageDatasetMappedColumnsProps) => {
    const isRunningOnSF = isAppRunningOnSF();
    const mappedColumns: React.ComponentProps<typeof TitleStack>[] = [
        {
            title: "Select Primary Key",
            subtitle: "Specify primary key column for your dataset here",
        },
        {
            title: "Choose Number Of Geospatial Columns",
            subtitle: "How many geospatial columns does your dataset have?",
        },
        {
            title: "Map Your Geospatial Columns",
            subtitle:
                "Select the columns from your dataset that represent their geospatial attribute",
        },
    ];

    return (
        <Stack
            width="100%"
            sx={{
                backgroundColor: (theme) => theme.palette.surface.container,
                padding: 8,
                gap: 4,
                borderRadius: (theme) => theme.radius.xs2,
            }}
        >
            <Stack gap={4} alignItems="flex-start">
                <Stack
                    flexDirection={"row"}
                    gap={1}
                    alignItems="center"
                    sx={{
                        padding: 2,
                        backgroundColor: (theme) =>
                            theme.palette.surface.onMain,
                        color: (theme) => theme.palette.disabled.main,
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    <ThumbnailAvatar
                        color="background"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        src={
                            displayImage ||
                            "https://biggeo.blob.core.windows.net/media/sf.png"
                        }
                        size="md"
                    />

                    <CompareArrowsOutline color={"disabled"} />
                    <CompanyAvatar
                        color="primary"
                        square
                        src="https://biggeo.blob.core.windows.net/media/bg-background.png"
                        size="md"
                    />
                </Stack>
                <Stack>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{
                            color: (theme) => theme.palette.background.onMain,
                        }}
                    >
                        Map Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        We need you to select your geospatial columns.
                    </Typography>
                </Stack>
                <Stack gap={4} width="100%">
                    <Link to={Routes.learnMoreDataManagement} target={"_blank"}>
                        <Button
                            variant="minimal"
                            sx={{
                                color: (theme) => theme.palette.info.main,
                            }}
                        >
                            Learn More
                        </Button>
                    </Link>
                </Stack>
                <Stack gap={4} width="100%">
                    <Divider color={200} />
                    <TitleStack
                        title="Want to change your column mapping configuration?"
                        subtitle="Refresh the dataset to overwrite current column mapping"
                    />
                    <Button
                        variant="outlined"
                        endNode={<SyncOutline size="xs" />}
                        onClick={onRefresh}
                        disabled={!isRunningOnSF}
                    >
                        Refresh
                    </Button>
                </Stack>
                {pipe(
                    mappedColumns,
                    A.mapWithIndex((index, mp) => (
                        <Stack gap={4} key={kebabCase(mp.title)} width="100%">
                            <Stack>
                                <Typography
                                    variant="title2"
                                    fontWeight="bold"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.background.onMain,
                                    }}
                                >
                                    {index + 1}. {mp.title}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    fontWeight="regular"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                >
                                    {mp.subtitle}
                                </Typography>
                            </Stack>

                            {index === 0 && (
                                <AutoComplete
                                    value={tableId}
                                    required
                                    label="Primary key"
                                    placeholder="ID"
                                    fullWidth
                                    disabled
                                />
                            )}

                            {index === 1 &&
                                Array.from({ length: 2 }, (_, i) => (
                                    <Grid
                                        // biome-ignore lint/suspicious/noArrayIndexKey: allow index
                                        key={`index-${i}`}
                                        container
                                        gap={1}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Radio
                                                name="geospatial-columns"
                                                value={2}
                                                checked={isEqual(
                                                    pipe(
                                                        split(
                                                            geospatialColumns,
                                                            ","
                                                        ),
                                                        A.size
                                                    ),
                                                    i + 1
                                                )}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body3"
                                                textColor="disabled.onContainer"
                                                fontWeight="semibold"
                                            >
                                                {`${i + 1} Column`}
                                                {i + 1 === 2 ? "s" : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}

                            {index === 2 &&
                                pipe(
                                    split(geospatialColumns, ","),
                                    E.fromPredicate(
                                        (x) => x.length === 2,
                                        ([x]) => x
                                    ),
                                    E.fold(
                                        (data) => (
                                            <AutoComplete
                                                value={data}
                                                required
                                                label="Geospatial Column"
                                                placeholder="Select"
                                                fullWidth
                                                disabled
                                            />
                                        ),
                                        ([LAT, LON]) => (
                                            <Stack>
                                                <Grid
                                                    container
                                                    gap={4}
                                                    sx={{
                                                        breakpoints: {
                                                            md: {
                                                                flexDirection:
                                                                    "row",
                                                            },
                                                            xs: {
                                                                flexDirection:
                                                                    "column",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Grid item md>
                                                        <AutoComplete
                                                            value={LAT}
                                                            required
                                                            label="Latitude"
                                                            placeholder="Search latitude"
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item md>
                                                        <AutoComplete
                                                            value={LON}
                                                            required
                                                            label="Longitude"
                                                            placeholder="Search longitude"
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        )
                                    )
                                )}
                            <Divider color={200} />
                        </Stack>
                    ))
                )}
            </Stack>
        </Stack>
    );
};

const TitleStack = ({
    title,
    subtitle,
}: { readonly title: string; readonly subtitle: string }) => {
    return (
        <Stack>
            <Typography variant="title2" fontWeight="bold">
                {title}
            </Typography>
            <Typography
                variant="body3"
                color="disabled"
                colorSet="container"
                invertColors
            >
                {subtitle}
            </Typography>
        </Stack>
    );
};
