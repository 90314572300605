import {
    LogicOperator,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    Dropdown,
    FilterType,
    GhostTextField,
    Grid,
    IconButton,
    LoadingBar,
    MenuItem,
    OverflowingTypography,
    Stack,
    Typography,
    WhereOperator,
    buttonClasses,
    iconButtonClasses,
    useDebouncedSearch,
} from "@biggeo/bg-ui/lab";
import { AddOutline, DeleteOutline } from "@biggeo/bg-ui/lab/icons";
import { match } from "@vividtheory/remotedata";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import every from "lodash/every";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import replace from "lodash/replace";
import startCase from "lodash/startCase";
import { BigGeoLink } from "../../../common/components/BigGeoLink";
import { ErrorPage } from "../../../common/components/ErrorPage";
import { SplitButton } from "../../../common/components/SplitButton";
import { useMap } from "../../mapbox/context";
import {
    MapFilterCriteriaDataset,
    MapFilterCriteriaDatasetItem,
    getFilterCriteriaOperators,
    mapColumnType,
    mapFilterCriteriaSign,
} from "../utils/utils";
import { IFilterCriteriaDatasets } from "../views/FilterCriteriaDatasets";

interface IFilterCriteriaDatasetItem
    extends Pick<
        IFilterCriteriaDatasets,
        | "onAddDatasetFilter"
        | "clearAll"
        | "deleteDatasetFilterItem"
        | "removeDataset"
        | "updateDatasetFilterItem"
    > {
    readonly item: MapFilterCriteriaDataset;
    readonly logicOperator: LogicOperator;
    readonly onAddDatasetFilter: () => void;
}

const FilterCriteriaDatasetItem = ({
    item,
    logicOperator,
    onAddDatasetFilter,
    deleteDatasetFilterItem,
    clearAll,
    removeDataset,
    updateDatasetFilterItem,
}: IFilterCriteriaDatasetItem) => {
    const { datasets } = useMap();
    const dataset = datasets.find((d) => d.dataSource.id === item.dataSourceId);
    const dggsIndexName = pipe(
        dataset?.dataSource.geographyColumn,
        O.fromNullable,
        O.fold(
            () => "DGGS_INDEX",
            (col) => `DGGS_INDEX_${replace(col, /[^\w\s]/gi, "_")}`
        )
    );

    const debounceSearch = useDebouncedSearch(500);
    const areFiltersEmpty =
        isEmpty(item.filters) ||
        every(item.filters, (value) =>
            every(value, (v) => isNil(v) || isEmpty(v))
        );

    const { remote } = useFetchSnowflakeTableSchemaQuery({
        variables: {
            databaseId: item.dataSourceId,
        },
    });

    const update = (i: {
        index: number;
        logicOperator?: LogicOperator;
        item: Partial<MapFilterCriteriaDatasetItem>;
    }) => {
        updateDatasetFilterItem({
            mapTemplateDatasetId: item.mapTemplateDatasetId,
            ...i,
        });
    };

    const handleValue = (i: {
        index: number;
        value?: string;
    }) => {
        debounceSearch(
            (v) =>
                update({
                    ...i,
                    item: {
                        value: v,
                    },
                }),
            i.value
        );
    };

    const formatSFColumnName = (column: string) => column.replace(" ", "_");

    return (
        <Stack gap={4} width="100%">
            <SplitButton
                text={item.label}
                onCloseClick={() => removeDataset(item.mapTemplateDatasetId)}
            />
            {match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    const columns = pipe(
                        data.fetchSnowflakeTableSchema.rows,
                        A.map((d) => ({
                            name: startCase(d.columnName),
                            type: d.dataType,
                        }))
                    );
                    const hasDggsColumn =
                        data.fetchSnowflakeTableSchema.rows.some(
                            (d) => d.columnName === dggsIndexName
                        );

                    if (!hasDggsColumn) {
                        return (
                            <Stack width="100%" gap={2} sx={{ p: 2 }}>
                                <Typography variant="body3">
                                    Non-geospatial filtering has not been
                                    enabled for this dataset, please review the
                                    documentation below to enable it
                                </Typography>
                                <BigGeoLink
                                    target="_blank"
                                    href={
                                        "https://docs.google.com/document/d/16zUnfusO1ebGZbgX3t7NoO9RH_-n55XaygDQFdhCRto/edit?pli=1#heading=h.wjg0bn75k8wn"
                                    }
                                >
                                    <Button>Documentation</Button>
                                </BigGeoLink>
                            </Stack>
                        );
                    }
                    return (
                        <>
                            <Stack width="100%" gap={2}>
                                {pipe(
                                    item.filters,
                                    A.mapWithIndex((index, filter) => {
                                        const showValue =
                                            !isEqual(
                                                filter.operator,
                                                WhereOperator.isEmpty
                                            ) &&
                                            !isEqual(
                                                filter.operator,
                                                WhereOperator.isNotEmpty
                                            );

                                        const operators = filter.type
                                            ? getFilterCriteriaOperators(
                                                  filter.type
                                              )
                                            : Object.values(WhereOperator);

                                        return (
                                            <Grid
                                                container
                                                key={filter.column}
                                                alignItems="center"
                                                spacing={2}
                                                width={"100%"}
                                            >
                                                <Grid item xs={2.5}>
                                                    {item.filters.length >= 2 &&
                                                        index !== 0 && (
                                                            <Stack alignItems="end">
                                                                <Dropdown
                                                                    placement="bottom-end"
                                                                    slotProps={{
                                                                        button: {
                                                                            fullWidth: true,
                                                                            disabled:
                                                                                index >
                                                                                    1 &&
                                                                                !!logicOperator,
                                                                            variant:
                                                                                "outlined",
                                                                            sx: {
                                                                                [`&.${buttonClasses.outlined}`]:
                                                                                    {
                                                                                        paddingY: 2.2,
                                                                                    },
                                                                            },
                                                                        },
                                                                    }}
                                                                    label={
                                                                        <OverflowingTypography
                                                                            variant="button"
                                                                            fontWeight="semibold"
                                                                            sx={{
                                                                                width: (
                                                                                    theme
                                                                                ) =>
                                                                                    theme.spacing(
                                                                                        7
                                                                                    ),
                                                                            }}
                                                                        >
                                                                            {startCase(
                                                                                logicOperator
                                                                            )}
                                                                        </OverflowingTypography>
                                                                    }
                                                                    content={pipe(
                                                                        Object.values(
                                                                            LogicOperator
                                                                        ),
                                                                        A.map(
                                                                            (
                                                                                operator
                                                                            ) => (
                                                                                <MenuItem
                                                                                    key={
                                                                                        operator
                                                                                    }
                                                                                    label={startCase(
                                                                                        operator
                                                                                    )}
                                                                                    density="dense"
                                                                                    value={
                                                                                        logicOperator
                                                                                    }
                                                                                    onClick={() =>
                                                                                        update(
                                                                                            {
                                                                                                index,
                                                                                                logicOperator:
                                                                                                    operator,
                                                                                                item: {},
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )
                                                                        )
                                                                    )}
                                                                />
                                                            </Stack>
                                                        )}
                                                </Grid>
                                                <Grid item xs={9.5}>
                                                    <Grid
                                                        container
                                                        width={"100%"}
                                                        flexWrap="nowrap"
                                                        columnGap={2}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            md={3}
                                                        >
                                                            <Dropdown
                                                                placement="bottom-end"
                                                                slotProps={{
                                                                    button: {
                                                                        fullWidth: true,
                                                                        variant:
                                                                            "outlined",
                                                                        sx: {
                                                                            [`&.${buttonClasses.outlined}`]:
                                                                                {
                                                                                    paddingY: 2.2,
                                                                                },
                                                                        },
                                                                    },
                                                                }}
                                                                label={
                                                                    <OverflowingTypography
                                                                        variant="button"
                                                                        fontWeight="semibold"
                                                                    >
                                                                        {filter.column ||
                                                                            "Column"}
                                                                    </OverflowingTypography>
                                                                }
                                                                content={
                                                                    <Stack
                                                                        gap={1}
                                                                        sx={{
                                                                            maxHeight:
                                                                                (
                                                                                    theme
                                                                                ) =>
                                                                                    theme.spacing(
                                                                                        95
                                                                                    ),
                                                                        }}
                                                                    >
                                                                        {pipe(
                                                                            columns,
                                                                            A.map(
                                                                                (
                                                                                    column
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            column.name
                                                                                        }
                                                                                        label={
                                                                                            column.name
                                                                                        }
                                                                                        density="dense"
                                                                                        value={
                                                                                            filter.column ||
                                                                                            first(
                                                                                                columns
                                                                                            )
                                                                                                ?.name
                                                                                        }
                                                                                        onClick={() =>
                                                                                            update(
                                                                                                {
                                                                                                    index,
                                                                                                    item: {
                                                                                                        column: formatSFColumnName(
                                                                                                            column.name
                                                                                                        ),
                                                                                                        type: mapColumnType(
                                                                                                            column.type
                                                                                                        ),
                                                                                                        operator:
                                                                                                            undefined,
                                                                                                        value: undefined,
                                                                                                    },
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            md={
                                                                showValue
                                                                    ? 3.5
                                                                    : 7
                                                            }
                                                        >
                                                            <Dropdown
                                                                placement="bottom-end"
                                                                slotProps={{
                                                                    button: {
                                                                        fullWidth: true,
                                                                        density:
                                                                            "dense",
                                                                        variant:
                                                                            "outlined",
                                                                        sx: {
                                                                            [`&.${buttonClasses.outlined}`]:
                                                                                {
                                                                                    paddingY: 2.2,
                                                                                },
                                                                        },
                                                                    },
                                                                }}
                                                                label={
                                                                    <OverflowingTypography
                                                                        variant="button"
                                                                        fontWeight="semibold"
                                                                        sx={{
                                                                            minWidth:
                                                                                (
                                                                                    theme
                                                                                ) =>
                                                                                    theme.spacing(
                                                                                        9
                                                                                    ),
                                                                        }}
                                                                    >
                                                                        {filter.operator
                                                                            ? mapFilterCriteriaSign(
                                                                                  filter.operator
                                                                              )
                                                                            : "Operator"}
                                                                    </OverflowingTypography>
                                                                }
                                                                content={
                                                                    <Stack
                                                                        gap={1}
                                                                        sx={{
                                                                            maxHeight:
                                                                                (
                                                                                    theme
                                                                                ) =>
                                                                                    theme.spacing(
                                                                                        95
                                                                                    ),
                                                                        }}
                                                                    >
                                                                        {pipe(
                                                                            operators,
                                                                            A.map(
                                                                                (
                                                                                    operator
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            operator
                                                                                        }
                                                                                        label={mapFilterCriteriaSign(
                                                                                            operator
                                                                                        )}
                                                                                        density="dense"
                                                                                        value={mapFilterCriteriaSign(
                                                                                            filter.operator ||
                                                                                                WhereOperator.eq
                                                                                        )}
                                                                                        onClick={() =>
                                                                                            update(
                                                                                                {
                                                                                                    index,
                                                                                                    item: {
                                                                                                        operator,
                                                                                                    },
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                }
                                                            />
                                                        </Grid>

                                                        {showValue && (
                                                            <Grid item xs>
                                                                <GhostTextField
                                                                    defaultValue={filter.value?.toString()}
                                                                    placeholder="Enter value"
                                                                    fullWidth
                                                                    outlined
                                                                    type={
                                                                        isEqual(
                                                                            filter.type,
                                                                            FilterType.NumberType
                                                                        )
                                                                            ? "number"
                                                                            : undefined
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        v
                                                                    ) =>
                                                                        handleValue(
                                                                            {
                                                                                index,
                                                                                value: v,
                                                                            }
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        ...(isEqual(
                                                                            filter.type,
                                                                            FilterType.NumberType
                                                                        ) && {
                                                                            "input::-webkit-inner-spin-button":
                                                                                {
                                                                                    "-webkit-appearance":
                                                                                        "none",
                                                                                },
                                                                            "input[type=number]":
                                                                                {
                                                                                    "-moz-appearance":
                                                                                        "textfield",
                                                                                },
                                                                        }),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            item
                                                            sx={{
                                                                display: "flex",
                                                                visibility:
                                                                    index === 0
                                                                        ? "hidden"
                                                                        : "visible",
                                                            }}
                                                        >
                                                            <IconButton
                                                                variant="outlined"
                                                                onClick={() =>
                                                                    deleteDatasetFilterItem(
                                                                        {
                                                                            mapTemplateDatasetId:
                                                                                item.mapTemplateDatasetId,
                                                                            index,
                                                                        }
                                                                    )
                                                                }
                                                                sx={{
                                                                    [`&.${iconButtonClasses.outlined}`]:
                                                                        {
                                                                            width: (
                                                                                theme
                                                                            ) =>
                                                                                theme.spacing(
                                                                                    9.5
                                                                                ),
                                                                            height: (
                                                                                theme
                                                                            ) =>
                                                                                theme.spacing(
                                                                                    9.5
                                                                                ),
                                                                        },
                                                                }}
                                                            >
                                                                <DeleteOutline size="sm" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                )}
                            </Stack>
                            <Stack
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    variant="ghost"
                                    color="primary"
                                    startNode={<AddOutline size="xs" />}
                                    onClick={onAddDatasetFilter}
                                >
                                    Add Filter
                                </Button>
                                <Button
                                    variant="ghost"
                                    color="error"
                                    disableFocusEffect
                                    disabled={areFiltersEmpty}
                                    onClick={() =>
                                        clearAll(item.mapTemplateDatasetId)
                                    }
                                >
                                    Clear All
                                </Button>
                            </Stack>
                        </>
                    );
                },
                Failure: (err) => <ErrorPage subtitle={err.message} />,
            })}
        </Stack>
    );
};

export default FilterCriteriaDatasetItem;
