import {
    FilterObject,
    InputRadius,
    ReqOptions,
} from "@biggeo/bg-server-lib/datascape-ai";
import Feature from "ol/Feature";
import Map from "ol/Map";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { Geometry } from "ol/geom";
import { createRegularPolygon } from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import { toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import { useEffect } from "react";
import { FunctionType, matchFunctionType } from "../../utils/utils";
import { createCircleFeature } from "../utils/radius";
import { getLayerGroup as initGetLayerGroup } from "../utils/utils";

export const useRadius = ({
    functionType,
    map,
    multiFilters,
    handleRadiusChange,
    radius,
    options,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<Map | null>;
    multiFilters: FilterObject[];
    handleRadiusChange: ({ radius }: { radius: Partial<InputRadius> }) => void;
    radius: InputRadius;
    options: ReqOptions;
}) => {
    const getLayerGroup = initGetLayerGroup(map.current);

    const drawCircle = (event: MapBrowserEvent<MouseEvent>) => {
        const coordinates = event.coordinate;
        const layerGroup =
            getLayerGroup<VectorLayer<VectorSource<Feature<Geometry>>>>(
                "radius"
            );

        // Create a circle feature
        const circleFeature = new Feature(createRegularPolygon());
        circleFeature.setStyle(
            new Style({
                fill: new Fill({
                    color: "rgba(255, 0, 0, 0.2)",
                }),
                stroke: new Stroke({
                    color: "red",
                    width: 2,
                }),
            })
        );
    };
    useEffect(() => {
        const handleClick = (e: MapBrowserEvent<MouseEvent>) => {
            const coordinate = toLonLat(e.coordinate);
            matchFunctionType(functionType, {
                radius: () => {
                    handleRadiusChange({
                        radius: {
                            ...radius,
                            center: {
                                latitude: coordinate[1] || 0,
                                longitude: coordinate[0] || 0,
                            },
                        },
                    });
                },
            });
        };

        // Add double-click event listener to the map

        const current = map.current;
        if (current) {
            current.on("dblclick", handleClick);

            if (functionType !== FunctionType.radius) {
                const layer =
                    getLayerGroup<VectorLayer<VectorSource<Feature<Geometry>>>>(
                        "radius"
                    );
                layer?.getSource()?.clear();
            }
        }

        // Cleanup function to remove event listener when the component is unmounted
        return () => {
            map.current?.un("dblclick", handleClick);
        };
    }, [map.current, functionType, radius, multiFilters, options]);

    useEffect(() => {
        updateCircleLayer(radius);
    }, [radius]);

    const updateCircleLayer = (radius: InputRadius) => {
        const circleFeature = createCircleFeature(
            [radius.center.longitude, radius.center.latitude],
            radius.radiusKm
        );

        const radiusLayer = getLayerGroup("radius") as VectorLayer<
            VectorSource<Feature<Geometry>>
        >;

        radiusLayer?.getSource()?.clear(); // Remove previous circles
        radiusLayer?.getSource()?.addFeature(circleFeature);
    };

    const onStyleLoad = (map: Map) => {
        const vectorSource = new VectorSource();

        const layer = new VectorLayer({
            source: vectorSource,
            properties: { id: `radius` },
            style: new Style({
                fill: new Fill({
                    color: "rgba(0, 0, 255, 0.2)", // Customize the circle's style
                }),
                stroke: new Stroke({
                    color: "blue",
                    width: 2,
                }),
            }),
        });

        map.addLayer(layer);
    };
    return { onStyleLoad };
};
