import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

export const getPreviousAndNextElements = <T>(
    element: T,
    array?: ReadonlyArray<T>
): {
    readonly previousElement?: T;
    readonly nextElement?: T;
} => {
    if (!array || isEmpty(array)) {
        return { previousElement: undefined, nextElement: undefined };
    }

    const index = array.indexOf(element);
    const isFirstElement = isEqual(index, 0);
    const isLastElement = isEqual(index, array.length - 1);

    const previousIndex = (array.length + index - 1) % array.length;
    const previousElement = array[previousIndex];

    const nextIndex = (index + 1) % array.length;
    const nextElement = array[nextIndex];

    return {
        previousElement: isFirstElement ? undefined : previousElement,
        nextElement: isLastElement ? undefined : nextElement,
    };
};
