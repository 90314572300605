import { Button, Stack, Tooltip, TooltipProps } from "@biggeo/bg-ui/lab";
import { ArrowUpwardOutline } from "@biggeo/bg-ui/lab/icons";
import { Link } from "react-router-dom";

export type FeedbackTooltipProps = React.PropsWithChildren<{
    feature: string;
    prioritizeRoute: string;
    learnMoreRoute: string;
    span?: boolean;
}> &
    Pick<TooltipProps, "placement" | "sx" | "disabled" | "slotProps">;

export const FeedbackTooltip = ({
    children,

    feature,
    prioritizeRoute,
    learnMoreRoute,
    span = false,

    placement = "right-start",
    ...props
}: FeedbackTooltipProps) => {
    return (
        <Tooltip
            placement={placement}
            title={`${feature} feature coming soon.`}
            bodyNode={
                <Stack gap={1}>
                    <Link to={prioritizeRoute} target={"_blank"}>
                        <Button
                            fullWidth
                            variant="filled"
                            color="primary"
                            endNode={<ArrowUpwardOutline size="xxs" />}
                        >
                            Prioritize
                        </Button>
                    </Link>
                    <Link to={learnMoreRoute} target={"_blank"}>
                        <Button fullWidth variant="outlined">
                            Learn More
                        </Button>
                    </Link>
                </Stack>
            }
            {...props}
            slotProps={{
                ...props.slotProps,
                title: {
                    fontWeight: "regular",
                    variant: "body4",
                    ...props.slotProps?.title,
                },
            }}
            sx={{
                maxWidth: (theme) => theme.spacing(30),
                ...props.sx,
            }}
        >
            {/* Note: Wrapping it in a span is necessary if the children are a MenuItem, 
            otherwise the tooltip won't show. It's a forward ref issue. */}
            {span ? <span>{children}</span> : children}
        </Tooltip>
    );
};
