import {
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    CancelOutline,
    CheckCircleOutline,
    ContentCopyOutline,
    KeyOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { BorderedStack } from "./BorderedStack";

export type ViewCopyApiKeyPanelProps = {
    readonly apiKey: string;
};

export const ViewCopyApiKeyPanel = ({ apiKey }: ViewCopyApiKeyPanelProps) => {
    const [copied, setCopied] = useState<undefined | "success" | "error">(
        undefined
    );
    const [show, setShow] = useState(false);

    const handleCopy = () => {
        navigator.clipboard?.writeText(apiKey).then(
            () => setCopied("success"),
            () => setCopied("error")
        );
        setTimeout(() => setCopied(undefined), 2000);
    };
    const handleShowHide = () => setShow(!show);

    return (
        <BorderedStack width="100%" gap={2}>
            <Grid container gap={2} justifyContent="space-between">
                <KeyOutline size="xs" />
                <Grid item>
                    <Grid
                        container
                        gap={2}
                        alignItems="center"
                        sx={{ position: "relative" }}
                    >
                        <IconButton
                            variant="minimal"
                            onClick={handleShowHide}
                            sx={{ position: "relative" }}
                        >
                            <VisibilityOutline
                                size="xs"
                                sx={{
                                    opacity: show ? 1 : 0,
                                    transition: "opacity 0.5s ease",
                                }}
                            />
                            <VisibilityOffOutline
                                size="xs"
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    opacity: show ? 0 : 1,
                                    transition: "opacity 0.5s ease",
                                }}
                            />
                        </IconButton>
                        <Divider orientation="vertical" />
                        <Button
                            variant="minimal"
                            onClick={handleCopy}
                            sx={{
                                opacity: copied === undefined ? 1 : 0,
                                transition: "opacity 0.5s ease",
                            }}
                            endNode={<ContentCopyOutline size="xs" />}
                        >
                            Copy
                        </Button>
                        <Button
                            variant="minimal"
                            color="success"
                            onClick={handleCopy}
                            readonly
                            sx={{
                                opacity: copied === "success" ? 1 : 0,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                transition: "opacity 0.5s ease",
                            }}
                            endNode={<CheckCircleOutline size="xs" />}
                        >
                            Copy
                        </Button>
                        <Button
                            variant="minimal"
                            color="error"
                            onClick={handleCopy}
                            readonly
                            sx={{
                                opacity: copied === "error" ? 1 : 0,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                transition: "opacity 0.5s ease",
                            }}
                            endNode={<CancelOutline size="xs" />}
                        >
                            Error
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container gap={2} sx={{ position: "relative" }}>
                <Typography
                    sx={{
                        fontFamily: "IBM Plex Mono",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: 1.43,
                        opacity: !show ? 1 : 0,
                        transition: "opacity 0.3s ease",
                    }}
                >
                    ●●●●●-●●●●●-●●●●●-●●●●●-●●●●●
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "IBM Plex Mono",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: 1.43,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        opacity: show ? 1 : 0,
                        transition: "opacity 0.3s ease",
                    }}
                >
                    {apiKey}
                </Typography>
            </Grid>
        </BorderedStack>
    );
};
