import {
    FetchSnowflakeDataQuery,
    FetchSnowflakeTableSchemaQuery,
    InputPolygon,
    InputViewBox,
    useFetchSnowflakeDataQuery,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    LoadingBar,
    LogicOperator,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import { map2, match } from "@vividtheory/remotedata";
import { useState } from "react";
import { ErrorPage } from "../../../common/components/ErrorPage";
import {
    FILTERED_DATA_TABLE_LIMIT,
    MapFilterCriteriaDataset,
    mapfilterCriteriaToMultifilter,
} from "../utils/utils";
import FilterCriteriaDatasetTableView from "../views/FilterCriteriaDatasetTableView";
import { ViewDatasetTableType } from "./ViewDatasetTableContainer";

interface IViewDatasetTable {
    readonly filterCriteria: ViewDatasetTableType;
    readonly channelId: string;
    readonly type: "viewTable";
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    readonly isRunningOnSF: boolean;
}

interface IFilterCriteriaDatasetTableContainer {
    readonly filterCriteria: MapFilterCriteriaDataset;
    readonly channelId: string;
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    readonly type: "filterCriteria";
    readonly isRunningOnSF: boolean;
}

type ViewTableContainer =
    | IViewDatasetTable
    | IFilterCriteriaDatasetTableContainer;

const FilterCriteriaDatasetTableContainer = ({
    channelId,
    isRunningOnSF,
    ...props
}: ViewTableContainer) => {
    const filter =
        props.type === "filterCriteria"
            ? mapfilterCriteriaToMultifilter(
                  props.filterCriteria,
                  isRunningOnSF
              )
            : {
                  filters: [],
                  databaseId: props.filterCriteria.dataSourceId,
                  logicOperator: LogicOperator.and,
                  collection: props.filterCriteria.collection,
                  databaseType: props.filterCriteria.type,
              };

    const [date] = useState(Date.now().toString());
    const {
        dataGridFetchInputProps: filteredDataGridProps,
        filterSearchPaginateProps: filteredDataPaginationProps,
    } = useDataGridOptions(FILTERED_DATA_TABLE_LIMIT);

    const {
        remote: columnsRD,
        queryReturn: { loading: cLoading },
    } = useFetchSnowflakeTableSchemaQuery({
        variables: {
            databaseId: props.filterCriteria.dataSourceId,
        },
    });

    const {
        remote: dataRD,
        queryReturn: { loading: dLoading },
    } = useFetchSnowflakeDataQuery({
        variables: {
            filters: {
                filters: {
                    filters: filter.filters,
                    databaseId: filter.databaseId,
                    logicOperator: filter.logicOperator,
                    collection: filter.collection,
                    databaseType: filter.databaseType,
                },
                limitOffset: {
                    limit: filteredDataGridProps.pageLimit,
                    offset:
                        filteredDataGridProps.pageLimit *
                        (filteredDataGridProps.pageIndex - 1),
                },
                dateTime: date,
                channelId,
                ...props.geospatialSelection,
            },
        },
    });

    const remote = map2(
        (c: FetchSnowflakeTableSchemaQuery) =>
            (d: FetchSnowflakeDataQuery) => ({
                columns: c.fetchSnowflakeTableSchema.rows,
                data: d.fetchSnowflakeData,
            }),
        columnsRD,
        dataRD
    );

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (data) => {
            return (
                <FilterCriteriaDatasetTableView
                    {...data}
                    title={
                        props.filterCriteria.label ||
                        props.filterCriteria.collection
                    }
                    loading={cLoading || dLoading}
                    filteredDataPaginationProps={filteredDataPaginationProps}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};

export default FilterCriteriaDatasetTableContainer;
