import { FileNameType } from "@biggeo/bg-server-lib/datascape-ai";
import {
    AspectRatio,
    Grid,
    Image,
    Stack,
    Typography,
    useTheme,
} from "@biggeo/bg-ui/lab";

import { BIG_GEO_SUPPORT_EMAIL } from "@biggeo/bg-utils";
import { isAppRunningOnSF } from "../redux/hooks";
import { DownloadButton } from "./DownloadButton";

export const ErrorPage = ({
    title,
    subtitle,
    action,
}: {
    readonly title?: string;
    readonly subtitle?: string;
    readonly action?: React.ReactNode;
}) => {
    const isRunningOnSF = isAppRunningOnSF();
    const theme = useTheme();

    return (
        <Grid container height="100%" sx={{ position: "relative" }}>
            <Stack
                gap={4}
                sx={{
                    position: "relative",
                    marginY: "auto",
                    marginLeft: "10%",
                    marginRight: "10%",
                    zIndex: 1,
                }}
            >
                <Stack
                    alignItems="flex-start"
                    sx={{
                        backdropFilter: "blur(10px)",
                        borderRadius: 4,
                        paddingY: 4,
                        paddingRight: 4,
                    }}
                >
                    <Typography variant="h1" fontWeight="bold">
                        {title ? title : "Oh No! A Glitch!"}
                    </Typography>
                    <Typography variant="h6">
                        {subtitle ? (
                            subtitle
                        ) : (
                            <>
                                The error is logged, but we can't see it.
                                Refresh the page. If the problem persists, click
                                the copy button and email us at{" "}
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {BIG_GEO_SUPPORT_EMAIL}
                                </span>
                                . We'll get it sorted out!
                            </>
                        )}
                    </Typography>
                </Stack>
                {isRunningOnSF && (
                    <DownloadButton fileName={FileNameType.logs} />
                )}
                {action}
            </Stack>
            <AspectRatio
                variant="3/2"
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    maxHeight: "100%",
                }}
            >
                <Image
                    src="https://biggeo.blob.core.windows.net/test/28b59452-a0b4-5e44-7ef5-ebf95a6b85dc.png.png"
                    width="100%"
                    height="100%"
                />
            </AspectRatio>
        </Grid>
    );
};
