import {
    ComputeMetrics,
    DbColumnType,
    FilterData,
    FilterItem,
    FilterObject,
    FilterType,
    InputPoint,
    InputPolygon,
    LogicOperator,
    SlimFilterObject,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridFilterModel, GridLogicOperator } from "@biggeo/bg-ui";
import { bbox, polygon } from "@turf/turf";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import concat from "lodash/concat";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import mapboxgl from "mapbox-gl";
import { match } from "ts-pattern";
import { MapContextDataset, MapContextFilter } from "../map/mapbox/context";
import { getShapeProperties } from "../map/utils/utils";

export type LatLng = { lat: number; long: number };

export type MinMaxLatLng = {
    min: LatLng;
    max: LatLng;
};

export type LatLngWithCount = LatLng & { count: number; resolution: number };

export type OmittedTypeData = Omit<FilterItem, "type" | "data">;

export const closePolygon = (polygon: number[][]) => {
    const first = polygon[0];
    const last = polygon[polygon.length - 1];

    if (!first || !last) {
        return [];
    }

    if (first[0] === last[0] && first[1] === last[1]) {
        return polygon;
    }

    return [...polygon, first];
};

export const closeInputPointPolygon = (polygon: InputPoint[]) => {
    const first = polygon[0];
    const last = polygon[polygon.length - 1];
    if (!first || !last) {
        return [];
    }

    if (
        first.latitude === last.latitude &&
        first.longitude === last.longitude
    ) {
        return polygon;
    }

    return [...polygon, first];
};
export const toNonReadonlyArray = <A>(a: readonly A[] | null | undefined) => {
    return pipe(
        a,
        O.fromNullable,
        O.fold(
            () => [],
            (a) => [...a]
        )
    );
};

export const toNonNullableArray = <A>(a: readonly A[] | null | undefined) => {
    return pipe(
        a,
        O.fromNullable,
        O.fold(
            () => [],
            (a) => a
        )
    );
};

export const convertSqmToSkm = (area: number): number => {
    return area / 1000000;
};

export const updateSelected = <T>(
    b: T,
    a: ReadonlyArray<T>
): ReadonlyArray<T> => {
    return pipe(
        includes(a, b),
        O.fromPredicate((x) => x),
        O.fold(
            () => concat(a, b),
            () => a.filter((v) => !isEqual(v, b))
        )
    );
};

export const formatNumberWithCommas = (number: number): string => {
    // Convert the number to a string
    const numString = number.toString();

    // Split the integer and decimal parts
    const parts = numString.split(".");

    // Format the integer part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the parts back together
    return parts.join(".");
};

export const snapToView = ({
    polygons,
    map,
}: {
    readonly polygons: InputPolygon[];
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
}) => {
    if (!isEmpty(polygons) && map.current) {
        const convertedPolygons = pipe(
            polygons,
            A.map((p) => {
                const polypoints = p?.outer?.points || [];
                const points = polypoints.map((p) => {
                    return [p.longitude, p.latitude];
                });
                return points.length >= 4
                    ? polygon([closePolygon(points)], {
                          ...getShapeProperties(p.properties || undefined),
                      })
                    : undefined;
            }),
            compact
        );

        if (!isEmpty(convertedPolygons) && convertedPolygons.length > 0) {
            const bBox = bbox(convertedPolygons[0]);

            const sw = new mapboxgl.LngLat(bBox[0], bBox[1]);
            const ne = new mapboxgl.LngLat(bBox[2], bBox[3]);

            // biome-ignore lint/correctness/noInnerDeclarations: <explanation>
            // biome-ignore lint/style/noVar: <explanation>
            var llb = new mapboxgl.LngLatBounds(sw, ne);

            // biome-ignore lint/complexity/noForEach: <explanation>
            convertedPolygons.forEach((convertedPolygon) => {
                const bboxCoord = bbox(
                    convertedPolygon
                ) as mapboxgl.LngLatBoundsLike;
                llb.extend(bboxCoord);
            });

            map.current.fitBounds(llb, { padding: 50 });
        }
    }
};

export const formatFileSize = (fileSizeInBytes: number): string => {
    if (fileSizeInBytes === 0) return "0 B";
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB"];
    const threshold = 1024;
    let i = 0;
    let size = fileSizeInBytes;
    while (size >= threshold && i < units.length - 1) {
        size /= threshold;
        i++;
    }
    return `${size.toFixed(1)}${units[i]}`;
};

export const matchFilterType = <T>(
    item: FilterItem,
    matcher: {
        [FilterType.StringType]: (
            f: OmittedTypeData & {
                type: FilterType.StringType;
            } & {
                data: {
                    stringData: string;
                };
            }
        ) => T;
        [FilterType.BooleanType]: (
            f: OmittedTypeData & {
                type: FilterType.BooleanType;
            } & {
                data: {
                    booleanData: boolean;
                };
            }
        ) => T;
        [FilterType.DateType]: (
            f: OmittedTypeData & {
                type: FilterType.DateType;
            } & {
                data: {
                    dateData: Date;
                };
            }
        ) => T;
        [FilterType.NumberType]: (
            f: OmittedTypeData & {
                type: FilterType.NumberType;
            } & {
                data: {
                    numberData: number;
                };
            }
        ) => T;
        defaultFunction: () => T;
    }
): T | null => {
    const dataType = item.type;
    switch (dataType) {
        case FilterType.NumberType: {
            if (item.data.numberData) {
                return matcher[FilterType.NumberType]({
                    type: dataType,
                    data: {
                        numberData: item.data.numberData,
                    },
                    column: item.column,
                    operator: item.operator,
                });
            }
            return matcher.defaultFunction();
        }
        case FilterType.BooleanType: {
            if (item.data.booleanData) {
                return matcher[FilterType.BooleanType]({
                    type: dataType,
                    data: {
                        booleanData: item.data.booleanData,
                    },
                    column: item.column,
                    operator: item.operator,
                });
            }
            return matcher.defaultFunction();
        }
        case FilterType.DateType: {
            if (item.data.dateData) {
                return matcher[FilterType.DateType]({
                    type: dataType,
                    data: {
                        dateData: item.data.dateData,
                    },
                    column: item.column,
                    operator: item.operator,
                });
            }
            return matcher.defaultFunction();
        }
        case FilterType.StringType: {
            if (item.data.stringData) {
                return matcher[FilterType.StringType]({
                    type: dataType,
                    data: {
                        stringData: item.data.stringData,
                    },
                    column: item.column,
                    operator: item.operator,
                });
            }
            return matcher.defaultFunction();
        }
        default:
            return matcher.defaultFunction();
    }
};

export const whereOperatorToFilterType = (op: WhereOperator) => {
    switch (op) {
        case WhereOperator.contains:
            return "contains";
        case WhereOperator.equals:
            return "equals";
        case WhereOperator.startsWith:
            return "startsWith";
        case WhereOperator.endsWith:
            return "endsWith";
        case WhereOperator.isEmpty:
            return "isEmpty";
        case WhereOperator.isNotEmpty:
            return "isNotEmpty";
        case WhereOperator.isAnyOf:
            return "isAnyOf";
        case WhereOperator.eq:
            return "=";
        case WhereOperator.neq:
            return "!=";
        case WhereOperator.lt:
            return "<";
        case WhereOperator.lte:
            return "<=";
        case WhereOperator.gt:
            return ">";
        case WhereOperator.gte:
            return ">=";
        default:
            return "contains";
    }
};

export const FilterTypeConverter = (
    col: DbColumnType,
    // biome-ignore lint/suspicious/noExplicitAny: allow any
    value: any
): FilterData => {
    switch (col) {
        case DbColumnType.Number:
            return { numberData: Number.parseFloat(value) };
        case DbColumnType.Boolean:
            return { booleanData: value };
        case DbColumnType.Date:
            return { dateData: value };
        default:
            return { stringData: value || "" };
    }
};

export const ColumnTypeToDataType = (col: DbColumnType): FilterType => {
    switch (col) {
        case DbColumnType.Number:
            return FilterType.NumberType;
        case DbColumnType.Boolean:
            return FilterType.BooleanType;
        case DbColumnType.Date:
            return FilterType.DateType;
        default:
            return FilterType.StringType;
    }
};

export const stringToWhere = (str: string): WhereOperator => {
    switch (str) {
        case "contains":
            return WhereOperator.contains;
        case "equals":
            return WhereOperator.equals;
        case "startsWith":
            return WhereOperator.startsWith;
        case "endsWith":
            return WhereOperator.endsWith;
        case "isEmpty":
            return WhereOperator.isEmpty;
        case "isNotEmpty":
            return WhereOperator.isNotEmpty;
        case "isAnyOf":
            return WhereOperator.isAnyOf;
        case "=":
            return WhereOperator.eq;
        case "!=":
            return WhereOperator.neq;
        case "<":
            return WhereOperator.lt;
        case "<=":
            return WhereOperator.lte;
        case ">":
            return WhereOperator.gt;
        case ">=":
            return WhereOperator.gte;
        default:
            return WhereOperator.none;
    }
};

export const filtersToFilterModel = (
    filter: FilterItem
): GridFilterModel["items"][number] => {
    return {
        field: filter.column,
        operator: whereOperatorToFilterType(filter.operator),
        value: matchFilterType<string | number | undefined | boolean>(filter, {
            [FilterType.StringType]: (data) => {
                return data.data.stringData;
            },
            [FilterType.NumberType]: (data) => {
                return data.data.numberData; //This needds to be fixed to numbers if I do it it breaks a lot of stuff but if you do anyOf then it has to be a list of numbers or a string idk
            },
            [FilterType.BooleanType]: (data) => {
                return data.data.booleanData;
            },
            [FilterType.DateType]: (data) => {
                return `${data.data.dateData}`;
            },
            defaultFunction: () => {
                return undefined;
            },
        }),
    };
};

export const logicOperatorMatch = (filterQuery: SlimFilterObject) => {
    return match(filterQuery.logicOperator)
        .with(LogicOperator.or, () => GridLogicOperator.Or)
        .with(LogicOperator.and, () => GridLogicOperator.And)
        .exhaustive();
};

export const getDatasetIdsFromParams = (
    searchParams: URLSearchParams
): readonly string[] => {
    return searchParams.get("datasetIds")?.split(",") || [];
};

export const getSavedAreaIdFromParams = (
    searchParams: URLSearchParams
): string => {
    return searchParams.get("savedAreaId")?.split("+")[0] || "0";
};

export const getUnIndexedDataSourceIdFromParams = (
    searchParams: URLSearchParams
): string => {
    return searchParams.get("dataSourceId")?.split("+")[0] || "0";
};
export const getSavedViewIdFromParams = (
    searchParams: URLSearchParams
): string => {
    return searchParams.get("savedViewId")?.split("+")[0] || "0";
};

export const CSVToArray = (
    data: string,
    delimiter = ",",
    omitFirstRow = false
) =>
    pipe(
        data.slice(omitFirstRow ? data.indexOf("\n") + 1 : 0).split("\n"),
        A.map((v) => v.split(delimiter))
    );

export const createTimeArray = (array: string[][]) =>
    pipe(
        array,
        A.map((item) => item[2] || "")
    );

export const valueToRoundTo = (value: string | number) => {
    const valueAsNumber = Number(value);

    return valueAsNumber < 1000
        ? 10
        : valueAsNumber < 10000
          ? 100
          : valueAsNumber < 100000
            ? 1000
            : 10000;
};

export const generateXAxisLabels = (array: string[][]) =>
    pipe(
        array,
        A.map((item) => {
            const entryValue = item[1] || "";
            const roundingTo = valueToRoundTo(entryValue);

            const xAxisLabel =
                Math.round(Number(entryValue) / roundingTo) * roundingTo;

            return xAxisLabel.toString();
        })
    );

export const generateBigGeoComparisonChartData = ({
    xAxisLabels,
    redisTimeArray,
    bgSearchTimeArray,
    pinotTimeArray,
}: {
    readonly xAxisLabels: string[];
    readonly postgisTimeArray?: string[];
    readonly mongodbTimeArray?: string[];
    readonly redisTimeArray?: string[];
    readonly bgSearchTimeArray?: string[];
    readonly oracleTimeArray?: (string | undefined)[];
    readonly pinotTimeArray?: string[];
    readonly pinotWithBigGeoTimeArray?: string[];
}) =>
    pipe(
        xAxisLabels,
        A.mapWithIndex((index) => ({
            name: xAxisLabels[index] || "",
            ...(pinotTimeArray && { pinot: Number(pinotTimeArray?.[index]) }),
            ...(redisTimeArray && { redis: Number(redisTimeArray?.[index]) }),
            ...(bgSearchTimeArray && {
                bgsearch: Number(bgSearchTimeArray[index]),
            }),
        }))
    );

export const thousandsSeparator = (s: string) =>
    s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const bgComparisonColors = {
    pinot: "#F04438",
    bigGeo: "#1c3fbd",
};

export const getClosestDatapoint = (data: string[], target: number): number => {
    const numbers = data.map((str) => Number(str));

    // Use binary search to find the element closest from the left and right
    let left = 0;
    let right = numbers.length - 1;

    let closest: number | undefined = undefined;
    let minDiff = Number.POSITIVE_INFINITY;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const currentDiff = Math.abs(numbers[mid] - target);

        if (currentDiff === 0) {
            return numbers[mid]; // Target found exactly, return directly
        }

        if (currentDiff < minDiff) {
            closest = numbers[mid];
            minDiff = currentDiff;
        }

        if (target < numbers[mid]) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }

    // If target is not found in the array, return the closest element found
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    return closest!;
};

export const getUsedComputeMemory = (
    computeMemoryMetrics: readonly ComputeMetrics[]
) => {
    return pipe(
        computeMemoryMetrics,
        toNonReadonlyArray,
        A.reduce(
            0,
            (acc, cur) =>
                acc +
                pipe(
                    cur.containerMemoryMatches,
                    A.reduce(0, (_acc, _cur) => _acc + (_cur.memoryUsed || 0))
                )
        )
    );
};

export const getAvailableComputeMemory = (
    computeMemoryMetrics: readonly ComputeMetrics[]
) => {
    return pipe(
        computeMemoryMetrics,
        toNonReadonlyArray,
        A.reduce(0, (acc, cur) => acc + cur.nodeAllocatableMatches.memory)
    );
};

const generateFilterData = ({
    filterType,
    value,
}: {
    filterType: FilterType;
    value: number | string | boolean | Date;
}): FilterData => {
    return match<FilterType, FilterData>(filterType)
        .with(FilterType.NumberType, () => ({
            numberData: value as number,
        }))
        .with(FilterType.StringType, () => ({
            stringData: value as string,
        }))
        .with(FilterType.BooleanType, () => ({
            booleanData: Boolean(value),
        }))
        .with(FilterType.DateType, () => ({ dateData: value as Date }))
        .exhaustive();
};

export const generateFiltersFromMapContextFilters = ({
    databaseId,
    filters,
}: { databaseId: string; filters: MapContextFilter[] }): FilterItem[] => {
    return pipe(
        filters,
        A.findFirst((item) => {
            if (item.filterCriteria && !isEmpty(item.filterCriteria)) {
                return (
                    item.visible &&
                    isEqual(item.filterCriteria[0]?.dataSourceId, databaseId)
                );
            }
            return false;
        }),
        O.foldW(
            () => [] as FilterItem[],
            (foundFilter) => {
                return pipe(
                    foundFilter.filterCriteria[0].filters,
                    A.map((filter) => {
                        if (filter.type && filter.value) {
                            return {
                                ...omit(filter, "value"),
                                data: generateFilterData({
                                    filterType: filter.type,
                                    value: filter.value,
                                }),
                            } as FilterItem;
                        }
                    }),
                    compact
                );
            }
        )
    );
};

export const getFiltersForExport = ({
    datasets,
    filters,
}: {
    datasets: MapContextDataset[];
    filters: MapContextFilter[];
}): FilterObject[] =>
    pipe(
        datasets,
        A.filter((item) => isEqual(item.isSelected, true)),
        A.map(({ dataSource }) => ({
            databaseId: dataSource.id,
            filters: generateFiltersFromMapContextFilters({
                databaseId: dataSource.id,
                filters,
            }),
            logicOperator: LogicOperator.and,
            collection: dataSource.collectionName,
            isPreview: false,
            databaseType: dataSource.type,
        }))
    );
