import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { RootState } from "../../../redux/reducer";
import { ToastModel } from "../ToastContainer";
import { toasterActions } from "./model";

export const useToaster = () =>
    useSelector((state: RootState) => state.toaster.toast);

export const useMapPopup = () =>
    useSelector((state: RootState) => state.toaster.mapPopup);

export const useOpenToast = () => {
    const dispatch = useDispatch();
    return ({
        title,
        autoHideDuration,
        additionalAction,
        severity,
    }: {
        readonly title: string;
        readonly autoHideDuration?: number;
    } & Pick<ToastModel, "additionalAction" | "severity">) =>
        compose(
            dispatch,
            toasterActions.openToast
        )({
            title,
            open: true,
            autoHideDuration: autoHideDuration || 6000,
            additionalAction,
            severity,
        });
};
