import { FilterObject, ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import { InputViewBox } from "@biggeo/bg-server-lib/datascape-ai";
import Map from "ol/Map";
import { getBottomLeft, getTopRight } from "ol/extent";
import { toLonLat } from "ol/proj";
import { useEffect } from "react";
import { FunctionType, matchFunctionType } from "../../utils/utils";

export const useViewport = ({
    functionType,
    map,
    multiFilters,
    handleViewportChange,
    viewport,
    options,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<Map | null>;
    multiFilters: FilterObject[];
    handleViewportChange: ({ viewport }: { viewport: InputViewBox }) => void;
    viewport: InputViewBox;
    options: ReqOptions;
}) => {
    const onViewportChange = (viewport: InputViewBox) => {
        matchFunctionType(functionType, {
            viewport: () => {
                handleViewportChange({ viewport });
            },
        });
    };

    const viewportFunction = () => {
        const extent = map.current
            ?.getView()
            .calculateExtent(map.current.getSize());
        if (extent) {
            const ne = getTopRight(extent);
            const sw = getBottomLeft(extent);
            const neLongLat = toLonLat(ne);
            const swLongLat = toLonLat(sw);
            const maxLat = neLongLat[1];
            const minLat = swLongLat[1];
            const maxLng = neLongLat[0];
            const minLng = swLongLat[0];
            onViewportChange({
                latBounds: {
                    min: minLat,
                    max: maxLat,
                },
                lngBounds: {
                    min: minLng,
                    max: maxLng,
                },
            });
        }
    };

    useEffect(() => {
        const handleMove = () => {
            matchFunctionType(functionType, {
                viewport: () => viewportFunction(),
            });
        };

        const current = map.current;

        if (current) {
            current.on("moveend", handleMove);
            if (functionType === FunctionType.viewport) {
                viewportFunction();
            }
        }
        return () => {
            if (current) {
                current.un("moveend", handleMove);
            }
        };
    }, [map.current, functionType, multiFilters, options]);

    const onStyleLoad = (map: Map) => {
        viewportFunction();
    };
    return { onStyleLoad, viewport };
};
