import { ComponentProps } from "react";

import { NavigationRailCell } from "@biggeo/bg-ui/lab";

export type NavigationRailProps = Pick<
    ComponentProps<typeof NavigationRailCell>,
    "selected" | "children" | "onClick"
> & {
    icon: ComponentProps<typeof NavigationRailCell>["startNode"];
    endNode?: ComponentProps<typeof NavigationRailCell>["endNode"];
};

export const NavigationRail = ({
    selected,
    icon,
    endNode,
    ...props
}: NavigationRailProps) => (
    <NavigationRailCell
        selected={selected}
        startNode={icon}
        endNode={endNode}
        sx={{
            borderRadius: (theme) => theme.radius.default,
        }}
        {...props}
    />
);
