import type {
    AreaExtended,
    BetaSearchResults,
    Geometries,
    Geometry,
    InputPolygon,
    InputPolygonProperties,
    InputViewBox,
    Point,
    PolygonProperties,
    ReqOptions,
    SavedArea,
    SubscriptionData,
    SubscriptionDataConnection,
    SubscriptionResponse,
    SubscriptionStringResponse,
    ViewBox,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
    bboxPolygon,
    booleanOverlap,
    booleanWithin,
    centroid,
    difference,
    featureCollection,
    intersect,
    midpoint,
    point,
    polygon,
    union,
} from "@turf/turf";
import { type Result, isOk } from "@vividtheory/vt-result";
import chroma from "chroma-js";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import concat from "lodash/concat";
import every from "lodash/every";
import filter from "lodash/filter";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import some from "lodash/some";
import mapboxgl from "mapbox-gl";
import { match } from "ts-pattern";
import { closeInputPointPolygon } from "../../utils/utils";
import { defaultLevelSets, levelSetPercentiles } from "../../utils/variables";
import type {
    InputPolygonWithId,
    PureDataStringHookReturnType,
} from "../hooks/pure-data-string-hook";
import { SavedPolygonType } from "../mapbox/hooks/saved-polygon-hooks";
import {
    MAP_TOOLTIP_ARROW_HEIGHT,
    MAP_TOOLTIP_HEIGHT,
    MAP_TOOLTIP_WIDTH,
} from "../mapbox/views/MapPromptPopup";
import { convertCoordsToPixels, getCircleVertices } from "./draw-modes-utils";
import type { RustBetaSearchType } from "./type";

export const sumCounts = <T extends { count: number }>(d: T[]) =>
    d.reduce((a, c) => a + c.count, 0);

export const isNonPointsLengthPositive = (
    data: SubscriptionResponse[]
): boolean => {
    return data.some((result: SubscriptionResponse) =>
        result.geometry ? !isEmpty(result.geometry.nonPoints) : false
    );
};
export const isNonPointCountPositive = (
    data: SubscriptionResponse[]
): boolean => {
    return data.some((result: SubscriptionResponse) =>
        result.geometry ? result.geometry.nonPointCount > 0 : false
    );
};

export const isAggregationNonEmpty = (
    data: SubscriptionResponse[]
): boolean => {
    return data.some(
        (result: SubscriptionResponse) => !isEmpty(result.geometry?.aggregation)
    );
};

export enum FunctionType {
    viewport = "viewport",
    radius = "radius",
    polygon = "polygon",
    savedPolygon = "savedPolygon",
    square = "square",
    fillArea = "fillArea",
    draw = "draw",
    data = "data",
}

export enum SavedPolygonSource {
    savedArea = "savedArea",
    savedView = "savedView",
}

export type LastFunctionType = Extract<
    FunctionType,
    | FunctionType.polygon
    | FunctionType.square
    | FunctionType.radius
    | FunctionType.fillArea
    | FunctionType.draw
    | FunctionType.data
>;

export type SetSavedViewPolygonsType = {
    deleteSavedPolygon: (id: string, polygons: SavedPolygonType) => void;
    updateSavedPolygon: (
        polygon: InputPolygonWithId,
        polygons: SavedPolygonType
    ) => void;
};

export const matchFunctionType = <T>(
    fType: FunctionType,
    matcher: {
        viewport?: (fType: FunctionType.viewport) => T;
        radius?: (fType: FunctionType.radius) => T;
        polygon?: (fType: FunctionType.polygon) => T;
        savedPolygon?: (fType: FunctionType.savedPolygon) => T;
        square?: (fType: FunctionType.square) => T;
    }
) => {
    switch (fType) {
        case FunctionType.radius:
            return matcher.radius?.(fType) || null;
        case FunctionType.polygon:
            return matcher.polygon?.(fType) || null;
        case FunctionType.viewport:
            return matcher.viewport?.(fType) || null;
        case FunctionType.savedPolygon:
            return matcher.savedPolygon?.(fType) || null;
        case FunctionType.square:
            return matcher.square?.(fType) || null;
        default:
            return null;
    }
};

export const updateArray = <
    T extends {
        readonly id?: string | number;
    },
>(
    b: T | T[],
    a: Array<T>
): Array<T> => {
    if (isArray(b)) {
        const seen = new Set();
        return concat(b, a).filter((el) => {
            const duplicate = seen.has(el.id);
            seen.add(el.id);
            return !duplicate;
        });
    }
    return pipe(
        a,
        A.findFirst((x) => isEqual(x.id, b.id)),
        O.fold(
            () => concat(a, b),
            (e) => a.map((i) => (isEqual(i.id, e.id) ? { ...i, ...b } : i))
        )
    );
};

export const updateSelected = <
    T extends {
        readonly id?: string | number | undefined;
    },
>(
    b: T,
    a: Array<T>
): Array<T> => {
    return pipe(
        a,
        A.findFirst((x) => isEqual(x.id, b.id)),
        O.fold(
            () => concat(a, b),
            () => a.filter((v) => !isEqual(v.id, b.id))
        )
    );
};

export const pickGeospatialSelection = (
    functionType: FunctionType,
    data: {
        viewport: InputViewBox;
        multipolygon?: InputPolygon[];
    }
): { viewport: InputViewBox } | { multipolygon: InputPolygon[] } => {
    if (functionType !== FunctionType.viewport && !isEmpty(data.multipolygon)) {
        return { multipolygon: data.multipolygon || [] };
    }

    return { viewport: data.viewport };
};

export const generateSteps = (count: number) => {
    if (count <= 0) {
        return [];
    }

    const arr = Array(count).fill(1);
    const step = 1 / (count - 1);
    const steps = arr.map((_, i) => i * step);

    return steps;
};

export const generateColorScale = (
    hex: string,
    rangeDisparity = 2.43,
    num = 9
) => {
    const main = !chroma.valid(hex) ? chroma("#000000") : chroma(hex);

    const scale = chroma
        .scale([
            main.darken(rangeDisparity).hex(),
            main.hex(),
            main.brighten(rangeDisparity).hex(),
        ])
        .colors(num);

    return scale;
};

export const subscriptionStringToSubscriptionResponse = ({
    data,
    timeMs,
    ...props
}: SubscriptionStringResponse): SubscriptionResponse => {
    if (!props.collectionExist) {
        return {
            databaseId: props.databaseId,
            dateTime: props.dateTime,
            requestId: props.requestId,
            uniqueId: props.uniqueId,
            collectionExist: props.collectionExist,
        };
    }

    const v: RustBetaSearchType = JSON.parse(data);

    return {
        ...props,
        geometry: {
            aggregation:
                v.aggregates.map((agg) => {
                    const data: SubscriptionData = {
                        cellID: agg.cell_index.index,
                        count: agg.point_count,
                        cellVertices: {
                            points:
                                agg.cell_vertices?.points.map((p) => {
                                    return {
                                        latitude: p?.latitude || 0,
                                        longitude: p?.longitude || 0,
                                    };
                                }) || [],
                        },
                        meanPoint: {
                            latitude: agg?.mean_point?.latitude || 0,
                            longitude: agg?.mean_point?.longitude || 0,
                        },
                    };
                    return data;
                }) || [],
            points: compact(
                v.records.map((p): Geometry | undefined => {
                    if (p.geometry?.geometry?.Point) {
                        return {
                            id:
                                "Uint64" in p.id.id
                                    ? { uint64: p.id.id.Uint64 }
                                    : { string: p.id.id.String },
                            point: {
                                latitude:
                                    p.geometry.geometry.Point.latitude || 0,
                                longitude:
                                    p.geometry.geometry.Point.longitude || 0,
                            },
                        };
                    }
                })
            ),

            // TODO: This is a temporary fix and is unnecessary with a fix currently in bgsearch, but not yet released
            levelSets: isEmpty(v.level_sets) ? defaultLevelSets : v.level_sets,
            timeMs,
            pointCount:
                v.records.reduce(
                    (p, c) => (c.geometry?.geometry?.Point ? p + 1 : p),
                    0
                ) || 0,
            nonPointCount:
                v.aggregates?.reduce(
                    (p, c) => p + (c?.non_point_count || 0),
                    0
                ) || 0,
            nonPoints: v.records
                ?.flatMap((g) => {
                    if (
                        g.geometry.geometry?.Polygon ||
                        g.geometry.geometry?.MultiPolygon ||
                        g.geometry.geometry?.LineStrip
                    ) {
                        return {
                            id:
                                "Uint64" in g.id.id
                                    ? { uint64: g.id.id.Uint64 }
                                    : { string: g.id.id.String },
                            polygon: g.geometry.geometry?.Polygon,
                            multipolygon: g.geometry.geometry?.MultiPolygon,
                            lineStrip: g.geometry.geometry?.LineStrip,
                        };
                    }
                })
                .filter((p) => p !== undefined) as Geometry[],
        },
    };
};

export const subscriptionStringToSubscriptionResponseNew = ({
    data,
    timeMs,
    ...props
}: SubscriptionStringResponse): SubscriptionResponse => {
    const v: Result<BetaSearchResults, string> = JSON.parse(data);

    const emptySubscriptionDataConnection: SubscriptionDataConnection = {
        aggregation: [],
        points: [],
        levelSets: defaultLevelSets,
        timeMs: 0,
        pointCount: 0,
        nonPointCount: 0,
        nonPoints: [],
        _tag: "error",
    };

    return {
        ...props,
        data: props.extraData,
        geometry: isOk(v)
            ? {
                  aggregation:
                      v.Ok?.aggregates?.map((agg) => {
                          const data: SubscriptionData = {
                              cellID: agg.cell_index.index,
                              count: agg.point_count.low,
                              cellVertices: {
                                  points:
                                      agg.cell_vertices?.points.map((p) => {
                                          return {
                                              latitude: p?.latitude || 0,
                                              longitude: p?.longitude || 0,
                                          };
                                      }) || [],
                              },
                              meanPoint: {
                                  latitude: agg?.mean_point?.latitude || 0,
                                  longitude: agg?.mean_point?.longitude || 0,
                              },
                          };
                          return data;
                      }) || [],
                  points: v.Ok?.records
                      ?.map((p) => {
                          if (p.geometry?.point) {
                              return {
                                  id: p.id,
                                  point: {
                                      latitude: p.geometry.point.latitude || 0,
                                      longitude:
                                          p.geometry.point.longitude || 0,
                                  },
                              };
                          }
                      })
                      .filter((p) => p !== undefined) as Geometry[],
                  levelSets: isEmpty(v.Ok.level_sets)
                      ? defaultLevelSets
                      : v.Ok.level_sets,
                  timeMs,
                  pointCount:
                      v.Ok?.records?.reduce(
                          (p, c) => (c.geometry?.point ? p + 1 : p),
                          0
                      ) || 0,
                  nonPointCount:
                      v.Ok?.aggregates?.reduce(
                          (p, c) => p + (c?.non_point_count.low || 0),
                          0
                      ) || 0,
                  nonPoints: v.Ok?.records
                      ?.flatMap((g) => {
                          if (
                              g.geometry?.polygon ||
                              g.geometry?.multi_polygon ||
                              g.geometry?.line_strip
                          ) {
                              return {
                                  id: g.id,
                                  polygon: g.geometry?.polygon,
                                  multipolygon: g.geometry?.multi_polygon,
                                  lineStrip: g.geometry?.line_strip,
                              };
                          }
                      })
                      .filter((p) => p !== undefined) as Geometry[],
              }
            : emptySubscriptionDataConnection,
    };
};

export const updateItem = (
    item: SelectableTreeMenuItem,
    selected: boolean
): SelectableTreeMenuItem => {
    return {
        id: item.id,
        title: item.title,
        selected: selected,
        subItems: pipe(
            item.subItems,
            toNonReadonlyArray,
            A.map((sub) => updateItem(sub, selected))
        ),
    };
};

export const handleSelectedAreas = (input: {
    ids: number[];
    type: "item" | "subItem";
    items: readonly SelectableTreeMenuItem[];
}): readonly SelectableTreeMenuItem[] => {
    const { ids, type, items } = input;

    const filterItems = match(type)
        .with("item", () =>
            pipe(
                items,
                toNonReadonlyArray,
                A.map((item) =>
                    ids.includes(item.id)
                        ? {
                              ...item,
                              selected: !item.selected,
                              subItems: pipe(
                                  item.subItems,
                                  toNonReadonlyArray,
                                  A.map((sub) =>
                                      updateItem(sub, !item.selected)
                                  )
                              ),
                          }
                        : item
                )
            )
        )
        .with("subItem", () =>
            pipe(
                items,
                toNonReadonlyArray,
                A.map((item) => ({
                    ...item,
                    subItems: pipe(
                        item.subItems,
                        toNonReadonlyArray,
                        A.map((sub) =>
                            ids.includes(sub.id)
                                ? { ...sub, selected: !sub.selected }
                                : sub
                        )
                    ),
                }))
            )
        )
        .exhaustive();

    return filterItems.map((item) => {
        if (!isEmpty(item.subItems)) {
            const allSubItemsAreSelected = every(item.subItems, (sub) =>
                isEqual(sub.selected, true)
            );

            return {
                ...item,
                selected: !!allSubItemsAreSelected,
            };
        }

        return item;
    });
};

export const handleFilterReset = (items: readonly SelectableTreeMenuItem[]) => {
    return pipe(
        items,
        toNonReadonlyArray,
        A.map((item) => updateItem(item, false))
    );
};

export const coordinateFeature = (lng: number, lat: number) => {
    return {
        center: [lng, lat],
        geometry: {
            type: "Point",
            coordinates: [lng, lat],
        },
        place_name: `Lat: ${lat} Lng: ${lng}`,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
    } as MapboxGeocoder.Result;
};

export const coordinatesGeocoder = (query: string): MapboxGeocoder.Result[] => {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
        return [];
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2));
        geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
};

export const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const convertSavedAreas = (
    savedAreas: readonly AreaExtended[],
    selectedId?: number
): SelectableTreeMenuItem[] => {
    return pipe(
        savedAreas,
        toNonReadonlyArray,
        A.map((area) => ({
            id: area.area.id,
            selected: false,
            title: area.area.name.trim(),
            subItems: pipe(
                area.savedAreas,
                toNonReadonlyArray,
                A.filter((sa) => Boolean(sa.isEnabled && sa.mapUse)),
                A.map(
                    (sa): SelectableTreeMenuItem => ({
                        id: sa.id,
                        title: sa.name.trim(),
                        selected: selectedId === sa.id,
                    })
                )
            ),
        }))
    );
};

export const moveItemToIndex = <T>(
    array: T[],
    currentIndex: number,
    destinationIndex: number
): T[] => {
    if (
        currentIndex < 0 ||
        currentIndex >= array.length ||
        destinationIndex < 0 ||
        destinationIndex >= array.length
    ) {
        return array;
    }

    // Remove the item from the current index
    const movedItem = array.splice(currentIndex, 1)[0];
    // Insert the item at the destination index
    array.splice(destinationIndex, 0, movedItem);
    return array;
};

export const getInputPolygon = (
    feature: GeoJSON.Feature<GeoJSON.Geometry>,
    id?: string
): InputPolygonWithId => {
    const featureId = feature.id as string;
    const coordinates = isPolygonFeature(feature)
        ? feature.geometry.coordinates[0]
        : [];
    const properties = feature.properties;

    const points = pipe(
        coordinates,
        A.map((coordinate) => ({
            latitude: coordinate[1],
            longitude: coordinate[0],
        })),
        A.filterWithIndex((i: number) => i !== coordinates.length - 1)
    );

    return {
        id: id || featureId,
        inners: [],
        outer: {
            points: closeInputPointPolygon(points),
        },
        properties: properties
            ? {
                  name: properties.name || undefined,
                  fill:
                      properties["fill-color"] && properties["fill-opacity"]
                          ? {
                                color: properties["fill-color"],
                                opacity: properties["fill-opacity"],
                            }
                          : undefined,
                  stroke:
                      properties["stroke-color"] && properties["stroke-opacity"]
                          ? {
                                color: properties["stroke-color"] || undefined,
                                opacity:
                                    properties["stroke-opacity"] || undefined,
                            }
                          : undefined,
                  areaId: properties.areaId || undefined,
                  savedAreaId: properties.savedAreaId || undefined,
              }
            : undefined,
    };
};

export const getGeometryType = (
    polygon: InputPolygonWithId
): GeoJSON.Polygon => {
    const { outer } = polygon;
    const { points } = outer;

    const coordinates = pipe(
        closeInputPointPolygon(points),
        A.map((point) => {
            return [point.longitude, point.latitude];
        })
    );

    return {
        type: "Polygon",
        coordinates: [coordinates],
    };
};

export const getFeatureType = (
    p: InputPolygonWithId
): GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> => {
    const polygon = getGeometryType(p);

    return {
        type: "Feature",
        geometry: polygon,
        properties: getShapeProperties(
            p.properties || undefined
        ) as GeoJSON.GeoJsonProperties,
    };
};

export const getMapFeatures = (
    draw: React.MutableRefObject<MapboxDraw | null>,
    isLoaded: boolean
): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] => {
    return pipe(
        isLoaded,
        O.fromPredicate((l) => l),
        O.fold(
            () => [],
            () =>
                pipe(
                    draw.current,
                    O.fromNullable,
                    O.fold(
                        () => [],
                        (current) =>
                            pipe(
                                current.getAll(),
                                (
                                    data?: GeoJSON.FeatureCollection<GeoJSON.Geometry>
                                ) => {
                                    if (data) {
                                        const { features } = data;

                                        return pipe(
                                            features,
                                            A.filter((feature) => {
                                                if (isPolygonFeature(feature)) {
                                                    return (
                                                        feature.geometry
                                                            .coordinates[0]
                                                            .length >= 3 &&
                                                        isArray(
                                                            feature.geometry
                                                                .coordinates[0]
                                                        )
                                                    );
                                                }

                                                return !!feature;
                                            }),
                                            A.map((f) => ({
                                                ...f,
                                                properties: {
                                                    ...f.properties,
                                                    featureId: f.id,
                                                },
                                            }))
                                        );
                                    }
                                },
                                compact
                            )
                    )
                )
        )
    );
};

export const getPolygonsThatHaveInnerPolygons = (
    inner: InputPolygonWithId[],
    outer: InputPolygonWithId[]
) => {
    return filter(outer, (outerPolygon) =>
        some(inner, (innerPolygon) =>
            booleanWithin(
                getGeometryType(innerPolygon),
                getGeometryType(outerPolygon)
            )
        )
    );
};

export const getPolygonsThatOverlapOtherPolygons = (
    inner: InputPolygonWithId[],
    outer: InputPolygonWithId[]
) => {
    /*
        booleanOverlap is to catch the polygons that are partly inside other polygons.
        booleanWithin is to catch the ones that are completely inside.
    */
    return filter(outer, (outerPolygon) =>
        some(
            inner,
            (innerPolygon) =>
                booleanOverlap(
                    getGeometryType(outerPolygon),
                    getGeometryType(innerPolygon)
                ) ||
                booleanWithin(
                    getGeometryType(innerPolygon),
                    getGeometryType(outerPolygon)
                )
        )
    );
};

export const isMultiPolygon = (
    value:
        | GeoJSON.Feature<GeoJSON.Polygon>
        | GeoJSON.Feature<GeoJSON.MultiPolygon>
        | GeoJSON.Feature<
              GeoJSON.Polygon | GeoJSON.MultiPolygon,
              GeoJSON.GeoJsonProperties
          >
): value is GeoJSON.Feature<GeoJSON.MultiPolygon> => {
    return value.geometry.type === "MultiPolygon";
};

export const isPolygon = (
    value:
        | GeoJSON.Feature<GeoJSON.Polygon>
        | GeoJSON.Feature<GeoJSON.MultiPolygon>
        | GeoJSON.Feature<
              GeoJSON.Polygon | GeoJSON.MultiPolygon,
              GeoJSON.GeoJsonProperties
          >
): value is GeoJSON.Feature<GeoJSON.Polygon> => {
    return value.geometry.type === "Polygon";
};

export const isPolygonFeature = (
    value: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
): value is GeoJSON.Feature<GeoJSON.Polygon> => {
    return value.geometry.type === "Polygon";
};

export const getVisiblePartOfPolygons = (
    polygons: InputPolygonWithId[],
    map: mapboxgl.Map
): InputPolygonWithId[] | undefined => {
    const bounds = map.getBounds();

    const viewportPolygon = bboxPolygon([
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
    ]);

    const unionPolygon = pipe(
        polygons,
        A.map((polygon) => getFeatureType(polygon)),
        (
            features: GeoJSON.Feature<
                GeoJSON.Polygon | GeoJSON.MultiPolygon,
                GeoJSON.GeoJsonProperties
            >[]
        ) =>
            features.reduce((acc, polygon) => {
                const output = union(featureCollection([acc, polygon]));

                return output ? output : acc;
            }, features[0])
    );

    const intersection = intersect(
        featureCollection([unionPolygon, viewportPolygon])
    );

    if (intersection) {
        const intersections: GeoJSON.Polygon[] = isMultiPolygon(intersection)
            ? intersection.geometry.coordinates.flatMap((c) => ({
                  type: "Polygon",
                  coordinates: c,
              }))
            : isPolygon(intersection)
              ? [
                    {
                        type: "Polygon",
                        coordinates: intersection.geometry.coordinates,
                    },
                ]
              : [];

        if (isEmpty(intersections)) {
            return;
        }

        return pipe(
            intersections,
            A.map(({ coordinates }) => {
                const points = pipe(
                    coordinates[0],
                    A.map((coordinate) => ({
                        latitude: coordinate[1],
                        longitude: coordinate[0],
                    })),
                    A.filterWithIndex(
                        (i: number) => i !== coordinates.length - 1
                    )
                );

                return {
                    id: "",
                    inners: [],
                    outer: {
                        points,
                    },
                };
            })
        );
    }
};

export const iLike = ({
    text,
    pattern,
}: {
    pattern?: string | null;
    text?: string | null;
}): boolean =>
    text && pattern ? text.toLowerCase().includes(pattern.toLowerCase()) : true;

export const fitBounds = ({
    latBounds,
    lngBounds,
}: {
    latBounds: { min: number; max: number };
    lngBounds: { min: number; max: number };
}) => {
    if (lngBounds.max - lngBounds.min > 360) {
        return {
            latBounds: {
                min: latBounds.min,
                max: latBounds.max,
            },
            lngBounds: {
                min: Math.max(lngBounds.min, -180),
                max: Math.min(lngBounds.max, 180),
            },
        };
    }
    return {
        latBounds: {
            min: latBounds.min,
            max: latBounds.max,
        },
        lngBounds: {
            min: lngBounds.min,
            max: lngBounds.max,
        },
    };
};

export const getBounds = (bounds: mapboxgl.LngLatBounds): InputViewBox => {
    const maxLat = bounds._ne.lat;
    const minLat = bounds._sw.lat;
    const maxLng = bounds._ne.lng;
    const minLng = bounds._sw.lng;

    return fitBounds({
        latBounds: {
            min: minLat,
            max: maxLat,
        },
        lngBounds: {
            min: minLng,
            max: maxLng,
        },
    });
};

export const getCenterFromViewport = (
    viewport: ViewBox
): { lng: number; lat: number } => {
    const centerLat = (viewport.latBounds.min + viewport.latBounds.max) / 2;
    const centerLng = (viewport.lngBounds.min + viewport.lngBounds.max) / 2;

    return {
        lat: centerLat,
        lng: centerLng,
    };
};

export const getShapeProperties = (
    properties?: PolygonProperties | InputPolygonProperties
):
    | {
          name?: string;
          "fill-color": string | undefined;
          "stroke-color": string | undefined;
          "fill-opacity": number | undefined;
          "stroke-opacity": number | undefined;
          areaId: number | undefined;
          savedAreaId: number | undefined;
      }
    | undefined => {
    return properties
        ? {
              name: properties.name || undefined,
              "fill-color": properties.fill?.color || undefined,
              "stroke-color": properties.stroke?.color || undefined,
              "fill-opacity": properties.fill?.opacity || undefined,
              "stroke-opacity": properties.stroke?.opacity || undefined,
              areaId: properties.areaId || undefined,
              savedAreaId: properties.savedAreaId || undefined,
          }
        : undefined;
};

export const getPolygonTooltipPosition = ({
    polygon,
    map,
    position = "top",
}: {
    polygon?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
    map: mapboxgl.Map;
    position?: "top" | "bottom";
}): {
    top: number;
    left: number;
} => {
    if (!polygon) {
        return {
            top: 0,
            left: 0,
        };
    }

    const geometry = polygon?.geometry;
    const type = polygon.properties?.isCircle
        ? "circle"
        : polygon.properties?.isSquare
          ? "square"
          : "polygon";

    const coordinates = match(type)
        .with("circle", () => {
            const vertices = getCircleVertices(polygon);
            const topVertex = vertices[0].geometry.coordinates;
            const bottomVertex = vertices[3].geometry.coordinates;

            const vertex = match(position)
                .with("top", () => topVertex)
                .with("bottom", () => bottomVertex)
                .exhaustive();

            return convertCoordsToPixels(vertex, map);
        })
        .with("square", () => {
            const coord1 = geometry.coordinates[0][2]; // Top left
            const coord2 = geometry.coordinates[0][3]; // Top right

            const coord3 = geometry.coordinates[0][1]; // Bottom left
            const coord4 = geometry.coordinates[0][0]; // Bottom right

            const midpointCoord = match(position)
                .with("top", () => midpoint(point(coord1), point(coord2)))
                .with("bottom", () => midpoint(point(coord3), point(coord4)))
                .exhaustive();

            return convertCoordsToPixels(
                midpointCoord.geometry.coordinates,
                map
            );
        })
        .with("polygon", () => {
            const topVertex = geometry.coordinates[0][0];
            const bottomVertex = geometry.coordinates[0][1];

            const vertex = match(position)
                .with("top", () => topVertex)
                .with("bottom", () => bottomVertex)
                .exhaustive();

            return convertCoordsToPixels(vertex, map);
        })
        .exhaustive();

    const top = match(position)
        .with(
            "top",
            () =>
                coordinates.top -
                MAP_TOOLTIP_HEIGHT -
                MAP_TOOLTIP_ARROW_HEIGHT -
                10
        )
        .with("bottom", () => coordinates.top + 10)
        .exhaustive();

    const left = match(position)
        .with("top", () => coordinates.left - MAP_TOOLTIP_WIDTH / 2 - 10)
        .with("bottom", () => coordinates.left)
        .exhaustive();

    return { top, left };
};

export const getOutsideArea = (
    savedAreas: readonly SavedArea[]
): {
    outsideArea: GeoJSON.Feature<GeoJSON.Polygon>;
    savedAreaPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[];
} => {
    const polygons: GeoJSON.Feature<
        GeoJSON.Polygon | GeoJSON.MultiPolygon,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        savedAreas,
        toNonReadonlyArray,
        A.flatMap((savedArea) =>
            savedArea.geometries.flatMap((geometry) => ({
                type: "Feature",
                properties:
                    getShapeProperties(geometry.properties || undefined) || {},
                geometry: {
                    type: "Polygon",
                    coordinates: geometry.coordinates,
                },
            }))
        )
    );

    const unionPolygon = polygons.reduce((acc, polygon) => {
        const output = union(featureCollection([acc, polygon]));

        return output ? output : acc;
    }, polygons[0]);

    // Bbox of the world
    const bbox: GeoJSON.BBox = [-180, -90, 180, 90];
    const bboxPolygonCoord = bboxPolygon(bbox);

    // Get the polygons outside our unionPolygon
    const outsideArea = difference(
        featureCollection([bboxPolygonCoord, unionPolygon])
    );

    return {
        outsideArea:
            outsideArea && isPolygon(outsideArea)
                ? outsideArea
                : {
                      type: "Feature",
                      geometry: {
                          type: "Polygon",
                          coordinates: [],
                      },
                      properties: {},
                  },
        savedAreaPolygons: polygons,
    };
};

export const areSquareCoordinatesCorrect = (
    coordinates: GeoJSON.Position[][]
): boolean => {
    const isSquare = coordinates[0].length === 5;

    if (!isSquare) {
        return false;
    }

    const first = coordinates[0][0];
    const second = coordinates[0][1];
    const third = coordinates[0][2];
    const fourth = coordinates[0][3];
    const fifth = coordinates[0][4];

    if (!isEqual(first, fifth)) {
        return false;
    }

    if (!isEqual(second[1], first[1])) {
        return false;
    }

    if (!isEqual(third[1], fourth[1])) {
        return false;
    }

    if (!isEqual(fourth[0], fifth[0])) {
        return false;
    }

    return true;
};

export const fixSquareCoordinates = (coordinates: GeoJSON.Position[][]) => {
    const fourthCoordinates = coordinates[0][3];
    const secondCoordinates = coordinates[0][1];

    return [
        pipe(
            coordinates[0],
            A.mapWithIndex((index, c) => {
                if (index === 1) {
                    return fourthCoordinates;
                }

                if (index === 3) {
                    return secondCoordinates;
                }

                return c;
            })
        ),
    ];
};

export const getPolygonFeatureFromConflict = ({
    currentShapeId,
    feature,
    features,
}: {
    currentShapeId?: string | number;
    feature: GeoJSON.Feature<GeoJSON.Polygon>;
    features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
}) => {
    return pipe(
        features,
        A.map((f) => {
            const isCircleProperty = !isUndefined(f.properties?.isCircle);
            const isSquareProperty = !isUndefined(f.properties?.isSquare);

            const coordinates = feature.geometry.coordinates;

            const isNotSquareAnymore =
                isSquareProperty && feature.geometry.coordinates[0].length > 5;

            // When an intersection is calculated, it sometimes messes up the
            // coordinates order, which is important for a square.
            const isCorrectSquare = areSquareCoordinatesCorrect(coordinates);

            const updatedCoordinates = isSquareProperty
                ? isCorrectSquare || isNotSquareAnymore
                    ? coordinates
                    : fixSquareCoordinates(coordinates)
                : coordinates;

            return f.id === currentShapeId
                ? ({
                      ...f,
                      geometry: {
                          ...f.geometry,
                          coordinates: updatedCoordinates,
                      },
                      properties: {
                          ...f.properties,
                          ...(isCircleProperty && {
                              isCircle: false,
                              wasCircle: true,
                          }),
                          ...(isNotSquareAnymore && {
                              isSquare: false,
                              wasSquare: true,
                          }),
                      },
                  } as GeoJSON.Feature<
                      GeoJSON.Geometry,
                      GeoJSON.GeoJsonProperties
                  >)
                : f;
        })
    );
};

export const getMultiPolygonFeatureFromConflict = ({
    currentShapeId,
    feature,
    features,
}: {
    currentShapeId?: string | number;
    feature: GeoJSON.Feature<GeoJSON.MultiPolygon>;
    features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
}) => {
    return pipe(
        features,
        A.concat(
            pipe(
                feature.geometry.coordinates,
                A.mapWithIndex((index, coordinates) => {
                    const isCircleProperty = !isUndefined(
                        feature.properties?.isCircle
                    );
                    const isSquareProperty = !isUndefined(
                        feature.properties?.isSquare
                    );

                    const isNotSquareAnymore =
                        isSquareProperty && coordinates[0].length > 5;

                    // When an intersection is calculated, it sometimes messes up the
                    // coordinates order, which is important for a square.
                    const isCorrectSquare =
                        areSquareCoordinatesCorrect(coordinates);

                    const updatedCoordinates = isSquareProperty
                        ? isCorrectSquare || isNotSquareAnymore
                            ? coordinates
                            : fixSquareCoordinates(coordinates)
                        : coordinates;

                    return {
                        id:
                            index !== 0
                                ? `${currentShapeId?.toString().slice(0, -1)}${index}`
                                : currentShapeId,
                        type: "Feature",
                        properties: {
                            ...feature.properties,
                            ...(isCircleProperty && {
                                isCircle: false,
                                wasCircle: true,
                            }),
                            ...(isNotSquareAnymore && {
                                isSquare: false,
                                wasSquare: true,
                            }),
                        },
                        geometry: {
                            type: MapboxDraw.constants.geojsonTypes.POLYGON,
                            coordinates: updatedCoordinates,
                        },
                    };
                })
            ) as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
        )
    );
};

export const setDrawFeature = ({
    currentShapeId,
    feature,
    draw,
    isLoaded,
}: {
    currentShapeId?: string | number;
    feature:
        | GeoJSON.Feature<GeoJSON.Polygon>
        | GeoJSON.Feature<GeoJSON.MultiPolygon>
        | GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>;
    draw: React.MutableRefObject<MapboxDraw | null>;
    isLoaded: boolean;
}): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] => {
    if (isPolygon(feature)) {
        const features = getPolygonFeatureFromConflict({
            feature,
            currentShapeId,
            features: getMapFeatures(draw, isLoaded),
        });

        draw.current?.set({
            type: "FeatureCollection",
            features,
        });

        return features;
    }

    if (isMultiPolygon(feature)) {
        const features = getMultiPolygonFeatureFromConflict({
            feature,
            currentShapeId,
            features: getMapFeatures(draw, isLoaded),
        });

        draw.current?.set({
            type: "FeatureCollection",
            features,
        });

        return features;
    }

    return [];
};

export const getConflictAreas = ({
    shape,
    savedAreas,
}: {
    savedAreas: readonly SavedArea[];
    shape?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
}): {
    intersection?: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    > | null;
    difference?: GeoJSON.Feature<
        GeoJSON.MultiPolygon | GeoJSON.Polygon,
        GeoJSON.GeoJsonProperties
    > | null;
    shape?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
} => {
    if (shape && !isPolygonFeature(shape)) {
        return { intersection: null, difference: null, shape };
    }

    const { outsideArea } = getOutsideArea(savedAreas);

    const intersection = shape
        ? intersect(featureCollection([shape, outsideArea]))
        : undefined;

    return {
        intersection,
        difference:
            shape && intersection
                ? difference(featureCollection([shape, intersection]))
                : undefined,
        shape,
    };
};

export const handleSavedViewPolygons = ({
    setSavedPolygons,
}: Pick<
    PureDataStringHookReturnType,
    "setSavedPolygons"
>): SetSavedViewPolygonsType => {
    const isSavedView = (polygons: SavedPolygonType) =>
        !isEmpty(polygons.polygons) &&
        isEqual(polygons.source, SavedPolygonSource.savedView);

    const deleteSavedPolygon = (id: string, polygons: SavedPolygonType) => {
        if (isSavedView(polygons)) {
            setSavedPolygons({
                polygons: pipe(
                    polygons.polygons,
                    toNonReadonlyArray,
                    A.map((s) => ({
                        ...s,
                        geometries: pipe(
                            s.geometries,
                            A.filterWithIndex(
                                (index, _) => !id.includes(index.toString())
                            )
                        ),
                    }))
                ),
            });
        }
    };

    const updateSavedPolygon = (
        polygon: InputPolygonWithId,
        polygons: SavedPolygonType
    ) => {
        if (isSavedView(polygons)) {
            setSavedPolygons({
                polygons: pipe(
                    polygons.polygons,
                    toNonReadonlyArray,
                    A.map((s) => {
                        if (isEmpty(s.geometries)) {
                            const { geometry, properties } =
                                getFeatureType(polygon);

                            return {
                                ...s,
                                geometries: [
                                    {
                                        type: "Polygon",
                                        coordinates: geometry.coordinates,
                                        properties,
                                    },
                                ],
                            };
                        }

                        return {
                            ...s,
                            geometries: pipe(
                                s.geometries,
                                A.mapWithIndex((index, g) =>
                                    polygon.id.includes(index.toString())
                                        ? {
                                              ...g,
                                              coordinates:
                                                  getGeometryType(polygon)
                                                      .coordinates,
                                          }
                                        : g
                                )
                            ),
                        };
                    })
                ),
            });
        }
    };

    return {
        deleteSavedPolygon,
        updateSavedPolygon,
    };
};

export const getPolygonsFromSavedAreas = (
    savedAreas: readonly SavedArea[]
): InputPolygonWithId[] =>
    pipe(
        savedAreas,
        toNonReadonlyArray,
        A.flatMap(({ id, geometries, areaId, name }) =>
            pipe(
                geometries,
                A.mapWithIndex((index, g) => ({
                    id: `${id}-${index}`,
                    inners: [],
                    outer: {
                        points: pipe(
                            g.coordinates,
                            // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
                            A.flatMap((c) => c),
                            A.map((c) => ({
                                latitude: c[1],
                                longitude: c[0],
                            }))
                        ),
                    },
                    properties: {
                        ...(g.properties && {
                            ...g.properties,
                        }),
                        areaId,
                        savedAreaId: id,
                        name: g.properties?.name ?? name,
                    },
                }))
            )
        )
    );

export const getSavedViewPolygons = (
    savedAreas: Geometries[]
): {
    drawnGeometries?: GeoJSON.Feature<GeoJSON.Polygon>[];
    savedAreaGeometries?: Pick<
        PolygonProperties,
        "name" | "areaId" | "savedAreaId"
    >[];
} => {
    const savedAreaGeometries = pipe(
        savedAreas,
        A.filter((geometry) => !!geometry.properties?.savedAreaId),
        A.map((g) =>
            g.properties
                ? {
                      name: g.properties.name,
                      areaId: g.properties.areaId,
                      savedAreaId: g.properties.savedAreaId,
                  }
                : undefined
        ),
        compact
    );

    const drawnGeometries = pipe(
        savedAreas,
        A.filter((geometry) => !geometry.properties?.savedAreaId),
        A.mapWithIndex((index, geometry) => {
            const id = `saved-view-${index}`;
            const isSquare = geometry.coordinates[0].length === 5;
            const isCircle = geometry.coordinates[0].length >= 65;

            const circle = isCircle
                ? polygon([geometry.coordinates[0]])
                : undefined;
            const center = circle ? centroid(circle) : undefined;

            return {
                id,
                type: MapboxDraw.constants.geojsonTypes.FEATURE,
                geometry: {
                    type: MapboxDraw.constants.geojsonTypes.POLYGON,
                    coordinates: geometry.coordinates,
                },
                properties: {
                    ...(geometry.properties && {
                        ...getShapeProperties(geometry.properties),
                    }),
                    isSquare,
                    isCircle,
                    featureId: id,
                    type: isSquare
                        ? FunctionType.square
                        : isCircle
                          ? FunctionType.radius
                          : FunctionType.polygon,
                    center: center ? center.geometry.coordinates : undefined,
                },
            };
        })
    );

    return {
        savedAreaGeometries: isEmpty(savedAreaGeometries)
            ? undefined
            : savedAreaGeometries,
        drawnGeometries: isEmpty(drawnGeometries) ? undefined : drawnGeometries,
    };
};

export const handleAntiMeridian = (dataPoints: Point[]) => {
    const longs = dataPoints.map((p) => {
        return p.longitude;
    });
    const minLong = Math.min(...longs);
    const maxLong = Math.max(...longs);

    const points = dataPoints.map((p) => {
        if (maxLong - minLong > 180 && p.longitude > 0) {
            return [p.longitude - 360, p.latitude];
        }
        return [p.longitude, p.latitude];
    });

    return points;
};

export const getDefaultDatasetConfigurationOptions = (
    isRunningOnSF: boolean
): ReqOptions => ({
    polygonMaxGeomsPerCell: isRunningOnSF ? 125 : 75,
    pointMaxGeomsPerCell: isRunningOnSF ? 200 : 101,
    polygonResolutionOffset: isRunningOnSF ? 3 : 3,
    pointResolutionOffset: 3,
    levelSets: levelSetPercentiles,
});
