import {
    DataProvider,
    MarketplaceDataset,
    useFetchDataProvidersQuery,
    useFetchMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Box, LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import { debouncedFn } from "@biggeo/bg-utils";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { match as tsMatch } from "ts-pattern";
import { ErrorPage } from "../../common/components/ErrorPage";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { OnlyAvailableInSnowflakeView } from "../../common/views/OnlyAvailableInSnowflakeView";
import {
    MarketplaceMapHeader,
    TabValues,
} from "../../components/MarketplaceMapHeader";
import { MarketplaceDatasets } from "../views/MarketplaceDatasets";
import { MarketplaceRecipes } from "../views/MarketplaceRecipes";
import { MarketplaceSidebar, SidebarMenu } from "../views/MarketplaceSidebar";

export const MarketplaceListViewContainer = () => {
    const { menuItem } = useParams();
    const isRunningOnSF = isAppRunningOnSF();

    const toPage = useNavigate();

    //this function currently is not used until recipe sidebar is ready
    const getSelectedMenu = (param?: string): string => {
        return tsMatch(camelCase(param))
            .with(SidebarMenu.datasets, () => SidebarMenu.datasets)
            .with(SidebarMenu.recipes, () => SidebarMenu.recipes)
            .otherwise(() => SidebarMenu.datasets);
    };

    //state below will be deleted once getSelectedMenu is ready
    const [selected, setSelected] = useState<string>(SidebarMenu.datasets);
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearch = debouncedFn<string>();

    const [selectedProvider, setSelectedProvider] = useState<string>("");
    const [selectedIndustry, setSelectedIndustry] = useState<string>("");

    const [showAllProviders, setShowAllProviders] = useState<boolean>(false);

    const [showAllIndustries, setShowAllIndustries] = useState<boolean>(false);

    const onIndustryClick = (industry: string) => {
        setSelectedProvider("");
        setSelectedIndustry(industry);
    };

    const onProviderClick = (providerId: string) => {
        setSelectedIndustry("");
        setSelectedProvider(providerId);
    };

    const sliceProvidersByMax = ({
        providers,
        max,
        condition,
    }: { providers: DataProvider[]; max: number; condition: boolean }) => {
        if (condition) {
            return providers;
        }
        return providers.slice(0, max);
    };
    const { remote: mktplaceRD } = useFetchMarketplaceDatasetsQuery({
        variables: {
            input: {
                ...(searchQuery && { searchQuery }),
                ...(selectedProvider && { fkProviderIds: [selectedProvider] }),
                ...(selectedIndustry && { category: selectedIndustry }),
            },
        },
    });

    const {
        queryReturn: { data: dataProvidersData },
    } = useFetchDataProvidersQuery({
        variables: {
            input: {},
        },
    });

    const convertToAvailableDataSource = (data: MarketplaceDataset) => {
        return {
            collectionName: data.name,
            id: data.id,
            isConnected: false,
            size: data.size,
            tableName: data.name,
            type: data.type,
            name: data.name,
            description: data.description,
            icon: data.img,
            price: data.price,
            fkProviderId: data.fkProviderId,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            deletedAt: data.deletedAt,
            geographyColumn: data.geographyColumn,
            tableId: data.tableId,
            path: data.path,
            opacity: 0,
            isPurchased: false,
        };
    };

    return (
        <Stack width={"100%"}>
            <MarketplaceMapHeader currentTab={TabValues.listView} />
            {!isRunningOnSF ? (
                <OnlyAvailableInSnowflakeView />
            ) : (
                <DoubleColumnLayout
                    height="100%"
                    leftHeader={startCase(getSelectedMenu(menuItem))}
                    left={
                        <Box
                            sx={{
                                border: "none",
                                height: "100%",
                                width: "100%",
                                breakpoints: {
                                    cmd: {
                                        width: 60,
                                        borderRight: 1,
                                        borderColor: (theme) =>
                                            theme.palette.stroke[100],
                                    },
                                },
                            }}
                        >
                            <MarketplaceSidebar
                                selected={selected}
                                setSelected={setSelected}
                                onSearch={(query) =>
                                    debouncedSearch(setSearchQuery, query)
                                }
                                toPage={toPage}
                                datasetProviders={
                                    dataProvidersData?.fetchDataProviders
                                        .dataProviders || []
                                }
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                selectedProvider={selectedProvider}
                                setSelectedProvider={setSelectedProvider}
                                showAllIndustries={showAllIndustries}
                                setShowAllIndustries={setShowAllIndustries}
                                showAllProviders={showAllProviders}
                                setShowAllProviders={setShowAllProviders}
                                sliceProvidersByMax={sliceProvidersByMax}
                                onIndustryClick={onIndustryClick}
                                onProviderClick={onProviderClick}
                            />
                        </Box>
                    }
                >
                    {tsMatch(getSelectedMenu(menuItem))
                        .with(SidebarMenu.datasets, () => {
                            return match(mktplaceRD, {
                                _: () => <LoadingBar />,
                                Success: (data) => (
                                    <MarketplaceDatasets
                                        datasources={pipe(
                                            data.fetchMarketplaceDatasets
                                                .marketplaceDatasets,
                                            A.map((d) =>
                                                convertToAvailableDataSource(d)
                                            )
                                        )}
                                    />
                                ),
                                Failure: (err) => (
                                    <ErrorPage subtitle={err.message} />
                                ),
                            });
                        })
                        .with(SidebarMenu.recipes, () => (
                            <MarketplaceRecipes
                                recipes={[]}
                                onBannerButtonClick={() => {
                                    // TODO: add toPage for routing
                                }}
                            />
                        ))
                        .otherwise(() => (
                            <></>
                        ))}
                </DoubleColumnLayout>
            )}
        </Stack>
    );
};
