import { FilterObject } from "@biggeo/bg-server-lib/datascape-ai";
import { WithPartialValues } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import {
    DEFAULT_LOGIC_OPERATOR,
    mapfilterCriteriaToMultifilter,
} from "../../filter-criteria/utils/utils";
import { getDefaultDatasetConfigurationOptions } from "../../utils/utils";
import {
    MapContextDataset,
    MapContextDatasetConfiguration,
    MapContextFilter,
    useMap,
} from "./map";

export type SetDatasetContextType = {
    addDatasets: (values: MapContextDataset | MapContextDataset[]) => void;
    updateDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => void;
    removeDataset: (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => void;
    overrideDatasets: (values: MapContextDataset[]) => void;
    reorderDatasets: (dataSourceIds: string[]) => void;
};

export const setDatasetContext = (): SetDatasetContextType => {
    const { dispatch } = useMap();

    const addDatasets = (values: MapContextDataset | MapContextDataset[]) => {
        dispatch?.({ type: "ADD_DATASETS", values });
    };

    const updateDataset = (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => {
        dispatch?.({ type: "UPDATE_DATASET", values });
    };

    const removeDataset = (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => {
        dispatch?.({ type: "REMOVE_DATASET", values });
    };

    const overrideDatasets = (values: MapContextDataset[]) => {
        dispatch?.({ type: "OVERRIDE_DATASETS", values });
    };

    const reorderDatasets = (dataSourceIds: string[]) => {
        dispatch?.({ type: "REORDER_DATASETS", values: { dataSourceIds } });
    };

    return {
        addDatasets,
        updateDataset,
        removeDataset,
        overrideDatasets,
        reorderDatasets,
    };
};

const getFilterObject = (dataset: MapContextDataset): FilterObject => ({
    collection: dataset.dataSource.collectionName,
    databaseId: dataset.dataSource.id,
    databaseType: dataset.dataSource.type,
    filters: [],
    isPreview: dataset.dataSource.isPreview ?? false,
    logicOperator: DEFAULT_LOGIC_OPERATOR,
    options: dataset.configuration.options,
});

export const getMultiFiltersType = (input: {
    datasets: MapContextDataset[];
    filters: MapContextFilter[];
    isRunningOnSF: boolean;
}): FilterObject[] => {
    const { datasets, filters, isRunningOnSF } = input;

    const selectedDatasets = pipe(
        datasets,
        A.filter((dataset) => isEqual(dataset.isSelected, true))
    );
    const visibleFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.visible, true))
    );

    if (isEmpty(visibleFilters) && !isEmpty(selectedDatasets)) {
        return pipe(selectedDatasets, A.map(getFilterObject));
    }

    if (!isEmpty(visibleFilters)) {
        return pipe(
            visibleFilters,
            A.flatMap((filter) =>
                pipe(
                    filter.filterCriteria,
                    A.map((d) =>
                        mapfilterCriteriaToMultifilter(d, isRunningOnSF)
                    )
                )
            ),
            (multiFilters) =>
                pipe(
                    multiFilters,
                    A.concat(
                        pipe(
                            selectedDatasets,
                            A.map((selectedDataset) => {
                                if (
                                    !multiFilters
                                        .map((m) => m.databaseId)
                                        .includes(selectedDataset.dataSource.id)
                                ) {
                                    return getFilterObject(selectedDataset);
                                }
                            }),
                            compact
                        )
                    )
                )
        );
    }

    return [];
};

export const getMultiFilters = (): FilterObject[] => {
    const { datasets, filters, isRunningOnSF } = useMap();

    return useMemo(
        () => getMultiFiltersType({ datasets, filters, isRunningOnSF }),
        [datasets, filters, isRunningOnSF]
    );
};

export const getDefaultDatasetConfiguration = (
    isRunningOnSF: boolean
): MapContextDatasetConfiguration => ({
    isOpen: false,
    options: getDefaultDatasetConfigurationOptions(isRunningOnSF),
    showLevelSets: true,
    showPoints: true,
});
