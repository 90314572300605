import { SvgIcon, SvgIconProps } from "@biggeo/bg-ui";
const SvgBigGeoAi = (props: SvgIconProps) => (
    <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-component-name="SvgBigGeoAi"
        {...props}
    >
        <path
            fill="url(#ai_svg__a)"
            d="M20 12.154V19.6h-1.878a2.37 2.37 0 0 1-2.37-2.37h-.06c-.74 1.662-2.462 2.77-4.708 2.77-3.231 0-7.293-2.37-7.293-8 0-4.615 3.385-8 8.185-8s8.123 3.17 8.123 8.154m-8.124 3.538c2.092 0 3.508-1.507 3.508-3.692s-1.416-3.692-3.508-3.692S8.368 9.815 8.368 12s1.416 3.692 3.508 3.692"
        />
        <path
            fill="url(#ai_svg__b)"
            fillRule="evenodd"
            d="M19.998 4.03v5.324c-.654-2.486-2.312-4.281-4.615-5.124v-.2z"
            clipRule="evenodd"
        />
        <path
            fill="url(#ai_svg__c)"
            fillRule="evenodd"
            d="M20 19.6v-7.446C20 7.169 16.675 4 11.875 4c-3.305 0-5.939 1.605-7.26 4.11a6.063 6.063 0 0 1 10.66 2.888q.107.473.108 1.002-.002.625-.149 1.168a6.064 6.064 0 0 1-10.962 2.2C5.51 18.593 8.493 20 10.983 20c2.247 0 3.97-1.108 4.708-2.77h.062a2.37 2.37 0 0 0 2.37 2.37z"
            clipRule="evenodd"
        />
        <defs>
            <linearGradient
                id="ai_svg__a"
                x1={11.845}
                x2={11.845}
                y1={4}
                y2={20}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#131438" />
                <stop offset={0.5} stopColor="#FFFFFF" />
                <stop offset={1} stopColor="#FFFFFF" />
            </linearGradient>
            <linearGradient
                id="ai_svg__b"
                x1={17.691}
                x2={17.691}
                y1={4.031}
                y2={9.354}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#153453" />
                <stop offset={1} stopColor="#131438" />
            </linearGradient>
            <linearGradient
                id="ai_svg__c"
                x1={11.127}
                x2={11.127}
                y1={4}
                y2={20}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFFFFF" />
                <stop offset={0.5} stopColor="#FFFFFF" />
                <stop offset={1} stopColor="#131438" />
            </linearGradient>
        </defs>
    </SvgIcon>
);
export default SvgBigGeoAi;
SvgBigGeoAi.displayName = "SvgBigGeoAi";
