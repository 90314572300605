import {
    SavedView,
    useFetchMapTemplatesWithSavedViewsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyScreen, LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Routes } from "../../navigation/redux/model";
import SavedViewsView from "../views/SavedViewsView";

const SavedViewsContainer = ({
    toPage,
}: { readonly toPage: (route: string) => void }) => {
    const { remote } = useFetchMapTemplatesWithSavedViewsQuery({
        variables: { input: { limit: 5, pageIndex: 1 } },
    });

    const openNewTab = (url: string) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const onLoadSaveView = (
        currentSavedView: SavedView,
        mapTemplateId: number
    ) => {
        openNewTab(
            `${Routes.mapView}/${mapTemplateId}/map?savedViewId=${currentSavedView.id}`
        );
    };
    return (
        <Stack width={"100%"} height="100%">
            {match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    const maps = data.fetchMapTemplatesWithSavedViews;
                    return !data.fetchMapTemplatesWithSavedViews.some(
                        (t) => t.savedViewExtended.total > 0
                    ) ? (
                        <EmptyScreen
                            title="No Saved Views Created"
                            subtitle="When you or your team perform actions on map templates, the activity can be seen here."
                            image="https://biggeo.blob.core.windows.net/media/Image2.png"
                            buttons={[
                                {
                                    onClick: () => {},
                                    children: "Learn More",
                                },
                            ]}
                        />
                    ) : (
                        <SavedViewsView
                            maps={maps}
                            onClickManage={(id) =>
                                toPage(
                                    `${Routes.mapTemplateSavedViews}/manage/${id}`
                                )
                            }
                            onClickViewAll={(id) => {
                                toPage(`${Routes.mapTemplateSavedViews}/${id}`);
                            }}
                            onClickSavedView={onLoadSaveView}
                        />
                    );
                },
                Failure: (err) => <ErrorPage subtitle={err.message} />,
            })}
        </Stack>
    );
};

export default SavedViewsContainer;
