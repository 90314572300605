import { SavedView } from "@biggeo/bg-server-lib/datascape-ai";

import { ActionType, action } from "typesafe-actions";

interface IModel {
    readonly mapDataRemoteData: Partial<SavedView>;
}

const initialState: IModel = {
    mapDataRemoteData: {},
};

const updateMapData = (p: Partial<SavedView>) => {
    return action("UPDATE_MAP_DATA", p);
};

export const mapDataActions = {
    updateMapData,
};

export type MapDataActionType = ActionType<typeof mapDataActions>;

type CombinedActions = MapDataActionType;

export const mapDataReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: CombinedActions
): IModel => {
    switch (action.type) {
        case "UPDATE_MAP_DATA":
            return {
                ...state,
                mapDataRemoteData: action.payload,
            };

        default:
            return state;
    }
};
