import { MarketplaceDatasetColumn } from "@biggeo/bg-server-lib/datascape-ai";
import { themeSpacingToNumber } from "@biggeo/bg-ui";
import { HorizontalFilledBarChart } from "@biggeo/bg-ui/charts";
import { MenuItem } from "@biggeo/bg-ui/lab";
import { theme } from "@biggeo/bg-ui/theme";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { useState } from "react";
import { horizontalFilledBarChartData } from "../dummyData";

interface IMarketplaceDatasetStatisticsContainer {
    readonly tableName: string;
    readonly columns: MarketplaceDatasetColumn[];
}

const MarketplaceDatasetStatisticsContainer = ({
    columns,
}: IMarketplaceDatasetStatisticsContainer) => {
    const [column, setColumn] = useState<string | undefined>(undefined);

    return (
        <HorizontalFilledBarChart
            title="Area data quality"
            selectChipProps={{
                label: column ? column : "Column",
                variant: "outlined",
                closeOnSelect: true,
                options: pipe(
                    columns,
                    A.map((c) => (
                        <MenuItem
                            key={c.name}
                            density="dense"
                            label={c.name}
                            onClick={() => setColumn(c.name)}
                        />
                    ))
                ),
            }}
            // MARK: Dummy data for now.
            data={horizontalFilledBarChartData}
            barChartProps={{
                responsiveContainerProps: {
                    height: themeSpacingToNumber(theme.spacing(12)),
                },
            }}
            color="chartNegative"
            endColor="background"
            disablePadding
            slotProps={{
                mainContent: {
                    sx: {
                        gap: 1,
                    },
                },
                topDivider: {
                    sx: {
                        display: "none",
                    },
                },
                root: {
                    sx: {
                        border: "none",
                    },
                },
            }}
        />
    );
};

export default MarketplaceDatasetStatisticsContainer;
