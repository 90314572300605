import {
    Button,
    EmptyState,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    MarketplaceBanner,
    MarketplaceRecipeCard,
} from "@biggeo/bg-ui/lab";
import { ExperimentOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import React from "react";

export type MarketplaceRecipesProps = {
    // TODO: replace with recipe backend type
    readonly recipes: readonly Omit<
        React.ComponentProps<typeof MarketplaceRecipeCard<string>>,
        "onClick" | "onConnect" | "onRequest"
    >[];
    readonly onBannerButtonClick: () => void;
};

export const MarketplaceRecipes = ({
    recipes,
    onBannerButtonClick,
}: MarketplaceRecipesProps) => {
    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea gap={4} flexDirection="column" sx={{ padding: 4 }}>
                <MarketplaceBanner
                    title="Recipes"
                    subtitle="Pre-built and ready to go solution"
                    action={
                        <Button
                            color="primary"
                            variant="outlined"
                            density="dense"
                            onClick={() => {
                                // TODO: Redirect to suggest a recipe route
                            }}
                        >
                            Suggest A Recipe
                        </Button>
                    }
                />
                {pipe(
                    // TODO: Replace when backend fetch is available
                    recipes,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.fold(
                        () => (
                            <EmptyState
                                title="No Recipes Added Yet"
                                subtitle="subtitle"
                                icon={<ExperimentOutline size="md" />}
                                buttonText="Suggest A Recipe"
                                onClick={onBannerButtonClick}
                            />
                        ),
                        (recipes) => (
                            <Grid container spacing={4} alignItems="stretch">
                                {pipe(
                                    recipes,
                                    toNonReadonlyArray,
                                    A.map((recipe) => (
                                        <Grid
                                            item
                                            key={recipe.productId}
                                            sx={{ display: "flex" }}
                                            xl={4}
                                            md={6}
                                            xs={12}
                                        >
                                            <MarketplaceRecipeCard
                                                productId={recipe.productId}
                                                icons={recipe.icons}
                                                price={recipe.price}
                                                name={recipe.name}
                                                source={recipe.source}
                                                isAdmin={recipe.isAdmin}
                                                onConnect={(productId) => {
                                                    console.log(
                                                        "onConnect",
                                                        productId
                                                    );
                                                }}
                                                onRequest={({
                                                    type,
                                                    productId,
                                                }) => {
                                                    console.log(
                                                        "onRequest",
                                                        type,
                                                        productId
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        )
                    )
                )}
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
