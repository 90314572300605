import { LogicOperator } from "@biggeo/bg-server-lib/datascape-ai";
import { Stack } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { IFilterCriteriaDatasets } from "../views/FilterCriteriaDatasets";
import FilterCriteriaDatasetItem from "./FilterCriteriaDatasetItem";

type IFilterCriteriaDatasetFilters = Pick<
    IFilterCriteriaDatasets,
    | "filters"
    | "onAddDatasetFilter"
    | "clearAll"
    | "deleteDatasetFilterItem"
    | "removeDataset"
    | "updateDatasetFilterItem"
>;

const FilterCriteriaDatasetFilters = ({
    filters,
    onAddDatasetFilter,
    deleteDatasetFilterItem,
    clearAll,
    removeDataset,
    updateDatasetFilterItem,
}: IFilterCriteriaDatasetFilters) => {
    return (
        <Stack gap={2} width="100%">
            {pipe(
                filters,
                A.map((item) => (
                    <Stack
                        width="100%"
                        key={item.mapTemplateDatasetId}
                        sx={{
                            border: 1,
                            borderStyle: "dashed",
                            borderColor: (theme) => theme.palette.stroke[200],
                            borderRadius: (theme) => theme.radius.default,
                            padding: 2,
                        }}
                    >
                        <FilterCriteriaDatasetItem
                            item={item}
                            onAddDatasetFilter={() =>
                                onAddDatasetFilter(item.mapTemplateDatasetId, {
                                    column: undefined,
                                    type: undefined,
                                    operator: undefined,
                                    value: undefined,
                                })
                            }
                            logicOperator={
                                item.logicOperator || LogicOperator.and
                            }
                            deleteDatasetFilterItem={deleteDatasetFilterItem}
                            clearAll={clearAll}
                            removeDataset={removeDataset}
                            updateDatasetFilterItem={updateDatasetFilterItem}
                        />
                    </Stack>
                ))
            )}
        </Stack>
    );
};

export default FilterCriteriaDatasetFilters;
