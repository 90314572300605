// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Map from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";

export const hexToRgb = (hex: string): string => {
    // Convert hex color to rgb
    const bigint = Number.parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
};

// Create features from geoJSON data
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const createFeatures = (format: GeoJSON) => (data: any) => {
    return format.readFeatures(data, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
    });
};

// Get all layers from map without Tile Layer
export const getLayersWithoutTileLayer = (map: Map | null) => {
    return map?.getLayers().getArray().slice(1);
};

// Get Layer Group from datasetId
export const getLayerGroup =
    (map: Map | null) =>
    <T extends BaseLayer>(datasetId: string): T | undefined => {
        const layers = getLayersWithoutTileLayer(map);

        return layers?.find((d) => d.get("id") === datasetId) as T;
    };

// Get specific layer from datasetId + layer name
export const getLayer =
    (map: Map) =>
    (datasetId: string) =>
    <T extends BaseLayer>(layer: string): T | undefined => {
        const get = getLayerGroup(map);
        const layerGroup = get<LayerGroup>(datasetId);

        return layerGroup
            ?.getLayers()
            .getArray()
            .find((d) => d.get("id") === `${datasetId}-${layer}-layer`) as T;
    };

export const reorderLayers = (map: Map, orderedGroupNames: string[]) => {
    const allLayers = map.getLayers().getArray();

    // Remove all layers
    // biome-ignore lint/complexity/noForEach: <explanation>
    allLayers.forEach((layer) => map.removeLayer(layer));

    // Add them back in the new order
    // biome-ignore lint/complexity/noForEach: <explanation>
    orderedGroupNames.forEach((groupName) => {
        const layerGroup = allLayers.find(
            (layer) => layer.get("id") === groupName
        );
        if (layerGroup) map.addLayer(layerGroup);
    });
};
