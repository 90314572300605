import {
    DataSource,
    MapTemplateDatasetExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { AddDatasetSubMenuContainer } from "../data/containers/AddDatasetSubMenuContainer";

export const AddDataSetSubMenuLayout = ({
    existingDatasets,
    mapTemplateId,
    setRemote,
    localDataList,
    setLocalDataList,
}: {
    readonly existingDatasets: DataSource[];
    readonly mapTemplateId: number;
    readonly setRemote: (v: MapTemplateDatasetExtended[]) => void;
    readonly localDataList: string[];
    readonly setLocalDataList: (v: string[]) => void;
}) => {
    return (
        <AddDatasetSubMenuContainer
            mapTemplateId={mapTemplateId}
            existingDatasets={existingDatasets}
            setRemote={setRemote}
            localDataList={localDataList}
            setLocalDataList={setLocalDataList}
        />
    );
};
