import {
    Box,
    CellItem,
    CompanyAvatar,
    DraggableListItem,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ProgressBar,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    DeleteOutline,
    DragIndicatorOutline,
    EditOutline,
    MoreVertOutline,
    TableRowsOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { ComponentClassNames } from "@biggeo/bg-ui/lab/system";

export type LinearGradientWithId = {
    offset: number;
    color: string;
    id: number;
};

// eslint-disable-next-line react-refresh/only-export-components
export const previewDatasetCellItemClasses: ComponentClassNames<
    "PreviewDatasetCellItem",
    "drag-indicator" | "visibility-icon"
> = {
    "drag-indicator": "BigGeoPreviewDatasetCellItem-dragIndicator",
    "visibility-icon": "BigGeoPreviewDatasetCellItem-visibilityIcon",
};

type Dataset = Readonly<{
    id: string;
    name: string;
    image: string;
    progress: number;
}>;

export type PreviewDatasetCellItemProps = Readonly<{
    index: number;
    dataset: Dataset;
    title?: string;
    disabled?: boolean;
    onClickCard?: (data: Dataset) => void;
    onView?: (id: string) => void;
    onEdit?: (id: string) => void;
    onRemove?: (id: string) => void;
    visibility?: boolean;
    setVisibility?: () => void;
}>;

export const PreviewDatasetCellItem = ({
    index,
    dataset,
    onClickCard,
    onView,
    onEdit,
    onRemove,
    title = "Download preview",
    disabled,
    visibility,
    setVisibility,
}: PreviewDatasetCellItemProps) => {
    const MAX_PROGRESS = 100;

    return (
        <DraggableListItem
            index={index}
            draggableId={dataset.id}
            slotProps={{
                container: {
                    sx: {
                        padding: 0,
                        backgroundColor: (theme) =>
                            theme.palette.background.main,
                    },
                },
            }}
            configuration={{
                alwaysShowDragHandle: true,
            }}
            sx={{
                position: "relative",
                borderRadius: (theme) => theme.radius.default,
                overflow: "hidden",
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                paddingTop: dataset.progress < MAX_PROGRESS ? 8 : 2,
                "&:hover": {
                    [`& .${previewDatasetCellItemClasses["visibility-icon"]}`]:
                        {
                            display: "block",
                        },
                },
            }}
            onClick={() => onClickCard?.(dataset)}
        >
            {dataset.progress < MAX_PROGRESS ? (
                <Grid
                    container
                    gap={1}
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        paddingX: 1,
                        paddingTop: 1,
                    }}
                >
                    <Grid item xs>
                        <Typography
                            variant="body4"
                            fontWeight="semibold"
                            truncate
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body4" fontWeight="semibold">
                            {dataset.progress}% Done
                        </Typography>
                    </Grid>
                    <Tooltip title="Aggregation style" sx={{ maxWidth: 40 }}>
                        <Grid item xs={12}>
                            <ProgressBar
                                value={dataset.progress || 0}
                                color={"success"}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            ) : (
                <Tooltip title="Aggregation style" sx={{ maxWidth: 30 }}>
                    <Box
                        height={2}
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            backgroundImage: `linear-gradient(to right, ${[
                                // TODO: Using random gradient at the team's request
                                { id: 0, offset: 0, color: "#FFFFFF" },
                                { id: 1, offset: 1, color: "#2F5AF0" },
                            ]
                                .map(
                                    (color) =>
                                        `${color.color} ${color.offset * 100}%`
                                )
                                .join(", ")})`,
                        }}
                    />
                </Tooltip>
            )}
            <CellItem
                density="none"
                disableHoverEffect
                disableActiveEffect
                slotProps={{
                    title: {
                        fontWeight: "regular",
                    },
                }}
                sx={{
                    padding: 1,
                    opacity: disabled ? 0.25 : 1,
                    pointerEvents: disabled ? "none" : "auto",
                }}
                title={dataset.name}
                startNode={
                    <Stack flexDirection="row" gap={1} alignItems="center">
                        <DragIndicatorOutline
                            className={
                                previewDatasetCellItemClasses["drag-indicator"]
                            }
                            sx={{ display: "none", cursor: "grab" }}
                        />
                        <CompanyAvatar square size="xxs" src={dataset.image} />
                    </Stack>
                }
                endNode={
                    <Stack flexDirection="row" gap={1} alignItems="center">
                        <IconButton
                            variant="minimal"
                            className={
                                previewDatasetCellItemClasses["visibility-icon"]
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisibility?.();
                            }}
                            sx={{ display: "none" }}
                        >
                            {visibility ? (
                                <VisibilityOutline size="xs" />
                            ) : (
                                <VisibilityOffOutline size="xs" />
                            )}
                        </IconButton>
                        {(onEdit || onView || onRemove) && (
                            <Menu
                                placement="bottom-end"
                                content={
                                    <>
                                        {onEdit && (
                                            <MenuItem
                                                label="Style"
                                                density="dense"
                                                startNode={
                                                    <EditOutline size="xs" />
                                                }
                                                onClick={() =>
                                                    onEdit(dataset.id)
                                                }
                                            />
                                        )}
                                        {onView && (
                                            <MenuItem
                                                label="View table"
                                                density="dense"
                                                startNode={
                                                    <TableRowsOutline size="xs" />
                                                }
                                                onClick={() =>
                                                    onView(dataset.id)
                                                }
                                            />
                                        )}
                                        {onRemove && (
                                            <MenuItem
                                                label="Remove"
                                                density="dense"
                                                startNode={
                                                    <DeleteOutline size="xs" />
                                                }
                                                color="error"
                                                onClick={() =>
                                                    onRemove(dataset.id)
                                                }
                                            />
                                        )}
                                    </>
                                }
                            >
                                <IconButton variant="minimal">
                                    <MoreVertOutline size="sm" />
                                </IconButton>
                            </Menu>
                        )}
                    </Stack>
                }
            />
        </DraggableListItem>
    );
};
