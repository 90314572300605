import { Grid, Hidden, IconButton, Stack, Tag } from "@biggeo/bg-ui/lab";
import { MagicButtonOutline, MenuOutline } from "@biggeo/bg-ui/lab/icons";
import { VelocityButton } from "../common/components/VelocityButton";

export type HeaderProps = {
    openSidebar?: () => void;
};

export const Header = ({ openSidebar }: HeaderProps) => {
    return (
        <Stack gap={4}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                flexWrap={"nowrap"}
                gap={4}
            >
                <Grid item>
                    <Grid container alignItems="center" gap={4}>
                        <Hidden variant="up" item>
                            <IconButton
                                variant="outlined"
                                density="dense"
                                onClick={openSidebar}
                            >
                                <MenuOutline />
                            </IconButton>
                        </Hidden>
                        {/* <Hidden item>
                            <VelocityButton variant="clickable" />
                        </Hidden> */}
                    </Grid>
                    <Tag
                        startNode={<MagicButtonOutline size={"xxs"} />}
                        density="dense"
                        variant="filled"
                        color="primary"
                        rounded
                    >
                        Beta
                    </Tag>
                </Grid>
                {/* Hidden at Jose's request */}
                {/* <Grid item>
                <Grid container gap={2} flexWrap="nowrap">
                    <IconButton variant={"outlined"}>
                        <ShoppingCartOutline size="xs" />
                    </IconButton>
                    <IconButton variant={"outlined"}>
                        <NotificationsOutline size="xs" />
                    </IconButton>
                </Grid>
            </Grid> */}
            </Grid>
        </Stack>
    );
};
