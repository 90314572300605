import { EmptyScreen } from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";

export const AllRecipes = () => {
    return (
        <EmptyScreen
            title="No Recipes Added"
            subtitle="You haven’t added any recipes yet. When you purchase recipes from the marketplace, they will appear here."
            image={"https://biggeo.blob.core.windows.net/media/Image3.png"}
            buttons={[
                {
                    children: "Find Recipe",
                    startNode: <ActionKeyOutline size="xs" />,
                    onClick: () => {
                        // TODO: navigate to add recipe
                    },
                },
                {
                    children: "Request Recipe",
                    onClick: () => {
                        // TODO: navigate to request recipe
                    },
                },
            ]}
        />
    );
};
