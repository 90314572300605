import { Grid, HorizontalScroller, Stack, Typography } from "@biggeo/bg-ui/lab";
import { Circle } from "@biggeo/bg-ui/lab/icons";
import { ReactNode } from "react";

export type IconLegendProps = {
    value?: ReactNode;
    label: ReactNode;
    color: string;
};

const IconLegend = ({
    values,
    isCompute,
    spaceBetweenItems,
}: {
    values: IconLegendProps[];
    isCompute?: boolean;
    spaceBetweenItems?: boolean;
}) => {
    return (
        <HorizontalScroller
            slotProps={{
                scrollContainer: {
                    gap: 2,
                    justifyContent: `${spaceBetweenItems ? "space-between" : "flex-start"}`,
                },
                leftButton: { density: "none" },
                rightButton: { density: "none" },
            }}
        >
            {values.map((data) => {
                return (
                    <Grid
                        container
                        key={`${data.label}-${data.color}`}
                        gap={1}
                        flexWrap="nowrap"
                    >
                        <Stack gap={1}>
                            <Grid
                                container
                                gap={1}
                                alignItems="center"
                                flexWrap="nowrap"
                            >
                                <Circle
                                    size="xxs"
                                    sx={{
                                        color: `${data.color}`,
                                    }}
                                />

                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                >
                                    {data.value}
                                </Typography>
                                {isCompute && data.label}
                            </Grid>
                            {!isCompute && (
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                >
                                    {data.label}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                );
            })}
        </HorizontalScroller>
    );
};

export default IconLegend;
