import { Grid, Stack, Typography } from "@biggeo/bg-ui/lab";
import { Box, Image } from "@biggeo/bg-ui/lab";
import { OpenInNewOutline } from "@biggeo/bg-ui/lab/icons";

type UseCaseCard = {
    readonly imgUrl: string;
    readonly title: string;
    readonly onClick: (id: string) => void;
};

export const UseCaseCard = ({ imgUrl, title, onClick }: UseCaseCard) => {
    return (
        <Stack
            gap={4}
            sx={{
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                padding: 2,
            }}
        >
            <Box
                sx={{
                    height: (theme) => theme.spacing(46.7),
                    width: "100%",
                    borderRadius: 5,
                    overflow: "hidden",
                    position: "relative",
                    backgroundImage: `url(${imgUrl || ""})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
                onClick={() => onClick}
            >
                <Image
                    src={imgUrl || ""}
                    alt={title || "use case image"}
                    height="100%"
                    width="100%"
                    sx={{ objectFit: "cover", layout: "fill" }}
                />
            </Box>

            <Grid
                container
                sx={{ cursor: "pointer", justifyContent: "space-between" }}
                onClick={() => {}}
                gap={1}
            >
                <Grid item>
                    <Typography variant="body3" fontWeight="semibold">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <OpenInNewOutline />
                </Grid>
            </Grid>
        </Stack>
    );
};
