import * as turf from "@turf/turf";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";

export const createCircleFeature = (
    coordinate: [number, number],
    radius: number
) => {
    const circle = turf.circle(coordinate, radius, {
        steps: 100,
        units: "kilometers",
        properties: {},
    });

    // Convert the Turf.js circle to a GeoJSON feature
    const circleFeature = new Feature({
        geometry: new GeoJSON().readGeometry(circle.geometry, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
        }),
    });

    return circleFeature;
};
