import { Chip, IconAvatar } from "@biggeo/bg-ui/lab";
import { VelocityIcon } from "@biggeo/bg-ui/lab/icons";
import { useNavigate } from "react-router";
import { Routes } from "../../navigation/redux/model";
import { hasBgVelocity } from "../redux/hooks";

export type VelocityButtonProps = {
    variant?: "clickable" | "readonly";
    hasVelocity?: boolean;
    onClick?: () => void;
};

export const VelocityButton = ({
    variant = "readonly",
    hasVelocity,
    onClick,
}: VelocityButtonProps) => {
    const velocity = hasVelocity !== undefined ? hasVelocity : hasBgVelocity();
    const navigate = useNavigate();

    const handleGetVelocity = () => navigate(Routes.velocity);
    const handleGoToApiKey = () => navigate(Routes.velocityApiKey);

    return (
        <Chip
            variant={!velocity ? "filled" : "outlined"}
            color={!velocity ? "primary" : "surface"}
            rounded
            density="dense"
            startNode={
                <IconAvatar size={5} sx={{ flexShrink: 0 }} color="background">
                    <VelocityIcon size="xxs" />
                </IconAvatar>
            }
            readonly={velocity && variant === "readonly"}
            slotProps={{
                label: {
                    textColor:
                        velocity && variant === "readonly"
                            ? "disabled.onContainer"
                            : "inherit",
                },
            }}
            onClick={
                onClick
                    ? onClick
                    : velocity
                      ? handleGoToApiKey
                      : handleGetVelocity
            }
        >
            {velocity
                ? variant === "clickable"
                    ? "Velocity API"
                    : "Enabled"
                : "Get Velocity"}
        </Chip>
    );
};
