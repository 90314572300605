import {
    AspectRatio,
    Box,
    Button,
    Image,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";

import { alpha } from "@biggeo/bg-ui";
import { useNavigate } from "react-router";
import { Routes } from "../../navigation/redux/model";

export const BGVelocityUnlocked = ({
    title,
    subtitle,
}: { readonly title?: string; readonly subtitle?: string }) => {
    const toPage = useNavigate();

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                containerType: "inline-size",
                minWidth: 0,
                display: "flex",
                position: "relative",
                background: (theme) => theme.palette.background.container,
                color: (theme) => theme.palette.background.onContainer,
                justifyContent: "center",
                paddingX: 1,
                paddingBottom: 2.5,
                breakpoints: {
                    xs: {
                        paddingTop: 8,
                    },
                    md: {
                        paddingTop: 35,
                    },
                },
            }}
        >
            <Stack
                gap={4}
                sx={{
                    zIndex: 1,
                    paddingY: 10,
                }}
            >
                <Box
                    sx={{
                        borderRadius: 25,
                        backgroundColor: alpha("#2F5AF0", 0.06),
                        alignSelf: "center",
                        justifyContent: "center",
                        padding: 5,
                        width: "34",
                        height: "34",
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: 25,
                            backgroundColor: alpha("#2F5AF0", 0.08),
                            padding: 5,
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: 25,
                                backgroundColor: alpha("#2F5AF0", 0.1),
                                padding: 5,
                            }}
                        >
                            <ThumbnailAvatar
                                src="https://biggeo.blob.core.windows.net/media/fdsa.png"
                                size="lg"
                            />
                        </Box>
                    </Box>
                </Box>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="135"
                >
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="background"
                        colorSet="main"
                        invertColors
                        sx={{ textAlign: "center" }}
                    >
                        {title ? title : "Crazy Speeds Unlocked"}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="disabled"
                        colorSet="container"
                        invertColors
                        sx={{ textAlign: "center" }}
                    >
                        {subtitle ? (
                            subtitle
                        ) : (
                            <>
                                Enjoy the full power Datascape with Velocity
                                now!
                            </>
                        )}
                    </Typography>
                </Stack>
                <Button
                    sx={{ alignSelf: "center" }}
                    onClick={() => toPage(`${Routes.explore}`)}
                >
                    Let's Go!
                </Button>
            </Stack>

            <AspectRatio
                variant="3/2"
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "70%",
                    minWidth: "500px",
                    transform: "translateX(30%)",
                }}
            >
                <Image
                    src="https://biggeo.blob.core.windows.net/media/83fcb7fab47a0cae9739dff1799f7bce.png"
                    width="100%"
                    height="100%"
                />
            </AspectRatio>
        </Box>
    );
};
