import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useMap } from "../../map/mapbox/context";
import { getMapFeatures } from "../../map/utils/utils";

export type OnMapLeaveProps = {
    children: React.ReactNode;
};

const OnMapLeave = ({ children }: OnMapLeaveProps) => {
    const { draw, isLoaded } = useMap();
    const trigger = pipe(
        getMapFeatures(draw, isLoaded),
        O.fromPredicate((f) => !isEmpty(f)),
        O.fold(
            () => false,
            () => true
        )
    );

    /*
        Handle page refresh.
    */
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isEqual(trigger, true)) {
                e.preventDefault();

                return trigger;
            }
        };

        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        };
    }, [trigger]);

    return children;
};

export default OnMapLeave;
