import { ComponentProps } from "react";

export type BigGeoLinkProps = ComponentProps<"a"> & {
    variant?: "redirect" | "new-tab";
};

export const BigGeoLink = ({
    variant = "redirect",
    target = variant === "new-tab" ? "_blank" : undefined,
    style,
    ...props
}: BigGeoLinkProps) => {
    return (
        <a
            target={target}
            style={{ textDecoration: "none", color: "inherit", ...style }}
            {...props}
        />
    );
};
