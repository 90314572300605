import { Stack } from "@biggeo/bg-ui/lab";
import { ComponentProps } from "react";

export type BorderedStackProps = ComponentProps<typeof Stack> & {
    padding?: number;
};

export const BorderedStack = ({
    padding = 4,
    sx,
    ...stackProps
}: BorderedStackProps) => {
    return (
        <Stack
            sx={{
                padding,
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                borderRadius: (theme) => theme.radius.xs3,
                ...sx,
            }}
            {...stackProps}
        />
    );
};
