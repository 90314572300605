import {
    Button,
    FlexScrollAreaContainer,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import React from "react";

export const DggsIndexColumnErrorPage = ({
    onClick,
}: { readonly onClick?: () => void }) => {
    return (
        <FlexScrollAreaContainer
            sx={{
                paddingY: 8,
                paddingX: 4,
                backgroundImage:
                    "url(https://biggeo.blob.core.windows.net/test/28b59452-a0b4-5e44-7ef5-ebf95a6b85dc.png.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right",
                breakpoints: {
                    xl: {
                        backgroundSize: "35%",
                    },
                    lg: {
                        backgroundSize: "40%",
                    },
                    md: {
                        backgroundSize: "45%",
                    },
                    xs: {
                        backgroundSize: "50%",
                    },
                },
            }}
        >
            <Stack
                gap={8}
                alignItems="center"
                justifyContent="center"
                height="100%"
            >
                <Stack>
                    <Typography variant="h4" fontWeight="bold">
                        We couldn't find what you're looking for
                    </Typography>
                    <Typography variant="body1">
                        To proceed, please update your dataset with the
                        necessary column.
                    </Typography>
                </Stack>
                <Button
                    color="primary"
                    onClick={onClick}
                    sx={{
                        borderRadius: (theme) => theme.radius.full,
                    }}
                >
                    Read Documentation
                </Button>
            </Stack>
        </FlexScrollAreaContainer>
    );
};
