import {
    CreateEditDeleteMapTemplate,
    useCreateEditDeleteMapTemplateMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { InfoCell, Typography, UserAvatar } from "@biggeo/bg-ui/lab";

import { useDispatch } from "react-redux";
import { compose } from "redux";
import { DeleteModal } from "../../common/components/DeleteModal";
import { modalActions } from "../../modal/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";

export const DeleteMapTemplateModal = ({
    template,
    navigate,
}: {
    readonly template: CreateEditDeleteMapTemplate;
    readonly navigate: (to?: string) => void;
}) => {
    const dispatch = useDispatch();
    const closeModal = compose(dispatch, modalActions.closeModal);

    const {
        executeMutation: deleteMapTemplate,
        mutationReturn: [_remove],
    } = useCreateEditDeleteMapTemplateMutation();

    const handleDeleteMapTemplate = ({
        template,
        deletedAt,
    }: {
        readonly template: CreateEditDeleteMapTemplate;
        readonly deletedAt: Date;
    }) => {
        deleteMapTemplate({
            variables: {
                input: {
                    id: template.id,
                    name: template.name,
                    status: template.status,
                    deletedAt,
                },
            },
            onCompleted: () => {
                closeModal();
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Map template deleted successfully!",
                        autoHideDuration: 5000,
                    })
                );
                navigate();
            },
        });
    };

    return (
        <DeleteModal
            subTitle={
                "This map template will be removed permanently from your organization"
            }
            body={
                <InfoCell
                    density={"none"}
                    startNode={
                        <UserAvatar
                            alt={template.name}
                            size="md"
                            src={template.thumbnail || ""}
                            sx={{
                                borderRadius: (theme) => theme.radius.default,
                            }}
                        />
                    }
                    title={
                        <Typography variant="body2" fontWeight="bold">
                            {template.name}
                        </Typography>
                    }
                />
            }
            description={template.description || undefined}
            onRemove={() => {
                handleDeleteMapTemplate({
                    template,
                    deletedAt: new Date(),
                });
            }}
        />
    );
};
