import { Box, EmptyScreen, Stack, TopAppBar } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";

export const TeamViews = () => {
    return (
        <Stack height="100%">
            <TopAppBar title="My Team" density="dense" divider />
            <Box
                sx={{
                    padding: 4,
                    flexGrow: 1,
                }}
            >
                <EmptyScreen
                    title="No Team Members On This Product"
                    subtitle="You don’t have any team members to this product. Team members added to this product will appear here."
                    image={
                        "https://biggeo.blob.core.windows.net/media/Image3.png"
                    }
                    buttons={[
                        {
                            children: "Invite Member",
                            startNode: <AddOutline size="xs" />,
                            onClick: () => {
                                // TODO: navigate to add member
                            },
                        },
                        {
                            children: "Learn More",
                            onClick: () => {
                                // TODO: navigate to learn more
                            },
                        },
                    ]}
                />
            </Box>
        </Stack>
    );
};
