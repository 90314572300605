import { useState } from "react";

export const Testsql = () => {
    const [sql, setSql] = useState("");
    const [result, setResult] = useState("");
    const runSql = (sql: string) => {
        const host = window.location.host.includes("local")
            ? "http://localhost:5000/sql"
            : "/sql";
        console.log(host);
        fetch(host, {
            method: "post",
            body: JSON.stringify({ sql }),
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setResult(JSON.stringify(result, null, 2));
            });
    };
    const getEnvs = () => {
        const host = window.location.host.includes("local")
            ? "http://localhost:5000/env"
            : "/env";
        console.log(host);
        fetch(host, {
            method: "get",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setResult(JSON.stringify(result, null, 2));
            });
    };
    return (
        <div style={{ margin: 20 }}>
            <textarea
                onChange={(e) => setSql(e.target.value)}
                rows={10}
                cols={100}
                onKeyUp={(event) => {
                    event.key === "Enter" && runSql(sql);
                }}
            />
            {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
            <button onClick={() => runSql(sql)}>send</button>
            {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
            <button onClick={() => getEnvs()}>envs</button>
            {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
            <button
                onClick={() => {
                    throw Error("Frontend error");
                }}
            >
                error
            </button>
            <pre>{result}</pre>
        </div>
    );
};

// export default Testsql;
