import { Box } from "@biggeo/bg-ui/lab";
import { ComponentProps } from "react";
import { MapBoxIcon } from "./MapBoxIcon";

export type CustomMapMarkerProps = Omit<
    ComponentProps<typeof MapBoxIcon>,
    "color"
> & {
    color?: string;
    shape?: "oval" | "square";
};

export const CustomMapMarker = ({
    color,
    shape = "oval",

    icon,
    ...props
}: CustomMapMarkerProps) => {
    return (
        <Box
            sx={{
                padding: 1,
                background: color,
                color: "white",
                borderRadius: (theme) =>
                    shape === "oval" ? theme.radius.full : 0,
            }}
        >
            <MapBoxIcon icon={icon} {...props} />
        </Box>
    );
};
