import { useState } from "react";

export type RenderControlsHookReturnType = {
    showTriangles: boolean;
    setShowTriangles: React.Dispatch<React.SetStateAction<boolean>>;
    showPoints: boolean;
    setShowPoints: React.Dispatch<React.SetStateAction<boolean>>;
    showFiltered: boolean;
    setShowFiltered: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useRenderControls = (): RenderControlsHookReturnType => {
    const [showTriangles, setShowTriangles] = useState(true);
    const [showPoints, setShowPoints] = useState(true);
    const [showFiltered, setShowFiltered] = useState(true);

    return {
        showTriangles,
        setShowTriangles,
        showPoints,
        setShowPoints,
        showFiltered,
        setShowFiltered,
    };
};
