import {
    DataSource,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
    useFetchMapTemplateDatasetsExtendedConnectionQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    EmptyScreen,
    ExitIntentModal,
    LoadingBar,
    Stack,
    Typography,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import * as R from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import some from "lodash/some";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { compose } from "redux";
import { ErrorPage } from "../../../common/components/ErrorPage";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { modalActions } from "../../../modal/redux/model";
import { Routes } from "../../../navigation/redux/model";
import { iLike } from "../../utils/utils";
import MapDatasetsConfigGrid from "./MapDatasetsConfigGrid";
import MapTemplateManageCard from "./MapTemplateManageCard";

const UNINDEXED_DATASET_INFO =
    "Your datasets that need to be indexed by BigGeo first will appear here.";
const No_DATASET_INFO =
    "You don't have any datasets from your data management area enabled for this map template.";

interface IMapDatasetsConfigView {
    readonly route?: "data" | "configuration";
    readonly mapTemplateId: number;
    readonly manageData: (id: number) => void;
    readonly hideBanner?: boolean;
}

const MapDatasetsConfigView = ({
    route,
    mapTemplateId,
    manageData,
    hideBanner = false,
}: IMapDatasetsConfigView) => {
    const isRunningOnSF = isAppRunningOnSF();
    const toPage = useNavigate();
    const isDataRoute = isEqual(route, "data");
    const { dataGridFetchInputProps } = useDataGridOptions(20);

    const reduxDispatch = useDispatch();
    const openModal = compose(reduxDispatch, modalActions.openModal);
    const closeModal = compose(reduxDispatch, modalActions.closeModal);

    const { remote, setRemote } =
        useFetchMapTemplateDatasetsExtendedConnectionQuery({
            variables: {
                input: {
                    fkMapTemplateId: mapTemplateId,
                },
            },
        });

    const open = (id: number) => {
        openModal({
            modalType: "new-dialog",
            component: (
                <ExitIntentModal
                    subtitle={
                        "You're about to leave this map template, consider creating a saved view to save your progress."
                    }
                    discard={closeModal}
                    save={() => {
                        closeModal();
                        manageData(id);
                    }}
                    buttons={{
                        text1: "Close Modal",
                        text2: "Go to Manage Data",
                    }}
                    withLoading={false}
                />
            ),
            dialogProps: {
                variant: "centered-small",
            },
        });
    };

    return R.match(remote, {
        _: () => <LoadingBar />,
        Success: ({ fetchMapTemplateDatasetsExtendedConnection: data }) => {
            const { count } = data;
            const mapTemplateData = first(data?.mapTemplateDatasets);
            const mapTemplateDatasets = pipe(
                data.mapTemplateDatasets,
                A.filter(
                    (d) =>
                        d.mapTemplateDataset !== null &&
                        d.mapTemplateDataset !== undefined &&
                        d.dataSource?.isConnected === true
                ),
                A.filter((d) =>
                    iLike({
                        text:
                            d.dataSource?.label ||
                            d.dataSource?.tableName ||
                            d.dataSource?.description,
                        pattern: dataGridFetchInputProps.searchText,
                    })
                )
            );

            const setMapTemplateDataset = (i: {
                mapTemplateDatasetId: number;
                mapTemplateDataset?: MapTemplateDataset;
                dataSource?: DataSource;
            }) => {
                const { mapTemplateDatasetId, mapTemplateDataset, dataSource } =
                    i;
                setRemote(
                    new R.Success({
                        fetchMapTemplateDatasetsExtendedConnection: {
                            count: data.count,
                            mapTemplateDatasets: pipe(
                                data.mapTemplateDatasets,
                                A.map((dataset) => {
                                    if (
                                        isEqual(
                                            dataset.mapTemplateDataset?.id,
                                            mapTemplateDatasetId
                                        )
                                    ) {
                                        return {
                                            ...dataset,
                                            ...omitBy(
                                                {
                                                    mapTemplateDataset,
                                                    dataSource,
                                                },
                                                isNil
                                            ),
                                        };
                                    }
                                    return dataset;
                                })
                            ),
                        },
                    })
                );
            };

            return pipe(
                mapTemplateDatasets,
                O.fromPredicate((d: MapTemplateDatasetExtended[]) =>
                    some(
                        d,
                        (item: MapTemplateDatasetExtended) =>
                            !isEmpty(item.dataSource)
                    )
                ),
                O.fold(
                    () => {
                        const leanMoreRoute =
                            "https://datascape.featurebase.app/help/articles/6375162-use-map-datasets";

                        return (
                            <EmptyScreen
                                title={
                                    isDataRoute
                                        ? `No Datasets ${mapTemplateId ? "Enabled" : "Available"}`
                                        : "No Datasets Enabled"
                                }
                                subtitle={
                                    (isDataRoute &&
                                        (mapTemplateId
                                            ? No_DATASET_INFO
                                            : UNINDEXED_DATASET_INFO)) ||
                                    No_DATASET_INFO
                                }
                                image={
                                    isDataRoute
                                        ? "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                                        : "https://biggeo.blob.core.windows.net/test/f0896fd5-e036-2957-9c1c-61dfa1ff8cf9.png.png"
                                }
                                buttons={[
                                    {
                                        startNode: <AddOutline />,
                                        onClick: () => {
                                            toPage(
                                                `${Routes.mapTemplatesManage}/${mapTemplateId}`
                                            );
                                        },
                                        children: "Enable Datasets",
                                        variant: "filled",
                                        color: "primary",
                                    },
                                    {
                                        onClick: () => {
                                            if (isDataRoute)
                                                toPage(
                                                    `${Routes.data}/available`
                                                );
                                        },
                                        children: isDataRoute ? (
                                            "Index Data"
                                        ) : (
                                            <Link to={leanMoreRoute}>
                                                <Typography
                                                    variant="body3"
                                                    fontWeight="semibold"
                                                >
                                                    Learn More
                                                </Typography>
                                            </Link>
                                        ),
                                    },
                                ]}
                            />
                        );
                    },
                    () => (
                        <Stack height="100%" gap={2}>
                            {!hideBanner && (
                                <MapTemplateManageCard
                                    id={mapTemplateId}
                                    name={
                                        mapTemplateData?.mapTemplate?.name || ""
                                    }
                                    thumbnail={
                                        mapTemplateData?.mapTemplate
                                            ?.thumbnail || undefined
                                    }
                                    manage={open}
                                />
                            )}
                            <MapDatasetsConfigGrid
                                route={route}
                                setMapTemplateDataset={setMapTemplateDataset}
                                isRunningOnSF={isRunningOnSF}
                                mapTemplateId={mapTemplateId}
                                totalRows={count}
                            />
                        </Stack>
                    )
                )
            );
        },
        Failure: (err) => {
            return <ErrorPage subtitle={err.message} />;
        },
    });
};

export default MapDatasetsConfigView;
