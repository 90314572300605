import {
    CollectionProgressStatus,
    Maybe,
    useListenToProcessCollectionSubscription,
} from "@biggeo/bg-server-lib/datascape-ai";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { databaseMetaDataActions } from "../../database-meta-data/redux/model";
import { SetDatasetContextType } from "../../map/mapbox/context/context-utils";

export type DatasourceDownloadProgressWrapperProps = Readonly<
    {
        datasourceId: string;
        children: React.FC<
            Readonly<{
                progress: string;
                status: Maybe<CollectionProgressStatus>;
            }>
        >;
    } & Pick<SetDatasetContextType, "updateDataset">
>;

export const DatasourceDownloadProgressWrapper = ({
    children,
    datasourceId,
    updateDataset,
}: DatasourceDownloadProgressWrapperProps) => {
    const { data } = useListenToProcessCollectionSubscription({
        variables: {
            channel: datasourceId,
        },

        context: { clientName: "node" },
        fetchPolicy: "no-cache",
        shouldResubscribe: true,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            data &&
            data?.listenToProcessCollection.status ===
                CollectionProgressStatus.complete
        ) {
            updateDataset({
                dataSourceId: datasourceId,
                dataset: {
                    isSelected: true,
                    isVisible: true,
                    dataSource: {
                        compute: true,
                        isConnected: true,
                        progress: 100,
                    },
                },
            });
        }
    }, [data?.listenToProcessCollection.status]);

    return children({
        progress: data?.listenToProcessCollection.progress ?? "0.00",
        status:
            data?.listenToProcessCollection.status ??
            CollectionProgressStatus.initializing,
    });
};
