export const bgsearch = `63500,1,0.0
50008,14568,0.0
6008,29114,0.0
46013,43674,0.0
3003,58196,0.0
2515,72752,0.0
8512,87291,0.01
16051,101851,0.01
6525,116432,0.01
35030,130960,0.01
35033,145659,0.01
51511,160015,0.01
15055,174713,0.01
35075,189134,0.01
30598,203802,0.02
21052,218312,0.01
75040,232866,0.01
21055,247440,0.02
52564,261920,0.01
44076,276490,0.02
11043,291050,0.01
6577,305480,0.02
18061,320140,0.01
21541,334696,0.02
20094,349212,0.02
6602,363660,0.02
41532,378229,0.02
15543,392770,0.01
71091,407334,0.04
36562,421944,0.04
29056,436381,0.02
9135,450975,0.02
19116,465582,0.02
19629,480012,0.02
68046,494549,0.02
45577,509346,0.03
74111,524047,0.03
3554,538657,0.04
3136,552858,0.03
7624,567497,0.03
50547,582039,0.03
36065,596660,0.05
73161,610967,0.04
26053,625661,0.04
46068,640009,0.04
24559,654597,0.07
29090,669145,0.06
69548,683994,0.05
57574,698486,0.03
44676,712833,0.05
15593,727504,0.04
36578,742042,0.06
68071,756492,0.05
45597,770958,0.06
3151,785558,0.06
26071,800178,0.03
57582,814634,0.04
59004,829130,0.03
28122,843847,0.06
52604,858288,0.05
63007,872736,0.04
2603,887342,0.04
47129,901883,0.04
52625,916425,0.05
63011,930939,0.05
7656,945461,0.07
668,960488,0.04
41017,974648,0.03
52638,989131,0.05
34518,1003653,0.03
1656,1018407,0.04
18520,1032830,0.04
8021,1047290,0.04
8022,1061945,0.07
12525,1076375,0.04
10089,1091055,0.09
28530,1105578,0.06
10666,1120039,0.06
47539,1134562,0.07
7037,1149194,0.05
68539,1163681,0.07
40036,1178252,0.04
69048,1192809,0.08
64690,1207761,0.07
45632,1222202,0.05
74188,1236445,0.09
6130,1251457,0.05
49626,1265510,0.1
74197,1280041,0.05
44191,1294577,0.06
13051,1309408,0.06
52667,1323669,0.06
1692,1338221,0.07
18206,1352763,0.06
45639,1367340,0.09
54661,1381823,0.06
57612,1396379,0.07
29728,1410981,0.08
23558,1425788,0.06
7736,1440022,0.06
10738,1454681,0.07
18554,1469100,0.09
66219,1483768,0.06
20219,1498224,0.09
7739,1512817,0.09
16204,1527451,0.07
51242,1542060,0.07
43194,1556840,0.06
13554,1571057,0.07
20578,1585592,0.07
72200,1600033,0.06
74247,1614782,0.1
15177,1629161,0.06
15180,1643642,0.1
20237,1658241,0.08
22145,1672768,0.06
54675,1687511,0.06
67063,1701870,0.07
6770,1716414,0.08
74275,1730926,0.08
40570,1745697,0.07
46576,1760098,0.07
34268,1774618,0.1
16212,1789251,0.08
47189,1803786,0.09
14607,1818188,0.08
14077,1833189,0.08
72219,1847345,0.09
29157,1861969,0.07
66270,1876535,0.08
31590,1890925,0.09
21592,1905495,0.08
68114,1920067,0.08
6784,1934579,0.08
51626,1949150,0.09
26623,1964525,0.09
19290,1978300,0.08
19295,1992948,0.09
56576,2007293,0.08
51297,2021887,0.08
56165,2036425,0.08
61097,2051218,0.09
74306,2065581,0.09
22579,2080104,0.11
39086,2094871,0.08
65239,2109129,0.1
30083,2123652,0.12
40082,2138247,0.09
56591,2152852,0.1
51326,2167394,0.1
38586,2181844,0.09
36610,2196473,0.1
13088,2210990,0.1
27197,2225498,0.11
39089,2240074,0.09
68152,2254583,0.1
52767,2269115,0.1
72280,2283807,0.11
13634,2298645,0.1
12092,2312929,0.11
72285,2327339,0.11
95,2342270,0.11
46620,2356780,0.11
60598,2371101,0.12
37600,2385603,0.11
13642,2400593,0.11
50142,2414737,0.12
9603,2429566,0.11
67106,2443712,0.11
67112,2458209,0.1
18614,2472827,0.11
19318,2487284,0.13
32123,2501825,0.12
69664,2516556,0.1
62143,2530957,0.12
44795,2545620,0.11
31625,2560317,0.13
4161,2574559,0.13
73610,2589336,0.14
68193,2603880,0.12
2122,2618338,0.15
21154,2632789,0.12
12625,2647349,0.11
59129,2661864,0.1
16671,2676448,0.13
59135,2690961,0.12
32639,2705548,0.11
23631,2720049,0.13
11152,2734780,0.13
38146,2749119,0.1
37650,2763645,0.1
66655,2778302,0.1
57662,2792999,0.14
68209,2807312,0.12
20664,2821970,0.11
68213,2836415,0.14
62183,2850958,0.14
14197,2865463,0.13
71190,2880295,0.15
26225,2894558,0.13
35218,2909128,0.13
22670,2923733,0.11
72673,2938226,0.11
50712,2952785,0.12
55161,2967871,0.14
18680,2981935,0.11
7180,2996380,0.12
46717,3010935,0.13
23661,3025474,0.12
8184,3040266,0.11
40727,3054557,0.16
28689,3069100,0.14
26755,3083993,0.15
42183,3098215,0.16
12694,3112775,0.12
30198,3127376,0.12
26276,3141844,0.13
66699,3156396,0.13
9269,3171176,0.14
51775,3185563,0.15
19328,3200072,0.14
6194,3214616,0.19
41710,3229274,0.15
73341,3243722,0.15
54214,3258208,0.16
29841,3272854,0.15
49703,3287304,0.17
21657,3301852,0.16
49178,3316650,0.19
56220,3330944,0.16
59722,3345487,0.17
16291,3360056,0.16
7872,3374582,0.19
3663,3389117,0.14
48378,3403646,0.15
17703,3418192,0.15
14253,3432743,0.18
9713,3447311,0.17
51382,3461839,0.15
36202,3476530,0.16
12233,3490919,0.17
60743,3505496,0.14
16783,3520056,0.15
23732,3534557,0.18
40269,3549124,0.17
28299,3563725,0.21
55202,3578550,0.15
73766,3592756,0.14
44893,3607507,0.2
19365,3621884,0.17
1312,3636500,0.16
50268,3651040,0.17
68313,3665504,0.16
24242,3680176,0.16
26824,3694768,0.2
69818,3709107,0.15
69825,3723892,0.15
62704,3738572,0.16
42210,3752965,0.16
55220,3767335,0.21
15297,3782000,0.18
30761,3796394,0.18
29874,3810934,0.18
37283,3825467,0.2
73786,3840036,0.15
38311,3854673,0.16
35817,3869102,0.16
62364,3883733,0.22
12321,3898197,0.16
68821,3912860,0.16
56835,3927310,0.16
53246,3941842,0.21
24262,3956395,0.16
2745,3970988,0.19
43345,3985471,0.2
72349,4000135,0.18
40337,4014629,0.2
71871,4029175,0.17
898,4043905,0.18
36276,4058204,0.18
72831,4072800,0.16
47836,4087390,0.22
71253,4101875,0.22
6896,4116682,0.23
27273,4131078,0.17
35389,4145508,0.19
44362,4160036,0.19
57342,4174722,0.18
72834,4189102,0.22
23398,4203671,0.2
57346,4218317,0.22
9843,4232747,0.18
9845,4247379,0.18
45332,4261875,0.23
49250,4276496,0.2
14789,4290931,0.24
46891,4305504,0.21
24889,4320012,0.22
73457,4335094,0.2`;

export const pinot = `1500,15,0.01
3002,17772,0.03
1505,29288,0.07
505,43703,0.07
1507,60546,0.07
510,73395,0.09
2517,87618,0.07
2528,101873,0.12
2535,122057,0.1
3507,131128,0.13
521,145849,0.12
525,160349,0.11
2536,174576,0.19
2546,188864,0.18
1002,205320,0.17
531,217735,0.22
532,233070,0.23
2558,256086,0.31
534,262383,0.28
1005,278443,0.26
2561,290555,0.33
2564,304700,0.31
1020,319167,0.29
545,333928,0.32
546,347971,0.42
1521,362394,0.29
1032,378314,0.39
1038,391559,0.33
3527,406255,0.28
1544,420721,0.41
1552,435889,0.4
1057,449839,0.42
3542,464076,0.5
1063,478387,0.42
3546,496024,0.41
1566,509315,0.48
2603,523692,0.43
1074,537234,0.46
2607,551957,0.48
2617,565702,0.59
3557,583937,0.53
1573,596265,0.46
2640,611641,1.11
2645,624730,1.17
2001,639273,0.62
3560,659155,0.71
2650,668146,1.09
555,683728,0.77
1088,696185,0.58
3564,712650,0.73
1579,728669,0.59
1582,742499,0.73
3567,754326,0.68
3569,770241,0.75
2002,782754,0.74
557,800789,0.81
557,800789,0.81
1588,827142,0.83
1095,845167,0.79
1592,856097,0.85
2005,870574,0.76
1598,885274,1.01
1600,898727,0.75
2652,913691,1.37
2011,929221,0.83
2656,942613,1.34
2662,958605,1.39
3011,973682,0.71
3014,988524,0.77
3020,1000117,0.77
561,1015449,0.9
3024,1031304,0.72
2020,1045446,0.76
31,1065964,1.04
2024,1075518,0.82
2027,1089267,0.82
1616,1102167,0.97
3036,1117437,0.88
1617,1131016,1.05
2668,1146152,1.52
2670,1163515,1.58
1130,1174795,1.74
2687,1188673,1.57
2691,1208385,1.7
38,1217701,1.1
2048,1232107,1.11
2699,1247323,1.72
40,1261782,1.2
1122,1276160,1.11
1623,1290009,1.21
2711,1304695,1.78
2718,1320605,1.65
43,1339944,1.0
2723,1348331,1.71
2726,1363707,1.68
46,1377381,1.13
1141,1394568,1.68
2731,1406871,2.03
2734,1421355,1.93
2737,1436280,2.07
58,1456336,1.29
3052,1464020,1.3
60,1482013,1.17
2740,1493928,2.0
3054,1509982,1.51
2741,1527618,1.8
1640,1537046,1.8
2052,1551540,1.2
3055,1571545,1.1
1644,1582250,1.88
2753,1594814,1.88
1144,1612434,1.89
2756,1624758,1.88
1647,1638583,1.76
2055,1657505,1.4
3060,1667210,1.46
2761,1684726,2.18
73,1699320,1.48
2768,1712091,1.89
3579,1725789,1.28
3582,1739669,1.29
1148,1757309,1.97
2780,1769913,1.97
564,1784633,1.49
2785,1798286,2.2
567,1813069,1.44
3066,1829067,1.55
2789,1841122,1.92
1156,1855911,1.91
1659,1876948,2.19
3603,1884413,1.36
579,1900154,1.65
582,1915444,1.69
3610,1928261,1.5
586,1945008,1.51
588,1961204,1.53
1665,1971599,2.3
1163,1989452,2.21
3616,2002113,1.65
597,2014915,2.04
3072,2029565,1.68
1166,2043696,2.45
3073,2059460,1.61
2809,2073681,2.34
607,2089298,1.76
3077,2102613,1.74
1176,2118487,2.23
3079,2131387,1.7
1180,2146023,2.37
3636,2159931,2.08
2081,2176079,1.62
1186,2188745,2.19
1189,2204460,2.52
1194,2219159,2.58
3090,2232043,1.71
1198,2247108,2.64
3654,2261649,2.48
623,2276263,1.82
2848,2295704,2.43
637,2306042,2.66
2090,2324865,1.92
640,2333911,2.36
2091,2348095,1.76
3097,2363241,1.79
2094,2380949,2.09
648,2394874,2.31
3100,2408093,1.8
92,2423128,1.86
3668,2437238,2.4
2104,2450783,2.05
100,2466108,2.04
653,2482618,2.78
3679,2492942,2.84
3679,2492942,2.84
3687,2522259,2.55
2113,2540559,2.05
655,2554718,2.93
656,2571031,2.53
659,2581892,2.89
101,2595149,2.08
3693,2609129,2.92
3694,2625356,2.4
3130,2638856,2.73
3131,2653434,2.87
669,2667057,3.03
672,2681945,2.75
3140,2696078,2.48
2141,2711676,2.72
3699,2732001,2.9
3700,2739363,2.66
3154,2753777,2.86
3158,2768731,2.81
138,2784589,2.95
2158,2797744,2.82
682,2813376,2.98
3710,2827645,2.54
3166,2842181,2.87
690,2855437,3.03
2162,2870050,3.02
3722,2884666,2.95
2165,2898705,3.11
2168,2913639,2.73
3728,2928204,2.88
717,2942333,3.36
719,2957033,3.01
722,2971873,2.89
726,2986396,3.15
729,3001332,3.22
2182,3015124,3.03
736,3031136,3.2
1685,3044327,2.96
3191,3058664,3.03
3193,3072906,3.35
3196,3087603,3.1
744,3103092,3.56
2195,3116494,3.44
748,3130684,3.25
750,3145512,3.32
1689,3162237,3.01
1207,3178383,3.49
1691,3196195,2.81
2856,3204553,3.18
1694,3221192,3.17
2857,3234545,3.2
2858,3246838,3.08
1697,3262195,3.32
1698,3279175,3.26
1699,3290259,3.05
1217,3304609,2.99
1705,3321121,3.26
2866,3335661,3.1
1224,3348546,3.36
1716,3362900,3.15
2201,3379113,3.5
1232,3393075,3.54
1234,3406029,3.49
2204,3421294,3.5
3215,3435260,3.53
3217,3449489,3.54
2225,3464257,3.19
782,3479038,3.06
220,3492972,3.43
233,3508086,3.39
3258,3521956,3.13
238,3537350,3.32
819,3551005,3.4
2261,3569397,3.05
1245,3591799,3.4
1245,3591799,3.4
1245,3591799,3.4
823,3625088,3.51
823,3625088,3.51
823,3625088,3.51
1728,3667862,3.28
1246,3684244,3.87
3265,3696723,3.5
832,3711136,3.49
837,3726348,3.66
2285,3741080,3.73
839,3756442,3.52
840,3769225,3.57
1247,3783022,3.71
267,3798015,3.79
843,3812137,3.39
3306,3826649,3.57
287,3841408,3.72
860,3855651,3.71
869,3871083,3.61
878,3884795,3.65
1259,3900046,3.94
2903,3915011,3.72
2323,3928309,3.82
3327,3942294,3.84
3329,3958037,3.65
885,3971292,3.62
1266,3987781,3.61
3332,4003169,3.7
311,4015396,3.66
2913,4029475,4.03
3334,4052491,3.94
1271,4058249,3.8
2331,4073379,3.86
1753,4092919,3.9
2332,4104365,3.6
314,4120436,3.9
1754,4136842,3.87
3339,4148157,4.19
1756,4159882,3.92
1762,4177654,4.01
2918,4191747,3.96
1763,4203597,3.92
2338,4218762,3.72
3348,4232118,3.75
1282,4247491,4.12
2347,4262274,3.76
1285,4276583,4.31
1798,4292199,4.07
1823,4305047,4.27
334,4319095,4.29
`;

export const redis = `121500,1,0.0
51022,14554,0.02
51526,29096,0.01
136621,43664,0.04
174031,58200,0.05
205515,72734,0.06
227527,87274,0.04
77006,101824,0.18
13025,116371,0.03
86558,130911,0.11
33049,145457,0.14
47533,160016,0.08
126031,174555,0.08
152022,189123,0.08
256062,203644,0.09
25535,218197,0.14
47545,232747,0.29
252074,247289,0.12
40081,261874,0.22
148541,276378,0.35
120585,290940,0.15
193060,305470,0.17
114071,320028,0.16
28591,334603,0.27
118041,349101,0.18
242570,363695,0.21
91078,378189,0.23
235533,392773,0.39
116026,407312,0.17
118535,421821,0.36
268554,436382,0.23
22603,450928,0.28
84561,465504,0.26
113125,480034,0.33
32043,494561,0.43
39061,509094,0.23
159118,523650,0.29
99146,538186,0.49
95621,552791,0.31
229625,567287,0.35
185059,581822,0.43
196554,596418,0.44
195157,610927,0.31
58473,625488,0.67
32049,640033,0.42
222640,654644,0.37
82577,669100,0.54
81098,683665,0.54
60102,698198,0.39
155067,712737,0.43
172550,727321,0.41
213066,741879,0.43
141577,756369,0.54
194579,771052,0.62
236504,785605,0.4
225581,800011,0.55
115087,814558,0.55
221081,829373,0.57
60638,843677,0.39
10647,858218,0.38
263007,872734,0.4
174508,887279,0.44
15645,901824,0.4
242649,916410,0.38
225012,930919,0.44
175154,945458,0.41
34152,960048,0.4
103517,974559,0.47
134137,989232,0.7
87017,1003690,0.48
102618,1018228,0.69
86660,1032742,0.76
265174,1047316,0.44
91174,1061835,0.44
103525,1076369,0.55
92677,1090959,0.49
41628,1105512,0.79
113171,1120118,0.71
131128,1134570,0.75
229037,1149096,0.59
97039,1163650,0.61
120043,1178190,0.6
168048,1192745,0.61
175675,1207341,0.78
172050,1221997,0.61
252194,1236436,0.79
235144,1250914,0.78
176196,1265518,0.8
264685,1280007,0.8
17634,1294610,0.82
40205,1309124,0.83
30718,1323759,0.85
125724,1338191,0.83
217703,1352736,0.82
225612,1367290,0.73
188724,1381830,0.82
233635,1396471,0.79
265552,1410951,0.65
151737,1425481,0.86
268635,1440032,0.83
64060,1454562,0.65
233643,1469112,0.83
144061,1483711,0.69
151744,1498361,0.87
152745,1512797,0.83
123738,1527293,0.82
262070,1541839,0.7
136236,1556416,0.88
63229,1570915,0.9
29204,1585603,0.9
49557,1600035,0.7
104058,1614568,0.66
217733,1629110,0.85
246712,1643686,0.88
271065,1658192,0.7
247780,1672762,0.9
146764,1687297,0.9
53066,1701838,0.77
29564,1716436,0.74
97065,1730922,0.89
60755,1745462,0.93
179571,1760054,0.75
30780,1774579,0.9
68073,1789159,0.79
76094,1803665,0.79
135077,1818208,0.76
259080,1832751,0.76
193258,1847294,0.92
85262,1861854,0.96
147071,1876403,0.87
255088,1890923,0.78
135091,1905460,0.74
107607,1920027,0.78
152792,1934557,0.96
31246,1949139,0.96
240165,1963650,0.94
143661,1978200,0.93
98797,1992771,1.0
184610,2007355,0.86
111305,2021852,0.96
79780,2036374,1.03
213134,2050922,1.0
11668,2065463,0.95
16579,2080011,0.94
148180,2094620,1.04
63292,2109113,1.05
231083,2123646,0.95
255110,2138201,0.96
199113,2152774,0.93
270683,2167282,1.01
182086,2181838,0.97
204141,2196502,1.06
230588,2210922,0.97
185107,2225479,0.98
48139,2240030,1.09
200108,2254587,1.01
237299,2269115,1.05
86774,2283678,1.1
22158,2298270,1.09
33132,2312761,1.13
35144,2327467,1.07
206095,2341832,0.96
88100,2356463,1.06
243098,2370961,0.98
27617,2385476,1.07
259127,2400041,1.1
116156,2414583,1.11
72620,2429164,1.09
44675,2443658,1.22
205115,2458205,1.02
241126,2472757,1.12
177615,2487292,1.06
221652,2501836,1.12
231118,2516454,1.03
54642,2530920,1.15
275125,2545484,1.1
106620,2560117,1.02
138121,2574554,1.02
253669,2589154,1.11
102192,2603727,1.15
191627,2618245,1.15
127171,2632741,1.11
193630,2647314,1.16
165161,2661915,1.19
199631,2676382,1.14
14646,2690947,1.25
115639,2705468,1.15
74178,2720018,1.25
90629,2734597,1.21
127179,2749121,1.18
176653,2763654,1.14
148654,2778195,1.14
268160,2792791,1.18
209659,2807304,1.19
25662,2821858,1.23
89205,2836393,1.27
117695,2850921,1.17
42662,2865491,1.29
214705,2880014,1.22
107725,2894561,1.21
155226,2909127,1.22
196717,2923664,1.22
198661,2938196,1.18
255660,2952749,1.19
199215,2967284,1.22
129637,2981861,1.22
181180,2996388,1.21
13671,3010930,1.24
111749,3025478,1.19
163685,3040021,1.23
42686,3054619,1.37
76689,3069137,1.27
163191,3083710,1.24
261799,3098191,1.24
181194,3112757,1.25
149714,3127290,1.25
107776,3141843,1.26
196199,3156405,1.26
229853,3170941,1.29
214151,3185474,1.27
33176,3200014,1.33
175329,3214591,1.31
7711,3229198,1.3
214163,3243657,1.28
151843,3258338,1.29
125862,3272782,1.32
174190,3287284,1.3
95847,3301843,1.37
219172,3316401,1.27
216248,3330996,1.32
130682,3345484,1.31
38156,3360047,1.33
71190,3374574,1.38
245193,3389101,1.31
112878,3403682,1.34
215177,3418226,1.32
28254,3432752,1.37
56713,3447282,1.34
270194,3461842,1.33
170687,3476379,1.32
132777,3490920,1.35
162746,3505464,1.3
129706,3520011,1.34
27314,3534587,1.43
94216,3549101,1.46
62200,3563738,1.35
89301,3578221,1.41
175808,3592749,1.35
51301,3607294,1.44
256295,3621829,1.36
118223,3636431,1.36
106300,3651019,1.38
100801,3665472,1.49
92854,3680087,1.45
252824,3694568,1.38
221829,3709102,1.39
215845,3723651,1.36
156858,3738199,1.39
121311,3752768,1.42
29786,3767310,1.45
152875,3781853,1.42
202352,3796426,1.41
73749,3810919,1.5
116800,3825476,1.36
90775,3840011,1.47
81235,3854610,1.54
131839,3869107,1.42
78897,3883661,1.51
167821,3898212,1.39
38857,3912760,1.53
242887,3927290,1.44
162281,3941863,1.45
180237,3956380,1.43
257826,3970958,1.45
146327,3985471,1.47
155391,4000043,1.45
2770,4014564,1.57
41371,4029102,1.57
76828,4043722,1.57
220767,4058237,1.45
274,4072762,1.6
221273,4087287,1.48
160886,4101857,1.49
200368,4116380,1.44
106801,4130983,1.46
1853,4145535,1.62
150901,4160013,1.45
152915,4174594,1.53
46799,4189226,1.55
45905,4203690,1.68
64287,4218193,1.65
230843,4232747,1.58
105845,4247287,1.58
258880,4261830,1.58
14863,4276375,1.64
188926,4290921,1.6
233821,4305476,1.56
216866,4320012,1.6
190456,4334714,1.62`;
