import { InputDataSource } from "@biggeo/bg-server-lib/datascape-ai";
import { match } from "ts-pattern";
import { DataManagementTab } from "../containers/DataManagementContainer";

export const getInputDataSource = (tab: DataManagementTab): InputDataSource => {
    return match(tab)
        .with(DataManagementTab.available, () => ({
            isConnected: true,
        }))
        .with(DataManagementTab.processing, () => ({
            isConnected: false,
            progress: {
                range: { min: 0, max: 99 },
            },
        }))
        .with(DataManagementTab.unIndexed, () => ({
            progress: { isNull: true },
        }))
        .exhaustive();
};
