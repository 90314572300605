import { PointDataInput } from "@biggeo/bg-server-lib/datascape-ai";
import { useSelector } from "react-redux";
import { ActionType as ActionT, action } from "typesafe-actions";
import { RootState } from "../../../redux/reducer";

export const useDataPointTooltipState = () =>
    useSelector((state: RootState) => state.mapTooltip.dataPointTooltip);

type MapTooltipModel = {
    dataPointTooltip: {
        selectedDataPoint?: PointDataInput;
    };
};

const initialState: MapTooltipModel = {
    dataPointTooltip: {
        selectedDataPoint: undefined,
    },
};

export const mapTooltipActions = {
    setSelectedDataPoint: (p: PointDataInput) =>
        action("SET_SELECTED_DATA_POINT", p),
};

export type CombinedActions = ActionT<typeof mapTooltipActions>;

export const mapTooltipReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: MapTooltipModel = initialState,
    action: CombinedActions
): MapTooltipModel => {
    if (!action) return state;
    switch (action.type) {
        case "SET_SELECTED_DATA_POINT":
            return {
                ...state,
                dataPointTooltip: {
                    ...state.dataPointTooltip,
                    selectedDataPoint: action.payload,
                },
            };
        default:
            return state;
    }
};
