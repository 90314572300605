import { Box, Chip, chipClasses } from "@biggeo/bg-ui/lab";
import { AddCircleOutline, Circle } from "@biggeo/bg-ui/lab/icons";
import { theme } from "@biggeo/bg-ui/theme";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import replace from "lodash/replace";
import { MapContextDataset } from "../map/mapbox/context";

export type DatasetChipProps = {
    readonly selectedDataset: MapContextDataset;
    readonly clickHandler: (
        databaseId: string,
        selectepdChip: string,
        geographyColumn: string,
        hasTableId: boolean,
        dataType?: string
    ) => void;
};

const DatasetChip = ({ clickHandler, selectedDataset }: DatasetChipProps) => {
    const dggsIndexName = pipe(
        selectedDataset.dataSource.geographyColumn,
        O.fromNullable,
        O.fold(
            () => "DGGS_INDEX",
            (col) => `DGGS_INDEX_${replace(col, /[^\w\s]/gi, "_")}`
        )
    );
    return (
        <Chip
            density="dense"
            variant="outlined"
            rounded
            startNode={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AddCircleOutline size="sm" />
                    <Circle
                        sx={{
                            color:
                                selectedDataset.mapTemplateDataset?.color ||
                                selectedDataset.dataSource.color ||
                                theme.palette.success.main,
                        }}
                        size="xxs"
                    />
                </Box>
            }
            onClick={() =>
                clickHandler(
                    selectedDataset.dataSource.id,
                    selectedDataset.dataSource.label ||
                        selectedDataset.dataSource.tableName,
                    dggsIndexName,
                    Boolean(selectedDataset.dataSource.tableId),
                    selectedDataset.dataSource.type
                )
            }
            sx={{
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: "none",
                },
                border: 1,
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.outline,
            }}
        >
            {selectedDataset.dataSource.label ||
                selectedDataset.dataSource.tableName}
        </Chip>
    );
};

export default DatasetChip;
