import {
    FilterObject,
    InputPoint,
    InputPolygon,
    ReqOptions,
} from "@biggeo/bg-server-lib/datascape-ai";
import Feature from "ol/Feature";
import Map from "ol/Map";
import { Geometry, Polygon } from "ol/geom";
import { Draw, Modify } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import { useEffect } from "react";
import { FunctionType, matchFunctionType } from "../../utils/utils";
import { getLayerGroup } from "../utils/utils";

export const usePolygon = ({
    functionType,
    map,
    multiFilters,
    polygon,
    handlePolygonChange,
    options,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<Map | null>;
    multiFilters: FilterObject[];
    polygon: InputPolygon;
    handlePolygonChange: ({ polygon }: { polygon: InputPolygon }) => void;
    options: ReqOptions;
}) => {
    const source = new VectorSource();

    useEffect(() => {
        const current = map.current;
        if (functionType === FunctionType.polygon) {
            if (current) {
                const getLayer = getLayerGroup(current);
                const interactions = current.getInteractions().getArray();
                const drawInteraction = interactions.find(
                    (d) => d.get("id") === "draw"
                );
                if (!drawInteraction) {
                    const layer =
                        getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                            "polygon"
                        );
                    const source = layer?.getSource() as VectorSource<
                        Feature<Geometry>
                    >;
                    const modify = new Modify({ source: source });
                    current.addInteraction(modify);
                    const draw = new Draw({
                        source: source,
                        type: "Polygon", // Specify the geometry type to be drawn
                    });
                    draw.set("id", "draw");

                    current.addInteraction(draw);
                    draw.on("drawend", (e) => {
                        updateArea(e.feature);
                        draw.setActive(false);
                    });
                    modify.on("modifyend", (d) => {
                        updateArea(d.features.getArray()[0]);
                    });
                }
            }
        } else {
            if (current) {
                const interactions = current.getInteractions().getArray();
                const drawInteraction = interactions.find(
                    (d) => d.get("id") === "draw"
                );

                if (!!drawInteraction) {
                    current.removeInteraction(drawInteraction);
                    const getLayer = getLayerGroup(current);
                    const layer =
                        getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                            "polygon"
                        );
                    layer?.getSource()?.clear();
                }
            }
        }
    }, [map.current, functionType, multiFilters, options]);

    const updateArea = (feature: Feature<Geometry>) => {
        const geometry = feature.getGeometry() as Polygon;
        const coordinates = geometry.getCoordinates();
        const edgePoints = coordinates[0];

        const points: InputPoint[] = edgePoints
            ?.map((coord) => {
                const t = transform(coord, "EPSG:3857", "EPSG:4326");
                return {
                    latitude: t[1],
                    longitude: t[0],
                };
            })
            .filter((point, i) => i !== edgePoints.length - 1);

        const localPolygon: InputPolygon = {
            inners: [],
            outer: { points: points },
        };
        onPolygonChange(localPolygon);
    };

    const onPolygonChange = (polygon: InputPolygon) => {
        matchFunctionType(functionType, {
            polygon: () => {
                handlePolygonChange({ polygon });
            },
        });
    };

    const onStyleLoad = (map: Map) => {
        const vectorLayer = new VectorLayer({
            source: source,
            properties: { id: "polygon" },
            style: new Style({
                fill: new Fill({
                    color: "rgba(255, 255, 255, 0.2)", // Adjust the fill style as needed
                }),
                stroke: new Stroke({
                    color: "#ffcc33",
                    width: 2,
                }),
            }),
        });
        map.addLayer(vectorLayer);
    };

    const resetPolygon = () => {
        const current = map.current;
        if (current) {
            const getLayer = getLayerGroup(current);
            const layer =
                getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                    "polygon"
                );
            layer?.getSource()?.clear();
            onPolygonChange({
                inners: [],
                outer: { points: [] },
            });
            const interactions = current.getInteractions().getArray();
            const drawInteraction = interactions.find(
                (d) => d.get("id") === "draw"
            );
            drawInteraction?.setActive(true);
        }
    };
    return { onStyleLoad, resetPolygon };
};
