import {
    FetchSavedViewsQuery,
    SavedView,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { useDispatch } from "react-redux";
import { mapDataActions } from "../../map/redux/model";
import SavedViewCard from "../views/SavedViewCard";

const SavedViewSubMenuContainer = ({
    resetMap,
    selectedSavedView,
    setSelectedSavedView,
    loadingSavedViews,
    savedViewsData,
}: {
    readonly resetMap: () => void;
    readonly setSelectedSavedView: (v: number) => void;
    readonly selectedSavedView?: number;
    readonly savedViewsData: FetchSavedViewsQuery | undefined;
    readonly loadingSavedViews: boolean;
}) => {
    const onLoadSaveView = (currentSavedView: SavedView) => {
        setSelectedSavedView(currentSavedView.id);
        resetMap();
    };

    return (
        <Stack gap={4} sx={{ padding: 3 }} width="100%">
            {loadingSavedViews && <LoadingBar />}
            {savedViewsData?.fetchSavedViews.savedViews.map((item) => {
                return (
                    <SavedViewCard
                        key={item.id}
                        selected={item.id === selectedSavedView}
                        title={item.name}
                        image={item.image || ""}
                        updatedAt={item.updatedAt}
                        bodyOnClick={() =>
                            item.id === selectedSavedView
                                ? undefined
                                : onLoadSaveView(item)
                        }
                    />
                );
            })}
        </Stack>
    );
};

export default SavedViewSubMenuContainer;
