import {
    Box,
    BreakpointSwapper,
    Button,
    Grid,
    IconButton,
    Menu,
    Stack,
    Tab,
    TabGroup,
} from "@biggeo/bg-ui/lab";
import { MenuOutline } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { useNavigate } from "react-router";
import { Routes } from "../navigation/redux/model";

export enum TabValues {
    mapView = "mapView",
    listView = "listView",
}
interface IMarketplaceMapHeader {
    readonly currentTab: TabValues.mapView | TabValues.listView;
}
export const MarketplaceMapHeader = ({ currentTab }: IMarketplaceMapHeader) => {
    const toPage = useNavigate();

    const createMapTemplate = () => {
        toPage(Routes.mapTemplatesCreate);
    };

    return (
        <Grid
            container
            sx={{
                paddingY: 4,
                paddingX: 2,
                borderBottom: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
            }}
        >
            <Grid item>
                <TabGroup variant="tonal" value={currentTab}>
                    <Tab
                        value={TabValues.mapView}
                        onClick={() => toPage(`${Routes.explore}/mapView`)}
                    >
                        Map View
                    </Tab>
                    <Tab
                        value={TabValues.listView}
                        onClick={() => toPage(`${Routes.listView}/datasets`)}
                    >
                        List View
                    </Tab>
                </TabGroup>
            </Grid>
            {isEqual(currentTab, "mapView") && (
                <BreakpointSwapper
                    item
                    desktop={
                        <Grid item>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={createMapTemplate}
                                >
                                    Create Map Template
                                </Button>
                            </Box>
                        </Grid>
                    }
                    mobile={
                        <Grid item>
                            <Menu
                                placement="bottom-start"
                                content={
                                    <Stack flexGrow={1} gap={2}>
                                        <Button
                                            variant="outlined"
                                            onClick={createMapTemplate}
                                        >
                                            Create Map Template
                                        </Button>
                                    </Stack>
                                }
                            >
                                <IconButton
                                    variant={"outlined"}
                                    density={"dense"}
                                >
                                    <MenuOutline size="sm" />
                                </IconButton>
                            </Menu>
                        </Grid>
                    }
                />
            )}
        </Grid>
    );
};
