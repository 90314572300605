import {
    FilterObject,
    InputPolygon,
    InputRadius,
    InputViewBox,
    Point,
    PointDataInput,
    ReqOptions,
    SavedPolygon,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Map from "ol/Map.js";
import View from "ol/View";
import { defaults as defaultInteractions } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import { useEffect, useRef, useState } from "react";
import { FunctionType } from "../../utils/utils";
import { useData } from "./data-hooks";
import { usePolygon } from "./polygon-hooks";
import { useRadius } from "./radius-hooks";
import { useSavedPolygon } from "./saved-polygon-hooks";
import { useViewport } from "./viewport-hooks";

const startingLatLng: Point = { latitude: 60, longitude: -114 };

export const useInitializeMap = ({
    functionType,
    multiFilters,
    viewport,
    handleViewportChange,
    recentResponse,
    selectedDataset,
    handleRadiusChange,
    polygon,
    radius,
    handlePolygonChange,
    showTriangles,
    showPoints,
    showFiltered,
    savedPolygon,
    options,
}: {
    functionType: FunctionType;
    multiFilters: FilterObject[];
    polygon: InputPolygon;
    viewport: InputViewBox;
    recentResponse: SubscriptionResponse | undefined;
    handleViewportChange: ({ viewport }: { viewport: InputViewBox }) => void;
    selectedDataset: readonly string[];
    handleRadiusChange: ({ radius }: { radius: Partial<InputRadius> }) => void;
    radius: InputRadius;
    handlePolygonChange: ({ polygon }: { polygon: InputPolygon }) => void;
    setSelectedPoint: (num?: PointDataInput) => void;
    showTriangles: boolean;
    showPoints: boolean;
    showFiltered: boolean;
    savedPolygon?: SavedPolygon;
    options: ReqOptions;
}) => {
    const map = useRef<Map | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const { onStyleLoad: onStyleLoadSavedPolygon } = useSavedPolygon({
        functionType,
        map,
        savedPolygon,
    });

    const { onStyleLoad: onStyleLoadRadius } = useRadius({
        functionType,
        map,
        multiFilters,
        handleRadiusChange,
        radius,
        options,
    });

    const { onStyleLoad: onStyleLoadViewport } = useViewport({
        functionType,
        map,
        multiFilters,
        viewport,
        handleViewportChange,
        options,
    });

    const { onStyleLoad: onStyleLoadPolygon, resetPolygon } = usePolygon({
        functionType,
        map,
        multiFilters,
        polygon,
        handlePolygonChange,
        options,
    });

    const { onStyleLoad: onStyleLoadData } = useData({
        map,
        functionType,
        multifilters: multiFilters,
        recentResponse,
        selectedDataset,
        showTriangles,
        showPoints,
        showFiltered,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!map.current)
            map.current = new Map({
                target: "map",
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                ],
                controls: [],
                interactions: defaultInteractions({ doubleClickZoom: false }),
                view: new View({
                    center: fromLonLat([
                        startingLatLng.longitude,
                        startingLatLng.latitude,
                    ]),
                    zoom: 5,
                }),
            });

        map.current.once("postcompose", () => {
            setIsLoaded(true);
            if (map.current) onStyleLoadData(map.current);
            if (map.current) onStyleLoadViewport(map.current);
            if (map.current) onStyleLoadRadius(map.current);
            if (map.current) onStyleLoadPolygon(map.current);
            if (map.current) onStyleLoadSavedPolygon(map.current);
        });
    }, []);
    return { isLoaded, polygon, resetPolygon };
};
