import { Box, Drawer } from "@biggeo/bg-ui";
import React from "react";

interface IFormDrawer {
    readonly open: boolean;
    readonly closeModal: () => void;
    readonly children: JSX.Element | undefined;
    readonly direction?: "left" | "right";
}

const FormDrawer: React.FC<IFormDrawer> = ({
    open,
    closeModal,
    children,
    direction,
}: IFormDrawer) => {
    const closeDrawer =
        () => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            closeModal();
        };
    return (
        <Drawer
            anchor={direction ?? "right"}
            open={open}
            onClose={closeDrawer()}
        >
            <Box
                sx={{
                    breakpoints: {
                        xs: {
                            width: "100%",
                            minWidth: 400,
                        },
                        sm: {
                            width: 185,
                            minWidth: 185,
                        },
                    },

                    flex: 1,
                }}
                role="presentation"
            >
                {children}
            </Box>
        </Drawer>
    );
};

export default FormDrawer;
