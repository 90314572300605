import {
    bannerUsers,
    blogs,
    changeLogs,
    resources,
    useCases,
} from "../dummy-data.tsx";
import HomePage from "../views/HomePage.tsx";

const HomeContainer = () => {
    // TODO: determine this boolean from server
    const isTemporary = true;

    return (
        <HomePage
            title={
                isTemporary
                    ? "Welcome back to Datascape"
                    : "Welcome back to Datascape, Olivia"
            }
            bannerUsers={bannerUsers}
            changeLogs={changeLogs}
            useCases={useCases}
            blogs={blogs}
            resources={resources}
            isTemporary={isTemporary}
        />
    );
};

export default HomeContainer;
