import { Drawer } from "@biggeo/bg-ui/lab";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { match } from "ts-pattern";
import { AllRecipes } from "../views/AllRecipes";
import { RecipesSidebar } from "../views/RecipesSidebar";

export const RecipesContainer = () => {
    const { menuItem } = useParams();
    const toPage = useNavigate();

    const getSelectedMenu = (param?: string) => {
        return match(param)
            .with("all", () => "all")
            .otherwise((val) => val || "all");
    };

    return (
        <DoubleColumnLayout
            height="100%"
            left={
                <RecipesSidebar
                    selected={getSelectedMenu(menuItem)}
                    mapTemplates={[]}
                    toPage={toPage}
                />
            }
            slotProps={{
                main: {
                    sx: {
                        padding: 4,
                    },
                },
                left: {
                    sx: {
                        display: "none",
                        breakpoints: {
                            md: {
                                display: "block",
                            },
                        },
                    },
                },
            }}
        >
            <Drawer>
                <RecipesSidebar
                    selected={getSelectedMenu(menuItem)}
                    mapTemplates={[]}
                    toPage={toPage}
                />
            </Drawer>
            <AllRecipes />
        </DoubleColumnLayout>
    );
};
