import { IconButton, OverflowingTypography, Stack } from "@biggeo/bg-ui/lab";
import {
    Ai,
    CloseOutline,
    CollapseContentOutline,
    ExpandContentOutline,
} from "@biggeo/bg-ui/lab/icons";

export interface AiChatHeaderProps {
    readonly header?: {
        readonly text: string;
        readonly icon: React.ReactNode;
    };
    readonly collapsed: boolean;
    readonly collapse: () => void;
    readonly close: () => void;
}

export const AiChatHeader = ({
    header = {
        text: "BigGeo Ai",
        icon: <Ai color="primary" />,
    },
    collapsed,
    collapse,
    close,
}: AiChatHeaderProps) => (
    <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
    >
        <Stack flexDirection="row" gap={1} alignItems="center">
            {header.icon}
            <OverflowingTypography variant="title3" fontWeight="bold">
                {header.text}
            </OverflowingTypography>
        </Stack>
        <Stack flexDirection="row" gap={2}>
            <IconButton
                density="dense"
                variant="minimal"
                onClick={collapse}
                sx={{
                    borderRadius: (theme) => theme.radius.full,
                }}
            >
                {collapsed ? (
                    <ExpandContentOutline size="xs" />
                ) : (
                    <CollapseContentOutline size="xs" />
                )}
            </IconButton>
            <IconButton
                density="dense"
                variant="minimal"
                onClick={close}
                sx={{
                    borderRadius: (theme) => theme.radius.full,
                }}
            >
                <CloseOutline size="xs" />
            </IconButton>
        </Stack>
    </Stack>
);
