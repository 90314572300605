import { MapTemplate } from "@biggeo/bg-server-lib/datascape-ai";
import { Grid, MenuItem } from "@biggeo/bg-ui/lab";
import { EditOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import MapTemplateCard from "../../components/MapTemplateCard/MapTemplateCard.tsx";
import { IMapTemplatesContainer } from "../containers/MapTemplatesContainer";
import { mapTemplateGridBreakpoints } from "../utils/utils";
import CreateMapTemplateCard from "./CreateMapTemplateCard";

interface IMapTemplatesView extends IMapTemplatesContainer {
    readonly manageMapTemplate: (id: number) => void;
    readonly toMapTemplate: (id: number) => void;
    readonly maps: MapTemplate[];
}

const MapTemplatesView = ({
    maps,
    createMapTemplate,
    manageMapTemplate,
    toMapTemplate,
}: IMapTemplatesView) => {
    return (
        <Grid container spacing={4} alignItems="flex-start">
            <Grid item {...mapTemplateGridBreakpoints}>
                <CreateMapTemplateCard onClick={createMapTemplate} />
            </Grid>
            {pipe(
                maps,
                A.map((map) => (
                    <Grid item key={map.id} {...mapTemplateGridBreakpoints}>
                        <MapTemplateCard
                            title={map.name}
                            subTitle={map.description || ""}
                            image={map.thumbnail || ""}
                            bodyOnClick={() => toMapTemplate(map.id)}
                            withAction
                            menuItems={
                                <MenuItem
                                    label="Manage"
                                    density="dense"
                                    startNode={<EditOutline size="xs" />}
                                    onClick={() => manageMapTemplate(map.id)}
                                />
                            }
                        />
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default MapTemplatesView;
