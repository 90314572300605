import {
    DbColumnType,
    FetchDataSourceQuery,
    FetchSnowflakeTableSchemaQuery,
    InputConnectDataSource,
    useConnectDataSourceMutation,
    useFetchDataSourceQuery,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    LoadingBar,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import { SplitLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { map2, match } from "@vividtheory/remotedata";
import { Formik } from "formik";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { compose } from "redux";
import tinycolor2 from "tinycolor2";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { MappingColumnForm } from "../../components/MappingColumnForm";
import { NewMappingModal } from "../../components/NewMappingModal";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";

export type FormValuesType = Readonly<
    Omit<InputConnectDataSource, "geographyColumn"> & {
        lat: string;
        long: string;
        geoSpatialColumn: string;
    }
>;
export const UnIndexedManagementContainer = () => {
    const { id } = useParams();
    const toPage = useNavigate();
    const dispatch = useDispatch();

    const openToast = compose(dispatch, toasterActions.openToast);

    const navigate = (to?: string) =>
        to ? toPage(`${to}`) : toPage(Routes.data);

    const { remote: dataSourceRd } = useFetchDataSourceQuery(
        id
            ? {
                  variables: {
                      id,
                  },
              }
            : { skip: true }
    );

    const { remote: fetchSnowflakeTableSchemaRd } =
        useFetchSnowflakeTableSchemaQuery(
            id
                ? {
                      variables: {
                          databaseId: id,
                      },
                  }
                : { skip: true }
        );

    const {
        executeMutation: connectDataSourceMutation,
        mutationReturn: [_cds, { loading: connectDataSourceLoading }],
    } = useConnectDataSourceMutation();

    const handleSubmit = (values: FormValuesType) => {
        const tinyColor = tinycolor2(values.color);
        connectDataSourceMutation({
            variables: {
                input: {
                    id: values.id,
                    geographyColumn:
                        values.geoSpatialColumn === ""
                            ? `${values.long},${values.lat}`
                            : `${values.geoSpatialColumn}`,
                    alias: values.alias,
                    collectionName: values.collectionName,
                    label: values.label,
                    color: tinyColor.toHexString(),
                    tableId: values.tableId,
                    description: values.description,
                    tableIdType: values.tableIdType,
                },
            },
            onCompleted: () => {
                openToast({
                    open: true,
                    title: "Data indexing started successfully",
                    autoHideDuration: 5000,
                });
                navigate(`${Routes.data}/processing`);
            },
            onError: (e) => {
                openToast({
                    open: true,
                    severity: Severity.error,
                    title: e.message,
                    autoHideDuration: 5000,
                });
            },
        });
    };

    const mappedData = map2(
        (dataSourceRd: FetchDataSourceQuery) =>
            (fetchSnowflakeTableSchemaRd: FetchSnowflakeTableSchemaQuery) => ({
                fetchDataSource: dataSourceRd.fetchDataSource,
                fetchSnowflakeTableSchema:
                    fetchSnowflakeTableSchemaRd.fetchSnowflakeTableSchema,
            }),
        dataSourceRd,
        fetchSnowflakeTableSchemaRd
    );

    return match(mappedData, {
        _: () => <LoadingBar />,
        Success: ({ fetchDataSource, fetchSnowflakeTableSchema }) => {
            const tableIdType = pipe(
                fetchSnowflakeTableSchema.rows,
                toNonReadonlyArray,
                A.findFirst((c) => c.columnName === fetchDataSource.tableId),
                O.foldW(
                    () => "",
                    ({ dataType }) => dataType
                ),
                (type) => {
                    if (type === DbColumnType.String) {
                        return "TEXT";
                    }
                    return "";
                }
            );
            const defaultValues: FormValuesType = {
                id: fetchDataSource.id,
                label: fetchDataSource.label || "",
                lat: "",
                long: "",
                tableId: fetchDataSource.tableId || "",
                alias: fetchDataSource.sfAlias || "",
                color: "#000000ff",
                geoSpatialColumn: "",
                description: fetchDataSource.description || "",
                collectionName: fetchDataSource.collectionName,
                tableIdType,
            };
            return (
                <Formik
                    initialValues={defaultValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={toFormikValidationSchema(
                        Zod.object({
                            label: Zod.string().optional(),
                            description: Zod.string().optional(),
                            color: Zod.string(),
                            lat: Zod.string().optional(),
                            long: Zod.string().optional(),
                            geoSpatialColumn: Zod.string().optional(),
                            tableId: Zod.string(),
                            tableIdType: Zod.string().optional(),
                        }).refine(
                            (data) => {
                                if (data.geoSpatialColumn) {
                                    return (
                                        !!data.geoSpatialColumn &&
                                        (!data.lat || !data.long)
                                    );
                                }
                                return (
                                    !data.geoSpatialColumn &&
                                    data.lat &&
                                    data.long
                                );
                            },
                            {
                                message: "Invalid column configuration",
                                path: ["geoSpatialColumn"], // You can specify the path for the error message
                            }
                        )
                    )}
                >
                    {({
                        values,
                        dirty,
                        isValid,
                        handleSubmit,
                        setFieldValue,
                    }) => {
                        return (
                            <Stack width="100%" height="100%">
                                <SplitLayoutWithHeader
                                    header={
                                        <BreadcrumbsGroup
                                            value={"index-data-source"}
                                            onClick={() => navigate()}
                                        >
                                            <BreadcrumbsButton value={"data"}>
                                                Data
                                            </BreadcrumbsButton>
                                            <BreadcrumbsButton
                                                value={"index-data-source"}
                                                hideSeparator
                                            >
                                                Index data source
                                            </BreadcrumbsButton>
                                        </BreadcrumbsGroup>
                                    }
                                    left={
                                        <MappingColumnForm
                                            onChange={(field, value) => {
                                                setFieldValue(field, value);
                                            }}
                                            values={{
                                                label: values.label,
                                                description:
                                                    values.description || "",
                                                tableName:
                                                    fetchDataSource.tableName,
                                            }}
                                            handleSubmit={() => handleSubmit()}
                                            disabled={!isValid || !dirty}
                                            loading={connectDataSourceLoading}
                                        />
                                    }
                                    right={
                                        <NewMappingModal
                                            tableColumns={
                                                fetchSnowflakeTableSchema.rows
                                            }
                                            values={{
                                                color: values.color,
                                                geoSpatialColumn:
                                                    values.geoSpatialColumn,
                                                lat: values.lat,
                                                long: values.long,
                                            }}
                                            tableId={values.tableId}
                                            onChange={(field, value) => {
                                                setFieldValue(field, value);
                                            }}
                                        />
                                    }
                                />
                            </Stack>
                        );
                    }}
                </Formik>
            );
        },
    });
};
