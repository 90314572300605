import {
    FilterObject,
    InputPolygon,
    InputViewBox,
    ReqOptions,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CircularLoading,
    Grid,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import React, { useState } from "react";
import { ErrorPage } from "../common/components/ErrorPage";
import TableContainer from "./TableContainer";

export type DataContainerProps = {
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    readonly channelId: string;
    readonly multiFilters: FilterObject[];
    readonly options: ReqOptions;
    readonly selectedChip: string;
    readonly databaseId: string;
    readonly geographyColumn: string;
    readonly hasTableId: boolean;
    readonly toPage: (url: string) => void;
};

const DataContainer = ({
    geospatialSelection,
    channelId,
    multiFilters,
    options,
    selectedChip,
    databaseId,
    geographyColumn,
    hasTableId,
    toPage,
}: DataContainerProps) => {
    const current = multiFilters.find(
        (filter) => filter.databaseId === databaseId
    );
    const [localFilters, setLocalFilters] = useState(current);

    const {
        queryReturn: { data, error },
    } = useFetchSnowflakeTableSchemaQuery({
        variables: {
            databaseId,
        },
    });

    const updateMultifilters = (f: FilterObject, _databaseId: string) => {
        setLocalFilters(f);
        return f;
    };

    if (error || !hasTableId) {
        return (
            <ErrorPage
                title={error ? undefined : "We need to re-index your dataset"}
                subtitle={
                    error
                        ? undefined
                        : "The Dataset that you're trying to access is missing the table id. Please re-index the dataset by clicking the button below."
                }
                action={
                    !error && (
                        <Button
                            color="primary"
                            onClick={() => toPage("/datasets")}
                        >
                            Re-index Dataset
                        </Button>
                    )
                }
            />
        );
    }

    if (data?.fetchSnowflakeTableSchema.rows && !!localFilters) {
        return (
            <Stack sx={{ width: "100%", padding: 4 }} gap={3}>
                <Typography variant={"body3"} fontWeight={"semibold"}>
                    {selectedChip}
                </Typography>
                <TableContainer
                    key={localFilters.databaseId}
                    cols={data?.fetchSnowflakeTableSchema.rows}
                    filters={{
                        filters: { ...localFilters, databaseId },
                        setFilters: (f) => updateMultifilters(f, databaseId),
                    }}
                    geospatialSelection={geospatialSelection}
                    channelId={channelId}
                    options={options}
                    geographyColumn={geographyColumn}
                    selectedChip={selectedChip}
                />
            </Stack>
        );
    }

    return (
        <Grid
            container
            sx={{ width: "100%", height: "100%", padding: 4 }}
            justifyContent="center"
            alignItems="center"
            gap={1}
        >
            <CircularLoading />
        </Grid>
    );
};

export default React.memo(DataContainer);
