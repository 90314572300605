import { AiResponse } from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Chip,
    IconAvatar,
    TextField,
    textInputBaseClasses,
} from "@biggeo/bg-ui/lab";
import { MagicButtonOutline } from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { CallBacksType } from "../../../utils/types";
import { AiChatType } from "../components/AiChatMessage";
import { AiChatWindow } from "../components/AiChatWindow";
import AiPromptIndicatorButton from "../components/AiPromptIndicatorButton";

interface IAiPromptButton {
    readonly setShowAiPrompt: (v: boolean) => void;
    readonly showAiPrompt: boolean;
    readonly onClickSend: (callbacks?: CallBacksType<AiResponse>) => void;
    readonly setPrompt: (v: string) => void;
    readonly loading: boolean;
    readonly prompts: AiChatType[];
}

const AiPromptButton = ({
    setShowAiPrompt,
    onClickSend,
    showAiPrompt,
    setPrompt,
    loading,
    prompts,
}: IAiPromptButton) => {
    const [openChat, setOpenChat] = useState<boolean>(false);
    const isSnowflake = isAppRunningOnSF();
    const onSendClick = () => {
        onClickSend({
            onSuccess: () => {
                setOpenChat(true);
                setShowAiPrompt(false);
            },
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    borderRadius: "50%",
                    bottom: 4,
                    right: "50%",
                    transform: "translateX(50%)",
                }}
            >
                <AiPromptIndicatorButton
                    onAiClick={() => setShowAiPrompt(!showAiPrompt)}
                />
            </Box>

            {openChat && (
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        left: 4,
                        bottom: 4,
                    }}
                >
                    <AiChatWindow
                        chat={prompts}
                        close={() => setOpenChat(false)}
                        editMessage={() => setShowAiPrompt(!showAiPrompt)}
                        validateAiResponse={() => {}}
                        undo={() => {}}
                        saveView={() => {}}
                    />
                </Box>
            )}
            {showAiPrompt && (
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: 107,
                        bottom: 20,
                        zIndex: 505,
                    }}
                >
                    <TextField
                        value={
                            !isSnowflake
                                ? "AI assistant only available in Snowflake"
                                : undefined
                        }
                        fullWidth
                        onChange={(e) => setPrompt(e?.target.value || "")}
                        placeholder="👋 Hello, What can I help you with?"
                        startNode={
                            <IconAvatar color="primary">
                                <MagicButtonOutline color="primary" />
                            </IconAvatar>
                        }
                        readonly={!isSnowflake}
                        sx={{
                            [`& .${textInputBaseClasses["input-root"]}`]: {
                                borderRadius: (theme) => theme.radius.full,
                                background: (theme) =>
                                    theme.palette.background.main,
                            },
                        }}
                        endNode={
                            <WithLoading loading={loading} text={""}>
                                <Chip
                                    onClick={onSendClick}
                                    variant={loading ? "outlined" : "filled"}
                                    color="primary"
                                    disabled={loading || !isSnowflake}
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                    }}
                                >
                                    Send
                                </Chip>
                            </WithLoading>
                        }
                    />
                </Box>
            )}
        </>
    );
};

export default AiPromptButton;
