import {
    MapTemplateWithSavedViews,
    SavedView,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Grid, HorizontalScroller, ScrollWrapper } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import SavedViewCard from "../../saved-views/views/SavedViewCard";

interface ISavedViewsView {
    readonly maps: MapTemplateWithSavedViews[];
    readonly onClickManage: (savedViewId: number) => void;
    readonly onClickViewAll: (mapTemplateId: number) => void;
    readonly onClickSavedView: (
        currentSavedView: SavedView,
        mapTemplateId: number
    ) => void;
}

const SavedViewsView = ({
    maps,
    onClickManage,
    onClickViewAll,
    onClickSavedView,
}: ISavedViewsView) => {
    return (
        <Grid container spacing={4}>
            {pipe(
                maps,
                A.map((map) => {
                    if (map.savedViewExtended.savedViews.length === 0)
                        return null;
                    return (
                        <Grid item key={map.id} xs={12}>
                            <ScrollWrapper
                                title={map.name}
                                image={map.thumbnail || ""}
                                showViewAll={map.savedViewExtended.total > 5}
                                onClick={() => onClickViewAll(map.id)}
                            >
                                {pipe(
                                    map.savedViewExtended.savedViews,
                                    O.fromPredicate((x) => !isEmpty(x)),
                                    O.fold(
                                        () => <></>,
                                        (views) => (
                                            <HorizontalScroller>
                                                <Grid
                                                    container
                                                    gap={4}
                                                    flexWrap="nowrap"
                                                    alignItems="stretch"
                                                >
                                                    {pipe(
                                                        views,
                                                        A.map((view) => (
                                                            <Grid
                                                                item
                                                                key={view.id}
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    width: (
                                                                        theme
                                                                    ) =>
                                                                        theme.spacing(
                                                                            75.625
                                                                        ),
                                                                }}
                                                            >
                                                                <SavedViewCard
                                                                    title={
                                                                        view.name
                                                                    }
                                                                    image={
                                                                        view.image ||
                                                                        ""
                                                                    }
                                                                    subTitle={
                                                                        view.description ||
                                                                        undefined
                                                                    }
                                                                    updatedAt={
                                                                        view.updatedAt
                                                                    }
                                                                    onClickManage={() =>
                                                                        onClickManage(
                                                                            view.id
                                                                        )
                                                                    }
                                                                    bodyOnClick={() =>
                                                                        onClickSavedView(
                                                                            view,
                                                                            map.id
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                        ))
                                                    )}
                                                </Grid>
                                            </HorizontalScroller>
                                        )
                                    )
                                )}
                            </ScrollWrapper>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
};

export default SavedViewsView;
