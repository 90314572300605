import {
    CreateEditDeleteMapTemplate,
    MapTemplateExtended,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import { useEffect, useState } from "react";
import { ErrorPage } from "../../common/components/ErrorPage";
import { CallBacksType } from "../../utils/types";
import MapTemplateForm from "../views/MapTemplateForm";

interface IMapTemplateFormContainer {
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly loading: boolean;
    readonly dataSourceId?: string;
}

const MapTemplateFormContainer = ({
    navigate,
    save,
    loading,
    dataSourceId,
}: IMapTemplateFormContainer) => {
    const [localData, setLocalData] = useState<string[]>([]);

    const { remote: dataSourcesRd } = useFetchDataSourcesQuery({
        variables: {
            input: {
                isConnected: true,
            },
        },
    });

    useEffect(() => {
        if (dataSourceId) {
            setLocalData([dataSourceId]);
        }
    }, [dataSourceId]);

    return match(dataSourcesRd, {
        _: () => <LoadingBar />,
        Success: (data) => {
            return (
                <MapTemplateForm
                    navigate={navigate}
                    save={save}
                    datasets={pipe(
                        data.fetchDataSources.dataSources,
                        toNonReadonlyArray,
                        A.map((item) => ({
                            id: item.id,
                            image: item.icon || "",
                            title: item.label || item.collectionName,
                            select: includes(localData, item.id),
                        }))
                    )}
                    loading={loading}
                    linkedDatasets={localData}
                    setLinkedDatasets={setLocalData}
                    initialDatasets={dataSourceId ? [dataSourceId] : undefined}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};

export default MapTemplateFormContainer;
