import {
    DatasetStyleType,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import { Expression, GeoJSONSource } from "mapbox-gl";
import { match } from "ts-pattern";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";
import {
    createSquareIcon,
    getLayerHeatmapStyle,
} from "../../utils/style-utils";
import { MapContextDataset } from "../context";
import { CustomShapeSource, DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";
import { isLayerPresent, isShapeStyleLayer } from "./data-layers-utils";

export const getFiltersLayers = ({
    dataset,
    sources,
    currentStyles,
    addedStyles,
    suffix,
    map,
    isLoaded,
}: {
    suffix: string;
    dataset?: MapContextDataset;
    sources: {
        aggregate: string;
        points: string;
    };
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
    map?: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded?: boolean;
}): Record<
    Exclude<
        DatasetStyleType,
        DatasetStyleType.stroke | DatasetStyleType.dataAggregation
    >,
    mapboxgl.AnyLayer | Record<ShapeStyle, mapboxgl.AnyLayer>
> => {
    const color = DEFAULT_SHAPE_COLOR;
    const opacity = dataset?.dataSource?.opacity || 0;
    const customMarker =
        dataset?.mapTemplateDataset?.styles?.customMarker || "airport";
    const marker = currentStyles?.customMarker || addedStyles?.customMarker;
    const shape = marker ? null : currentStyles?.shape || addedStyles?.shape;

    const { heatMapColorArray } = getLayerHeatmapStyle(
        addedStyles?.dataAggregation?.heatmap,
        dataset?.dataSource?.color || undefined
    );

    const heatmap: Expression = !isEmpty(heatMapColorArray)
        ? ["interpolate", ["linear"], ["heatmap-density"], ...heatMapColorArray]
        : [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(255,255,255,0)",
              0.5,
              `${addedStyles?.fill?.color || color}`,
              1,
              `${addedStyles?.fill?.color || color}`,
          ];

    if (shape === ShapeStyle.square && map?.current && isLoaded) {
        const image = map.current.hasImage("filtered-square-icon");
        const iconStyle = {
            color: {
                color: currentStyles?.fill?.color || undefined,
                opacity: currentStyles?.fill?.opacity || undefined,
            },
            stroke: {
                color:
                    currentStyles?.stroke?.color ||
                    currentStyles?.fill?.color ||
                    undefined,
                opacity:
                    currentStyles?.stroke?.opacity ||
                    currentStyles?.fill?.opacity ||
                    undefined,
            },
        };

        if (image) {
            map.current.updateImage(
                "filtered-square-icon",
                createSquareIcon({
                    ...iconStyle,
                })
            );
        } else {
            map.current.addImage(
                "filtered-square-icon",
                createSquareIcon({
                    ...iconStyle,
                }),
                { pixelRatio: 4 }
            );
        }
    }

    return {
        [DatasetStyleType.shape]: {
            [ShapeStyle.square]: {
                id: `filtered-points-icon-square-${suffix}`,
                type: "symbol",
                source: sources.points,
                minzoom: 11,
                layout: {
                    "icon-image": "filtered-square-icon",
                    "icon-size": 0.7,
                    visibility: isEqual(shape, ShapeStyle.square)
                        ? "visible"
                        : "none",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                },
                paint: {
                    "icon-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [11, 0],
                            [13, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
            [ShapeStyle.oval]: {
                id: `filtered-points-icon-oval-${suffix}`,
                type: "circle",
                source: sources.points,
                minzoom: 11,
                layout: {
                    visibility: isEqual(shape, ShapeStyle.oval)
                        ? "visible"
                        : "none",
                },
                paint: {
                    "circle-radius": 4,
                    "circle-color": `${currentStyles?.fill?.color || color}`,
                    "circle-stroke-color": `${currentStyles?.stroke?.color || color}`,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: currentStyles?.stroke?.opacity || opacity,
                        stops: [
                            [11, 0],
                            [13, currentStyles?.stroke?.opacity || opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [11, 0],
                            [13, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
        },
        [DatasetStyleType.fill]: {
            id: `filtered-heatmap-${suffix}`,
            type: "heatmap",
            source: sources.aggregate,
            paint: {
                "heatmap-weight": [
                    "interpolate",
                    ["cubic-bezier", 0.1, 0.7, 0.9, 0.3],
                    ["get", "count"],
                    0,
                    0,
                    3000,
                    20,
                ],
                "heatmap-intensity": {
                    stops: [
                        [8, 1],
                        [11, 3],
                    ],
                },
                "heatmap-radius": 15,
                "heatmap-color": heatmap,
                "heatmap-opacity": {
                    default: addedStyles?.fill?.opacity || opacity,
                    stops: [
                        [11, addedStyles?.fill?.opacity || opacity],
                        [13, 0],
                    ],
                },
            },
        },
        [DatasetStyleType.customMarker]: {
            id: `filtered-custom-icons-${suffix}`,
            type: "symbol",
            source: sources.points,
            minzoom: 11,
            layout: {
                "icon-image":
                    addedStyles?.customMarker ||
                    currentStyles?.customMarker ||
                    customMarker,
                "icon-size": 1.2,
                visibility: isNil(shape) && customMarker ? "visible" : "none",
            },
            paint: {
                "icon-opacity": {
                    default: currentStyles?.fill?.opacity || opacity,
                    stops: [
                        [11, 0],
                        [13, currentStyles?.fill?.opacity || opacity],
                    ],
                },
            },
        },
    };
};

export const setFiltersLayers = (input: {
    addedStyles: Partial<MapFilterCriteriaStyle>;
    currentStyles: Partial<MapFilterCriteriaStyle>;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix: string;
}) => {
    const { map, isLoaded, addedStyles, currentStyles, suffix } = input;

    const color = DEFAULT_SHAPE_COLOR;
    const opacity = 0.9;

    if (isLoaded && map.current) {
        const isDatasetLayerPresent = (layerId: string): boolean => {
            return map.current
                ? isLayerPresent({
                      layerId,
                      map: map.current,
                      isLoaded,
                  })
                : false;
        };

        if (isEqual(addedStyles.shape, ShapeStyle.square)) {
            map.current.setLayoutProperty(
                `filtered-points-icon-square-${suffix}`,
                "visibility",
                "visible"
            );

            const image = map.current.hasImage("filtered-square-icon");

            const squareColor = {
                color: currentStyles.fill?.color || color,
                opacity: currentStyles.fill?.opacity || opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };

            if (image) {
                map.current.updateImage(
                    "filtered-square-icon",
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    })
                );
            } else {
                map.current.addImage(
                    "filtered-square-icon",
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    }),
                    { pixelRatio: 4 }
                );
            }

            if (isDatasetLayerPresent(`filtered-custom-icons-${suffix}`)) {
                map.current.setLayoutProperty(
                    `filtered-custom-icons-${suffix}`,
                    "visibility",
                    "none"
                );
            }

            if (isDatasetLayerPresent(`filtered-points-icon-oval-${suffix}`)) {
                map.current.setLayoutProperty(
                    `filtered-points-icon-oval-${suffix}`,
                    "visibility",
                    "none"
                );
            }
        }

        if (isEqual(addedStyles.shape, ShapeStyle.oval)) {
            map.current.setLayoutProperty(
                `filtered-points-icon-oval-${suffix}`,
                "visibility",
                "visible"
            );

            if (isDatasetLayerPresent(`filtered-custom-icons-${suffix}`)) {
                map.current.setLayoutProperty(
                    `filtered-custom-icons-${suffix}`,
                    "visibility",
                    "none"
                );
            }
            if (
                isDatasetLayerPresent(`filtered-points-icon-square-${suffix}`)
            ) {
                map.current.setLayoutProperty(
                    `filtered-points-icon-square-${suffix}`,
                    "visibility",
                    "none"
                );
            }

            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-color",
                `${currentStyles.fill?.color || color}`
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                `${currentStyles.stroke?.color || color}`
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-opacity",
                {
                    default: currentStyles.stroke?.opacity || opacity,
                    stops: [
                        [11, 0],
                        [13, currentStyles.stroke?.opacity || opacity],
                    ],
                }
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                {
                    default: currentStyles.fill?.opacity || opacity,
                    stops: [
                        [11, 0],
                        [13, currentStyles.fill?.opacity || opacity],
                    ],
                }
            );
        }

        if (addedStyles.fill && !isEmpty(addedStyles.fill)) {
            // Update square shape color
            const squareColor = {
                color: addedStyles.fill.color,
                opacity: addedStyles.fill.opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };
            map.current.updateImage(
                "filtered-square-icon",
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );

            // Update oval shape color
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-color",
                addedStyles.fill.color
            );

            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                addedStyles.fill.opacity
            );

            // Update custom marker color
            map.current.setPaintProperty(
                `filtered-custom-icons-${suffix}`,
                "icon-color",
                addedStyles.fill.color
            );
        }

        if (addedStyles.stroke && !isEmpty(addedStyles.stroke)) {
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                addedStyles.stroke.color
            );

            // Update square icon color
            const squareColor = {
                color: currentStyles.fill?.color,
                opacity: currentStyles.fill?.opacity,
            };

            const squareStroke = {
                color: addedStyles.stroke.color,
                opacity: addedStyles.stroke.opacity || opacity,
            };

            map.current.updateImage(
                "filtered-square-icon",
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );
        }

        if (addedStyles.customMarker) {
            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "visibility",
                "visible"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-square-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-oval-${suffix}`,
                "visibility",
                "none"
            );

            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "icon-image",
                addedStyles.customMarker
            );
        }

        if (addedStyles.dataAggregation?.heatmap) {
            const { heatMapColorArray } = getLayerHeatmapStyle(
                addedStyles.dataAggregation.heatmap
            );

            map.current.setLayoutProperty(
                `filtered-heatmap-${suffix}`,
                "visibility",
                "visible"
            );

            map.current.setPaintProperty(
                `filtered-heatmap-${suffix}`,
                "heatmap-color",
                [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    ...heatMapColorArray,
                ]
            );
        }
    }
};

export const setFilterVisibility = (input: {
    visibility: "visible" | "none";
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix: string;
    dataset?: MapContextDataset;
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
}) => {
    const {
        visibility,
        map,
        isLoaded,
        suffix,
        dataset,
        currentStyles,
        addedStyles,
    } = input;

    if (map.current && isLoaded) {
        const layers = getFiltersLayers({
            sources: {
                aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                points: `${CustomShapeSource.filtering}-points-${suffix}`,
            },
            dataset,
            suffix,
            currentStyles,
            addedStyles,
            map,
            isLoaded,
        });

        const isDatasetLayerPresent = (layerId: string): boolean =>
            map.current
                ? isLayerPresent({
                      layerId,
                      map: map.current,
                      isLoaded,
                  })
                : false;

        if (isEqual(visibility, "none")) {
            const aggregateSource = map.current.getSource(
                `${CustomShapeSource.filtering}-aggregate-${suffix}`
            ) as GeoJSONSource;

            const pointsSource = map.current.getSource(
                `${CustomShapeSource.filtering}-points-${suffix}`
            ) as GeoJSONSource;

            if (aggregateSource) {
                aggregateSource.setData({
                    type: "FeatureCollection",
                    features: [],
                });
            }

            if (pointsSource) {
                pointsSource.setData({
                    type: "FeatureCollection",
                    features: [],
                });
            }

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    if (isDatasetLayerPresent(value.square.id)) {
                        map.current?.removeLayer(value.square.id);
                    }

                    if (isDatasetLayerPresent(value.oval.id)) {
                        map.current?.removeLayer(value.oval.id);
                    }
                } else {
                    if (isDatasetLayerPresent(value.id)) {
                        map.current?.removeLayer(value.id);
                    }
                }
            });
        }

        if (isEqual(visibility, "visible")) {
            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    if (!isDatasetLayerPresent(value.square.id)) {
                        map.current?.addLayer(value.square);
                    }
                    if (!isDatasetLayerPresent(value.oval.id)) {
                        map.current?.addLayer(value.oval);
                    }
                } else {
                    if (!isDatasetLayerPresent(value.id)) {
                        map.current?.addLayer(value);
                    }
                }
            });
        }
    }
};

export const handleFilterLayers = (input: {
    action: "add" | "remove";
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix: string;
    dataset?: MapContextDataset;
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
    hierarchy?: Partial<{
        previousFilterId: string;
        nextFilterId: string;
    }>;
    sources?: {
        points: string;
        aggregate: string;
    };
    sourcesData?: {
        points: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
        aggregate: GeoJSON.Feature<
            GeoJSON.Geometry,
            GeoJSON.GeoJsonProperties
        >[];
    };
}) => {
    const {
        action,
        map,
        isLoaded,
        suffix,
        dataset,
        currentStyles,
        addedStyles,
        hierarchy,
        sources: _sources,
        sourcesData,
    } = input;

    if (map.current && isLoaded) {
        const sources = _sources ?? {
            aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
            points: `${CustomShapeSource.filtering}-points-${suffix}`,
        };

        const layers = getFiltersLayers({
            sources,
            dataset,
            suffix,
            currentStyles,
            addedStyles,
            map,
            isLoaded,
        });

        const isDatasetLayerPresent = (layerId: string): boolean =>
            map.current
                ? isLayerPresent({
                      layerId,
                      map: map.current,
                      isLoaded,
                  })
                : false;

        if (isEqual(action, "remove")) {
            mapValues(sources, (source: string) => {
                const geojsonSource = map.current?.getSource(
                    source
                ) as GeoJSONSource;

                if (geojsonSource) {
                    geojsonSource.setData({
                        type: "FeatureCollection",
                        features: [],
                    });
                }
            });

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    if (isDatasetLayerPresent(value.square.id)) {
                        map.current?.removeLayer(value.square.id);
                    }

                    if (isDatasetLayerPresent(value.oval.id)) {
                        map.current?.removeLayer(value.oval.id);
                    }
                } else {
                    if (isDatasetLayerPresent(value.id)) {
                        map.current?.removeLayer(value.id);
                    }
                }
            });
        }

        if (isEqual(action, "add")) {
            if (sourcesData) {
                mapValues(
                    sources,
                    (source: string, key: "aggregate" | "points") => {
                        const geojsonSource = map.current?.getSource(
                            source
                        ) as GeoJSONSource;

                        const features = match(key)
                            .with("aggregate", () => sourcesData.aggregate)
                            .with("points", () => sourcesData.points)
                            .exhaustive();

                        if (geojsonSource) {
                            geojsonSource.setData({
                                type: "FeatureCollection",
                                features,
                            });
                        } else {
                            map.current?.addSource(source, {
                                type: "geojson",
                                data: {
                                    type: "FeatureCollection",
                                    features,
                                },
                            });
                        }
                    }
                );
            }

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    if (!isDatasetLayerPresent(value.square.id)) {
                        map.current?.addLayer(value.square);
                    } else {
                        if (
                            isEqual(currentStyles?.shape, ShapeStyle.square) &&
                            !currentStyles?.customMarker
                        ) {
                            map.current?.setLayoutProperty(
                                value.square.id,
                                "visibility",
                                "visible"
                            );
                        }
                    }

                    if (!isDatasetLayerPresent(value.oval.id)) {
                        map.current?.addLayer(value.oval);
                    } else {
                        if (
                            isEqual(currentStyles?.shape, ShapeStyle.oval) &&
                            !currentStyles?.customMarker
                        ) {
                            map.current?.setLayoutProperty(
                                value.oval.id,
                                "visibility",
                                "visible"
                            );
                        }
                    }

                    if (value && hierarchy?.previousFilterId) {
                        map.current?.moveLayer(
                            value.square.id.replace(
                                suffix,
                                hierarchy.previousFilterId
                            )
                        );
                        map.current?.moveLayer(
                            value.oval.id.replace(
                                suffix,
                                hierarchy.previousFilterId
                            )
                        );
                    }
                } else {
                    const id = value.id;

                    if (!isDatasetLayerPresent(id)) {
                        map.current?.addLayer(value);
                    } else {
                        if (
                            currentStyles?.customMarker &&
                            id.includes("custom-icons")
                        ) {
                            map.current?.setLayoutProperty(
                                id,
                                "visibility",
                                "visible"
                            );
                        }
                    }

                    if (hierarchy?.previousFilterId) {
                        map.current?.moveLayer(
                            id.replace(suffix, hierarchy.previousFilterId)
                        );
                    }
                }
            });
        }
    }
};

export const getFilterLayerIds = (input: {
    type?: "point" | "data";
    suffixes: string[];
}): string[] => {
    const { type, suffixes } = input;

    const layers = pipe(
        suffixes,
        A.map((suffix) =>
            getFiltersLayers({
                suffix,
                sources: {
                    aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                    points: `${CustomShapeSource.filtering}-points-${suffix}`,
                },
            })
        )
    );

    const layerIds = pipe(
        layers,
        A.map((layer) =>
            pipe(
                mapValues(layer, (value) => {
                    if (isShapeStyleLayer(value)) {
                        return [value.oval.id, value.square.id];
                    }

                    return [value.id];
                }),
                Object.values,
                flatten
            )
        ),
        flatten
    );

    if (type) {
        return match(type)
            .with("point", () => layerIds.filter((l) => l.includes("icon")))
            .with("data", () => layerIds.filter((l) => !l.includes("icon")))
            .exhaustive();
    }

    return layerIds;
};
