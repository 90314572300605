import {
    Button,
    Divider,
    IconButton,
    Stack,
    Typography,
    buttonClasses,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";

export type SplitButtonProps = {
    text: string;
    onCloseClick: () => void;
};

export const SplitButton = ({ text, onCloseClick }: SplitButtonProps) => (
    <Stack flexDirection="row">
        <Button
            variant="ghost"
            density="dense"
            readonly
            sx={{
                [`&.${buttonClasses.ghost}`]: {
                    borderLeft: 1,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.outline,
                    borderRadius: 0,
                    borderTopLeftRadius: (theme) => theme.radius.default,
                    borderBottomLeftRadius: (theme) => theme.radius.default,
                },
            }}
        >
            <Typography variant="button" fontWeight="semibold">
                {text}
            </Typography>
        </Button>
        <Divider
            orientation="vertical"
            color={200}
            sx={{
                margin: 0,
            }}
        />
        <IconButton
            variant="ghost"
            density="none"
            onClick={onCloseClick}
            sx={{
                padding: 1.5,
                borderRight: 1,
                borderTop: 1,
                borderBottom: 1,
                borderColor: (theme) => theme.palette.outline,
                borderRadius: 0,
                borderTopRightRadius: (theme) => theme.radius.default,
                borderBottomRightRadius: (theme) => theme.radius.default,
            }}
        >
            <CloseOutline size="xs" />
        </IconButton>
    </Stack>
);
