import { PointDataInput } from "@biggeo/bg-server-lib/datascape-ai";
import { useState } from "react";
import { MapLayout } from "../../layouts/MapLayout";
import { useRenderControls } from "../hooks/render-data-hooks";
import OpenLayersContainer from "../openlayers/containers/OpenLayersContainer";
import { FunctionType } from "../utils/utils";

import { Consumers } from "../../common/redux/model";
import { usePureDataStringOpenLayer } from "../hooks/pure-data-string-openlayers-hook";

const OpenLayersWrapper = ({
    setConsumption,
    activeConsumption,
}: {
    activeConsumption: Consumers;
    setConsumption: (consumption: Consumers) => void;
}) => {
    const {
        responses,
        multiFilters,
        handleRadiusChange,
        handleViewportChange,
        handlePolygonChange,
        functionType,
        setFunctionType,
        radius,
        viewport,
        polygon,
        channelId,
        recentResponse,
        savedPolygon,
        options,
        setOptions,
    } = usePureDataStringOpenLayer();
    const {
        showTriangles,
        setShowTriangles,
        showPoints,
        setShowPoints,
        showFiltered,
        setShowFiltered,
    } = useRenderControls();

    const changeConsumption = (con: Consumers) => {
        setFunctionType(FunctionType.viewport);
        setConsumption(con);
    };

    const [selectedPoint, setSelectedPoint] = useState<PointDataInput>();

    const [selectedDataset, setSelectedDataset] = useState<readonly string[]>(
        []
    );

    return (
        <MapLayout
            savedAreasData={{ fetchAllAreasExtended: [] }}
            savedViewsData={{ fetchSavedViews: { total: 0, savedViews: [] } }}
            loadingSavedViews={false}
            setFunctionType={setFunctionType}
            responses={responses}
            multiFilters={multiFilters}
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
            //addRemoveDataset={addRemoveDataset}
            selectedDataset={selectedDataset}
            setSelectedDataset={setSelectedDataset}
            setSavedPolygons={() => {
                // TODO change type setSavedPolygon
            }}
            setSelectedSavedView={() => {}}
            handleSavedPolygons={() => {}}
            options={options}
            setOptions={setOptions}
            activeConsumption={activeConsumption}
            changeConsumption={changeConsumption}
            setShowFiltered={setShowFiltered}
            setShowPoints={setShowPoints}
            setShowTriangles={setShowTriangles}
            showFiltered={showFiltered}
            showPoints={showPoints}
            showTriangles={showTriangles}
            channelId={channelId}
            functionType={functionType}
            viewport={viewport}
            recentResponse={recentResponse}
        >
            {() => (
                <OpenLayersContainer
                    showTriangles={showTriangles}
                    showPoints={showPoints}
                    showFiltered={showFiltered}
                    key={activeConsumption}
                    functionType={functionType}
                    multiFilters={multiFilters}
                    setSelectedPoint={setSelectedPoint}
                    handleRadiusChange={handleRadiusChange}
                    recentResponse={recentResponse}
                    selectedDataset={selectedDataset}
                    radius={radius}
                    viewport={viewport}
                    handleViewportChange={handleViewportChange}
                    polygon={polygon}
                    handlePolygonChange={handlePolygonChange}
                    savedPolygon={savedPolygon}
                    options={options}
                />
            )}
        </MapLayout>
    );
};

export default OpenLayersWrapper;
