import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    ColorPicker,
    Stack,
    TextField,
    colorToHexString,
    colorToRgbString,
} from "@biggeo/bg-ui/lab";
import { Formik } from "formik";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import startCase from "lodash/startCase";
import { match } from "ts-pattern";
import { AccordionWithDivider } from "../../common/components/AccordionWithDivider";
import {
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../mapbox/hooks/style-hooks";
import { MapFeature, MapLayerForm } from "./MapShapeLayers";

export enum MapShapeStylesCategory {
    name = "name",
    fill = "fill",
    stroke = "stroke",
}

export type MapShapeColorType = {
    color: string;
    opacity: number;
};

export type MapShapeStylesValue = undefined | string | MapShapeColorType;

export type MapShapeStylesForm = Record<
    MapShapeStylesCategory,
    MapShapeStylesValue
>;

export const isColorSetter = (
    value: MapShapeStylesValue
): value is MapShapeColorType => isObject(value);

interface IMapShapeLayerStyles {
    readonly goBack: () => void;
    readonly currentFeature: MapFeature;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly onChange: (i: Partial<MapLayerForm>) => void;
}

const MapShapeLayerStyles = ({
    goBack,
    currentFeature,
    draw,
    onChange,
}: IMapShapeLayerStyles) => {
    const initialValues: MapShapeStylesForm = {
        name: currentFeature.properties?.name || undefined,
        fill: {
            color:
                currentFeature.properties?.["fill-color"] ||
                DEFAULT_SHAPE_COLOR,
            opacity:
                currentFeature.properties?.["fill-opacity"] ||
                DEFAULT_SHAPE_OPACITY,
        },
        stroke: {
            color:
                currentFeature.properties?.["stroke-color"] ||
                DEFAULT_SHAPE_COLOR,
            opacity:
                currentFeature.properties?.["stroke-opacity"] ||
                DEFAULT_SHAPE_OPACITY,
        },
    };

    const getValue = (
        values: MapShapeStylesForm,
        category: MapShapeStylesCategory
    ): MapShapeColorType => {
        const value = values[category];

        const color = isColorSetter(value) ? value.color : DEFAULT_SHAPE_COLOR;
        const opacity = isColorSetter(value)
            ? value.opacity
            : DEFAULT_SHAPE_OPACITY;

        return { color, opacity };
    };

    const save = (i: {
        property: MapShapeStylesCategory;
        value: MapShapeStylesValue;
    }) => {
        const { property, value } = i;
        const featureId = currentFeature.id;

        if (featureId) {
            if (isString(featureId) && !isColorSetter(value)) {
                draw.current?.setFeatureProperty(featureId, property, value);
                onChange({
                    currentFeature: {
                        ...currentFeature,
                        properties: {
                            ...currentFeature.properties,
                            [property]: value,
                        },
                    },
                });
            }

            if (isString(featureId) && isColorSetter(value)) {
                draw.current?.setFeatureProperty(
                    featureId,
                    `${property}-color`,
                    value.color
                );
                draw.current?.setFeatureProperty(
                    featureId,
                    `${property}-opacity`,
                    value.opacity
                );

                onChange({
                    currentFeature: {
                        ...currentFeature,
                        properties: {
                            ...currentFeature.properties,
                            [`${property}-color`]: value.color,
                            [`${property}-opacity`]: value.opacity,
                        },
                    },
                });
            }
        }
    };

    return (
        <Stack gap={2} width={"100%"}>
            <BreadcrumbsGroup value={"styles"}>
                <BreadcrumbsButton value="shape-layer" onClick={() => goBack()}>
                    Shape Layer
                </BreadcrumbsButton>
                <BreadcrumbsButton value={"styles"} hideSeparator>
                    Styles
                </BreadcrumbsButton>
            </BreadcrumbsGroup>
            <Formik<MapShapeStylesForm>
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(_, actions) => {
                    actions.setSubmitting(false);
                }}
            >
                {({ values, setValues }) => {
                    const onChange = (i: Partial<MapShapeStylesForm>) => {
                        setValues((p) => ({ ...p, ...i }));
                    };

                    return pipe(
                        Object.values(MapShapeStylesCategory),
                        A.map((category) => {
                            return (
                                <AccordionWithDivider
                                    key={category}
                                    label={startCase(category)}
                                >
                                    {match(category)
                                        .with(
                                            MapShapeStylesCategory.name,
                                            () => (
                                                <Stack gap={2} width={"100%"}>
                                                    <TextField
                                                        name="name"
                                                        label="Shape name"
                                                        optional
                                                        fullWidth
                                                        subLabel="(Optional)"
                                                        placeholder="Enter name"
                                                        autoComplete="off"
                                                        defaultValue={
                                                            currentFeature
                                                                .properties
                                                                ?.name
                                                        }
                                                        onChange={(_, v) => {
                                                            onChange({
                                                                [category]: v,
                                                            });
                                                        }}
                                                    />
                                                    <Button
                                                        color="surface"
                                                        density="dense"
                                                        disabled={isEqual(
                                                            initialValues[
                                                                category
                                                            ],
                                                            values[category]
                                                        )}
                                                        sx={{
                                                            alignSelf:
                                                                "flex-end",
                                                        }}
                                                        onClick={() =>
                                                            save({
                                                                property:
                                                                    category,
                                                                value: values[
                                                                    category
                                                                ],
                                                            })
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </Stack>
                                            )
                                        )
                                        .with(
                                            MapShapeStylesCategory.fill,
                                            () => {
                                                const { color } = getValue(
                                                    values,
                                                    category
                                                );

                                                return (
                                                    <ColorPicker
                                                        initialColor={color}
                                                        color={color}
                                                        onChange={(color) => {
                                                            const params = {
                                                                color: colorToHexString(
                                                                    color.hex
                                                                ),
                                                                opacity:
                                                                    color.rgb.a,
                                                            };

                                                            onChange({
                                                                [category]:
                                                                    params,
                                                            });

                                                            save({
                                                                property:
                                                                    category,
                                                                value: params,
                                                            });
                                                        }}
                                                    />
                                                );
                                            }
                                        )
                                        .with(
                                            MapShapeStylesCategory.stroke,
                                            () => {
                                                const { color } = getValue(
                                                    values,
                                                    category
                                                );

                                                return (
                                                    <ColorPicker
                                                        initialColor={color}
                                                        color={color}
                                                        onChange={(color) => {
                                                            const params = {
                                                                color: colorToHexString(
                                                                    color.hex
                                                                ),
                                                                opacity:
                                                                    color.rgb.a,
                                                            };

                                                            onChange({
                                                                [category]:
                                                                    params,
                                                            });

                                                            save({
                                                                property:
                                                                    category,
                                                                value: params,
                                                            });
                                                        }}
                                                    />
                                                );
                                            }
                                        )
                                        .exhaustive()}
                                </AccordionWithDivider>
                            );
                        })
                    );
                }}
            </Formik>
        </Stack>
    );
};

export default MapShapeLayerStyles;
