import {
    Divider,
    Grid,
    IconButton,
    InfoCell,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { useMap } from "../../map/mapbox/context";
import { setDatasetVisibility } from "../../map/mapbox/utils/data-layers-utils";
import { DatasetResultType } from "../../map/mapbox/views/MapLegendInfo";
import IconLegend from "./IconLegend";
import IconLegendBar from "./IconLegendBar";

export type IconLegendProps = {
    time: number;
    color: string;
    label: string;
};

const MapInterfaceLegend = ({
    map,
    isLoaded,
    result,
    shapeIcon,
}: {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    result: DatasetResultType;
    shapeIcon: JSX.Element;
}) => {
    const { filters } = useMap();

    const found = result.found;
    const rendered = result.rendered;
    const values = result.timeTaken;

    const hasVisibleFilters = !isEmpty(
        pipe(
            filters,
            A.filter((f) => isEqual(f.visible, true))
        )
    );

    const [pointsVisible, setPointsVisible] = useState<boolean>(true);

    const handleVisibility = (value: boolean) => {
        setPointsVisible(value);

        const current = map.current;

        if (current && isLoaded) {
            const visibility = value ? "visible" : "none";

            setDatasetVisibility({
                map: current,
                prefix: result.dataSource.id,
                levelSets: [],
                visibility,
                isLoaded,
                points: {
                    shape: result.mapTemplateDataset?.styles?.customMarker
                        ? undefined
                        : result.mapTemplateDataset?.styles?.shape || undefined,
                    customMarker:
                        result.mapTemplateDataset?.styles?.customMarker ||
                        undefined,
                },
            });
        }
    };

    return (
        <Stack gap={2} width="100%">
            <Stack>
                <Typography variant="body4" fontWeight="semibold">
                    Speed Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    See below a breakdown of last query
                </Typography>
            </Stack>

            <Stack gap={2} width="100%">
                {/* <Grid container flexWrap="nowrap">
                    {values.map((data) => {
                        const calculatedWidth = (
                            (data.time / totalMs) *
                            100
                        ).toFixed(2);
                        return (
                            <Grid
                                key={`${data.time}-${data.color}`}
                                item
                                width={`${calculatedWidth}%`}
                                height={1}
                                sx={{
                                    backgroundColor: data.color,
                                }}
                            />
                        );
                    })}
                </Grid> */}
                <IconLegendBar
                    values={pipe(
                        values,
                        A.map((data) => ({
                            label: data.label,
                            color: data.color,
                            ratio: data.time,
                        }))
                    )}
                />

                <IconLegend
                    values={pipe(
                        values,
                        A.map((data) => ({
                            color: data.color,
                            value: `${data.time > 0 ? data.time : 0}ms`,
                            label: data.label,
                        }))
                    )}
                />
            </Stack>
            <Stack>
                <Grid
                    container
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                >
                    <Typography variant="body4" fontWeight="regular">
                        Found
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {found.toLocaleString()}
                    </Typography>
                </Grid>
                <Grid
                    container
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                >
                    <Typography variant="body4" fontWeight="regular" truncate>
                        Rendered
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {rendered.toLocaleString()}
                    </Typography>
                </Grid>
            </Stack>

            <Divider />

            <Stack>
                <Typography variant="body4" fontWeight="semibold">
                    Dataset Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    Dataset points breakdown
                </Typography>
            </Stack>
            <InfoCell
                density="none"
                title="All Points"
                startNode={shapeIcon}
                endNode={
                    <IconButton
                        variant="minimal"
                        onClick={() => {
                            handleVisibility(!pointsVisible);
                        }}
                        disabled={hasVisibleFilters}
                    >
                        {pointsVisible ? (
                            <VisibilityOutline size="xs" />
                        ) : (
                            <VisibilityOffOutline size="xs" />
                        )}
                    </IconButton>
                }
                slotProps={{
                    title: {
                        fontWeight: "regular",
                    },
                }}
            />
        </Stack>
    );
};

export default MapInterfaceLegend;
