import { Stack, Typography } from "@biggeo/bg-ui/lab";
import MapTemplateDatasetsMenu from "../components/MapTemplateDatasetsMenu";
import { MapFilterCriteriaDataset } from "../utils/utils";

interface IFilterCriteriaDatasetsEmptyView {
    readonly mapTemplateId: number;
    readonly onDatasetClick: (i: MapFilterCriteriaDataset) => void;
    readonly includedDatasets: string[];
}

const FilterCriteriaDatasetsEmptyView = (
    props: IFilterCriteriaDatasetsEmptyView
) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
                padding: 8,
                border: 1,
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.stroke[200],
                borderRadius: (theme) => theme.radius.default,
            }}
        >
            <Stack alignItems="center">
                <Typography variant="body3" fontWeight="bold">
                    Select Dataset
                </Typography>
                <Typography
                    variant="body3"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    Pick the dataset you want to start filtering on
                </Typography>
            </Stack>
            <MapTemplateDatasetsMenu {...props} />
        </Stack>
    );
};

export default FilterCriteriaDatasetsEmptyView;
