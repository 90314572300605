import { ExportProgressStatus } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Accordion,
    Checkbox,
    Divider,
    Grid,
    InfoCell,
    OverflowingTypography,
    ProgressBar,
    Stack,
    ThumbnailAvatar,
    Tooltip,
    Typography,
    thumbnailAvatarClasses,
    useTheme,
} from "@biggeo/bg-ui/lab";
import Handlebars from "handlebars";

import { ErrorOutline, ExpandContentOutline } from "@biggeo/bg-ui/lab/icons";
import { snakeCase, upperCase } from "lodash";
import startCase from "lodash/startCase";
import { match } from "ts-pattern";
import { formatNumberWithCommas } from "../../utils/utils";
export const ExportDatasetCard = ({
    dataId,
    dataName,
    dataImage,
    dataStatus,
    convertedProgress,
    convertedSize,
    dataSelectStatus,
    progress,
    results,
    rows,
    dataFrom,
    dataType,
    ddlCommand,
    dmlCommand,
    onSelect,
    tableName = "INSERT_TABLE_NAME",
}: {
    readonly dataId: string;
    readonly dataName: string;
    readonly dataImage: string;
    readonly dataStatus: ExportProgressStatus;
    readonly convertedProgress: string;
    readonly convertedSize: string;
    readonly dataSelectStatus: boolean;
    readonly progress: number;
    readonly results?: number;
    readonly rows?: number;
    readonly dataFrom?: string;
    readonly dataType?: string;
    readonly ddlCommand?: string;
    readonly dmlCommand?: string;
    readonly onSelect: (i: string) => void;
    readonly tableName?: string;
}) => {
    const theme = useTheme();
    const ddlTemplate = Handlebars.compile(ddlCommand)({
        tableName: snakeCase(tableName).toUpperCase(),
    });
    const dmlTemplate = Handlebars.compile(dmlCommand)({
        tableName: snakeCase(tableName).toUpperCase(),
    });
    return (
        <Stack
            sx={{
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                borderRadius: (theme) => theme.radius.xs3,
            }}
            width={"100%"}
        >
            <Stack
                sx={{ padding: 3 }}
                width={"100%"}
                gap={match(dataStatus)
                    .with(ExportProgressStatus.Success, () => 0)
                    .with(ExportProgressStatus.Failed, () => 2)
                    .with(ExportProgressStatus.Downloading, () => 2)
                    .otherwise(() => 0)}
            >
                <InfoCell
                    density="none"
                    startNode={
                        <Grid
                            container
                            sx={{
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Checkbox
                                onChange={() => onSelect(dataId)}
                                checked={dataSelectStatus}
                            />

                            <ThumbnailAvatar
                                size="sm"
                                src={dataImage}
                                alt={dataName}
                                sx={{
                                    [`& .${thumbnailAvatarClasses.image}`]: {
                                        backgroundColor: (theme) =>
                                            theme.palette.surface.container,
                                    },
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                }}
                            />

                            <Stack sx={{ maxWidth: "100%" }}>
                                <OverflowingTypography
                                    fontWeight="semibold"
                                    variant="body3"
                                    truncate
                                    textColor="background.onMain"
                                >
                                    {dataName}
                                </OverflowingTypography>

                                <Grid container flexWrap="nowrap">
                                    {results && (
                                        <Typography
                                            variant="body4"
                                            textColor="disabled.onContainer"
                                            truncate
                                        >
                                            {"Results: "}
                                            <span
                                                style={{
                                                    color: theme.palette
                                                        .background.onMain,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {formatNumberWithCommas(
                                                    results
                                                )}
                                            </span>
                                        </Typography>
                                    )}
                                    {rows && (
                                        <Typography
                                            variant="body4"
                                            textColor="disabled.onContainer"
                                            truncate
                                        >
                                            {"Rows: "}
                                            <span
                                                style={{
                                                    color: theme.palette
                                                        .background.onMain,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {rows}
                                            </span>
                                        </Typography>
                                    )}
                                    {/* Hidden on Alfath's request */}
                                    {/* &nbsp;
                                    <Typography
                                        variant="body4"
                                        textColor="disabled.onContainer"
                                        truncate
                                    >
                                        {"| Size: "}
                                        <span
                                            style={{
                                                color: theme.palette.background
                                                    .onMain,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {`${convertedSize} `}
                                        </span>
                                    </Typography> */}
                                    &nbsp;
                                    {dataFrom && (
                                        <Typography
                                            variant="body4"
                                            textColor="disabled.onContainer"
                                            truncate
                                        >
                                            {"| From: "}
                                            <span
                                                style={{
                                                    color: theme.palette
                                                        .background.onMain,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {startCase(dataFrom)}
                                            </span>
                                        </Typography>
                                    )}
                                    {dataType && (
                                        <Typography
                                            variant="body4"
                                            textColor="disabled.onContainer"
                                            truncate
                                        >
                                            {"| Type: "}
                                            <span
                                                style={{
                                                    color: theme.palette
                                                        .background.onMain,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {dataType}
                                            </span>
                                        </Typography>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    }
                />

                <Stack width={"100%"} gap={2}>
                    {dataStatus === ExportProgressStatus.Downloading ? (
                        <>
                            <Divider />
                            <Grid container gap={1}>
                                <Grid item xs>
                                    <Typography
                                        variant="body4"
                                        fontWeight="semibold"
                                        truncate
                                    >
                                        Processing {convertedProgress} of{" "}
                                        {convertedSize}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body4"
                                        fontWeight="semibold"
                                    >
                                        {progress}% Done
                                    </Typography>
                                </Grid>
                                <Tooltip
                                    title="Aggregation style"
                                    sx={{ maxWidth: 40 }}
                                >
                                    <Grid item xs={12}>
                                        <ProgressBar
                                            value={progress || 0}
                                            color={"success"}
                                        />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </>
                    ) : dataStatus === ExportProgressStatus.Failed ? (
                        <>
                            <Divider />
                            <Grid container gap={2} alignItems="center">
                                <ErrorOutline size="xs" color="error" />

                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    textColor="disabled.onContainer"
                                    truncate
                                >
                                    Not enough memory on Snowflake resources
                                </Typography>
                            </Grid>
                        </>
                    ) : undefined}
                </Stack>
            </Stack>
            <Accordion
                density="comfortable"
                label="View SQL Command"
                slots={{
                    expandedIcon: ExpandContentOutline,
                    expandIcon: ExpandContentOutline,
                }}
                slotProps={{
                    expandedIcon: {
                        sx: {
                            transition: "none",
                        },
                    },
                    expandIcon: {
                        sx: {
                            transition: "none",
                        },
                    },
                    wrapper: {
                        sx: { padding: 0 },
                    },
                }}
                sx={{
                    backgroundColor: (theme) => theme.palette.surface.container,
                    borderTopLeftRadius: (theme) => theme.radius.xs3,
                    borderTopRightRadius: (theme) => theme.radius.xs3,
                }}
            >
                <Stack
                    sx={{
                        padding: 3,
                        gap: 2,
                        backgroundColor: (theme) =>
                            theme.palette.surface.container,
                    }}
                >
                    <Typography variant="body3" color="disabled">
                        <code>
                            ## USE THIS COMMAND TO EXPORT DATASET OUTSIDE
                            DATASCAPE TO YOUR OWN SNOWFLAKE INSTANCE
                        </code>
                    </Typography>
                    <Typography variant="body3">
                        <code>{ddlTemplate}</code>
                    </Typography>
                    <Typography variant="body3">
                        <code>{dmlTemplate}</code>
                    </Typography>
                </Stack>
            </Accordion>
        </Stack>
    );
};
