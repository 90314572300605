import { Box } from "@biggeo/bg-ui/lab";
import { ReactNode } from "react";

export type FullMapLayoutProps = {
    children?: ReactNode;
};

export const FullMapLayout = ({ children }: FullMapLayoutProps) => {
    return (
        <Box
            sx={{
                height: "100dvh",
                width: "100%",
                containerType: "inline-size",
                display: "flex",
                overflow: "hidden",
                background: (theme) => theme.palette.background.container,
                color: (theme) => theme.palette.background.onContainer,
            }}
        >
            {children}
        </Box>
    );
};
