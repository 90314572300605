import {
    DataSource,
    UserSnowflakeTableData,
    useUpdateDataSourceMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    AvatarGroup,
    Button,
    CircularLoading,
    ColFilterType,
    DataGridContainer,
    EmptyScreen,
    IFilterSearchPaginate,
    IconAvatar,
    MemoizedField,
    ProgressBar,
    Select,
    Stack,
    ThumbnailAvatar,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    BigGeoLogo,
    Info,
    KeyOutline,
    SyncOutline,
} from "@biggeo/bg-ui/lab/icons";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import { useState } from "react";
import { Link } from "react-router-dom";
import { VelocityButton } from "../../common/components/VelocityButton.tsx";
import { hasBgVelocity, isAppRunningOnSF } from "../../common/redux/hooks.ts";
import { ComputeView } from "../../data-sets/views/ComputeView.tsx";
import { Routes } from "../../navigation/redux/model.ts";
import { formatNumberWithCommas } from "../../utils/utils.ts";
import { DataManagementTab } from "./DataManagementContainer.tsx";
import DatasetsUsedInContainer from "./DatasetsUsedInContainer.tsx";

export interface IAvailableDatasetsContainer {
    readonly dataSources?: readonly DataSource[];
    readonly total?: number;
    readonly tab: DataManagementTab;
    readonly toPage: (path: string) => void;
    readonly isRunningOnSF: boolean;
    readonly loading: boolean;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly isSNP: boolean;
    readonly setLocalDataSources: React.Dispatch<
        React.SetStateAction<readonly DataSource[]>
    >;
    readonly snowflakeData?: readonly UserSnowflakeTableData[];
}
export const AvailableDatasetsContainer = ({
    dataSources,
    total,
    snowflakeData,
    tab,
    loading,
    filterSearchPaginateProps,
    toPage,
    isSNP,
    setLocalDataSources,
}: IAvailableDatasetsContainer) => {
    const [currentRowId, setCurrentRowId] = useState<string | undefined>(
        undefined
    );

    const isRunningOnSF = isAppRunningOnSF();

    const tooltips: {
        compute: string;
        computeUsage: string;
        usedIn: string;
        mapView: string;
        enable: string;
        mapUse: string;
        velocity: string;
    } = {
        compute:
            "This determines whether or not your data is actively using your available compute",
        computeUsage:
            "The percentage this dataset uses in your available compute",
        usedIn: "The map templates that currently have this dataset enabled",
        mapView: "Quick navigate to the map with this dataset on",
        enable: "This determines whether or the dataset is available in your map configuration",
        mapUse: "This determines whether or not the dataset is available in your map configuration",
        velocity:
            "Fast geospatial querying engine that processes data up to 100x faster.",
    };

    const columns: GridColDef<DataSource>[] = [
        {
            field: "id",
            headerName: "ID",
            filterable: false,
            sortable: false,
        },
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 378,
            sortable: false,
            type: ColFilterType.string,
            filterable: true,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.compute)}
                    title={params.row.label || params.row.tableName}
                    subTitle={params.row.description || undefined}
                />
            ),
        },
        {
            field: "tableName",
            headerName: "Mapped Table",
            flex: 1,
            minWidth: 150,
            sortable: false,
            type: ColFilterType.string,
            renderCell: (params) => {
                const [tableName, setTableName] = useState<string>(
                    params.row.tableName
                );

                const {
                    executeMutation: updateDataSource,
                    mutationReturn: [_, { loading: updateSourceLoading }],
                } = useUpdateDataSourceMutation();

                const snowflakeTablesObject = snowflakeData?.map((data) => ({
                    label: data.tableName,
                }));

                return (
                    <Select
                        disabled={updateSourceLoading}
                        endNode={updateSourceLoading && <CircularLoading />}
                        options={snowflakeTablesObject}
                        value={tableName}
                        required
                        label="Table Name"
                        placeholder="Select"
                        helperText="Select the table that the dataset belongs to"
                        fullWidth
                        sx={{
                            cursor: "pointer",
                        }}
                        onChange={(value) => {
                            setTableName(`${value}`);
                            updateDataSource({
                                variables: {
                                    input: {
                                        id: params.row.id,
                                        tableName: value,
                                        sfAlias: snowflakeData?.find(
                                            (val) => val.tableName === value
                                        )?.alias,
                                    },
                                },
                                onCompleted: (data) => {
                                    setLocalDataSources((prev) =>
                                        prev.map((c) =>
                                            c.id === data.updateDataSource.id
                                                ? { ...c, ...data }
                                                : c
                                        )
                                    );
                                },
                            });
                        }}
                    />
                );
            },
        },
        {
            field: "velocity",
            headerName: "Velocity",
            minWidth: 125,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.boolean,
            renderCell: (params) => (
                <MemoizedField disable={Boolean(!params.row.compute)}>
                    <VelocityButton
                        hasVelocity={
                            !!params.row.fkMarketplaceDatasetId ||
                            hasBgVelocity()
                        }
                    />
                </MemoizedField>
            ),
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip
                        title={tooltips.velocity}
                        sx={{ maxWidth: 45 }}
                        bodyNode={
                            <Link
                                to={Routes.bigGeoProductsVelocity}
                                target="_blank"
                            >
                                <Button variant="outlined" fullWidth>
                                    Learn More
                                </Button>
                            </Link>
                        }
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
        },
        {
            field: "src",
            headerName: "Src",
            minWidth: 64,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.string,
            renderCell: (params) => (
                <MemoizedField disable={Boolean(!params.row.compute)}>
                    <IconAvatar color="primary" size="xs">
                        <BigGeoLogo />
                    </IconAvatar>
                </MemoizedField>
            ),
        },
        {
            field: "size",
            headerName: "Size",
            minWidth: 84,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.number,
            filterable: true,
            align: "left",
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.compute)}
                    title={formatNumberWithCommas(params.row.size)}
                />
            ),
        },
        {
            field: "usedIn",
            headerName: "Used In",
            minWidth: 130,
            sortable: false,
            type: ColFilterType.boolean,
            headerAlign: "center",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.usedIn} sx={{ maxWidth: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        key={params.row.id}
                        disable={Boolean(!params.row.compute)}
                    >
                        <DatasetsUsedInContainer dataSourceId={params.row.id} />
                    </MemoizedField>
                );
            },
        },
        {
            field: "compute",
            headerName: "Compute",
            minWidth: 110,
            filterable: true,
            sortable: false,
            type: ColFilterType.boolean,
            headerAlign: "center",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.compute} sx={{ maxWidth: 52 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <ComputeView
                        id={params.row.id}
                        collectionName={params.row.collectionName}
                        setLocalDataSources={setLocalDataSources}
                        currentComputedId={currentRowId}
                        setCurrentComputedId={setCurrentRowId}
                    />
                );
            },
        },
        {
            field: "computeUsage",
            headerName: "Compute Usage",
            minWidth: 160,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.boolean,
            align: "left",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip
                        title={tooltips.computeUsage}
                        sx={{ maxWidth: 45 }}
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField disable={Boolean(!params.row.compute)}>
                        <Stack sx={{ width: "100%" }} gap={2}>
                            <Typography variant="body4" fontWeight="semibold">
                                {`${params.row.progress || 0}%`}
                            </Typography>

                            <ProgressBar
                                value={10}
                                sx={{
                                    height: (theme) => theme.spacing(1.5),
                                    borderRadius: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.disabled.main,
                                }}
                            />
                        </Stack>
                    </MemoizedField>
                );
            },
        },
        {
            field: "manage",
            sortable: false,
            headerName: "",
            minWidth: 110,
            renderCell: (params) => {
                // TODO update with the right data
                const isNeedRefresh = !params.row.isConnected;

                return (
                    <Stack sx={{ width: "100%" }}>
                        {isNeedRefresh ? (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                endNode={<SyncOutline size="xs" />}
                                disabled={!!currentRowId}
                                onClick={() => {
                                    setCurrentRowId(params.row.id);
                                    // TODO: add onClick + setCurrentRowId to undefined on success and on error.
                                }}
                            >
                                Refresh
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                onClick={() => {
                                    toPage(`${Routes.dataManage}/${params.id}`);
                                }}
                            >
                                Manage
                            </Button>
                        )}
                    </Stack>
                );
            },
        },
    ];

    return (
        <>
            {!loading &&
            !filterSearchPaginateProps.searchText &&
            isEmpty(filterSearchPaginateProps.filterObject) &&
            total === 0 ? (
                <EmptyScreen
                    title={"No Datasets Available"}
                    subtitle={
                        "Your datasets that need to be indexed by BigGeo first will appear here."
                    }
                    image={
                        "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                    }
                    buttons={[
                        {
                            startNode: <ActionKeyOutline />,
                            onClick: () => {
                                toPage(`${Routes.explore}/datasets`);
                            },
                            children: "Add Dataset",
                            variant: "filled",
                            color: "primary",
                        },
                        {
                            onClick: () => {
                                toPage(Routes.accessKeys);
                            },
                            children: "Dataset Access Key",
                            startNode: <KeyOutline />,
                        },
                    ]}
                />
            ) : (
                <DataGridContainer
                    pinnedColumns={{ right: ["manage", "mapUse"] }}
                    columnVisibilityModel={{
                        id: false,
                        usedIn: tab === "available",
                        computeUsage: false,
                        tableName: isSNP,
                        compute: isRunningOnSF,
                        manage: isRunningOnSF,
                    }}
                    columns={columns}
                    rows={dataSources || []}
                    rowCount={total || 0}
                    loading={loading}
                    title={"Data sources"}
                    filterSearchPaginateProps={filterSearchPaginateProps}
                    enableColumnResize
                />
            )}
        </>
    );
};
