import {
    CompanyAvatar,
    Grid,
    SubmittingButton,
    Typography,
} from "@biggeo/bg-ui/lab";

export type BigGeoIndexedCardProps = {
    readonly image: string;
    readonly id: string;
    readonly name: string;
    readonly subTitle: string;
    readonly downloadStatus?: "download" | "downloading" | "downloaded";
    readonly onClickDownload?: () => void;
};

export const BigGeoIndexedCard = ({
    image,
    id,
    name,
    subTitle,
    downloadStatus = "download",
    onClickDownload,
}: BigGeoIndexedCardProps) => {
    return (
        <Grid container gap={2} alignItems="center">
            <CompanyAvatar
                square
                size="md"
                color="surface"
                src={image}
                alt={id.toString()}
            />
            <Grid item xs minWidth={0}>
                <Typography variant="body3" fontWeight="semibold" truncate>
                    {name}
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    truncate
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    By {subTitle}
                </Typography>
            </Grid>
            {onClickDownload && (
                <SubmittingButton
                    type="downloading"
                    loading={downloadStatus === "downloading"}
                    _type="button"
                    onClick={onClickDownload}
                >
                    {downloadStatus === "downloaded"
                        ? "Downloaded"
                        : "Download"}
                </SubmittingButton>
            )}
        </Grid>
    );
};
