import { AsyncError } from "@biggeo/bg-server-lib/datascape-ai";
import React, { useContext, useEffect, useState } from "react";
import { useAsyncErrors } from "../redux/hooks";

type IAsyncError = {
    readonly asyncError?: AsyncError;
    readonly setAsyncError: (d?: AsyncError) => void;
};

const AsyncErrorContext = React.createContext<IAsyncError>({
    asyncError: undefined,
    setAsyncError: () => {
        //
    },
});

export const AsyncErrorProvider = ({
    children,
}: { children: React.ReactNode }) => {
    const [asyncError, setAsyncError] = useState<AsyncError | undefined>(
        undefined
    );

    const error = useAsyncErrors("c12j3c1234n01825v");
    useEffect(() => {
        setAsyncError(error.data);
    }, [error]);

    return (
        <AsyncErrorContext.Provider
            value={{ asyncError: asyncError, setAsyncError: setAsyncError }}
        >
            {children}
        </AsyncErrorContext.Provider>
    );
};

export const useAsyncError = () => {
    const { asyncError, setAsyncError } = useContext(AsyncErrorContext);

    return { asyncError, setAsyncError };
};
