import { useDispatch } from "react-redux";

import { Dialog } from "@biggeo/bg-ui/lab";
import {
    useModal,
    useModalDirection,
    useModalType,
    useSelectedModal,
} from "./redux/hooks";
import { modalActions } from "./redux/model";
import DialogModal from "./views/DialogModal";
import FormDrawer from "./views/FormDrawer";

export const Modal = () => {
    const dispatch = useDispatch();
    const modal = useModal();
    const closeModal = () => {
        dispatch(modalActions.closeModal());
    };
    const modalChild = useSelectedModal();
    const modalType = useModalType();
    const direction = useModalDirection();

    if (modalType === "drawer") {
        return (
            <FormDrawer
                open={modal.openModal}
                direction={direction}
                closeModal={closeModal}
            >
                {modalChild}
            </FormDrawer>
        );
    }
    if (modalType === "dialog") {
        return (
            <DialogModal open={modal.openModal} closeModal={closeModal}>
                {modalChild}
            </DialogModal>
        );
    }
    return (
        <Dialog
            open={modal.openModal}
            onClose={closeModal}
            {...modal.dialogProps}
        >
            {modalChild}
        </Dialog>
    );
};
