import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import { TabHeader } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import { NestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import { useNavigate, useParams } from "react-router";
import { match } from "ts-pattern";
import { Routes } from "../../navigation/redux/model";
import MapTemplatesContainer from "../containers/MapTemplatesContainer";
import SavedViewsContainer from "../containers/SavedViewsContainer";

export enum MapTemplatesTab {
    maps = "maps",
    savedViews = "savedViews",
    // activity = "activity",
}

const MapTemplatesPage = () => {
    const { tab } = useParams();
    const toPage = useNavigate();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );
    const getSelectedTab = (param?: string): MapTemplatesTab => {
        return (
            match(camelCase(param))
                .with(MapTemplatesTab.maps, () => MapTemplatesTab.maps)
                .with(
                    MapTemplatesTab.savedViews,
                    () => MapTemplatesTab.savedViews
                )
                // .with(MapTemplatesTab.activity, () => MapTemplatesTab.activity)
                .otherwise(() => MapTemplatesTab.maps)
        );
    };

    const selectedTab = getSelectedTab(tab);

    const createMapTemplate = () => {
        toPage(Routes.mapTemplatesCreate);
    };

    return (
        <NestedLayoutWithHeader title="Map Templates" tag="Product Admin">
            <TabHeader
                hideScrollButtons="desktop-only"
                currentTab={selectedTab}
                tabs={pipe(
                    Object.values(MapTemplatesTab),
                    A.map((tab) => ({
                        value: tab,
                        onClick: () =>
                            toPage(`${Routes.mapTemplates}/${kebabCase(tab)}`),
                        children: startCase(tab),
                    }))
                )}
                cta={
                    selectedTab === MapTemplatesTab.maps
                        ? {
                              startNode: <AddOutline size="xs" />,
                              onClick: createMapTemplate,
                              children: isMobile ? "Create" : "Create Template",
                          }
                        : undefined
                }
            />
            {match(selectedTab)
                .with(MapTemplatesTab.maps, () => (
                    <MapTemplatesContainer
                        createMapTemplate={createMapTemplate}
                    />
                ))
                .with(MapTemplatesTab.savedViews, () => (
                    <SavedViewsContainer toPage={toPage} />
                ))
                // .with(MapTemplatesTab.activity, () => <ActivityContainer />)
                .exhaustive()}
        </NestedLayoutWithHeader>
    );
};

export default MapTemplatesPage;
