import { Fade } from "@biggeo/bg-ui";
import { Box, Grid, Stack } from "@biggeo/bg-ui/lab";
import { useState } from "react";
import { ClusterColorMapper } from "./ClusterColorMapper";
import { ColorSwatchOption } from "./ColorSwatchSelector";
import { HeatMapClusterMapperCard } from "./HeatMapClusterMapperCard";
import { HeatMapColorMapper } from "./HeatMapColorMapper";

export interface HeatMapClusterColorMapperProps {
    readonly heatMapValue?: ColorSwatchOption;
    readonly clusterValue?: ColorSwatchOption;
    readonly extraOptions?: ColorSwatchOption[];

    readonly onClickHeatMapValue?: (heatMapValue: ColorSwatchOption) => void;
    readonly onClickClusterValue?: (clusterValue: ColorSwatchOption) => void;
}

export const HeatMapClusterColorMapper = ({
    heatMapValue,
    clusterValue,
    extraOptions,

    onClickHeatMapValue,
    onClickClusterValue,
}: HeatMapClusterColorMapperProps) => {
    const [selected, setSelected] = useState<"heatmap" | "cluster">("heatmap");

    return (
        <Stack gap={2} width={"100%"}>
            <Grid container gap={2}>
                {(heatMapValue || onClickHeatMapValue) && (
                    <HeatMapClusterMapperCard
                        title="Heat Map"
                        img="https://biggeo.blob.core.windows.net/media/Heatmap IMG.png"
                        selected={selected === "heatmap"}
                        onClick={() => setSelected("heatmap")}
                    />
                )}
                {(clusterValue || onClickClusterValue) && (
                    <HeatMapClusterMapperCard
                        title="Cluster"
                        img="https://biggeo.blob.core.windows.net/media/Cluster IMG.png"
                        selected={selected === "cluster"}
                        onClick={() => setSelected("cluster")}
                    />
                )}
            </Grid>
            {selected === "heatmap" && onClickHeatMapValue && (
                <Fade in={selected === "heatmap"} timeout={500}>
                    <Box>
                        <HeatMapColorMapper
                            value={heatMapValue}
                            extraOptions={extraOptions}
                            onChange={(value) => onClickHeatMapValue?.(value)}
                        />
                    </Box>
                </Fade>
            )}
            {selected === "cluster" && onClickClusterValue && (
                <Fade in={selected === "cluster"} timeout={500}>
                    <Box>
                        <ClusterColorMapper
                            value={clusterValue}
                            onChange={(value) => onClickClusterValue?.(value)}
                        />
                    </Box>
                </Fade>
            )}
        </Stack>
    );
};
