import { DatabaseType } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    Image,
    TopAppBar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";

export const ComingSoonPage = ({
    onClose,
    dataType,
    isRunningOnSF,
}: {
    readonly onClose: () => void;
    readonly dataType?: string;
    readonly isRunningOnSF: boolean;
}) => (
    <FlexScrollAreaContainer>
        <TopAppBar
            density="dense"
            endNode={<CloseOutline onClick={onClose} />}
            divider
        />
        <FlexScrollArea
            sx={{
                padding: 4,
                flexDirection: "column",
                justifyContent: "center",
                gap: 4,
            }}
        >
            <Grid container flexDirection="column">
                <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    sx={{
                        textAlign: "center",
                    }}
                >
                    Coming Soon
                </Typography>
                <Typography
                    variant="body1"
                    fontWeight={"regular"}
                    sx={{ textAlign: "center" }}
                >
                    {`BigGeo Optimized non-geospatial ${dataType === DatabaseType.polygon && !isRunningOnSF ? "polygon" : ""} filtering`}
                </Typography>
            </Grid>

            <Box sx={{ alignSelf: "center" }}>
                <Image
                    src="https://biggeo.blob.core.windows.net/media/as0df8cc123mn908ec.png"
                    width={486}
                    height={303}
                />
            </Box>
        </FlexScrollArea>
    </FlexScrollAreaContainer>
);
