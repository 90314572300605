import { Box, Chip, chipClasses } from "@biggeo/bg-ui/lab";
import { UndoOutline } from "@biggeo/bg-ui/lab/icons";

interface IResetView {
    readonly resetView: () => void;
}

const ResetView = ({ resetView }: IResetView) => {
    return (
        <Box
            sx={{
                position: "absolute",
                display: "flex",
                right: 4,
                bottom: 4,
            }}
        >
            <Chip
                variant="outlined"
                endNode={<UndoOutline size={"sm"} />}
                onClick={() => resetView()}
                sx={{
                    [`&.${chipClasses.outlined}`]: {
                        background: (theme) => theme.palette.background.main,
                    },
                }}
            >
                Reset View
            </Chip>
        </Box>
    );
};

export default ResetView;
