import { ExitIntentModal } from "@biggeo/bg-ui/lab";

export type ConflictAreasModalProps = {
    readonly onClickDecline: () => void;
    readonly onClickAccept: () => void;
};

const ConflictAreasModal = ({
    onClickDecline,
    onClickAccept,
}: ConflictAreasModalProps) => {
    return (
        <ExitIntentModal
            withLoading={false}
            subtitle={
                "Shapes outside area boundary will be discarded. Are you sure you want to proceed?"
            }
            discard={onClickDecline}
            save={onClickAccept}
            buttons={{
                text1: "Nevermind",
                text2: "Yes",
            }}
        />
    );
};

export default ConflictAreasModal;
