import { gql, useLazyQuery } from "@apollo/client";
import {
    FetchMapUpdatesDocument,
    InputPolygon,
    InputRadius,
    InputSavedPolygon,
    InputViewBox,
    NewFilterObjectConnection,
    ReqOptions,
    SavedPolygon,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { v4 as uuidV4 } from "uuid";
import {
    useLatestMapChangesV2,
    useServerWebsocket,
} from "../utils/subscription";
import { FunctionType, matchFunctionType } from "../utils/utils";

const channelId = uuid();

export type InputPolygonWithId = InputPolygon & {
    readonly id: string;
};

const defaultRadius: InputRadius = {
    center: {
        longitude: 0,
        latitude: 0,
    },
    radiusKm: 100,
};

const defaultPolygon: InputPolygon = {
    inners: [],
    outer: { points: [] },
};

export const usePureDataStringOpenLayer = () => {
    const [options, setOptions] = useState<ReqOptions>({
        polygonMaxGeomsPerCell: 75,
        pointMaxGeomsPerCell: 101,
        polygonResolutionOffset: 3,
        pointResolutionOffset: 3,
    });

    const [savedPolygon, _setSavedPolygon] = useState<SavedPolygon>();
    const [functionType, setFunctionType] = useState<FunctionType>(
        FunctionType.viewport
    );
    const [responses, setResponses] = useState<
        Partial<Record<string, SubscriptionResponse | null>>
    >({});
    const [recentResponse, setRecentResponse] =
        useState<SubscriptionResponse>();

    const [radius, setRadius] = useState<InputRadius>(defaultRadius);
    const [viewport, setViewport] = useState<InputViewBox>({
        latBounds: {
            min: 24.396308,
            max: 71.5388,
        },
        lngBounds: {
            min: -140.0,
            max: -66.93457,
        },
    });
    const [polygon, setPolygon] = useState<InputPolygon>(defaultPolygon);

    useEffect(() => {
        matchFunctionType(functionType, {
            radius: () => {
                handleRadiusChange({
                    radius: defaultRadius,
                });
            },
            viewport: () => {
                // handleViewportChange({
                //     viewport: viewport,
                // });
            },
            polygon: () => {
                setPolygon(defaultPolygon);
            },
            savedPolygon: () => {
                handleSavedPolygonChange(undefined);
            },
        });
    }, [functionType]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        matchFunctionType(functionType, {
            radius: () => {
                handleRadiusChange({
                    radius: radius,
                });
            },
            viewport: () => {
                // handleViewportChange({
                //     viewport: viewport,
                // });
            },
            polygon: () => {
                handlePolygonChange({
                    polygon: defaultPolygon,
                });
            },
            savedPolygon: () => {
                handleSavedPolygonChange(savedPolygon);
            },
        });
    }, [options]);

    const clearData = () => {
        setResponses({});
    };

    useLatestMapChangesV2(channelId, (response) => {
        setResponses({ ...responses, [response.databaseId]: response });
        setRecentResponse(response);
    });
    const [_execute] = useLazyQuery<
        { fetchMapUpdates: boolean },
        { input: NewFilterObjectConnection }
    >(FetchMapUpdatesDocument);

    const handleRadiusChange = ({
        radius: localRadius,
    }: {
        radius: Partial<InputRadius>;
    }) => {
        const newRadius = { ...radius, ...localRadius };
        setRadius(newRadius);
        // socket.emit("updateViewPortV2", {
        //     radius: newRadius,
        //     dateTime: Date.now().toString(),
        //     channel: channelId,
        //     multiFilters,
        //     options,
        //     requestId: uuidV4(),
        //     isStreaming: false,
        // });
        // if (multiFilters.some((d) => d.filters.length > 0))
        //     execute({
        //         variables: {
        //             input: {
        //                 radius: newRadius,
        //                 dateTime: Date.now().toString(),
        //                 channelId: channelId,
        //                 filters: multiFilters,
        //                 options,
        //             },
        //         },
        //     });
    };

    const handleViewportChange = ({ viewport }: { viewport: InputViewBox }) => {
        setViewport(viewport);
        // socket.emit("updateViewPortV2", {
        //     viewport,
        //     dateTime: Date.now().toString(),
        //     channel: channelId,
        //     multiFilters,
        //     options,
        //     requestId: uuidV4(),
        //     isStreaming: false,
        // });
        // if (multiFilters.some((d) => d.filters.length > 0))
        //     execute({
        //         variables: {
        //             input: {
        //                 viewport,
        //                 dateTime: Date.now().toString(),
        //                 channelId: channelId,
        //                 filters: multiFilters,
        //                 options,
        //             },
        //         },
        //     });
    };

    const handlePolygonChange = ({ polygon }: { polygon: InputPolygon }) => {
        // socket.emit("updateViewPortV2", {
        //     polygon: polygon,
        //     dateTime: Date.now().toString(),
        //     channel: channelId,
        //     multiFilters,
        //     options,
        //     requestId: uuidV4(),
        //     isStreaming: false,
        // });
        // if (multiFilters.some((d) => d.filters.length > 0))
        //     execute({
        //         variables: {
        //             input: {
        //                 polygon: polygon,
        //                 dateTime: Date.now().toString(),
        //                 channelId: channelId,
        //                 filters: multiFilters,
        //                 options,
        //             },
        //         },
        //     });
    };

    const handleSavedPolygonChange = (_savedPolygon?: InputSavedPolygon) => {
        // socket.emit("updateViewPortV2", {
        //     polygon: savedPolygon?.polygon || defaultPolygon,
        //     dateTime: Date.now().toString(),
        //     channel: channelId,
        //     multiFilters,
        //     options,
        //     requestId: uuidV4(),
        //     isStreaming: false,
        // });
        // if (multiFilters.some((d) => d.filters.length > 0))
        //     execute({
        //         variables: {
        //             input: {
        //                 polygon: savedPolygon?.polygon || defaultPolygon,
        //                 dateTime: Date.now().toString(),
        //                 channelId: channelId,
        //                 filters: multiFilters,
        //                 options,
        //             },
        //         },
        //     });
    };

    return {
        polygon,
        clearData,
        responses,
        channelId,
        multiFilters: [],
        handleRadiusChange,
        handleViewportChange,
        handlePolygonChange,
        functionType,
        setFunctionType,
        radius,
        viewport,
        recentResponse,
        savedPolygon,
        setSavedPolygon: handleSavedPolygonChange,
        options,
        setOptions,
    };
};
