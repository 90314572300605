import { Button, Divider, Select, Stack, svgClasses } from "@biggeo/bg-ui/lab";
import { useEffect, useState } from "react";
import { CustomMapMarker } from "../../../common/components/CustomMapMarker";
import { MapboxIcon, mapboxIconList } from "../../utils/mapbox-icons";

export type DatasetCustomMarkerProps = {
    value?: string;
    onChange: (v: string | null) => void;
    color?: string;
};

const DatasetCustomMarker = ({
    value,
    onChange,
    color,
}: DatasetCustomMarkerProps) => {
    const [selectedIcon, setSelectedIcon] = useState<MapboxIcon | undefined>(
        undefined
    );

    useEffect(() => {
        if (value && mapboxIconList.includes(value)) {
            setSelectedIcon(value as MapboxIcon);
        }
    }, [value]);

    return (
        <Select
            fullWidth
            options={mapboxIconList.map((item) => ({
                label: item,
                startNode: (
                    <CustomMapMarker color={color} icon={item} size="xxs" />
                ),
                onClick: () => {
                    onChange(item);
                    mapboxIconList.includes(item) &&
                        setSelectedIcon(item as MapboxIcon);
                },
            }))}
            startNode={
                selectedIcon && (
                    <CustomMapMarker
                        color={color}
                        icon={selectedIcon}
                        size="xxs"
                    />
                )
            }
            dropdownEndNode={
                selectedIcon &&
                value && (
                    <Stack width={"100%"}>
                        <Divider />
                        <Stack
                            flexDirection="row"
                            justifyContent="flex-end"
                            sx={{
                                padding: 3,
                            }}
                        >
                            <Button
                                variant="minimal"
                                onClick={() => onChange(null)}
                            >
                                Remove
                            </Button>
                        </Stack>
                    </Stack>
                )
            }
            optionHeight={36}
            value={value}
            placeholder="Search marker"
            slotProps={{
                dropdown: {
                    sx: {
                        padding: 0,
                        marginTop: 2,
                        maxHeight: "300px !important",
                        gap: 0,
                    },
                },
            }}
            sx={{
                [`& .${svgClasses.root}`]: {
                    width: "12px !important",
                    height: "12px !important",
                },
            }}
        />
    );
};

export default DatasetCustomMarker;
