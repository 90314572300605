import { Svg } from "@biggeo/bg-ui/lab";
import { Suspense } from "react";
import { MapboxIcon, mapboxIcons } from "../../map/utils/mapbox-icons";

export type MapBoxIconProps = Omit<
    React.ComponentProps<typeof Svg>,
    "colorSet" | "invertColors"
> & {
    icon?: MapboxIcon | string;
};

export const MapBoxIcon = ({
    icon,

    ...props
}: MapBoxIconProps) => {
    const Icon = icon ? mapboxIcons[icon] : undefined;

    return Icon ? (
        <Suspense>
            <Icon {...props} />
        </Suspense>
    ) : null;
};
