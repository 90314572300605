import {
    Button,
    Grid,
    IconAvatar,
    IconButton,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CloseOutline, SnowflakeLogo } from "@biggeo/bg-ui/lab/icons";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { Routes } from "../../navigation/redux/model";
import { isAppRunningOnSF, useClosableCallToActions } from "../redux/hooks";
import { commonActions } from "../redux/model";

export const OnlyAvailableOnSnowflakeBanner = () => {
    const dispatch = useDispatch();
    const open = useClosableCallToActions("onlyAvailableOnSnowflakeBanner");
    const show = !isAppRunningOnSF() && open;

    const handleClose = () =>
        compose(
            dispatch,
            commonActions.closeCallToAction
        )("onlyAvailableOnSnowflakeBanner");

    const content = (
        <>
            <Typography variant="body3">
                Full Datascape use is only available in Snowflake Native Apps
            </Typography>
            <Link to={Routes.datascapeSnowflakeMarketplace} target="_blank">
                <Button color="primary">Get It On Snowflake</Button>
            </Link>
        </>
    );
    const icon = (
        <IconAvatar size="xxs" color="background">
            <SnowflakeLogo size="xs" />
        </IconAvatar>
    );

    const close = (
        <IconButton
            variant="minimal"
            color="inherit"
            onClick={handleClose}
            sx={{
                background: "transparent",
            }}
        >
            <CloseOutline />
        </IconButton>
    );

    return (
        show && (
            <Grid
                container
                sx={{
                    padding: 4,
                    color: (theme) => theme.palette.primary.onMain,
                    background:
                        "linear-gradient(62deg, #2F5AF0 -0.11%, #1B348A 100%)",
                    alignItems: "flex-start",
                    breakpoints: {
                        md: {
                            alignItems: "center",
                        },
                    },
                }}
            >
                <Grid
                    item
                    xs
                    minWidth={0}
                    sx={{
                        display: "none",
                        breakpoints: {
                            md: {
                                display: "block",
                            },
                        },
                    }}
                >
                    <Grid
                        container
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {icon}
                        {content}
                    </Grid>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "none",
                        breakpoints: {
                            md: {
                                display: "block",
                            },
                        },
                    }}
                >
                    {close}
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    item
                    gap={2}
                    sx={{
                        breakpoints: {
                            md: {
                                display: "none",
                            },
                        },
                    }}
                >
                    <Grid container gap={2} justifyContent="space-between">
                        {icon}
                        {close}
                    </Grid>
                    {content}
                </Grid>
            </Grid>
        )
    );
};
