import { ApolloQueryResult } from "@apollo/client";
import {
    DataSource,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    InputFetchSavedView,
    InputViewBox,
    MapStyleType,
    PolygonProperties,
    ReqOptions,
    SavedArea,
    SavedView,
    SubscriptionResponse,
    useFetchSavedViewByIdLazyQuery,
    useSwitchComputeOffMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Fade } from "@biggeo/bg-ui";
import {
    Box,
    SelectableTreeMenuItem,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";

import { snapToView } from "../../../utils/utils";
import { FunctionContainer } from "../../FunctionContainer";
import AiPromptContainer from "../../ai/containers/AiPromptContainer";
import type {
    InputPolygonWithId,
    PureDataStringHookReturnType,
} from "../../hooks/pure-data-string-hook";
import { useMapData } from "../../redux/hooks";
import { mapDataActions } from "../../redux/model";
import {
    FunctionType,
    LastFunctionType,
    getDefaultDatasetConfigurationOptions,
    getInputPolygon,
    getMapFeatures,
    getSavedViewPolygons,
    pickGeospatialSelection,
} from "../../utils/utils";
import { InitializeMapProps, useInitializeMap } from "../hooks/hooks";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks";

import * as A from "fp-ts/Array";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { levelSetPercentiles } from "../../../utils/variables";
import { getContextFilters } from "../../filter-criteria/utils/utils";
import { RenderControlsHookReturnType } from "../../hooks/render-data-hooks";
import { DataPointTooltip } from "../../map-tooltip/components/DataPointTooltip";
import {
    removeCustomLayers,
    setCustomShapeStyling,
} from "../../utils/style-utils";
import { MapContextDataset, MapContextFilter, useMap } from "../context";
import { SetDatasetContextType } from "../context/context-utils";
import Accreditation from "../views/Accreditation";
import MapControls from "../views/MapControls";
import MapInfoElements from "../views/MapInfoElements";
import MapLoadingView from "../views/MapLoadingView";
import { MapPromptPopup } from "../views/MapPromptPopup";
import ResetView from "../views/ResetView";
import SaveView from "../views/SaveView";
import { IMapMainContainer } from "./MapMainContainer";
import { getDataSource } from "./SaveViewForm";

export interface MapboxMapContainerProps
    extends Pick<
            PureDataStringHookReturnType,
            | "deleteShape"
            | "handleSavedPolygons"
            | "handlePolygonsOnZoom"
            | "setSavedPolygons"
        >,
        Pick<InitializeMapProps, "handleSelectedShapes">,
        SetDatasetContextType {
    readonly channelId: string;
    readonly functionType: FunctionType;
    readonly multiFilters: FilterObject[];
    readonly viewport: InputViewBox;
    readonly handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly savedPolygons: SavedPolygonType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly polygons?: InputPolygonWithId[];
    readonly handleMultiPolygons: ({
        polygon,
    }: {
        readonly polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    readonly clearShapes?: () => void;
    readonly setReFetchSavedAreas?: (value: boolean) => void;
    readonly openSaveViewPopper?: boolean;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly selectedAreaId?: number;
    readonly setSelectedAreaId?: (id?: number) => void;
    readonly resetAreasFilters: () => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly selectedSavedView?: number;
    readonly setSelectedSavedView?: (id?: number) => void;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly handleSelectedSavedPolygons: (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
    }) => void;
    readonly selectableItems: SelectableTreeMenuItem[];
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly savedAreaId: number;
    readonly savedViewId: number;
    readonly setSearchParams: SetURLSearchParams;
    readonly mapTemplateId: number;
    readonly isLoading?: boolean;
    readonly isFilterCriteriaOpen: boolean;
    readonly filters: MapContextFilter[];
    readonly setFilters: (f: MapContextFilter[]) => void;
    readonly mapStyle: MapStyleType;
    readonly selectSavedAreas: (
        savedAreas: Pick<PolygonProperties, "name" | "areaId" | "savedAreaId">[]
    ) => void;
    readonly clearFilters: () => void;
}

export const MapboxMapContainer = ({
    channelId,
    functionType,
    multiFilters,
    viewport,
    handleViewportChange,
    recentResponse,
    savedPolygons,
    setFunctionType,
    clearShapes,
    setReFetchSavedAreas,
    openSaveViewPopper,
    setOpenSaveViewPopper,
    selectedAreaId,
    setSelectedAreaId,
    polygons,
    handleMultiPolygons,
    resetAreasFilters,
    selectedSavedView,
    setSelectedSavedView,
    responses,
    deleteShape,
    handleSavedPolygons,
    handlePolygonsOnZoom,
    handleSelectedSavedPolygons,
    selectableItems,
    handleSelectedShapes,
    selectedShapes,
    setSavedPolygons,
    savedAreaId,
    savedViewId,
    setSearchParams,
    mapTemplateId,
    isFilterCriteriaOpen,
    filters,
    setFilters,
    mapStyle,
    updateDataset,
    overrideDatasets,
    selectSavedAreas,
    clearFilters,
}: MapboxMapContainerProps) => {
    const isRunningOnSF = isAppRunningOnSF();
    const dispatch = useDispatch();
    const mapReduxData = useMapData();

    const [lastFunctionType, setLastFunctionType] = useState<
        LastFunctionType | undefined
    >(undefined);

    const [localMapData, setLocalMapData] = useState<
        Partial<SavedView> | undefined
    >(undefined);

    const [prompt, setPrompt] = useState("");

    const {
        map,
        draw,
        isLoaded,
        datasets,
        dispatch: mapDispatch,
        isDataLoaded,
    } = useMap();
    const isFilterCriteriaLoading = isDataLoaded;

    const {
        changeProjection,
        projection,
        zoomIn,
        zoomOut,
        selectMode,
        isSelectMode,
        showAiPrompt,
        setShowAiPrompt,
        isDrawMode,
    } = useInitializeMap({
        functionType,
        multiFilters,
        recentResponse,
        viewport,
        handleViewportChange,
        polygons: polygons || [],
        savedPolygons,
        deleteShape,
        handleMultiPolygons,
        handleSavedPolygons,
        handlePolygonsOnZoom: (p) => handlePolygonsOnZoom?.(p),
        setFunctionType,
        handleSelectedSavedPolygons,
        selectableItems,
        handleSelectedShapes,
        isFilterCriteriaOpen,
        setSavedPolygons,
    });

    const { executeQuery } = useFetchSavedViewByIdLazyQuery();
    const { executeMutation: switchComputeOff } = useSwitchComputeOffMutation();

    const switchOffDataset = (id: string) => {
        const updateDataSource = (data: Partial<DataSource>) => {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: "Load collection failed. Please turn it back on from the data tab.",
                    autoHideDuration: 8000,
                    severity: Severity.error,
                })
            );

            updateDataset({
                dataSourceId: id,
                dataset: {
                    dataSource: data,
                    isSelected: false,
                    isVisible: false,
                },
            });
        };

        const dataset = datasets.find((d) => d.dataSource.id === id);

        if (dataset) {
            switchComputeOff({
                variables: {
                    input: {
                        id,
                        collectionName: dataset.dataSource.collectionName,
                    },
                },
                onCompleted: (d) => {
                    updateDataSource(d.switchComputeOff);
                },
                onError: () => {
                    updateDataSource({
                        isLoading: false,
                        compute: false,
                    });
                },
            });
        }
    };

    const bounds = map.current?.getBounds();

    const isDrawFunctionType =
        functionType === FunctionType.polygon ||
        functionType === FunctionType.radius ||
        functionType === FunctionType.square;

    const handleSavedView = ({
        savedArea,
        viewport,
        filters: savedViewFilters,
        datasetsExtended,
        datasets: savedViewDatasets,
        isRunningOnSF,
    }: Pick<SavedView, "savedArea" | "viewport" | "filters" | "datasets"> & {
        datasetsExtended: MapContextDataset[];
        isRunningOnSF: boolean;
    }) => {
        if (savedArea && !isEmpty(savedArea.geometries)) {
            const { drawnGeometries, savedAreaGeometries } =
                getSavedViewPolygons(savedArea.geometries);

            if (drawnGeometries) {
                handleSavedPolygons(
                    pipe(
                        drawnGeometries,
                        A.map((f) => getInputPolygon(f))
                    )
                );

                if (draw.current) {
                    draw.current.add({
                        type: "FeatureCollection",
                        features: drawnGeometries,
                    });

                    if (map.current) {
                        setCustomShapeStyling({
                            map: map.current,
                            isLoaded,
                            datasets,
                            geometries: drawnGeometries,
                        });
                    }
                }
            }

            if (savedAreaGeometries) {
                selectSavedAreas(savedAreaGeometries);
            }
        }

        if (viewport) {
            map.current?.fitBounds([
                {
                    lat: viewport.latBounds.min,
                    lng: viewport.lngBounds.min,
                },
                {
                    lat: viewport.latBounds.max,
                    lng: viewport.lngBounds.max,
                },
            ]);

            if (!savedArea || isEmpty(savedArea.geometries)) {
                handleViewportChange({ viewport });
            }
        }

        if (savedViewFilters) {
            const { filters } = getContextFilters(
                savedViewFilters,
                datasetsExtended,
                isRunningOnSF
            );

            if (filters) {
                setFilters(filters);
            }
        }

        if (savedViewDatasets && !isEmpty(savedViewDatasets)) {
            overrideDatasets(
                pipe(
                    savedViewDatasets,
                    A.filter((d) => !!d.progress && d.progress >= 100),
                    A.map((d) => {
                        return {
                            dataSource: getDataSource(d),
                            mapTemplateDataset: d.mapTemplateDatasetId
                                ? {
                                      id: d.mapTemplateDatasetId,
                                      fkMapTemplateId: mapTemplateId,
                                      fkDataSourceId: d.id,
                                      mapUse: true,
                                      enable: true,
                                      styles: d.styles || undefined,
                                  }
                                : undefined,
                            isSelected: d.selected,
                            isVisible: d.selected,
                            isGettingStyled: false,
                            isLegendOpen: false,
                            isTableViewed: false,
                            configuration: {
                                isOpen: false,
                                showLevelSets:
                                    d.configuration?.showLevelSets ?? true,
                                showPoints: d.configuration?.showPoints ?? true,
                                options: d.configuration?.options
                                    ? {
                                          pointMaxGeomsPerCell:
                                              d.configuration?.options
                                                  .pointMaxGeomsPerCell,
                                          pointResolutionOffset:
                                              d.configuration?.options
                                                  .pointResolutionOffset,
                                          polygonMaxGeomsPerCell:
                                              d.configuration?.options
                                                  .polygonMaxGeomsPerCell,
                                          polygonResolutionOffset:
                                              d.configuration?.options
                                                  .polygonResolutionOffset,
                                          levelSets:
                                              d.configuration?.options
                                                  .levelSets ??
                                              levelSetPercentiles,
                                      }
                                    : getDefaultDatasetConfigurationOptions(
                                          isRunningOnSF
                                      ),
                            },
                        };
                    })
                )
            );
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isEmpty(mapReduxData)) {
            if (isLoaded) {
                mapReduxData.fkSavedAreaId &&
                    setFunctionType(FunctionType.savedPolygon);
            } else {
                map.current?.on("style.load", () => {
                    mapReduxData.fkSavedAreaId &&
                        setFunctionType(FunctionType.savedPolygon);
                });
            }
            setLocalMapData(mapReduxData);
            setSelectedAreaId?.(mapReduxData.fkSavedAreaId || undefined);
            if (mapReduxData?.viewport) {
                setSearchParams({
                    savedViewId: mapReduxData.id?.toString() || "0",
                });
            } else if (mapReduxData?.fkSavedAreaId) {
                setSearchParams({
                    savedAreaId: mapReduxData.fkSavedAreaId?.toString() || "0",
                });
            }
        }
    }, [mapReduxData]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        mapReduxData?.id !== savedViewId &&
            savedViewId &&
            executeQuery({
                variables: { id: savedViewId },
                onCompleted: ({ fetchSavedViewById }) => {
                    if (fetchSavedViewById) {
                        if (!selectedAreaId) {
                            setSelectedAreaId?.(savedAreaId);
                        }
                        if (!selectedSavedView) {
                            setSelectedSavedView?.(savedViewId);
                        }
                        dispatch(
                            mapDataActions.updateMapData(fetchSavedViewById)
                        );
                    }
                },
            });
    }, [savedViewId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const updateData = () => {
            const savedViewDatasets = localMapData?.datasets || undefined;
            const savedArea = localMapData?.savedArea || undefined;
            const viewport = localMapData?.viewport || undefined;
            const savedViewFilters = localMapData?.filters || undefined;

            handleSavedView({
                savedArea,
                viewport,
                filters: savedViewFilters,
                datasetsExtended: datasets,
                datasets: savedViewDatasets,
                isRunningOnSF,
            });
        };

        if (localMapData) {
            mapDispatch?.({
                type: "SET_MAP_STYLE",
                values: localMapData.mapSetting
                    ? localMapData.mapSetting.mapStyle
                    : MapStyleType.light,
            });
        }

        if (isLoaded && map.current) {
            updateData();
        }
    }, [
        localMapData,
        isLoaded,
        map.current,
        datasets.length,
        selectableItems.length,
        isRunningOnSF,
    ]);

    const onClose = () => {
        draw.current?.changeMode("simple_select");

        if (isDrawFunctionType) {
            setLastFunctionType(functionType);
        }

        setFunctionType(FunctionType.viewport);
    };

    const shapes = getMapFeatures(draw, isLoaded);
    const hasDrawnShapes = !isEmpty(shapes);
    const hasSelectedAreas = !isEmpty(savedPolygons);
    const hasDataSets = !isEmpty(
        pipe(
            datasets,
            A.filter((d) => isEqual(d.isSelected, true))
        )
    );
    const hasPolygons = !isEmpty(polygons);
    const hasFilters = !isEmpty(filters);

    const canResetView =
        hasDrawnShapes ||
        hasSelectedAreas ||
        hasDataSets ||
        hasPolygons ||
        hasFilters;

    const resetView = () => {
        if (draw.current && hasDrawnShapes) {
            draw.current.deleteAll();
            draw.current?.changeMode("simple_select");
            if (map.current) {
                removeCustomLayers(map.current);
            }
        }

        if (hasSelectedAreas) {
            resetAreasFilters();
        }

        if (hasDataSets) {
            pipe(
                datasets,
                A.filter((d) => isEqual(d.isSelected, true)),
                A.map((d) => {
                    updateDataset({
                        dataSourceId: d.dataSource.id,
                        dataset: {
                            isVisible: false,
                            isSelected: false,
                            isTableViewed: false,
                            isLegendOpen: false,
                        },
                    });
                })
            );
        }

        if (hasFilters) {
            clearFilters();
        }

        const source = map.current?.getSource(
            "saved-polygons"
        ) as GeoJSONSource;

        if (source) {
            source.setData({
                type: "FeatureCollection",
                features: [],
            });
        }

        setLocalMapData(undefined);
        setSelectedAreaId?.(undefined);
        setSelectedSavedView?.(undefined);
        clearShapes?.();
        setFunctionType(FunctionType.viewport);
        setLastFunctionType(undefined);
        selectMode(false);
        setSearchParams({});
        dispatch(mapDataActions.updateMapData({}));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // When they click on custom boundaries from map side menu
        if (functionType === FunctionType.savedPolygon) {
            draw.current?.changeMode("simple_select");
            selectMode(false);
        }
    }, [functionType]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependencies
    useEffect(() => {
        if (
            recentResponse &&
            !isNil(recentResponse.collectionExist) &&
            !recentResponse.collectionExist
        ) {
            switchOffDataset(recentResponse.databaseId);
            updateDataset({
                dataSourceId: recentResponse.databaseId,
                dataset: {
                    isSelected: false,
                    isVisible: false,
                },
            });
        }
    }, [recentResponse]);

    return (
        <Fade in={isLoaded} timeout={1500}>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    id="map"
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        lineHeight: 0,
                        borderRadius: (theme) => theme.radius.default,
                        overflow: "hidden",
                    }}
                />
                <MapPromptPopup />
                <MapInfoElements
                    responses={responses}
                    map={map}
                    datasets={datasets}
                    isLoaded={isLoaded}
                />
                <FunctionContainer
                    onClose={onClose}
                    functionType={functionType}
                    setFunctionType={setFunctionType}
                    draw={draw}
                    resetView={resetView}
                    selectMode={selectMode}
                    isSelectMode={isSelectMode}
                    selectedShapes={selectedShapes}
                    snapToView={() =>
                        snapToView({
                            polygons: polygons || [],
                            map,
                        })
                    }
                    setReFetchSavedAreas={setReFetchSavedAreas}
                    isDrawMode={isDrawMode}
                    lastFunctionType={lastFunctionType}
                    setLastFunctionType={setLastFunctionType}
                    hasDrawnShapes={hasDrawnShapes}
                    mapTemplateId={mapTemplateId}
                    deleteShape={deleteShape}
                />
                <MapControls
                    map={map}
                    changeProjection={changeProjection}
                    projection={projection}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    geospatialSelection={pickGeospatialSelection(functionType, {
                        viewport,
                        multipolygon: polygons?.map((c) => ({
                            inners: c.inners,
                            outer: c.outer,
                        })),
                    })}
                    channelId={channelId}
                />
                <SaveView
                    openSaveViewPopper={openSaveViewPopper}
                    setOpenSaveViewPopper={setOpenSaveViewPopper}
                    bounds={bounds}
                    viewport={viewport}
                    draw={draw}
                    onCloseDraw={onClose}
                    savedViewId={savedViewId}
                    isLoaded={isLoaded}
                    filters={filters}
                    mapStyle={mapStyle}
                    map={map}
                    datasets={datasets}
                    savedPolygons={savedPolygons}
                    isRunningOnSF={isRunningOnSF}
                />
                <AiPromptContainer
                    handleViewportChange={handleViewportChange}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    map={map}
                    draw={draw}
                    setFunctionType={setFunctionType}
                    snapToView={snapToView}
                    polygons={polygons}
                    handleMultiPolygons={handleMultiPolygons}
                    setShowAiPrompt={setShowAiPrompt}
                    showAiPrompt={showAiPrompt}
                />
                <Accreditation />
                {canResetView && !openSaveViewPopper && (
                    <ResetView resetView={resetView} />
                )}
                {!!isFilterCriteriaLoading && <MapLoadingView />}
                {!isSelectMode && !isDrawMode ? (
                    // Wrapping this in a Stack is necessary, otherwise it throws a Node error when you try
                    // to draw after opening the tooltip.
                    <Stack>
                        <DataPointTooltip />
                        {/* <HeatMapTooltip /> */}
                    </Stack>
                ) : null}
            </Box>
        </Fade>
    );
};
