import { useFetchMapTemplateWithSavedViewsByIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyScreen, LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { Routes } from "../../navigation/redux/model";
import SavedViewList from "../../saved-views/views/SavedViewList";
import { MapTabs } from "../map-wrappers/MapViewWrapper";
import { IMapConfiguration } from "./MapConfiguration";

interface IMapSavedViews extends IMapConfiguration {}

const MapSavedViews = ({ mapTemplateId, toPage }: Readonly<IMapSavedViews>) => {
    const {
        queryReturn: { data },
    } = useFetchMapTemplateWithSavedViewsByIdQuery({
        variables: { mapTemplateId: mapTemplateId },
    });
    return (
        <Stack gap={4} sx={{ padding: 4 }}>
            {pipe(
                data,
                O.fromNullable,
                O.map(({ fetchMapTemplateWithSavedViewsById }) =>
                    pipe(
                        fetchMapTemplateWithSavedViewsById,
                        O.fromPredicate(
                            (total) => total.savedViewExtended.total > 0
                        ),
                        O.fold(
                            () => (
                                <EmptyScreen
                                    title={"No Saved Views"}
                                    subtitle={
                                        "When you create a saved view on this map template, they will appear here."
                                    }
                                    image={
                                        "https://biggeo.blob.core.windows.net/media/config-empty-saved-view.png"
                                    }
                                    buttons={[
                                        {
                                            startNode: <AddOutline />,
                                            onClick: () => {
                                                toPage?.(
                                                    `${Routes.mapView}/${mapTemplateId}/${MapTabs.map}`
                                                );
                                            },
                                            children: "Create View",
                                            variant: "filled",
                                            color: "primary",
                                        },
                                        {
                                            onClick: () => {},
                                            children: "Learn More",
                                        },
                                    ]}
                                />
                            ),
                            ({ savedViewExtended, id, name, thumbnail }) => (
                                <SavedViewList
                                    data={savedViewExtended.savedViews || []}
                                    mapTemplateId={id}
                                    title={name}
                                    image={thumbnail || undefined}
                                />
                            )
                        )
                    )
                ),
                O.getOrElse(() => <LoadingBar />)
            )}
        </Stack>
    );
};

export default MapSavedViews;
