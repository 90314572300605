import { Box, Chip, Menu, MenuItem } from "@biggeo/bg-ui/lab";
import { ExpandMoreOutline } from "@biggeo/bg-ui/lab/icons";
import React, { useEffect } from "react";
import { getLinearGradient } from "../utils/gradient";

export type ColorSwatch = {
    range: number;
    color: string;
}[];

export type ColorSwatchOption = {
    id: number | "custom";
    swatch: ColorSwatch;
};

export type ColorSwatchSelectorProps = {
    variant?: "gradient" | "discrete";
    open?: boolean;
    value?: ColorSwatchOption;
    options?: ColorSwatchOption[];
    onChange?: (_value: ColorSwatchOption) => void;
    onOpenChange?: (_open: boolean) => void;
};

export const ColorSwatchSelector = ({
    variant = "gradient",
    open: _open = false,
    value: _value,
    options,
    onChange,
    onOpenChange,
}: ColorSwatchSelectorProps) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState<ColorSwatchOption | undefined>(
        _value ?? options?.[0]
    );

    useEffect(() => {
        _value && setValue(_value);
    }, [_value]);

    useEffect(() => {
        _open && setOpen;
    }, [_open]);

    return (
        <Menu
            open={open ?? _open}
            onOpenChange={(open) => {
                if (onOpenChange) {
                    onOpenChange(open);
                } else {
                    setOpen(open);
                }
            }}
            sx={{
                padding: 1,
                minWidth: 50,
            }}
            content={
                <>
                    {options?.map((option, index) => (
                        <MenuItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            onClick={() => {
                                if (onChange) {
                                    onChange(option);
                                } else {
                                    setValue(option);
                                }
                            }}
                            density="none"
                            sx={{
                                position: "relative",
                                height: 4.5,
                                borderRadius: (theme) => theme.radius.default,
                                background:
                                    variant === "gradient"
                                        ? getLinearGradient(option)
                                        : `linear-gradient(to right, ${option?.swatch.map((color, index) => `${index > 0 ? `${color.color} ${((index - 1) / option.swatch.length) * 100}%,` : ""} ${color.color} ${((index + 1) / option.swatch.length) * 100}%`).join(", ")})`,
                                "&::before": {
                                    content: '""',
                                    zIndex: -1,
                                    borderRadius: "inherit",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: " 100%",
                                    height: "100%",
                                    backgroundImage:
                                        "linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)",
                                    backgroundSize: "20px 20px",
                                    backgroundPosition:
                                        "0 0, 0 10px, 10px -10px, -10px 0px",
                                },
                            }}
                        />
                    ))}
                </>
            }
        >
            <Chip
                endNode={
                    <ExpandMoreOutline
                        sx={{
                            transform: open ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.2s",
                        }}
                    />
                }
                sx={{
                    paddingLeft: 3,
                    width: "100%",
                    alignItems: "stretch",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        flexGrow: 1,
                        borderRadius: (theme) => theme.radius.default,
                        background:
                            variant === "gradient"
                                ? `linear-gradient(to right, ${value?.swatch.map((color, index) => `${color.color} ${(index / value.swatch.length) * 100}%`).join(", ")})`
                                : `linear-gradient(to right, ${value?.swatch.map((color, index) => `${index > 0 ? `${color.color} ${((index - 1) / value.swatch.length) * 100}%,` : ""} ${color.color} ${((index + 1) / value.swatch.length) * 100}%`).join(", ")})`,
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            zIndex: -1,
                            borderRadius: "inherit",
                            top: 0,
                            left: 0,
                            width: " 100%",
                            height: "100%",
                            backgroundImage:
                                "linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)",
                            backgroundSize: "20px 20px",
                            backgroundPosition:
                                "0 0, 0 10px, 10px -10px, -10px 0px",
                        },
                    }}
                />
            </Chip>
        </Menu>
    );
};
