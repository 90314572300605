import { Grid } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { GenericDraggableItem } from "../../common/components/GenericDraggableItem";
import { DrawShapeLayer } from "../../components/DrawShapeLayer";
import {
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../mapbox/hooks/style-hooks";
import { getShapeIcon } from "../utils/draw-modes-utils";
import { FunctionType } from "../utils/utils";
import { MapFeature, MapLayerForm } from "./MapShapeLayers";

interface IMapShapeLayersView {
    readonly state: MapFeature[];
    readonly values: MapLayerForm;
    readonly onDelete: (id: string) => void;
    readonly onMore: (feature?: MapFeature) => void;
    readonly onChange: (i: Partial<MapLayerForm>) => void;
    readonly onHover: (f: MapFeature, isHovered: boolean) => void;
}

const MapShapeLayersView = ({
    values,
    state,
    onDelete,
    onMore,
    onChange,
    onHover,
}: IMapShapeLayersView) => {
    return (
        <Grid container spacing={1}>
            {pipe(
                values.features,
                A.mapWithIndex((index, shape) => {
                    const type: FunctionType = shape.properties?.type;

                    return (
                        <Grid key={shape.id} item xs={12}>
                            <GenericDraggableItem<MapFeature>
                                type="ShapeLayer"
                                index={index}
                                state={state}
                                onHoverAndDrop={(state) => {
                                    const orderedStateIds = state.map(
                                        (s) => s.id
                                    );

                                    onChange({
                                        features: pipe(
                                            values.features,
                                            (features) =>
                                                features.sort(
                                                    (a, b) =>
                                                        orderedStateIds.indexOf(
                                                            a.id
                                                        ) -
                                                        orderedStateIds.indexOf(
                                                            b.id
                                                        )
                                                )
                                        ),
                                    });
                                }}
                            >
                                <DrawShapeLayer<string>
                                    layerId={shape.id as string}
                                    shapeIcon={getShapeIcon({
                                        functionType: type,
                                    })}
                                    name={
                                        shape.properties?.name ||
                                        "Layer shape name"
                                    }
                                    selected={
                                        shape.properties?.selected || false
                                    }
                                    onDelete={onDelete}
                                    onMore={() => onMore(shape)}
                                    onHover={(isHovered) =>
                                        onHover(shape, isHovered)
                                    }
                                    color={{
                                        color:
                                            shape.properties?.["fill-color"] ||
                                            DEFAULT_SHAPE_COLOR,
                                        opacity:
                                            shape.properties?.[
                                                "fill-opacity"
                                            ] || DEFAULT_SHAPE_OPACITY,
                                    }}
                                    borderColor={{
                                        color:
                                            shape.properties?.[
                                                "stroke-color"
                                            ] || DEFAULT_SHAPE_COLOR,
                                        opacity:
                                            shape.properties?.[
                                                "stroke-opacity"
                                            ] || DEFAULT_SHAPE_OPACITY,
                                    }}
                                />
                            </GenericDraggableItem>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
};

export default MapShapeLayersView;
