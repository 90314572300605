import { MapTemplateStatus } from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Box,
    Grid,
    IconButton,
    Menu,
    OverflowingTypography,
    Stack,
    ThumbnailAvatar,
    thumbnailAvatarClasses,
} from "@biggeo/bg-ui/lab";
import {
    Favorite,
    FavoriteOutline,
    LinkOutline,
    MoreHorizOutline,
    OpenInNewOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { MapTemplateStatusChip } from "../../map-templates/views/MapTemplateStatusChip.tsx";

export type BasicCardType = {
    readonly title: string;
    readonly subTitle: string;
    readonly status?: MapTemplateStatus;
    readonly image: string;
    readonly secondImage?: string;
    readonly bodyOnClick: () => void;
};

type WithoutActionType = BasicCardType & { readonly withAction?: false };

type WithActionType = BasicCardType & {
    readonly withAction: true;
    readonly isFavorite?: boolean;
    readonly onClickFavorite?: () => void;
    readonly onClickOpenInNew?: () => void;
    readonly onClickMore?: () => void;
    readonly menuItems: React.ReactNode;
};

type MapTemplateCardType = WithActionType | WithoutActionType;

const MapTemplateCard = ({
    title,
    subTitle,
    status = MapTemplateStatus.active,
    image,
    secondImage,
    bodyOnClick,
    ...props
}: MapTemplateCardType) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const [hover, setHover] = useState(false);

    return (
        <Stack
            sx={{
                padding: 4,
                gap: 4,
                borderRadius: (theme) => theme.radius.xs3,
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                cursor: "pointer",
                backgroundColor: (theme) => theme.palette.background.main,
                boxShadow: (theme) =>
                    hover ? theme.shadows.allAround : undefined,
            }}
            onClick={bodyOnClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" gap={2}>
                        <ThumbnailAvatar src={image} square size="md" />
                        <LinkOutline size="xs" />
                        <Box
                            sx={{
                                border: (theme) =>
                                    `1px solid ${theme.palette.stroke[100]}`,
                                borderRadius: (theme) => theme.radius.default,
                            }}
                        >
                            <ThumbnailAvatar
                                src={
                                    secondImage ||
                                    "https://biggeo.blob.core.windows.net/media/Datascape-Logo-Img.png"
                                }
                                square
                                size="md"
                                sx={{
                                    [`& .${thumbnailAvatarClasses.image}`]: {
                                        backgroundColor: (theme) =>
                                            theme.palette.background.main,
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {props.withAction && (hover || isMobile) && (
                    <Grid item>
                        <Grid container alignItems="center" gap={1}>
                            {props.onClickOpenInNew && (
                                <IconButton
                                    variant="minimal"
                                    onClick={props.onClickOpenInNew}
                                >
                                    <OpenInNewOutline size="xs" />
                                </IconButton>
                            )}
                            {props.onClickFavorite && (
                                <IconButton
                                    variant="minimal"
                                    onClick={props.onClickFavorite}
                                >
                                    {props.isFavorite ? (
                                        <Favorite size="xs" color="error" />
                                    ) : (
                                        <FavoriteOutline size="xs" />
                                    )}
                                </IconButton>
                            )}
                            <Menu
                                placement="bottom-end"
                                content={props.menuItems}
                            >
                                <IconButton variant="minimal">
                                    <MoreHorizOutline size="xs" />
                                </IconButton>
                            </Menu>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Stack
                sx={{
                    minHeight: (theme) => theme.spacing(14),
                }}
            >
                <OverflowingTypography variant="title2" fontWeight="bold">
                    {title}
                </OverflowingTypography>
                <OverflowingTypography
                    lineClamp={2}
                    variant="body3"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    {subTitle}
                </OverflowingTypography>
            </Stack>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <MapTemplateStatusChip status={status} />
            </Box>
        </Stack>
    );
};

export default MapTemplateCard;
