import { MainLayout } from "@biggeo/bg-ui/lab/layouts";
import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { DataAccessKeyFormPanel } from "../../data-access-keys/components/DataAccessKeyFormPanel";
import { NavigationContext, NavigationReducer } from "../../map/mapbox/context";
import { OnlyAvailableOnSnowflakeBanner } from "../components/OnlyAvailableOnSnowflakeBanner";

const DatascapeMainLayout = ({
    children,
    hideSidebar = false,
    hideHeader = false,
}: {
    readonly children: React.ReactNode;
    readonly hideSidebar?: boolean;
    readonly hideHeader?: boolean;
}) => {
    const location = useLocation();
    const [state, dispatch] = useReducer(NavigationReducer, {
        location,
    });
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        dispatch({ type: "SET_LOCATION", values: { location } });
    }, [location]);

    return (
        <NavigationContext.Provider value={{ ...state, dispatch }}>
            <MainLayout
                header={
                    <Header openSidebar={() => setSidebarOpen(!sidebarOpen)} />
                }
                banner={<OnlyAvailableOnSnowflakeBanner />}
                mainSidebar={<Sidebar isTemporary={true} />}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                hideSidebar={hideSidebar}
                hideHeader={hideHeader}
            >
                {children}
                <DataAccessKeyFormPanel />
            </MainLayout>
        </NavigationContext.Provider>
    );
};

export default DatascapeMainLayout;
