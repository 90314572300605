import { Accordion, Divider, Grid } from "@biggeo/bg-ui/lab";
import { ComponentProps } from "react";

export type AccordionWithDividerProps = Omit<
    ComponentProps<typeof Accordion>,
    "slots" | "slotProps"
>;

export const AccordionWithDivider = ({
    density = "dense",
    disableHoverEffect = true,
    disableActiveEffect = true,
    children,
    ...props
}: AccordionWithDividerProps) => (
    <Accordion
        disableHoverEffect={disableHoverEffect}
        disableActiveEffect={disableActiveEffect}
        density={density}
        slotProps={{
            label: {
                hideTooltip: true,
            },
        }}
        {...props}
    >
        <Grid container gap={2}>
            <Divider
                orientation={"vertical"}
                sx={{
                    marginLeft: 4,
                }}
            />
            <Grid item xs>
                {children}
            </Grid>
        </Grid>
    </Accordion>
);
