import MapboxDraw, {
    type DrawCustomModeThis,
    type DrawMode,
    type MapMouseEvent,
    type MapTouchEvent,
} from "@mapbox/mapbox-gl-draw";
import { circle, distance, point } from "@turf/turf";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import { match } from "ts-pattern";
import { Consumers, MapEngineSelectType } from "../../common/redux/model.ts";
import store from "../../redux/store.ts";
import {
    CircleState,
    DirectSelectState,
    PolygonState,
    SimpleSelectState,
    SquareState,
    VertexCoordPath,
} from "./draw-modes-types.ts";
import {
    getCircleAdditionalFeatures,
    limitCircleVertices,
    limitSquareVertices,
    updateSquareCoordinates,
} from "./draw-modes-utils";
import { FunctionType, isPolygonFeature } from "./utils";

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝

export const getDrawMode = (type: FunctionType) =>
    match(type)
        .with(FunctionType.polygon, () => "draw_polygon")
        .with(FunctionType.radius, () => "draw_circle")
        .with(FunctionType.square, () => "draw_square")
        .otherwise(() => "simple_select");

export const getMapEngineSelectMode = (): MapEngineSelectType => {
    const mapEnginesState = store.getState().common?.mapEngines;
    const state = mapEnginesState.find((engine) =>
        isEqual(engine.consumer, Consumers.mapbox)
    );

    return (
        state?.selectMode ?? {
            mode: false,
            selectedShapes: [],
        }
    );
};

// ██████╗  ██████╗ ██╗  ██╗   ██╗ ██████╗  ██████╗ ███╗   ██╗    ███╗   ███╗ ██████╗ ██████╗ ███████╗███████╗
// ██╔══██╗██╔═══██╗██║  ╚██╗ ██╔╝██╔════╝ ██╔═══██╗████╗  ██║    ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██╔════╝
// ██████╔╝██║   ██║██║   ╚████╔╝ ██║  ███╗██║   ██║██╔██╗ ██║    ██╔████╔██║██║   ██║██║  ██║█████╗  ███████╗
// ██╔═══╝ ██║   ██║██║    ╚██╔╝  ██║   ██║██║   ██║██║╚██╗██║    ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ╚════██║
// ██║     ╚██████╔╝███████╗██║   ╚██████╔╝╚██████╔╝██║ ╚████║    ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████║
// ╚═╝      ╚═════╝ ╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝

export const DrawPolygonModeOverride = {
    ...MapboxDraw.modes.draw_polygon,
    clickOnVertex(
        this: DrawCustomModeThis,
        state: PolygonState,
        e: MapMouseEvent | MapTouchEvent
    ) {
        if (e.type === "mouseup" || e.type === "touchend") {
            state.polygon.setProperty("event", "double_click");

            this.changeMode("simple_select", {
                featureIds: [state.polygon.id],
            });

            // To draw a new shape right after it
            setTimeout(() => {
                this.changeMode("draw_polygon");
            }, 20);
        }
    },
    onKeyUp(
        this: DrawCustomModeThis,
        state: PolygonState,
        event: KeyboardEvent
    ) {
        const id = `${state.polygon.id}`;

        if (event.key === "Enter") {
            state.polygon.setProperty("event", "enter");
            return this.changeMode("simple_select", { featureIds: [id] });
        }

        if (event.key === "Escape") {
            state.polygon.setProperty("event", "escape");
            this.deleteFeature(id, { silent: true });
            this.changeMode("simple_select", {}, { silent: true });

            setTimeout(() => {
                this.changeMode("draw_polygon");
            }, 20);
        }
    },
    onStop(this: DrawCustomModeThis, state: PolygonState) {
        state.polygon.setProperty("type", FunctionType.polygon);

        MapboxDraw.lib.doubleClickZoom.enable(this);
        const id = `${state.polygon.id}`;
        state.polygon.setProperty("featureId", id);

        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });
        this.activateUIButton();

        // Remove last added coordinate
        state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);

        if (!this.getFeature(id)) {
            return;
        }

        if (state.polygon.isValid()) {
            this.map.fire("draw.create", {
                features: [state.polygon.toGeoJSON()],
            });

            // To draw a new shape right after it
            setTimeout(() => {
                this.changeMode("draw_polygon");
            }, 20);
        } else {
            this.deleteFeature(id, { silent: true });
            this.changeMode("simple_select", {}, { silent: true });

            if (
                state.polygon.properties?.event &&
                (isEqual(state.polygon.properties.event, "enter") ||
                    isEqual(state.polygon.properties.event, "escape"))
            ) {
                setTimeout(() => {
                    this.changeMode("draw_polygon");
                }, 20);
            }
        }
    },
};

// ███████╗ ██████╗ ██╗   ██╗ █████╗ ██████╗ ███████╗    ███╗   ███╗ ██████╗ ██████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║   ██║██╔══██╗██╔══██╗██╔════╝    ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██╔════╝
// ███████╗██║   ██║██║   ██║███████║██████╔╝█████╗      ██╔████╔██║██║   ██║██║  ██║█████╗  ███████╗
// ╚════██║██║▄▄ ██║██║   ██║██╔══██║██╔══██╗██╔══╝      ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ╚════██║
// ███████║╚██████╔╝╚██████╔╝██║  ██║██║  ██║███████╗    ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████║
// ╚══════╝ ╚══▀▀═╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝

export const DrawSquareMode = {
    onSetup(this: DrawCustomModeThis) {
        const square = this.newFeature({
            type: MapboxDraw.constants.geojsonTypes.FEATURE,
            properties: {
                isSquare: true,
                currentCursorPosition: [],
                type: FunctionType.square,
            },
            geometry: {
                type: MapboxDraw.constants.geojsonTypes.POLYGON,
                coordinates: [[]],
            },
        });
        this.addFeature(square);

        this.clearSelectedFeatures();
        MapboxDraw.lib.doubleClickZoom.disable(this);
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
        this.setActionableState({
            trash: true,
            combineFeatures: true,
            uncombineFeatures: true,
        });

        return {
            square,
        };
    },
    onClick(
        this: DrawCustomModeThis,
        state: SquareState,
        event: MapMouseEvent
    ) {
        event.preventDefault();

        if (isEmpty(state.startPoint)) {
            const startPoint = [event.lngLat.lng, event.lngLat.lat];
            state.startPoint = startPoint;

            // Starting point - minX, minY
            state.square.updateCoordinate(
                "0.0",
                state.startPoint[0],
                state.startPoint[1]
            );
        }

        if (
            !isEmpty(state.square.coordinates) &&
            state.square.coordinates[0].length >= 4
        ) {
            const id = `${state.square.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    onMouseUp(
        this: DrawCustomModeThis,
        state: SquareState,
        event: MapMouseEvent
    ) {
        state.endPoint = [event.lngLat.lng, event.lngLat.lat];
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });
        this.changeMode("simple_select", { featuresId: state.square.id });

        // To draw a new shape right after it
        setTimeout(() => {
            this.changeMode("draw_square" as DrawMode);
        }, 20);
    },
    onStop(this: DrawCustomModeThis, state: SquareState) {
        MapboxDraw.lib.doubleClickZoom.enable(this);
        const id = `${state.square.id}`;
        state.square.setProperty("featureId", id);

        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });
        this.activateUIButton();

        if (!this.getFeature(id)) {
            return;
        }

        // Remove last added coordinate
        state.square.removeCoordinate("0.4");

        if (state.square.isValid()) {
            this.map.fire("draw.create", {
                features: [state.square.toGeoJSON()],
            });

            // To draw a new shape right after it
            setTimeout(() => {
                this.changeMode("draw_square" as DrawMode);
            }, 20);
        } else {
            this.deleteFeature(id, { silent: true });
            this.changeMode("simple_select", {}, { silent: true });
        }
    },

    onTrash(this: DrawCustomModeThis, state: SquareState) {
        const id = `${state.square.id}`;
        this.deleteFeature(id, { silent: true });
        this.changeMode("simple_select");
    },
    onMouseMove(state: SquareState, event: MapMouseEvent | MapTouchEvent) {
        state.square.setProperty("currentCursorPosition", [
            event.lngLat.lng,
            event.lngLat.lat,
        ]);

        if (!state.startPoint) {
            return;
        }
        const [startLng, startLat] = state.startPoint;
        const [currentLng, currentLat] = [event.lngLat.lng, event.lngLat.lat];

        // Calculate the new width and height of the square
        const width = Math.abs(currentLng - startLng);
        const height = Math.abs(currentLat - startLat);

        // Calculate the new coordinates for the square vertices
        const minX = Math.min(startLng, currentLng);
        const minY = Math.min(startLat, currentLat);
        const maxX = minX + width;
        const maxY = minY + height;

        // Update the coordinates of the square vertices
        state.square.updateCoordinate("0.0", minX, minY); // Bottom left
        state.square.updateCoordinate("0.1", maxX, minY); // Bottom right
        state.square.updateCoordinate("0.2", maxX, maxY); // Top right
        state.square.updateCoordinate("0.3", minX, maxY); // Top left
    },
    onKeyUp(
        this: DrawCustomModeThis,
        state: SquareState,
        event: KeyboardEvent
    ) {
        if (event.key === "Enter") {
            const id = `${state.square.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    toDisplayFeatures(
        state: SquareState,
        geojson: GeoJSON.Feature<GeoJSON.Geometry>,
        display: (geojson: GeoJSON.Feature<GeoJSON.Geometry>) => void
    ) {
        const isActivePolygon = geojson.properties?.id === state.square.id;

        const json = {
            ...geojson,
            properties: {
                ...geojson.properties,
                active: isActivePolygon.toString(),
            },
        };

        if (!isActivePolygon) {
            display(json);
            return;
        }

        if (!state.startPoint) {
            return;
        }

        display(json);
    },
    // Mobile functionality
    onTouchMove(state: SquareState, event: MapTouchEvent) {
        return this.onMouseMove(state, event);
    },
    onTouchStart(
        this: DrawCustomModeThis,
        state: SquareState,
        event: MapTouchEvent
    ) {
        event.preventDefault();

        if (isEmpty(state.startPoint)) {
            const startPoint = [event.lngLat.lng, event.lngLat.lat];
            state.startPoint = startPoint;

            // Starting point - minX, minY
            state.square.updateCoordinate(
                "0.0",
                state.startPoint[0],
                state.startPoint[1]
            );
        }

        if (
            !isEmpty(state.square.coordinates) &&
            state.square.coordinates[0].length >= 4
        ) {
            const id = `${state.square.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    onTouchEnd(
        this: DrawCustomModeThis,
        state: SquareState,
        event: MapTouchEvent
    ) {
        // onMouseUp basically but duplicated because `this` from the object and `this` from the props
        // create issues if we call this.onMouseUp.
        state.endPoint = [event.lngLat.lng, event.lngLat.lat];
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });
        this.changeMode("simple_select", { featuresId: state.square.id });

        // To draw a new shape right after it
        setTimeout(() => {
            this.changeMode("draw_square" as DrawMode);
        }, 20);
    },
};

//  ██████╗██╗██████╗  ██████╗██╗     ███████╗    ███╗   ███╗ ██████╗ ██████╗ ███████╗███████╗
// ██╔════╝██║██╔══██╗██╔════╝██║     ██╔════╝    ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██╔════╝
// ██║     ██║██████╔╝██║     ██║     █████╗      ██╔████╔██║██║   ██║██║  ██║█████╗  ███████╗
// ██║     ██║██╔══██╗██║     ██║     ██╔══╝      ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ╚════██║
// ╚██████╗██║██║  ██║╚██████╗███████╗███████╗    ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████║
//  ╚═════╝╚═╝╚═╝  ╚═╝ ╚═════╝╚══════╝╚══════╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝

export const DrawCircleMode = {
    onSetup(this: DrawCustomModeThis) {
        const circle = this.newFeature({
            type: MapboxDraw.constants.geojsonTypes.FEATURE,
            properties: {
                isCircle: true,
                center: [],
                radiusInKm: 0,
                cursorCoordinates: [[], []],
                isMobile: false,
                type: FunctionType.radius,
            },
            geometry: {
                type: MapboxDraw.constants.geojsonTypes.POLYGON,
                coordinates: [[]],
            },
        });

        this.addFeature(circle);

        this.clearSelectedFeatures();
        MapboxDraw.lib.doubleClickZoom.disable(this);
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
        this.setActionableState({
            trash: true,
            combineFeatures: true,
            uncombineFeatures: true,
        });

        return {
            circle,
        };
    },
    onClick(
        this: DrawCustomModeThis,
        state: CircleState,
        event: MapMouseEvent
    ) {
        event.preventDefault();

        const currentCenter = state.circle.properties?.center;

        if (isEmpty(currentCenter)) {
            state.circle.setProperty("center", [
                event.lngLat.lng,
                event.lngLat.lat,
            ]);
        }

        // This means there was a 2nd click. Setting the change mode will trigger onStop.
        if (!isEmpty(currentCenter)) {
            state.circle.setProperty("cursorCoordinates", [
                currentCenter,
                [event.lngLat.lng, event.lngLat.lat],
            ]);

            const id = `${state.circle.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    onMouseMove(state: CircleState, event: MapMouseEvent | MapTouchEvent) {
        const center = state.circle.properties?.center;

        if (!isEmpty(center)) {
            const distanceInKm = distance(
                point(center),
                point([event.lngLat.lng, event.lngLat.lat]),
                { units: "kilometers" }
            );
            const circleFeature = circle(center, distanceInKm);
            state.circle.incomingCoords(circleFeature.geometry.coordinates);

            state.circle.setProperty("radiusInKm", distanceInKm);
            state.circle.setProperty("cursorCoordinates", [
                center,
                [event.lngLat.lng, event.lngLat.lat],
            ]);
        }
    },
    onTrash(this: DrawCustomModeThis, state: CircleState) {
        const id = `${state.circle.id}`;
        this.deleteFeature(id, { silent: true });
        this.changeMode("simple_select");
    },
    onStop(this: DrawCustomModeThis, state: CircleState) {
        const id = `${state.circle.id}`;
        state.circle.setProperty("featureId", id);

        MapboxDraw.lib.doubleClickZoom.enable(this);
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });

        this.activateUIButton();

        if (!this.getFeature(id)) {
            return;
        }

        state.circle.removeCoordinate("0.64");

        if (state.circle.isValid()) {
            this.map.fire("draw.create", {
                features: [state.circle.toGeoJSON()],
            });

            // To draw a new shape right after it
            setTimeout(() => {
                this.changeMode("draw_circle" as DrawMode);
            }, 20);
        } else {
            this.deleteFeature(id, { silent: true });
            this.changeMode(
                MapboxDraw.constants.modes.SIMPLE_SELECT,
                {},
                { silent: true }
            );
        }
    },
    onKeyUp(
        this: DrawCustomModeThis,
        state: CircleState,
        event: KeyboardEvent
    ) {
        if (event.key === "Enter") {
            const id = `${state.circle.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    toDisplayFeatures(
        this: DrawCustomModeThis,
        state: CircleState,
        geojson: GeoJSON.Feature<GeoJSON.Polygon>,
        display: (geojson: GeoJSON.Feature<GeoJSON.Geometry>) => void
    ) {
        const isActivePolygon = geojson.properties?.id === state.circle.id;
        const json = {
            ...geojson,
            properties: {
                ...geojson.properties,
                active: isActivePolygon
                    ? MapboxDraw.constants.activeStates.ACTIVE
                    : MapboxDraw.constants.activeStates.INACTIVE,
            },
            geometry: {
                ...geojson.geometry,
                coordinates: isNil(geojson.geometry.coordinates[0][0])
                    ? [[]]
                    : geojson.geometry.coordinates,
            },
        };

        const { centeredPoint, lineString, cursorPrompt } =
            getCircleAdditionalFeatures({
                center: state.circle.properties?.center || [],
                cursorCoordinates: state.circle.properties?.cursorCoordinates,
                radius: state.circle.properties
                    ? state.circle.properties.radiusInKm.toFixed(2)
                    : 0,
            });

        display(centeredPoint);
        display(lineString);
        display(cursorPrompt);
        display(json);
    },
    // Mobile functionality
    onTouchMove(state: CircleState, event: MapTouchEvent) {
        return this.onMouseMove(state, event);
    },
    onTouchStart(
        this: DrawCustomModeThis,
        state: CircleState,
        event: MapTouchEvent
    ) {
        // onClick basically but duplicated because `this` from the object and `this` from the props
        // create issues if we call this.onClick.

        // Disable map dragging when you draw a cirle on mobile.
        this.map.dragPan.disable();

        const currentCenter = state.circle.properties?.center;

        state.circle.setProperty("isMobile", true);

        if (isEmpty(currentCenter)) {
            state.circle.setProperty("center", [
                event.lngLat.lng,
                event.lngLat.lat,
            ]);
        }

        // This means there was a 2nd click. Setting the mode change  will trigger onStop.
        if (
            !isEmpty(currentCenter) &&
            state.circle.coordinates &&
            !isEmpty(state.circle.coordinates[0])
        ) {
            state.circle.setProperty("cursorCoordinates", [
                currentCenter,
                [event.lngLat.lng, event.lngLat.lat],
            ]);

            const id = `${state.circle.id}`;
            return this.changeMode("simple_select", { featureIds: [id] });
        }
    },
    onTouchEnd(this: DrawCustomModeThis, state: CircleState) {
        // onMouseUp basically but duplicated because `this` from the object and `this` from the props
        // create issues if we call this.onMouseUp.

        this.map.dragPan.enable();
        this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
            featureIds: [state.circle.id],
        });

        // To draw a new shape right after it
        setTimeout(() => {
            this.changeMode("draw_circle" as DrawMode);
        }, 20);
    },
};

// ██████╗ ██████╗  █████╗  ██████╗      ██████╗ ██╗   ██╗███████╗██████╗ ██████╗ ██╗██████╗ ███████╗███████╗
// ██╔══██╗██╔══██╗██╔══██╗██╔════╝     ██╔═══██╗██║   ██║██╔════╝██╔══██╗██╔══██╗██║██╔══██╗██╔════╝██╔════╝
// ██║  ██║██████╔╝███████║██║  ███╗    ██║   ██║██║   ██║█████╗  ██████╔╝██████╔╝██║██║  ██║█████╗  ███████╗
// ██║  ██║██╔══██╗██╔══██║██║   ██║    ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██╗██╔══██╗██║██║  ██║██╔══╝  ╚════██║
// ██████╔╝██║  ██║██║  ██║╚██████╔╝    ╚██████╔╝ ╚████╔╝ ███████╗██║  ██║██║  ██║██║██████╔╝███████╗███████║
// ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝      ╚═════╝   ╚═══╝  ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═════╝ ╚══════╝╚══════╝

export const DirectSelectModeOverride = {
    ...MapboxDraw.modes.direct_select,
    onVertex(
        this: DrawCustomModeThis,
        state: DirectSelectState,
        event: MapMouseEvent
    ) {
        this.map.dragPan.disable();
        state.canDragMove = true;
        state.dragMoveLocation = event.lngLat;

        const properties = event.featureTarget.properties;
        const isMidpoint = !!properties?.real_meta;

        const path = properties?.coord_path;

        state.selectedCoordPaths = [path];

        const paths = state.selectedCoordPaths.map((p) => ({
            feature_id: state.featureId,
            coord_path: p,
        }));

        if (!isMidpoint) {
            this.setSelectedCoordinates(paths);
        }
    },
    dragFeature(
        this: DrawCustomModeThis,
        state: DirectSelectState,
        event: MapMouseEvent,
        delta: {
            lng: number;
            lat: number;
        }
    ) {
        const id = state.featureId;
        const feature = this.getSelected();

        const selectMode = getMapEngineSelectMode();

        const isShapeSelected =
            isString(id) &&
            isEqual(selectMode.mode, true) &&
            selectMode.selectedShapes.includes(id);

        if (isShapeSelected && feature) {
            MapboxDraw.lib.moveFeatures(feature, delta);
            // biome-ignore lint/complexity/noForEach: <explanation>
            feature
                .filter((feature) => feature.properties?.isCircle)
                .map((circle) => circle.properties?.center)
                .forEach((center) => {
                    center[0] += delta.lng;
                    center[1] += delta.lat;
                });
            state.dragMoveLocation = event.lngLat;
        }
    },
    dragVertex(
        this: DrawCustomModeThis,
        state: DirectSelectState,
        event: MapMouseEvent,
        delta: {
            lng: number;
            lat: number;
        }
    ) {
        const movedVertex = [event.lngLat.lng, event.lngLat.lat];

        if (state.feature.properties?.isCircle) {
            const center = state.feature.properties.center;

            const radius = distance(point(center), point(movedVertex), {
                units: "kilometers",
            });
            const circleFeature = circle(center, radius);
            state.feature.incomingCoords(circleFeature.geometry.coordinates);

            state.feature.setProperty("radiusInKm", radius);
        } else if (state.feature.properties?.isSquare) {
            const movedVertexCoordPath: VertexCoordPath =
                state.selectedCoordPaths[0];

            updateSquareCoordinates({
                movedVertex,
                movedVertexCoordPath,
                feature: state.feature,
            });
        } else {
            const selectedCoords = state.selectedCoordPaths.map(
                (coord_path: string) => state.feature.getCoordinate(coord_path)
            );

            const selectedCoordPoints = selectedCoords.map(
                (coordinates: GeoJSON.Position) => ({
                    type: MapboxDraw.constants.geojsonTypes.FEATURE,
                    properties: {},
                    geometry: {
                        type: MapboxDraw.constants.geojsonTypes.POINT,
                        coordinates,
                    },
                })
            ) as unknown as MapboxDraw.DrawFeature[];

            const constrainedDelta = MapboxDraw.lib.constrainFeatureMovement(
                selectedCoordPoints,
                delta
            );

            selectedCoords.forEach((_: GeoJSON.Position, i: number) => {
                const coordinate = selectedCoords[i];

                state.feature.updateCoordinate(
                    state.selectedCoordPaths[i],
                    coordinate[0] + constrainedDelta.lng,
                    coordinate[1] + constrainedDelta.lat
                );
            });
        }
    },
    onMouseUp(this: DrawCustomModeThis, state: DirectSelectState) {
        // Change mode to simple_select when resizing from a vertex is done.
        // This cleans up the state by deleting the line string generated when the user
        // resized from a specific vertex, allowing it to create a new one when they
        // resize from a new vertex.
        const vertexWasSelected = !isEmpty(state.selectedCoordPaths);
        const isCircle = isEqual(state.feature.properties?.isCircle, true);

        if (vertexWasSelected && isCircle) {
            const id = `${state.featureId}`;

            if (state.feature) {
                this.map.fire("draw.update", {
                    features: [state.feature.toGeoJSON()],
                });
            }

            return this.changeMode("simple_select", { featureIds: [id] });
        }

        if (state.feature) {
            this.map.fire("draw.update", {
                features: [state.feature.toGeoJSON()],
            });
        }
    },
    toDisplayFeatures(
        this: DrawCustomModeThis,
        state: DirectSelectState,
        geojson: GeoJSON.Feature<GeoJSON.Geometry>,
        display: (geojson: GeoJSON.Feature<GeoJSON.Geometry>) => void
    ) {
        if (state.featureId === geojson.properties?.id) {
            const json = {
                ...geojson,
                properties: {
                    ...geojson.properties,
                    active: MapboxDraw.constants.activeStates.ACTIVE,
                },
            };

            display(json);

            if (
                geojson.properties?.user_isCircle &&
                isPolygonFeature(geojson)
            ) {
                const supplementaryPoints = limitCircleVertices(geojson);

                const vertexWasSelected = !isEmpty(state.selectedCoordPaths);

                // It introduces a glitch on mobile so I desactivated it.
                // Seeing the line string and radius on resizing only works
                // on desktop for now.
                if (
                    vertexWasSelected &&
                    isEqual(state.feature.properties?.isMobile, false)
                ) {
                    const selectedCoords = state.selectedCoordPaths.map(
                        (coord_path: string) =>
                            state.feature.getCoordinate(coord_path)
                    );

                    const cursorCoordinates = [
                        state.feature.properties?.center,
                        ...selectedCoords,
                    ];

                    const { centeredPoint, lineString, cursorPrompt } =
                        getCircleAdditionalFeatures({
                            center: geojson.properties.user_center,
                            cursorCoordinates,
                            radius: state.feature.properties
                                ? state.feature.properties.radiusInKm.toFixed(2)
                                : 0,
                            vertexPath: state.selectedCoordPaths[0],
                        });

                    display(centeredPoint);
                    display(lineString);
                    display(cursorPrompt);

                    supplementaryPoints?.forEach(display);
                } else {
                    supplementaryPoints?.forEach(display);
                }
            } else if (
                geojson.properties?.user_isSquare &&
                isPolygonFeature(geojson)
            ) {
                const supplementaryPoints = limitSquareVertices(geojson);

                supplementaryPoints?.forEach(display);
            } else {
                /* `user_wasCircle` or `user_wasSquare` as true means that the shape was created as a circle
                 *  or square but got cut because it had a conflict area (Custom Boundaries).
                 *  We don't want to show the midpoints in this case because they mess up
                 *  the shape on resizing.
                 */
                const supplementaryPoints =
                    MapboxDraw.lib.createSupplementaryPoints(geojson, {
                        midpoints:
                            !isEqual(
                                geojson.properties?.user_wasCircle,
                                true
                            ) &&
                            !isEqual(geojson.properties?.user_wasSquare, true),
                        selectedPaths: state.selectedCoordPaths,
                    });

                supplementaryPoints?.forEach(display);
            }
        } else {
            const json = {
                ...geojson,
                properties: {
                    ...geojson.properties,
                    active: MapboxDraw.constants.activeStates.INACTIVE,
                },
            };
            display(json);
        }
    },
};

export const SimpleSelectModeOverride = {
    ...MapboxDraw.modes.simple_select,
    onDrag(
        this: DrawCustomModeThis,
        state: SimpleSelectState,
        e: MapMouseEvent
    ) {
        const feature = this.getSelected();

        if (!isEmpty(feature)) {
            const id = feature[0].id;

            const selectMode = getMapEngineSelectMode();

            const isShapeSelected =
                isString(id) &&
                isEqual(selectMode.mode, true) &&
                selectMode.selectedShapes.includes(id);

            if (state.canDragMove && isShapeSelected) {
                state.dragMoving = true;
                e.originalEvent.stopPropagation();

                const lng = state.dragMoveLocation
                    ? state.dragMoveLocation.lng
                    : 0;
                const lat = state.dragMoveLocation
                    ? state.dragMoveLocation.lat
                    : 0;

                const delta = {
                    lng: e.lngLat.lng - lng,
                    lat: e.lngLat.lat - lat,
                };

                MapboxDraw.lib.moveFeatures(feature, delta);

                state.dragMoveLocation = e.lngLat;
            }
        }
    },
    toDisplayFeatures(
        this: DrawCustomModeThis,
        _: SimpleSelectState,
        geojson: GeoJSON.Feature<GeoJSON.Geometry>,
        display: (geojson: GeoJSON.Feature<GeoJSON.Geometry>) => void
    ) {
        const json = {
            ...geojson,
            properties: {
                ...geojson.properties,
                active: this.isSelected(geojson.properties?.id)
                    ? MapboxDraw.constants.activeStates.ACTIVE
                    : MapboxDraw.constants.activeStates.INACTIVE,
            },
        };

        display(json);

        this.setActionableState({
            combineFeatures: true,
            uncombineFeatures: true,
            trash: true,
        });

        // It's a vertex (point), not a feature (polygon).
        if (
            geojson.properties?.active !==
                MapboxDraw.constants.activeStates.ACTIVE ||
            geojson.geometry.type === MapboxDraw.constants.geojsonTypes.POINT
        ) {
            return;
        }

        if (geojson.properties.user_isCircle && isPolygonFeature(geojson)) {
            const supplementaryPoints = limitCircleVertices(geojson);

            const { centeredPoint, lineString, cursorPrompt } =
                getCircleAdditionalFeatures({
                    center: geojson.properties?.user_center || [],
                    cursorCoordinates:
                        geojson.properties?.user_cursorCoordinates,
                    radius: geojson.properties
                        ? geojson.properties.user_radiusInKm.toFixed(2)
                        : 0,
                });
            display(centeredPoint);
            display(lineString);
            display(cursorPrompt);
            supplementaryPoints?.map((point) => display(point));
        } else if (
            geojson.properties.user_isSquare &&
            isPolygonFeature(geojson)
        ) {
            const supplementaryPoints = limitSquareVertices(geojson);

            supplementaryPoints?.map((point) => display(point));
        } else {
            const supplementaryPoints =
                MapboxDraw.lib.createSupplementaryPoints(geojson);

            supplementaryPoints?.map((point) => display(point));
        }
    },
};
