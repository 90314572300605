import { ApolloError } from "@apollo/client";
import {
    EntitlementType,
    VerifiedAccessKeyResponse,
    VerifiedAccessKeyStatus,
    useCreateEntitlementByAccessKeyMutation,
    usePurchaseBgVelocityMutation,
    useVerifyEntitlementAccessKeyLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Severity } from "@biggeo/bg-ui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hasBgVelocity } from "../../common/redux/hooks";
import { commonActions } from "../../common/redux/model";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import MarketplaceUpgradeForm, {
    UpgradeInfo,
} from "../views/MarketplaceUpgradeForm";
export type BillingType = "AccessKey" | "Snowflake";

const MarketplaceUpgradeContainer = () => {
    const toPage = useNavigate();
    const dispatch = useDispatch();

    const navigate = (to?: string) =>
        to ? toPage(`${to}`) : toPage(Routes.explore);

    const hasVelocity = hasBgVelocity();
    if (hasVelocity) {
        navigate();
    }

    const {
        executeMutation: purchaseBgVelocity,
        mutationReturn: [_a, { loading: purchaseLoading }],
    } = usePurchaseBgVelocityMutation();

    const {
        executeMutation: createEntitlementByAccessKey,
        mutationReturn: [_b, { loading: createLoading }],
    } = useCreateEntitlementByAccessKeyMutation();

    const { executeQuery: verifyEntitlementAccessKey } =
        useVerifyEntitlementAccessKeyLazyQuery();

    const [billingType, setBillingType] = useState<BillingType>("AccessKey");

    const verifyAccessKey = (
        accessKey: string,
        onSuccess: (data: VerifiedAccessKeyResponse) => void
    ) => {
        verifyEntitlementAccessKey({
            variables: {
                input: {
                    accessKey,
                    type: EntitlementType.Velocity,
                },
            },
            onCompleted: (data) => {
                onSuccess(data.verifyEntitlementAccessKey);
            },
        });
    };

    const confirm = (input: UpgradeInfo) => {
        const onCompleted = () => {
            dispatch(commonActions.setHasBgVelocity({ hasBgVelocity: true }));
            toPage(Routes.unlocked);
        };
        const onError = (err: ApolloError) => {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: `Failed to order Velocity: ${err.message}`,
                    autoHideDuration: 5000,
                    severity: Severity.error,
                })
            );
        };
        if (
            billingType === "AccessKey" &&
            input.status === VerifiedAccessKeyStatus.Success
        ) {
            createEntitlementByAccessKey({
                variables: {
                    input: {
                        accessKey: input.accessKey,
                        type: EntitlementType.Velocity,
                    },
                },
                onCompleted,
                onError,
            });
        } else if (billingType === "Snowflake") {
            purchaseBgVelocity({
                onCompleted,
                onError,
            });
        }
    };

    return (
        <MarketplaceUpgradeForm
            confirm={confirm}
            loading={purchaseLoading || createLoading}
            verifyAccessKey={verifyAccessKey}
            billingType={billingType}
            setBillingType={setBillingType}
        />
    );
};

export default MarketplaceUpgradeContainer;
