import {
    CreateEditDeleteMapTemplate,
    FetchDataSourcesQuery,
    FetchMapTemplateExtendedQuery,
    MapTemplateExtended,
    useFetchDataSourcesQuery,
    useFetchMapTemplateExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as R from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import { useState } from "react";
import { ErrorPage } from "../../common/components/ErrorPage";
import { CallBacksType } from "../../utils/types";
import MapTemplateForm from "../views/MapTemplateForm";

interface IManageMapTemplateContainer {
    readonly id: number;
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly loading: boolean;
}

const ManageMapTemplateContainer = ({
    id,
    navigate,
    save,
    loading,
}: IManageMapTemplateContainer) => {
    const [localData, setLocalData] = useState<string[]>([]);
    const { remote: mapTemplateExtendedRd, setRemote } =
        useFetchMapTemplateExtendedQuery({
            variables: { id },
            onCompleted: (data) => {
                setLocalData(
                    pipe(
                        data.fetchMapTemplateExtended.datasets,
                        toNonReadonlyArray,
                        A.map((d) => d.fkDataSourceId)
                    )
                );
            },
        });

    const { remote: dataSourcesRd } = useFetchDataSourcesQuery({
        variables: {
            input: {
                isConnected: true,
            },
        },
    });

    const mappedRd = R.map2(
        (mapTemplateExtendedRd: FetchMapTemplateExtendedQuery) =>
            (dataSourceRd: FetchDataSourcesQuery) => ({
                fetchMapTemplateExtended:
                    mapTemplateExtendedRd.fetchMapTemplateExtended,
                fetchDataSources: dataSourceRd.fetchDataSources,
            }),
        mapTemplateExtendedRd,
        dataSourcesRd
    );

    return R.match(mappedRd, {
        _: () => <LoadingBar />,
        Success: ({
            fetchMapTemplateExtended: { datasets: linkedDatasets, mapTemplate },
            fetchDataSources: { dataSources },
        }) => {
            // TODO: this has a race condition issue where it rerenders the component before onSuccess happens
            return (
                <MapTemplateForm
                    map={mapTemplate}
                    navigate={navigate}
                    save={(input, callbacks) => {
                        save(input, {
                            ...callbacks,
                            onSuccess: (data) => {
                                callbacks?.onSuccess?.(data);
                                setRemote(
                                    new R.Success({
                                        fetchMapTemplateExtended: data,
                                    })
                                );
                            },
                        });
                    }}
                    initialDatasets={linkedDatasets.map(
                        (d) => d.fkDataSourceId
                    )}
                    linkedDatasets={localData}
                    loading={loading}
                    setLinkedDatasets={setLocalData}
                    datasets={pipe(
                        dataSources,
                        A.map((item) => ({
                            id: item.id,
                            title: item.label || item.collectionName,
                            image: item.icon || "",
                            select: includes(localData, item.id),
                        }))
                    )}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};

export default ManageMapTemplateContainer;
