import {
    Button,
    Grid,
    IconAvatar,
    InfoCell,
    OverflowingTypography,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    BugReportOutline,
    EventListOutline,
    MoveToInboxOutline,
    SupportOutline,
    VelocityIcon,
} from "@biggeo/bg-ui/lab/icons";
import { CenteredLayout } from "@biggeo/bg-ui/lab/layouts";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { BorderedStack } from "../../common/components/BorderedStack";
import { ViewCopyApiKeyPanel } from "../../common/components/ViewCopyApiKeyPanel";
import { AccessKeyStatusTag } from "../../data-access-keys/components/AccessKeyStatusTag";

const InfoCellHelper = ({
    title,
    subtitle,
    icon,
    button,
    link,
}: {
    title: string;
    subtitle: string;
    icon: ReactNode;
    button: string;
    link: string;
}) => (
    <InfoCell
        variant="outlined"
        startNode={
            <IconAvatar size="md" square>
                {icon}
            </IconAvatar>
        }
        endNode={
            <Link to={link} target="_blank">
                <Button tabIndex={-1} variant="outlined">
                    {button}
                </Button>
            </Link>
        }
        title={title}
        subtitle={subtitle}
        slots={{
            subtitle: (props) => (
                <OverflowingTypography
                    {...props}
                    lineClamp={2}
                    truncate={undefined}
                />
            ),
        }}
        sx={{ backgroundColor: (theme) => theme.palette.background.main }}
    />
);

/**
 * UI/UX Handoff (Delete this block after completion)
 * External Requirements
 *  - IMPORTANT: This page cannot be accessed unless the user has purchased the Velocity API
 *      - In the Routes.tsx file there is a new route (velocityApiKey) for this page that will need to be protected against unauthorized access
 *      - This can also be handled internally. See the Internal Requirements below
 * Internal Requirements (search the following TODOs to see the internal requirements for completion):
 * - TODO: Protect against unauthorized access
 * - TODO: Populate quick instructions
 * - TODO: Key Status
 * - TODO: Fetch API key
 */
export const VelocityApiKeyPage = () => {
    // TODO: Prevent unauthorized access - Implement the protection against access to this page if the user has not purchased the Velocity API
    return (
        <CenteredLayout>
            <Stack
                gap={8}
                sx={{
                    paddingY: 4,
                    paddingX: 4,
                    breakpoints: { md: { paddingY: 10 } },
                }}
            >
                <Stack>
                    <IconAvatar size="sm" square>
                        <VelocityIcon size="md" />
                    </IconAvatar>
                    <Typography variant="h6" fontWeight="bold">
                        Velocity API Key
                    </Typography>
                    <Typography textColor="disabled.onContainer">
                        See your velocity API key details here
                    </Typography>
                </Stack>
                <BorderedStack gap={4}>
                    <Grid container gap={4} alignItems="flex-start">
                        <Stack>
                            <Typography variant="body2" fontWeight="semibold">
                                Quick copy
                            </Typography>
                            <Typography
                                variant="body3"
                                textColor="disabled.onContainer"
                            >
                                {/* TODO: Populate quick instructions - Replace the text below with the proper "quick instructions"  */}
                                {
                                    "<Quick instructions of what to do with the API go here>"
                                }
                            </Typography>
                        </Stack>
                        {/* TODO: Key Status - Implement the key status */}
                        <AccessKeyStatusTag status="active" />
                    </Grid>
                    {/* TODO: Fetch API key - Implement the provider or inline fetch for the API key */}
                    <ViewCopyApiKeyPanel apiKey="12345-ABCDE-67890-FGHIJ-12345" />
                </BorderedStack>
                <BorderedStack gap={4}>
                    <Stack>
                        <Typography variant="body2" fontWeight="semibold">
                            Velocity Resources
                        </Typography>
                        <Typography
                            variant="body3"
                            textColor="disabled.onContainer"
                        >
                            Get help, view developer documentation or report any
                            issues
                        </Typography>
                    </Stack>
                    <InfoCellHelper
                        icon={<SupportOutline size="md" />}
                        title="Help Center"
                        subtitle="Get product help guides with using BigGeo Velocity API"
                        button="Get Support"
                        link="https://biggeo.featurebase.app/help"
                    />
                    <InfoCellHelper
                        icon={<EventListOutline size="md" />}
                        title="Change Log"
                        subtitle="See what’s changed since your last time here with Datascape"
                        button="View Change Log"
                        link="https://biggeo.featurebase.app/changelog"
                    />
                    <InfoCellHelper
                        icon={<BugReportOutline size="md" />}
                        title="Report a bug"
                        subtitle="Something is not working quite right? Let us know immediately"
                        button="Create Issue"
                        link="https://biggeo.featurebase.app/?b=66aabc8edfbf93feddf85303"
                    />
                    <InfoCellHelper
                        icon={<MoveToInboxOutline size="md" />}
                        title="Give feedback"
                        subtitle="Give us feedback on how you’re finding the use of Velocity API"
                        button="Give Feedback"
                        link="https://biggeo.featurebase.app/?b=66aabcb6f611e8ff2d09cbe1"
                    />
                </BorderedStack>
            </Stack>
        </CenteredLayout>
    );
};
