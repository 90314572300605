import { MapTemplateStatus } from "@biggeo/bg-server-lib/datascape-ai";
import { Tag } from "@biggeo/bg-ui/lab";
import { MainPaletteDefinition } from "@biggeo/bg-ui/lab/theme";
import capitalize from "lodash/capitalize";
import { match } from "ts-pattern";

type MapTemplateStatusColor = keyof MainPaletteDefinition | "inherit";
export const MapTemplateStatusChip = ({
    status,
}: {
    readonly status: MapTemplateStatus;
}) => {
    const matchStatusColor = (templateStatus: MapTemplateStatus) => {
        return match<MapTemplateStatus, MapTemplateStatusColor>(templateStatus)
            .with(MapTemplateStatus.active, () => "success")
            .otherwise(() => "warning");
    };

    return (
        <Tag
            color={matchStatusColor(status)}
            variant="tonal"
            sx={{ alignSelf: "flex-end" }}
            density="dense"
        >
            {capitalize(status)}
        </Tag>
    );
};
