import { PointDataContainer } from "../../PointDataContainer";
import { dataPointTooltipId, useDataPointTooltip } from "../redux/hooks";
import { MapTooltip } from "./MapTooltip";

export const DataPointTooltip = () => {
    //Injects the tooltip into the map and handles returning the selected data point
    const { selectedDataPoint } = useDataPointTooltip();

    return (
        <MapTooltip tooltipId={dataPointTooltipId}>
            {selectedDataPoint && (
                <PointDataContainer selectedPoint={selectedDataPoint} />
            )}
        </MapTooltip>
    );
};
