import { Button, Grid, IconAvatar, Stack, Typography } from "@biggeo/bg-ui/lab";
import {
    CompareArrowsOutline,
    DatascapeIcon,
    SnowflakeLogo,
} from "@biggeo/bg-ui/lab/icons";
import { Link } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import { isAppRunningOnSF } from "../redux/hooks";

export const OnlyAvailableOnSnowflakePanel = () => {
    const show = !isAppRunningOnSF();
    return (
        show && (
            <Stack
                gap={2}
                sx={{
                    padding: 3,
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                    borderRadius: (theme) => theme.radius.xs3,
                }}
            >
                <Grid container alignItems="center" gap={1}>
                    <IconAvatar size="xxs">
                        <DatascapeIcon size="xs" />
                    </IconAvatar>
                    <CompareArrowsOutline size="xxs" color="disabled" />
                    <IconAvatar size="xxs">
                        <SnowflakeLogo size="xs" />
                    </IconAvatar>
                </Grid>
                <Stack>
                    <Typography variant="body4" fontWeight="semibold">
                        Only Available in Snowflake
                    </Typography>
                    <Typography
                        variant="body4"
                        textColor="disabled.onContainer"
                    >
                        Full Datascape use is only available in Snowflake Native
                        Apps
                    </Typography>
                </Stack>
                <Link
                    to={Routes.datascapeSnowflakeMarketplace}
                    target="_blank"
                    tabIndex={-1}
                >
                    <Button color="primary">Get It On Snowflake</Button>
                </Link>
            </Stack>
        )
    );
};
