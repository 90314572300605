import { MapTemplate } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CompanyAvatar,
    FlexScrollAreaContainer,
    NavigationRailCell,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { AddOutline, DownloadOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import { NoMapTemplateView } from "../../map-templates/views/NoMapTemplatesView";
import { Routes } from "../../navigation/redux/model";

export type RecipesSidebarProps = {
    readonly selected: string;
    readonly mapTemplates: readonly MapTemplate[];
    readonly toPage: (url: string) => void;
};

export const RecipesSidebar = ({
    selected,
    mapTemplates,
    toPage,
}: RecipesSidebarProps) => {
    return (
        <FlexScrollAreaContainer
            sx={{
                width: "100%",
                breakpoints: {
                    md: {
                        width: 60,
                    },
                },
            }}
        >
            <Stack gap={4} sx={{ padding: 4, height: "100%" }}>
                <NavigationRailCell
                    onClick={() => toPage(`${Routes.recipes}/all`)}
                >
                    All
                </NavigationRailCell>
                <Stack gap={2}>
                    <Typography
                        variant="body3"
                        fontWeight="semibold"
                        color="disabled"
                        colorSet="container"
                        invertColors
                        sx={{
                            paddingLeft: 2,
                        }}
                    >
                        Manage by map template
                    </Typography>
                    {pipe(
                        mapTemplates,
                        O.fromPredicate((x) => !isEmpty(x)),
                        O.fold(
                            () => <NoMapTemplateView />,
                            (templates) => (
                                <Stack gap={1}>
                                    {pipe(
                                        templates,
                                        toNonReadonlyArray,
                                        A.map((template) => (
                                            <NavigationRailCell
                                                key={template.id}
                                                icon={
                                                    <ThumbnailAvatar
                                                        alt={
                                                            template.name || ""
                                                        }
                                                        size="xxs"
                                                        src={
                                                            template.thumbnail ||
                                                            ""
                                                        }
                                                        color="surface"
                                                    />
                                                }
                                                selected={isEqual(
                                                    template.id,
                                                    selected
                                                )}
                                                onClick={() =>
                                                    toPage(
                                                        `${Routes.recipes}/${kebabCase(template.id.toString())}`
                                                    )
                                                }
                                            >
                                                {template.name}
                                            </NavigationRailCell>
                                        ))
                                    )}
                                </Stack>
                            )
                        )
                    )}
                </Stack>
            </Stack>
            <Stack gap={2} sx={{ padding: 3 }}>
                <Button
                    color="primary"
                    startNode={<AddOutline size="xs" />}
                    fullWidth
                    density="dense"
                >
                    Find Data Source
                </Button>
                <Button
                    variant="outlined"
                    color="surface"
                    startNode={<DownloadOutline size="xs" />}
                    fullWidth
                    density="dense"
                >
                    Find Data Source
                </Button>
            </Stack>
        </FlexScrollAreaContainer>
    );
};
