import {
    useFetchComputeMetricsQuery,
    useFetchReservedMemoryQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Box, Button, Stack, Typography } from "@biggeo/bg-ui/lab";
import { MemoryOutline } from "@biggeo/bg-ui/lab/icons";
import { theme } from "@biggeo/bg-ui/lab/theme";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { match } from "ts-pattern";
import {
    isAppRunningOnSF,
    useComputeMetrics,
    useReservedMemory,
} from "../common/redux/hooks";
import { commonActions } from "../common/redux/model";
import {
    getAvailableComputeMemory,
    getUsedComputeMemory,
} from "../utils/utils";
import IconLegend from "./MapInterfaceLegend/IconLegend";
import IconLegendBar from "./MapInterfaceLegend/IconLegendBar";

const ComputeMemorySpaceCard = () => {
    const isRunningOnSF = isAppRunningOnSF();

    const dispatch = useDispatch();

    const {
        queryReturn: {
            refetch: refetchComputeMetrics,
            data: computeMetricsData,
        },
    } = useFetchComputeMetricsQuery({ ...(!isRunningOnSF && { skip: true }) });

    !isEmpty(computeMetricsData) &&
        dispatch(
            commonActions.setComputeMetrics({
                computeMetrics: computeMetricsData.fetchComputeMetrics,
            })
        );

    const {
        queryReturn: {
            refetch: refetchReservedMemory,
            data: reservedMemoryData,
        },
    } = useFetchReservedMemoryQuery({ ...(!isRunningOnSF && { skip: true }) });

    !isEmpty(reservedMemoryData) &&
        dispatch(
            commonActions.setReservedMemory({
                reservedMemory: reservedMemoryData.fetchReservedMemory,
            })
        );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isRunningOnSF) {
                refetchComputeMetrics();
                refetchReservedMemory();
            }
        }, 30000);
        return () => {
            clearInterval(intervalId);
        };
    }, [isRunningOnSF]);

    enum MemoryMetrics {
        used = "used",
        reserved = "reserved",
        available = "available",
    }

    const computeMemoryMetrics = useComputeMetrics();
    const reservedMemory = useReservedMemory();

    const usedComputeMemory = getUsedComputeMemory(computeMemoryMetrics);
    const availableComputeMemory =
        getAvailableComputeMemory(computeMemoryMetrics);

    const getRatioByMemoryMetric = (metric: MemoryMetrics) =>
        match<MemoryMetrics>(metric)
            .with(MemoryMetrics.used, () => ({
                ratio: usedComputeMemory,
            }))
            .with(MemoryMetrics.reserved, () => ({ ratio: reservedMemory }))
            .with(MemoryMetrics.available, () => ({
                ratio: availableComputeMemory,
            }))
            .exhaustive();

    const getMetricColorAndLabel = (metric: MemoryMetrics) => {
        return match<MemoryMetrics>(metric)
            .with(MemoryMetrics.used, () => ({
                label: (
                    <Typography
                        variant="body4"
                        fontWeight="semibold"
                        textTransform="capitalize"
                    >
                        {MemoryMetrics.used}
                    </Typography>
                ),
                color: theme.palette.surface.main,
            }))
            .with(MemoryMetrics.reserved, () => ({
                label: (
                    <Typography
                        variant="body4"
                        fontWeight="semibold"
                        textTransform="capitalize"
                    >
                        {MemoryMetrics.reserved}
                    </Typography>
                ),
                color: theme.palette.primary.main,
            }))
            .with(MemoryMetrics.available, () => ({
                label: (
                    <Typography
                        variant="body4"
                        fontWeight="semibold"
                        textTransform="capitalize"
                    >
                        {MemoryMetrics.available}
                    </Typography>
                ),
                color: theme.palette.disabled.main,
            }))
            .exhaustive();
    };
    return (
        <Stack
            gap={2}
            sx={{
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                padding: 3,
                borderRadius: (theme) => theme.radius.xs3,
            }}
        >
            <Typography variant={"body4"} fontWeight={"semibold"}>
                Memory Space
            </Typography>
            <IconLegendBar
                values={pipe(
                    Object.values(MemoryMetrics),
                    A.map((item) => ({
                        ...getMetricColorAndLabel(item),
                        ...getRatioByMemoryMetric(item),
                    }))
                )}
            />
            <IconLegend
                values={pipe(
                    Object.values(MemoryMetrics),
                    A.map(getMetricColorAndLabel)
                )}
                isCompute
            />

            <Box
                sx={{
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                <Button
                    variant="outlined"
                    startNode={<MemoryOutline size="xs" />}
                    fullWidth
                >
                    View Usage
                </Button>
            </Box>
        </Stack>
    );
};

export default ComputeMemorySpaceCard;
