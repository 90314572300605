import { FlexScrollArea, FlexScrollAreaContainer } from "@biggeo/bg-ui/lab";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import kebabCase from "lodash/kebabCase";
import { useReducer, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { Consumers } from "../../common/redux/model";
import { MapHeader } from "../../components/MapHeader";
import {
    MarketplaceMapHeader,
    TabValues,
} from "../../components/MarketplaceMapHeader";
import { ExportToSFPage } from "../../marketplace/pages/ExportToSFPage";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import {
    getSavedAreaIdFromParams,
    getSavedViewIdFromParams,
} from "../../utils/utils";
import MapConfiguration from "../containers/MapConfiguration";
import MapSavedViews from "../containers/MapSavedViews";
import MapMainContainer from "../mapbox/containers/MapMainContainer";
import { MapContext, MapContextDataset, MapReducer } from "../mapbox/context";

export enum MapTabs {
    map = "map",
    configuration = "configuration",
    savedViews = "savedViews",
}

interface IMapViewWrapper {
    readonly currentTab?: MapTabs;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly mapTemplateId: number;
    readonly isSavedAreaForm?: boolean;
    readonly isFromMarketplace?: boolean;
    readonly datasets: MapContextDataset[];
    readonly isExporting?: boolean;
    readonly setIsExporting?: (isExporting: boolean) => void;
}

const MapViewWrapper = ({
    currentTab,
    activeConsumption,
    setConsumption,
    mapTemplateId,
    isSavedAreaForm,
    isFromMarketplace,
    datasets,
    isExporting,
    setIsExporting,
}: IMapViewWrapper) => {
    const toPage = useNavigate();
    const tab = currentTab ?? MapTabs.map;
    const reduxDispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();

    const [openSaveViewPopper, setOpenSaveViewPopper] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const savedAreaId = Number.parseInt(getSavedAreaIdFromParams(searchParams));
    const savedViewId = Number.parseInt(getSavedViewIdFromParams(searchParams));

    const map = useRef<mapboxgl.Map | null>(null);
    const draw = useRef<MapboxDraw | null>(null);
    const [state, dispatch] = useReducer(MapReducer, {
        map,
        draw,
        isLoaded: false,
        mapStates: [],
        selectedShapes: {
            type: "FeatureCollection",
            features: [],
        },
        filters: [],
        mapStyle: undefined,
        datasets,
        isDataLoaded: false,
        isRunningOnSF,
    });

    const handleMapTabs = (t: MapTabs) => {
        toPage(`${Routes.mapView}/${mapTemplateId}/${kebabCase(t)}`);
        reduxDispatch(
            toasterActions.openMapPopup({
                sx: { display: "none" },
            })
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <MapContext.Provider value={{ ...state, dispatch }}>
                {pipe(
                    isExporting,
                    O.fromPredicate((x) => Boolean(x)),
                    O.foldW(
                        () => (
                            <FlexScrollAreaContainer width="100%">
                                {isFromMarketplace && (
                                    <MarketplaceMapHeader
                                        currentTab={TabValues.mapView}
                                    />
                                )}
                                {!isFromMarketplace && (
                                    <MapHeader
                                        currentTab={tab}
                                        handleMapTabs={handleMapTabs}
                                        saveView={() =>
                                            setOpenSaveViewPopper(true)
                                        }
                                        isExporting={isExporting}
                                        setIsExporting={setIsExporting}
                                    />
                                )}
                                <FlexScrollArea flexDirection="column">
                                    {match(tab)
                                        .with(MapTabs.configuration, () => (
                                            <MapConfiguration
                                                mapTemplateId={mapTemplateId}
                                                toPage={toPage}
                                                isSavedAreaForm={
                                                    isSavedAreaForm
                                                }
                                            />
                                        ))
                                        .with(MapTabs.savedViews, () => (
                                            <MapSavedViews
                                                mapTemplateId={mapTemplateId}
                                                toPage={toPage}
                                            />
                                        ))
                                        .with(MapTabs.map, () => (
                                            <MapMainContainer
                                                tab={tab}
                                                mapTemplateId={mapTemplateId}
                                                activeConsumption={
                                                    activeConsumption
                                                }
                                                setConsumption={setConsumption}
                                                setOpenSaveViewPopper={
                                                    setOpenSaveViewPopper
                                                }
                                                openSaveViewPopper={
                                                    openSaveViewPopper
                                                }
                                                isFromMarketplace={
                                                    isFromMarketplace
                                                }
                                                savedAreaId={savedAreaId}
                                                savedViewId={savedViewId}
                                                searchParams={searchParams}
                                                setSearchParams={
                                                    setSearchParams
                                                }
                                            />
                                        ))
                                        .exhaustive()}
                                </FlexScrollArea>
                            </FlexScrollAreaContainer>
                        ),
                        () => <ExportToSFPage setIsExporting={setIsExporting} />
                    )
                )}
            </MapContext.Provider>
        </DndProvider>
    );
};

export default MapViewWrapper;
