import MarketplaceUpgradeContainer from "../containers/MarketplaceUpgradeContainer.tsx";

export type AvailableDataset = {
    readonly id: string;
    readonly title: string;
    readonly image?: string;
    readonly select?: boolean;
};

const UpgradeToBGVelocityPage = () => {
    return <MarketplaceUpgradeContainer />;
};

export default UpgradeToBGVelocityPage;
