import { useFetchMapTemplatesQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import { useNavigate } from "react-router-dom";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Routes } from "../../navigation/redux/model";
import MapTemplatesView from "../views/MapTemplatesView";

export interface IMapTemplatesContainer {
    readonly createMapTemplate: () => void;
}

const MapTemplatesContainer = (props: IMapTemplatesContainer) => {
    const toPage = useNavigate();

    const manageMapTemplate = (id: number) => {
        toPage(`${Routes.mapTemplatesManage}/${id}`);
    };

    const toMapTemplate = (id: number) => {
        toPage(`${Routes.mapView}/${id}/map`);
    };

    const { remote } = useFetchMapTemplatesQuery({
        variables: {
            input: {
                isMarketplaceMapTemplate: false,
            },
        },
    });

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (data) => {
            return (
                <MapTemplatesView
                    {...props}
                    manageMapTemplate={manageMapTemplate}
                    maps={data.fetchMapTemplates.mapTemplates}
                    toMapTemplate={toMapTemplate}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};

export default MapTemplatesContainer;
