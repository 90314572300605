import { LogicOperator, Stack } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import FilterCriteriaDatasetFilters from "../components/FilterCriteriaDatasetFilters";
import MapTemplateDatasetsMenu from "../components/MapTemplateDatasetsMenu";
import {
    MapFilterCriteriaDataset,
    MapFilterCriteriaDatasetItem,
} from "../utils/utils";
import FilterCriteriaDatasetsEmptyView from "./FilterCriteriaDatasetsEmptyView";

export interface IFilterCriteriaDatasets {
    readonly mapTemplateId: number;
    readonly filters: MapFilterCriteriaDataset[];
    readonly onDatasetClick: (i: MapFilterCriteriaDataset) => void;
    readonly onAddDatasetFilter: (
        mapTemplateDatasetId: number,
        filter: Partial<MapFilterCriteriaDatasetItem>
    ) => void;
    readonly deleteDatasetFilterItem: (i: {
        mapTemplateDatasetId: number;
        index: number;
    }) => void;
    readonly clearAll: (mapTemplateDatasetId: number) => void;
    readonly removeDataset: (mapTemplateDatasetId: number) => void;
    readonly updateDatasetFilterItem: (i: {
        mapTemplateDatasetId: number;
        index: number;
        logicOperator?: LogicOperator;
        item: Partial<MapFilterCriteriaDatasetItem>;
    }) => void;
    readonly selectedDatasets: string[];
}

const FilterCriteriaDatasets = ({
    filters,
    mapTemplateId,
    onDatasetClick,
    onAddDatasetFilter,
    deleteDatasetFilterItem,
    clearAll,
    removeDataset,
    updateDatasetFilterItem,
    selectedDatasets,
}: IFilterCriteriaDatasets) => {
    return (
        <Stack gap={2} width="100%">
            {pipe(
                filters,
                O.fromPredicate((f) => !isEmpty(f)),
                O.fold(
                    () => (
                        <FilterCriteriaDatasetsEmptyView
                            mapTemplateId={mapTemplateId}
                            onDatasetClick={onDatasetClick}
                            includedDatasets={selectedDatasets}
                        />
                    ),
                    () => (
                        <FilterCriteriaDatasetFilters
                            filters={filters}
                            onAddDatasetFilter={onAddDatasetFilter}
                            deleteDatasetFilterItem={deleteDatasetFilterItem}
                            clearAll={clearAll}
                            removeDataset={removeDataset}
                            updateDatasetFilterItem={updateDatasetFilterItem}
                        />
                    )
                )
            )}
            {/* {!isEmpty(filters) && (
                <MapTemplateDatasetsMenu
                    mapTemplateId={mapTemplateId}
                    onDatasetClick={onDatasetClick}
                    text="Add Dataset"
                    placement="bottom-end"
                    omittedDatasets={pipe(
                        filters,
                        A.map((f) => f.dataSourceId)
                    )}
                    includedDatasets={selectedDatasets}
                />
            )} */}
        </Stack>
    );
};

export default FilterCriteriaDatasets;
