import { Tag } from "@biggeo/bg-ui/lab";
import { CancelFilled, CheckCircle } from "@biggeo/bg-ui/lab/icons";

export type AccessKeyStatusTagProps = {
    status: "active" | "inactive";
};

export const AccessKeyStatusTag = ({ status }: AccessKeyStatusTagProps) => {
    return (
        <Tag
            variant="tonal"
            density="dense"
            rounded
            color={status === "active" ? "success" : "error"}
            endNode={
                status === "active" ? (
                    <CheckCircle size="xxs" />
                ) : (
                    <CancelFilled size="xxs" />
                )
            }
        >
            {status === "active" ? "Active Key" : "Inactive Key"}
        </Tag>
    );
};
