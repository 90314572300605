import { useFetchLinkedMapTemplatesQuery } from "@biggeo/bg-server-lib/datascape-ai";
import {
    AvatarGroup,
    Button,
    CircularLoading,
    Stack,
    ThumbnailAvatar,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ErrorOutline, PublicOutline } from "@biggeo/bg-ui/lab/icons";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";

interface IDatasetsUsedInContainer {
    readonly dataSourceId: string;
}

const DatasetsUsedInContainer = ({
    dataSourceId,
}: IDatasetsUsedInContainer) => {
    const toPage = useNavigate();
    const { remote } = useFetchLinkedMapTemplatesQuery({
        variables: { dataSourceId },
    });

    return match(remote, {
        _: () => <CircularLoading size="md" />,
        Initialized: () => <CircularLoading size="md" />,
        Failure: (err) => (
            <Stack flexDirection="row" alignItems="center" gap={2}>
                <ErrorOutline size="sm" color="error" />
                <Tooltip title={err.message}>
                    <Typography variant="body3" color="error" truncate>
                        Error
                    </Typography>
                </Tooltip>
            </Stack>
        ),
        Success: ({ fetchLinkedMapTemplates: mapTemplates }) => (
            <AvatarGroup size="xs" max={2}>
                {pipe(
                    mapTemplates,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.foldW(
                        () => (
                            <Button
                                variant="outlined"
                                density="dense"
                                startNode={<PublicOutline size="xs" />}
                                onClick={() =>
                                    toPage(
                                        `${Routes.mapTemplatesCreate}/${dataSourceId}`
                                    )
                                }
                            >
                                Create map
                            </Button>
                        ),
                        A.map((mapTemplate) => (
                            <ThumbnailAvatar
                                key={mapTemplate.id}
                                src={mapTemplate.img || undefined}
                                alt={mapTemplate.name}
                                color="surface"
                            />
                        ))
                    )
                )}
            </AvatarGroup>
        ),
    });
};

export default DatasetsUsedInContainer;
