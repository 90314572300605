import { Grid } from "@biggeo/bg-ui/lab";
import { Theme } from "@emotion/react";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { FC, ReactNode } from "react";
import { match } from "ts-pattern";

export type IconLegendBarProps = {
    label: ReactNode;
    color: string;
    ratio: number;
};

enum ItemPositions {
    first = "first",
    last = "last",
}

const IconLegendBar: FC<{ values: IconLegendBarProps[] }> = ({
    values,
}: { values: IconLegendBarProps[] }) => {
    const total = pipe(
        values,
        A.reduce(0, (acc: number, cur: IconLegendBarProps) => acc + cur.ratio)
    );

    const applyBorderRadius = (itemPosition: ItemPositions) => {
        return match<ItemPositions>(itemPosition)
            .with(ItemPositions.first, () => ({
                borderTopLeftRadius: (theme: Theme) => theme.radius.full,
                borderBottomLeftRadius: (theme: Theme) => theme.radius.full,
            }))
            .with(ItemPositions.last, () => ({
                borderTopRightRadius: (theme: Theme) => theme.radius.full,
                borderBottomRightRadius: (theme: Theme) => theme.radius.full,
            }))
            .exhaustive();
    };
    return (
        <Grid container flexWrap="nowrap">
            {pipe(
                values,
                A.mapWithIndex((index, data) => {
                    const calculatedWidth = (
                        (data.ratio / total) *
                        100
                    ).toFixed(2);
                    const isFirstItem = (i: number) => isEqual(i, 0);
                    const isLastItem = (i: number) =>
                        isEqual(i, values.length - 1);
                    return (
                        <Grid
                            key={`${data.label}-${data.color}`}
                            item
                            width={`${calculatedWidth}%`}
                            height={1}
                            sx={{
                                backgroundColor: data.color,
                                ...(isFirstItem(index) && {
                                    ...applyBorderRadius(ItemPositions.first),
                                }),
                                ...(isLastItem(index) && {
                                    ...applyBorderRadius(ItemPositions.last),
                                }),
                                border: (theme) => theme.radius.full,
                            }}
                        />
                    );
                })
            )}
        </Grid>
    );
};

export default IconLegendBar;
