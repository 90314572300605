import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    ISlotable,
    IconButton,
    SlotsBase,
    SlotsProvider,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ChevronLeftOutline,
    DragHandleVerticalOutline,
} from "@biggeo/bg-ui/lab/icons";
import { mergeSx, useBelowBreakpoint } from "@biggeo/bg-ui/lab/system";
import { ComponentProps, ReactNode, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

export type NestedSubSidebarSlots = SlotsBase<{
    title: ComponentProps<typeof Typography>;
    sidebarContainer: ComponentProps<typeof FlexScrollAreaContainer>;
    drawerContainer: ComponentProps<typeof FlexScrollAreaContainer>;
    drawerContentWrapper: ComponentProps<typeof FlexScrollArea>;
    closeButton: ComponentProps<typeof IconButton>;
    closeIcon: ComponentProps<typeof ChevronLeftOutline>;
    dragIndicator: ComponentProps<typeof DragHandleVerticalOutline>;
}>;

// eslint-disable-next-line react-refresh/only-export-components
export const nestedSubSidebarSlots: NestedSubSidebarSlots = {
    title: Typography,
    sidebarContainer: FlexScrollAreaContainer,
    drawerContainer: FlexScrollAreaContainer,
    drawerContentWrapper: FlexScrollArea,
    closeButton: IconButton,
    closeIcon: ChevronLeftOutline,
    dragIndicator: DragHandleVerticalOutline,
};

export type NestedSubSidebarDefaultableProps =
    ISlotable<NestedSubSidebarSlots> & {
        /**
         * The initial width of the drawer
         * @type number
         * @default 20
         */
        initialWidth?: number;
        /**
         * The height of the drawer when opened on mobile
         * @type number | string
         * @default "50vh"
         */
        mobileHeight?: number | string;
    };

export type NestedSubSidebarProps = ComponentProps<typeof Box> &
    NestedSubSidebarDefaultableProps & {
        /**
         * The title of the drawer
         * @type ReactNode
         * @default undefined
         */
        title?: ReactNode;
        /**
         * The content of the drawer
         * @type ReactNode
         * @default undefined
         */
        drawerContent?: ReactNode;
        /**
         * If true, the drawer will be open
         * @type boolean
         * @default undefined
         */
        open?: boolean;
        /**
         * A callback that fires when the drawer is opened or closed.
         * This component manages the open state internally, so this callback is only used to notify the parent component of the change.
         * @type (open: boolean) => void
         * @default undefined
         */
        onOpenChange?: (open: boolean) => void;
    };

/**
 * This component reorders the drawer and sidebar on mobile and swaps the width and height in order to satisfy the mobile spec requirements.
 *
 * This means the component requires that the direct children of the component instance manage their flex direction on both mobile and desktop.
 *
 * For a working example please see https://minerva.vividtheory.com/?path=/docs/layouts-nestedsubsidebar--docs
 */
export const NestedSubSidebar = ({
    title,
    drawerContent,
    initialWidth = 20,
    mobileHeight = "50vh",
    open: _open,
    onOpenChange,

    slots,
    slotProps,

    children,
    sx,
    ...rest
}: NestedSubSidebarProps) => {
    const mobile = useBelowBreakpoint("md");

    const parentRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const drawerRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(_open ?? false);
    const [dragging, setDragging] = useState(false);
    const [drawer, setDrawer] = useState<{ size: number; offset: number }>({
        size: initialWidth,
        offset: mobile
            ? sidebarRef.current?.offsetHeight ?? 0
            : sidebarRef.current?.offsetWidth ?? 0,
    });

    useEffect(() => {
        setOpen(_open ?? false);
    }, [_open]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setDrawer({
            size: initialWidth,
            offset: mobile
                ? sidebarRef.current?.offsetHeight ?? 0
                : sidebarRef.current?.offsetWidth ?? 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, sidebarRef.current, mobile]);

    const maxSizeUpper = window ? window.innerWidth : drawer.size;
    const maxSizeLower = window ? 80 : drawer.size;

    return (
        <SlotsProvider baseSlots={nestedSubSidebarSlots} slots={slots}>
            {({
                title: Title,
                sidebarContainer: SidebarContainer,
                drawerContainer: DrawerContainer,
                drawerContentWrapper: DrawerContentWrapper,
                closeButton: CloseButton,
                closeIcon: CloseIcon,
                dragIndicator: DragIndicator,
            }) => (
                <Box
                    ref={parentRef}
                    sx={mergeSx(
                        {
                            position: "relative",
                            height: "auto",

                            display: "flex",
                            flexDirection: "column-reverse",

                            breakpoints: {
                                md: {
                                    height: "100%",
                                    flexDirection: "row",
                                },
                            },
                        },
                        sx
                    )}
                    {...rest}
                >
                    <SidebarContainer
                        ref={sidebarRef}
                        {...slotProps?.sidebarContainer}
                        sx={mergeSx(
                            {
                                background: (theme) =>
                                    theme.palette.background.main,
                                zIndex: 1,
                                width: "100%",
                                flexDirection: "row",
                                height: "auto",
                                breakpoints: {
                                    md: {
                                        height: "100%",
                                        width: "auto",
                                        flexDirection: "column",
                                    },
                                },
                            },
                            slotProps?.sidebarContainer?.sx
                        )}
                    >
                        {children}
                    </SidebarContainer>
                    <DrawerContainer
                        ref={drawerRef}
                        {...slotProps?.drawerContainer}
                        sx={mergeSx(
                            {
                                position: "absolute",
                                overflow: !open ? "hidden" : undefined,
                                opacity: open ? 1 : 0,
                                background: (theme) =>
                                    theme.palette.background.main,
                                zIndex: (theme) => theme.zIndex.drawer,

                                "&:hover": {
                                    "& .drag-indicator": {
                                        opacity: 1,
                                    },
                                },

                                width: "100%",
                                height: open ? mobileHeight : 0,
                                bottom: `${drawer.offset}px`,
                                left: 0,
                                borderBottom: 1,
                                transition:
                                    "height ease-in-out 0.2s, opacity ease-in-out 0.2s",
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                                borderStartStartRadius: (theme) =>
                                    theme.radius.default,
                                borderStartEndRadius: (theme) =>
                                    theme.radius.default,

                                breakpoints: {
                                    md: {
                                        transition: dragging
                                            ? "none"
                                            : "width ease-in-out 0.2s, opacity ease-in-out 0.2s",
                                        width: `${open ? drawer.size : 0}px`,
                                        minWidth: open ? 20 : 0,
                                        height: "100%",
                                        maxHeight: "100%",
                                        top: 0,
                                        left: `${drawer.offset}px`,
                                        bottom: "auto",

                                        borderLeft: 1,
                                        borderBottom: 0,
                                        borderColor: (theme) =>
                                            theme.palette.stroke[100],
                                        borderStartStartRadius: 0,
                                        borderEndEndRadius: (theme) =>
                                            theme.radius.default,
                                        borderStartEndRadius: (theme) =>
                                            theme.radius.default,
                                    },
                                },
                            },
                            slotProps?.drawerContainer?.sx
                        )}
                    >
                        <Grid
                            container
                            gap={1}
                            flexWrap="nowrap"
                            alignItems="center"
                            sx={{ padding: 4 }}
                        >
                            <Grid item xs minWidth={0}>
                                {typeof title === "string" ? (
                                    <Title
                                        variant="title3"
                                        truncate
                                        fontWeight="bold"
                                        {...slotProps?.title}
                                    >
                                        {title}
                                    </Title>
                                ) : (
                                    title
                                )}
                            </Grid>
                            <Grid item>
                                <CloseButton
                                    variant="tonal"
                                    {...slotProps?.closeButton}
                                    onClick={(e) => {
                                        setOpen(!open);
                                        onOpenChange?.(!open);
                                        slotProps?.closeButton?.onClick?.(e);
                                    }}
                                >
                                    <CloseIcon
                                        {...slotProps?.closeIcon}
                                        sx={mergeSx(
                                            {
                                                transform: "rotate(270deg)",
                                                breakpoints: {
                                                    md: {
                                                        transform:
                                                            "rotate(0deg)",
                                                    },
                                                },
                                            },
                                            sx
                                        )}
                                    />
                                </CloseButton>
                            </Grid>
                        </Grid>
                        <DrawerContentWrapper
                            {...slotProps?.drawerContentWrapper}
                        >
                            {drawerContent}
                        </DrawerContentWrapper>
                        {!mobile && (
                            <Draggable
                                axis="x"
                                onStart={() => setDragging(true)}
                                onStop={() => setDragging(false)}
                                onDrag={(_, data) => {
                                    const newSize = drawer.size + data.deltaX;

                                    setDrawer({
                                        ...drawer,
                                        size:
                                            newSize < maxSizeUpper &&
                                            newSize > maxSizeLower
                                                ? newSize
                                                : drawer.size,
                                    });
                                }}
                            >
                                <DragIndicator
                                    {...slotProps?.dragIndicator}
                                    className="drag-indicator"
                                    sx={mergeSx(
                                        {
                                            position: "absolute",
                                            opacity: 0,
                                            transition: "opacity 0.3s",
                                            paddingX: 0,
                                            paddingY: 2,
                                            cursor: "grab",
                                            background: (theme) =>
                                                theme.palette.disabled.onMain,
                                            color: (theme) =>
                                                theme.palette.disabled.main,
                                            borderRadius: (theme) =>
                                                theme.radius.full,
                                            "&:active": {
                                                cursor: "grabbing",
                                            },

                                            top: "50%",
                                            right: 0,
                                            transform: "translate(50%, -50%)",
                                        },
                                        slotProps?.dragIndicator?.sx
                                    )}
                                />
                            </Draggable>
                        )}
                    </DrawerContainer>
                </Box>
            )}
        </SlotsProvider>
    );
};
