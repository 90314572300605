import {
    CreateEditDeleteMapTemplate,
    UploadFile,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    DescriptionTextField,
    MultiSelectorAccordion,
    Stack,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { useDispatch } from "react-redux";
import { UploadSingleImage } from "../../files/components/UploadSingleImage.tsx";
import { toasterActions } from "../../toaster/containers/redux/model.ts";
import { AvailableDataset } from "../pages/CreateOrManageMapTemplatePage.tsx";

interface IMapTemplateFormView {
    readonly values: CreateEditDeleteMapTemplate;
    readonly onChange: (i: Partial<CreateEditDeleteMapTemplate>) => void;
    readonly datasets: AvailableDataset[];
    readonly linkedDatasets: string[];
    readonly setLinkedDatasets: (datasets: string[]) => void;
    readonly isSubmitting: boolean;
}

const MapTemplateFormView = ({
    values,
    onChange,
    datasets,
    setLinkedDatasets,
    isSubmitting,
}: IMapTemplateFormView) => {
    const dispatch = useDispatch();
    return (
        <Stack gap={4}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
                <ThumbnailAvatar
                    src={values.thumbnail || ""}
                    square
                    size="lg"
                    color="tertiary"
                />
                <UploadSingleImage<UploadFile>
                    label={"Map Thumbnail"}
                    withButton
                    callbacks={{
                        onSuccess: (file) => {
                            onChange({
                                thumbnail:
                                    file.url === "" ? undefined : file.url,
                            });
                        },
                        onError: () =>
                            dispatch(
                                toasterActions.openToast({
                                    open: true,
                                    title: "Upload image failed",
                                    autoHideDuration: 5000,
                                })
                            ),
                    }}
                />
            </Stack>
            <TextField
                fullWidth
                required
                name="name"
                label="Map name"
                value={values.name || undefined}
                placeholder="Enter name"
                onChange={(_, v) =>
                    onChange({ name: v === "" ? undefined : v })
                }
                disabled={isSubmitting}
            />
            <DescriptionTextField
                value={values.description || undefined}
                onChange={(_, v) =>
                    onChange({ description: v === "" ? undefined : v })
                }
                disabled={isSubmitting}
            />

            <Stack gap={4}>
                <Stack gap={0.5}>
                    <Typography variant="body2" fontWeight="bold">
                        Available datasets
                    </Typography>
                    <Typography
                        variant="body3"
                        textColor="disabled.onContainer"
                    >
                        Would you like to make any datasets available for use on
                        this map?
                    </Typography>
                </Stack>
                <MultiSelectorAccordion
                    expanded
                    accordionLabel={"Available datasets"}
                    dataList={datasets}
                    onChange={(selectedIds) => {
                        onChange({ fkDataSourceIds: selectedIds });
                        setLinkedDatasets(selectedIds);
                    }}
                    orderItems={{ title: "Name" }}
                    avatarType="thumbnail"
                />
            </Stack>
        </Stack>
    );
};

export default MapTemplateFormView;
