import { keyframes } from "@biggeo/bg-ui";
import { Box, IconButton, Stack } from "@biggeo/bg-ui/lab";
import { SxProp } from "@biggeo/bg-ui/lab/system";
import { Keyframes } from "@emotion/react";
import BigGeoAi from "../../../icons/BigGeoAi";

interface IAiPromptIndicatorButton {
    readonly onAiClick: () => void;
}

interface IEllipse {
    readonly color: string;
    readonly animation: Keyframes;
}

const animationStyle = ({ color, animation }: IEllipse): SxProp => ({
    background: `radial-gradient(circle, ${color} 100%, ${color} 0%)`,
    filter: "blur(2px)",
    animation: `${animation} 1.5s infinite ease-in-out`,
});

const ellipseFiveAnimation = keyframes`
    0% { transform: translate(25%, -5%); }
    25% { transform: translate(-2%, -2%); }
    50% { transform: translate(15%, 25%); }
    75% { transform: translate(25%, 12%); }
    100% { transform: translate(25%, -5%); }
`;

const ellipseFourAnimation = keyframes`
    0% { transform: translate(15%, 8%); }
    25% { transform: translate(2%, -8%); }
    50% { transform: translate(-8%, -5%); }
    75% { transform: translate(18%, -2%); }
    100% { transform: translate(15%, 8%); }
`;

const ellipseThreeAnimation = keyframes`
    0% { transform: translate(-5%, 8%); }
    25% { transform: translate(-2%, -2%); }
    50% { transform: translate(8%, -8%); }
    75% { transform: translate(10%, -2%); }
    100% { transform: translate(-5%, 8%); }
`;

const AiIcon = () => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 15,
            height: 15,
        }}
    >
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                borderRadius: (theme) => theme.radius.full,
                background: "radial-gradient(circle, #2F5AF0 100%, #2F5AF0 0%)",
                filter: "blur(1.5px)",
            }}
        />
        <IconButton
            disableHoverEffect
            color="background"
            density="dense"
            sx={{
                borderRadius: (theme) => theme.radius.full,
                background:
                    "linear-gradient(-130deg, #1B348A 0%, #2F5AF0 100%)",
            }}
        >
            <BigGeoAi />
        </IconButton>
    </Box>
);

const EllipseFive = ({ color, animation }: IEllipse) => (
    <Box
        sx={{
            ...animationStyle({ color, animation }),
            borderRadius: "50%",
            position: "absolute",
            width: 12.5,
            height: 12.5,
        }}
    />
);

const EllipseFour = ({ color, animation }: IEllipse) => (
    <Box
        sx={{
            ...animationStyle({ color, animation }),
            borderRadius: "50%",
            position: "absolute",
            width: 14.5,
            height: 14.5,
        }}
    />
);

const EllipseThree = ({ color, animation }: IEllipse) => (
    <Box
        sx={{
            ...animationStyle({ color, animation }),
            borderRadius: "50%",
            position: "absolute",
            width: 15,
            height: 15,
        }}
    />
);

const AiPromptIndicatorButton = ({ onAiClick }: IAiPromptIndicatorButton) => (
    <Stack onClick={onAiClick} sx={{ cursor: "pointer" }}>
        <EllipseThree color="#1BB0BC" animation={ellipseThreeAnimation} />
        <EllipseFour color="#A976E1" animation={ellipseFourAnimation} />
        <EllipseFive color="#2F5AF0" animation={ellipseFiveAnimation} />
        <AiIcon />
    </Stack>
);

export default AiPromptIndicatorButton;
