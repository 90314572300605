import { Grid, Typography } from "@biggeo/bg-ui/lab";

import { ReactNode } from "react";

type MinimalHeaderProps = {
    readonly title: string;
    readonly endNode?: ReactNode;
};

export const MinimalHeader = ({ title, endNode }: MinimalHeaderProps) => {
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item>
                <Typography variant={"body2"} fontWeight={"semibold"}>
                    {title}
                </Typography>
            </Grid>
            <Grid item>{endNode}</Grid>
        </Grid>
    );
};
