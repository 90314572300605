import { FileNameType } from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import { Button, Severity } from "@biggeo/bg-ui/lab";
import capitalize from "lodash/capitalize";
import { ComponentProps, useState } from "react";
import { useDispatch } from "react-redux";
import { appHttpLink } from "../../map/utils/subscription";
import { toasterActions } from "../../toaster/containers/redux/model";

interface IDownloadButton {
    readonly fileName: FileNameType;
    readonly cta?: ComponentProps<typeof Button>;
}

export const DownloadButton = ({ fileName, cta }: IDownloadButton) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const url = appHttpLink.options.uri;

    const download = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${url}/download/${fileName}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                setLoading(false);
                throw new Error(
                    `Error downloading ${fileName}. Network request issue.`
                );
            }

            const blob = new Blob([await response.blob()]);
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.href = blobUrl;
            link.download = `${fileName}.zip`;
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(blobUrl);
        } catch (_error) {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: `An error occured while downloading ${fileName}. Please try again later.`,
                    autoHideDuration: 5000,
                    severity: Severity.error,
                })
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <WithLoading
            loading={loading}
            text={`Downloading ${capitalize(fileName)}...`}
        >
            <Button
                variant="filled"
                color="info"
                rounded
                {...cta}
                onClick={download}
            >
                Download {capitalize(fileName)}
            </Button>
        </WithLoading>
    );
};
