import {
    CreateEditDeleteMapTemplate,
    MapTemplate,
    MapTemplateDataset,
    MapTemplateExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    Stack,
    StickyFabPlacementHelper,
    SubmittingButton,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { orderBy } from "lodash";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { OnPageLeave } from "../../common/components/OnPageLeave.tsx";
import { modalActions } from "../../modal/redux/model.tsx";
import { Routes } from "../../navigation/redux/model.ts";
import { CallBacksType } from "../../utils/types";
import { AvailableDataset } from "../pages/CreateOrManageMapTemplatePage.tsx";
import { DeleteMapTemplateModal } from "./DeleteMapTemplateModal.tsx";
import MapTemplateFormView from "./MapTemplateFormView";

interface IMapTemplateForm {
    readonly map?: MapTemplate;
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly datasets: AvailableDataset[];
    readonly initialDatasets?: string[];
    readonly linkedDatasets: string[];
    readonly setLinkedDatasets: (datasets: string[]) => void;
    readonly loading: boolean;
}

const MapTemplateForm = ({
    map,
    navigate,
    save,
    datasets,
    initialDatasets,
    linkedDatasets,
    setLinkedDatasets,
    loading,
}: IMapTemplateForm) => {
    const type = map ? "manage" : "create";
    const isEdit = !!map;
    const dispatch = useDispatch();

    const openModal = (map: CreateEditDeleteMapTemplate) => {
        dispatch(
            modalActions.openModal({
                modalType: "new-dialog",
                dialogProps: {
                    variant: "centered-medium",
                },
                component: (
                    <DeleteMapTemplateModal
                        template={map}
                        navigate={navigate}
                    />
                ),
            })
        );
    };

    const setFormValues = (map?: MapTemplate, fkDataSourceIds?: string[]) => {
        const orderedArray = orderBy(fkDataSourceIds, ["asc"]);
        return {
            id: map?.id,
            name: map?.name || "",
            thumbnail: map?.thumbnail || undefined,
            description: map?.description || undefined,
            fkDataSourceIds: pipe(
                orderedArray,
                O.fromNullable,
                O.fold(
                    () => [],
                    A.map((t) => t)
                )
            ),
        };
    };

    const initialValues = setFormValues(map, initialDatasets);

    const [shouldNotTrigger, setShouldNotTrigger] = useState(false);

    return (
        <Formik<CreateEditDeleteMapTemplate>
            validateOnMount
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
                setShouldNotTrigger(true);

                save(values, {
                    onSuccess: () => {
                        actions.setSubmitting(false);
                        navigate(`${Routes.mapTemplates}`);
                    },
                    onError: () => {
                        setShouldNotTrigger(false);
                    },
                });
            }}
            validationSchema={toFormikValidationSchema(
                z.object({
                    id: z.number().optional(),
                    name: z.string(),
                    thumbnail: z.string().optional(),
                    description: z.string().optional(),
                    fkDataSourceIds: z.string().array().optional(),
                })
            )}
        >
            {({
                values,
                setValues,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting,
            }) => {
                return (
                    <OnPageLeave
                        trigger={
                            isEdit &&
                            !isEqual(values, initialValues) &&
                            !shouldNotTrigger
                        }
                        save={({ navigate }) => {
                            save(values, {
                                onSuccess: () => {
                                    navigate();
                                },
                            });
                        }}
                    >
                        <CenteredNestedLayoutWithHeader
                            title={
                                <BreadcrumbsGroup
                                    value={`${type}-map-template`}
                                >
                                    <BreadcrumbsButton
                                        value="map-templates"
                                        onClick={() => navigate()}
                                    >
                                        Map templates
                                    </BreadcrumbsButton>
                                    <BreadcrumbsButton
                                        value={`${type}-map-template`}
                                        onClick={() =>
                                            navigate(
                                                map
                                                    ? `/map-templates/manage/${map.id}`
                                                    : "/map-templates/create"
                                            )
                                        }
                                        hideSeparator
                                    >
                                        {`${startCase(type)} map template`}
                                    </BreadcrumbsButton>
                                </BreadcrumbsGroup>
                            }
                        >
                            <Stack gap={4}>
                                <Stack gap={0.5}>
                                    <Typography
                                        variant="title1"
                                        fontWeight="bold"
                                    >
                                        {startCase(type)} Map Template
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        textColor="disabled.onContainer"
                                    >
                                        {`${type === "manage" ? "Edit" : "Enter"} map template details`}
                                    </Typography>
                                </Stack>
                                <MapTemplateFormView
                                    values={values}
                                    onChange={(i) =>
                                        setValues((p) => ({ ...p, ...i }))
                                    }
                                    linkedDatasets={linkedDatasets}
                                    setLinkedDatasets={setLinkedDatasets}
                                    datasets={datasets}
                                    isSubmitting={isSubmitting}
                                />
                                <StickyFabPlacementHelper
                                    placement="right"
                                    sx={{
                                        paddingY: 4,
                                        backgroundColor: (theme) =>
                                            theme.palette.background.container,
                                    }}
                                >
                                    <Stack
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                    >
                                        {map ? (
                                            <Stack flexDirection="row" gap={4}>
                                                <Button
                                                    type="submit"
                                                    variant={"outlined"}
                                                    color={"error"}
                                                    disabled={isSubmitting}
                                                    onClick={() =>
                                                        openModal(map)
                                                    }
                                                >
                                                    Remove
                                                </Button>
                                                <SubmittingButton
                                                    type="saving"
                                                    loading={loading}
                                                    disabled={
                                                        !isValid || !dirty
                                                    }
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                >
                                                    Save Changes
                                                </SubmittingButton>
                                            </Stack>
                                        ) : (
                                            <SubmittingButton
                                                type="creating"
                                                loading={loading}
                                                disabled={!isValid || !dirty}
                                                onClick={() => handleSubmit()}
                                            >
                                                Create Map Template
                                            </SubmittingButton>
                                        )}
                                    </Stack>
                                </StickyFabPlacementHelper>
                            </Stack>
                        </CenteredNestedLayoutWithHeader>
                    </OnPageLeave>
                );
            }}
        </Formik>
    );
};

export default MapTemplateForm;
