import { Box, IconButton, Stack, Tooltip, Typography } from "@biggeo/bg-ui/lab";
import { Link } from "react-router-dom";
import { version } from "../../../../package.json";

import { InfoOutline } from "@biggeo/bg-ui/lab/icons";
import { isAppRunningOnSF } from "../../../common/redux/hooks.ts";

const Accreditation = () => {
    const isRunningOnSF = isAppRunningOnSF();

    return (
        <Box
            sx={{
                position: "absolute",
                display: "flex",
                left: 4,
                bottom: 4,
            }}
        >
            <Tooltip
                title={
                    isRunningOnSF ? (
                        ""
                    ) : (
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            }}
                        >
                            Accreditation
                        </Typography>
                    )
                }
                bodyNode={
                    <Stack
                        justifyContent="space-between"
                        gap={2}
                        sx={{ paddingLeft: 0.5 }}
                    >
                        {!isRunningOnSF && (
                            <Stack>
                                <Link
                                    to="https://openstreetmap.org/copyright"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        OpenStreetMap
                                    </Typography>
                                </Link>
                                <Link
                                    to="https://open.alberta.ca/licence"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        Alberta Government
                                    </Typography>
                                </Link>
                                <Link
                                    to="https://opencellid.org/"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        OpenCelliD
                                    </Typography>
                                </Link>
                                <Link
                                    to="https://data-usdot.opendata.arcgis.com/search?collection=Dataset"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        U.S. Department of Transportation
                                    </Typography>
                                </Link>
                            </Stack>
                        )}
                        <Typography variant="body3">v {version}</Typography>
                    </Stack>
                }
                sx={{
                    width: "fit-content",
                }}
            >
                <span>
                    <IconButton
                        density="dense"
                        color="background"
                        sx={{
                            border: (theme) =>
                                `${theme.spacing(0.25)} solid ${
                                    theme.palette.stroke[100]
                                }`,
                        }}
                    >
                        <InfoOutline />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
};

export default Accreditation;
