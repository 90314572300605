import {
    Grid,
    Stack,
    ThumbnailAvatar,
    Typography,
    thumbnailAvatarClasses,
} from "@biggeo/bg-ui/lab";
import { CompareArrowsOutline } from "@biggeo/bg-ui/lab/icons";
import { useComputeMetrics } from "../../common/redux/hooks";

export const ExportToSFHeader = ({
    isRunningOnSF,
}: { isRunningOnSF?: boolean }) => {
    return (
        <Stack width={"100%"}>
            <Stack
                gap={4}
                width={"100%"}
                sx={{
                    border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                    borderRadius: (theme) => theme.radius.xs3,
                    breakpoints: {
                        cmd: {
                            padding: 8,
                        },
                        xs: {
                            paddingX: 4,
                            paddingY: 8,
                        },
                    },
                }}
            >
                <Stack gap={3} width={"100%"}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                        sx={{
                            paddingRight: (theme) => theme.spacing(2),
                            paddingLeft: (theme) => theme.spacing(2),
                        }}
                    >
                        <ThumbnailAvatar
                            src={
                                "https://biggeo.blob.core.windows.net/media/MapTemplateLogo.png"
                            }
                            square
                            size="md"
                            sx={{
                                borderRadius: (theme) => theme.radius.default,
                                border: "1px solid",
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                                [`& .${thumbnailAvatarClasses.image}`]: {
                                    backgroundColor: (theme) =>
                                        theme.palette.surface.container,
                                },
                            }}
                        />

                        <CompareArrowsOutline size="xs" color="disabled" />

                        <ThumbnailAvatar
                            src={
                                "https://biggeo.blob.core.windows.net/media/sf.png"
                            }
                            square
                            size="md"
                            sx={{
                                [`& .${thumbnailAvatarClasses.image}`]: {
                                    backgroundColor: (theme) =>
                                        theme.palette.surface.container,
                                },
                                borderRadius: (theme) => theme.radius.default,
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                            }}
                        />
                    </Grid>

                    <Grid item sx={{ textAlign: "center" }}>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            textColor="background.onMain"
                            sx={{
                                display: "inline",
                            }}
                        >
                            Export Datasets to Snowflake
                        </Typography>
                    </Grid>

                    <Grid item sx={{ textAlign: "center" }}>
                        <Typography
                            variant="body3"
                            fontWeight="regular"
                            textColor="background.onMain"
                        >
                            These datasets will be exported to your Snowflake
                            Datascape native app CORE folder.
                        </Typography>
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    );
};
