import {
    Box,
    Button,
    Chip,
    ColorPicker,
    GhostTextField,
    Grid,
    IconButton,
    Popper,
    Stack,
    Typography,
    colorToRgbString,
} from "@biggeo/bg-ui/lab";
import {
    AddOutline,
    DeleteOutline,
    ExpandMoreOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useEffect, useState } from "react";
import { ColorSwatchOption, ColorSwatchSelector } from "./ColorSwatchSelector";

export type ClusterColorMapperProps = {
    value?: ColorSwatchOption;
    onChange?: (value: ColorSwatchOption) => void;
};

const swatchOptions: ColorSwatchOption[] = [
    {
        id: 0,
        swatch: [
            {
                range: 100,
                color: "#D7F5FF",
            },
            {
                range: 500,
                color: "#FF9432",
            },
            {
                range: 800,
                color: "#2688FA",
            },
            {
                range: 1000,
                color: "#0D3D75",
            },
        ],
    },
    {
        id: 1,
        swatch: [
            {
                range: 100,
                color: "#FDA543",
            },
            {
                range: 500,
                color: "#FB3640",
            },
            {
                range: 800,
                color: "#A90448",
            },
            {
                range: 1000,
                color: "#0B1658",
            },
        ],
    },
    {
        id: 2,
        swatch: [
            {
                range: 100,
                color: "#60F3D0",
            },
            {
                range: 500,
                color: "#4E93FC",
            },
            {
                range: 800,
                color: "#23E5E5",
            },
            {
                range: 1000,
                color: "#1163E0",
            },
        ],
    },
    {
        id: 4,
        swatch: [
            {
                range: 100,
                color: "#F39810",
            },
            {
                range: 200,
                color: "#E14616",
            },
            {
                range: 500,
                color: "#CAF6E1",
            },
            {
                range: 800,
                color: "#431B0E",
            },
            {
                range: 1000,
                color: "#3DB97D",
            },
        ],
    },
    {
        id: 5,
        swatch: [
            {
                range: 100,
                color: "#F2F1F5",
            },
            {
                range: 200,
                color: "#DDF056",
            },
            {
                range: 500,
                color: "#C5DF15",
            },
            {
                range: 800,
                color: "#53D0EC",
            },
            {
                range: 1000,
                color: "#088CAA",
            },
        ],
    },
    {
        id: 6,
        swatch: [
            {
                range: 100,
                color: "#F5F4D9",
            },
            {
                range: 200,
                color: "#DDCA6A",
            },
            {
                range: 500,
                color: "#CDA955",
            },
            {
                range: 800,
                color: "#88897A",
            },
            {
                range: 1000,
                color: "#2A2925",
            },
        ],
    },
];

export const ClusterColorMapper = ({
    value: _value,
    onChange,
}: ClusterColorMapperProps) => {
    const [value, setValue] = useState<ColorSwatchOption | undefined>(
        _value ?? swatchOptions[0]
    );
    const [colorPickerOpen, setColorPickerOpen] = useState<number | undefined>(
        undefined
    );

    useEffect(() => {
        _value && setValue(_value);
    }, [_value]);

    return (
        <Stack gap={2} width="100%">
            <Typography variant="body3" fontWeight="semibold">
                Cluster density
            </Typography>
            <Grid container gap={2} alignItems="center">
                <Grid item xs={2}>
                    <Typography
                        variant="body3"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        Fill
                    </Typography>
                </Grid>
                <Grid item xs>
                    <ColorSwatchSelector
                        variant="discrete"
                        value={value}
                        onChange={(value) => {
                            setValue(value);
                            onChange?.(value);
                        }}
                        options={swatchOptions}
                    />
                </Grid>
            </Grid>

            {value?.swatch.map(({ range, color }, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <Grid key={index} container gap={2} alignItems="center">
                    <Grid item xs={2}>
                        <GhostTextField
                            outlined
                            value={`${range}`}
                            onChange={(_, range) => {
                                const newValue: ColorSwatchOption = {
                                    id: "custom",
                                    swatch:
                                        value?.swatch.map((swatch, i) =>
                                            i === index
                                                ? {
                                                      ...swatch,
                                                      range: range
                                                          ? Number.parseInt(
                                                                range
                                                            )
                                                          : 0,
                                                  }
                                                : swatch
                                        ) ?? [],
                                };
                                setValue(newValue);
                                onChange?.(newValue);
                            }}
                            onBlur={() => {
                                const sortedValue: ColorSwatchOption = {
                                    id: "custom",
                                    swatch: value?.swatch.sort(
                                        (a, b) => a.range - b.range
                                    ),
                                };
                                setValue(sortedValue);
                                onChange?.(sortedValue);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs>
                        <Popper
                            open={colorPickerOpen === index}
                            onOpenChange={(open) =>
                                setColorPickerOpen(open ? index : undefined)
                            }
                            sx={{ width: 75, padding: 2 }}
                            content={
                                <ColorPicker
                                    initialColor={color}
                                    onApply={(color) => {
                                        setValue((prev) => ({
                                            id: "custom",
                                            swatch:
                                                prev?.swatch.map((swatch, i) =>
                                                    i === index
                                                        ? {
                                                              ...swatch,
                                                              color: colorToRgbString(
                                                                  color.rgb
                                                              ),
                                                          }
                                                        : swatch
                                                ) ?? [],
                                        }));
                                        setColorPickerOpen(undefined);
                                    }}
                                />
                            }
                        >
                            <Chip
                                sx={{
                                    width: "100%",
                                }}
                                slotProps={{
                                    label: {
                                        sx: {
                                            flexGrow: 1,
                                            textAlign: "left",
                                        },
                                    },
                                }}
                                startNode={
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: 4,
                                            height: 4,
                                            borderRadius: (theme) =>
                                                theme.radius.default,
                                            background: color,
                                            "&::before": {
                                                content: '""',
                                                zIndex: -1,
                                                borderRadius: "inherit",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: " 100%",
                                                height: "100%",
                                                backgroundImage:
                                                    "linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)",
                                                backgroundSize: "20px 20px",
                                                backgroundPosition:
                                                    "0 0, 0 10px, 10px -10px, -10px 0px",
                                            },
                                        }}
                                    />
                                }
                                endNode={
                                    <ExpandMoreOutline
                                        sx={{
                                            transform:
                                                colorPickerOpen === index
                                                    ? "rotate(180deg)"
                                                    : "rotate(0deg)",
                                            transition: "transform 0.2s",
                                        }}
                                    />
                                }
                            >
                                {color}
                            </Chip>
                        </Popper>
                    </Grid>
                    <Grid item>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                const newValue: ColorSwatchOption = {
                                    id: "custom",
                                    swatch: [
                                        ...(value?.swatch ?? []).filter(
                                            (_, i) => i !== index
                                        ),
                                    ],
                                };
                                setValue(newValue);
                                onChange?.(newValue);
                            }}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Button
                variant="ghost"
                startNode={<AddOutline />}
                onClick={() => {
                    const newValue: ColorSwatchOption = {
                        id: "custom",
                        swatch: [
                            ...(value?.swatch ?? []),
                            {
                                range: 0,
                                color: "#000000",
                            },
                        ],
                    };
                    setValue(newValue);
                    onChange?.(newValue);
                }}
            >
                Add Range
            </Button>
        </Stack>
    );
};
