import { SavedPolygon } from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import Feature from "ol/Feature";
import Map from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { transformExtent } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import { useEffect } from "react";
import { closePolygon } from "../../../utils/utils";
import { FunctionType } from "../../utils/utils";
import { getLayerGroup } from "../utils/utils";

export const useSavedPolygon = ({
    functionType,
    map,
    savedPolygon,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<Map | null>;
    savedPolygon?: SavedPolygon;
}) => {
    const format = new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
    });
    useEffect(() => {
        // Set visibility
        if (map.current) {
            const getLayer = getLayerGroup(map.current);

            const pointVisibility =
                functionType === FunctionType.savedPolygon ? true : false;

            const layer =
                getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                    "saved-polygons"
                );

            layer?.setVisible(pointVisibility);
        }
    }, [functionType, savedPolygon]);

    const onPolygonChange = (localSavedPolygon?: SavedPolygon) => {
        const current = map.current;
        if (current) {
            const getLayer = getLayerGroup(current);
            const layer =
                getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                    "saved-polygons"
                );
            if (localSavedPolygon) {
                const points = localSavedPolygon.polygon.outer.points.map(
                    (p) => {
                        return [p.longitude, p.latitude];
                    }
                );

                const poly = turf.polygon([closePolygon(points)], {
                    label: localSavedPolygon.name,
                    color: localSavedPolygon.color || "blue",
                });

                const polyData: any = {
                    type: "FeatureCollection",
                    features: [poly],
                } as const;

                const source = new VectorSource({
                    features: format.readFeatures(polyData),
                }) as VectorSource<Feature<Geometry>>;

                layer?.setSource(source);

                const bbox = turf.bbox(poly);

                const transformedExtent = transformExtent(
                    bbox,
                    "EPSG:4326",
                    "EPSG:3857"
                );

                const view = current.getView();
                view.fit(transformedExtent, {
                    padding: [200, 200, 200, 200],
                    duration: 1000, // Duration of the animation in milliseconds
                });
            } else {
                layer?.getSource()?.clear();
            }
        }
    };

    useEffect(() => {
        onPolygonChange(savedPolygon);
    }, [savedPolygon]);

    const onStyleLoad = (map: Map) => {
        const layer = new VectorLayer({
            source: new VectorSource(),
            properties: {
                id: `saved-polygons`,
            },
            visible: false,
            style: new Style({
                fill: new Fill({
                    color: `rgba(0, 0, 0, 0.5)`,
                }),
                stroke: new Stroke({
                    color: "black",
                    width: 3,
                }),
            }),
        });
        map.addLayer(layer);
    };
    return { onStyleLoad };
};
