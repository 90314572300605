import {
    MapTemplateDatasetExtended,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyState } from "@biggeo/bg-ui/lab";
import { TableRowsOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { isAppRunningOnSF } from "../../common/redux/hooks.ts";
import { DatasetPointShape } from "../../components/DatapointShape/types.ts";
import { MapLayoutLeftContent } from "../views/MapLayoutLeftContent.tsx";

import { LoadingBar } from "@biggeo/bg-ui/lab";
import isEqual from "lodash/isEqual";
import { AddDataSetSubMenuLayout } from "../../layouts/AddDatasetSubMenuLayout.tsx";
import { Routes } from "../../navigation/redux/model.ts";
import {
    SetDatasetContextType,
    getDefaultDatasetConfiguration,
} from "../mapbox/context/context-utils";
import { useMapTemplateDatasets } from "../mapbox/context/hooks.ts";
import { useMapData } from "../redux/hooks.ts";

type MapLayoutLeftContentContainerProps = React.PropsWithChildren<
    {
        readonly map: React.MutableRefObject<mapboxgl.Map | null>;
        readonly mapTemplateId: number;
        readonly datasetShape?: readonly DatasetPointShape[];
        readonly setDatasetShape?: (datasetShape: DatasetPointShape) => void;
        readonly recentResponse?: SubscriptionResponse;
        readonly isFromMarketplace?: boolean;
        readonly onViewTable: (dataSourceId: string, state: boolean) => void;
        readonly isLoaded: boolean;
        readonly isSavedViewPage: boolean;
    } & SetDatasetContextType
>;

export const MapLayoutLeftContentContainer = ({
    mapTemplateId,
    isFromMarketplace,
    map,
    onViewTable,
    isLoaded,
    addDatasets,
    updateDataset,
    isSavedViewPage,
    reorderDatasets,
}: MapLayoutLeftContentContainerProps) => {
    const toPage = useNavigate();
    const isRunningOnSF = isAppRunningOnSF();
    const savedViewData = useMapData();

    const [localDataList, setLocalDataList] = useState<string[]>([]);

    const { datasets: filteredDatasets, loading } = useMapTemplateDatasets(
        {
            fkMapTemplateId: mapTemplateId,
            mapUse: true,
            isPreview: false,
        },
        { skip: !!isSavedViewPage }
    );

    const savedViewLoading =
        !!isSavedViewPage &&
        !isEqual(filteredDatasets.length, savedViewData.datasets?.length);

    const setUpdatedDatasets = async (
        datasetsToAdd: MapTemplateDatasetExtended[]
    ) => {
        addDatasets(
            pipe(
                datasetsToAdd,
                A.map((d) => ({
                    dataSource: d.dataSource,
                    mapTemplateDataset: d.mapTemplateDataset,
                    isVisible: false,
                    isSelected: false,
                    isGettingStyled: false,
                    isTableViewed: false,
                    isLegendOpen: false,
                    configuration:
                        getDefaultDatasetConfiguration(isRunningOnSF),
                }))
            )
        );
    };

    const toDataManagementPage = () => {
        toPage(`${Routes.data}/map-template/${mapTemplateId}`);
    };

    if (loading || savedViewLoading) {
        return <LoadingBar />;
    }

    return pipe(
        filteredDatasets,
        A.filter(
            (d) =>
                isEqual(d.dataSource.isPreview, false) ||
                isEqual(d.dataSource.isPreview, null)
        ),
        O.fromPredicate((datasets) => !isEmpty(datasets)),
        O.fold(
            () => (
                <EmptyState
                    icon={<TableRowsOutline />}
                    title={"No Datasets Enabled"}
                    subtitle={"Enable datasets to use on this map"}
                    border={false}
                    onClick={toDataManagementPage}
                    buttonWithMenu={
                        <AddDataSetSubMenuLayout
                            mapTemplateId={mapTemplateId}
                            existingDatasets={pipe(
                                filteredDatasets,
                                A.map((d) => d.dataSource)
                            )}
                            setRemote={setUpdatedDatasets}
                            localDataList={localDataList}
                            setLocalDataList={setLocalDataList}
                        />
                    }
                />
            ),
            (data) => (
                <MapLayoutLeftContent
                    datasets={data}
                    map={map}
                    isLoaded={isLoaded}
                    isRunningOnSF={isRunningOnSF}
                    mapTemplateId={mapTemplateId}
                    isFromMarketplace={isFromMarketplace}
                    setRemote={setUpdatedDatasets}
                    localDataList={localDataList}
                    onViewTable={onViewTable}
                    setLocalDataList={setLocalDataList}
                    updateDataset={updateDataset}
                    isSavedViewPage={isSavedViewPage}
                    reorderDatasets={reorderDatasets}
                />
            )
        )
    );
};
