import { SvgIcon, SvgIconProps } from "@biggeo/bg-ui";
const PolylineOutline = (props: SvgIconProps) => (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
            d="M13.2083 18.625V16.6218L7.21465 13.625H3.20825V9.04167H6.6794L9.04159 6.3301V2.375H13.6249V6.95829H10.1537L7.79154 9.66985V12.5032L13.2083 15.2115V14.0417H17.7915V18.625L13.2083 18.625ZM10.2915 5.70831H12.3749V3.62494H10.2915V5.70831ZM4.45821 12.375H6.54159V10.2916H4.45821V12.375ZM14.4582 17.375H16.5416V15.2916H14.4582V17.375Z"
            fill="currentColor"
        />
    </SvgIcon>
);
export default PolylineOutline;
