import {
    Box,
    Button,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Stack,
    Tooltip,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import { useState } from "react";
import { AiChatHeader } from "./AiChatHeader";
import { AiChatMessage, AiChatType } from "./AiChatMessage";

export interface AiChatWindowProps {
    readonly header?: {
        readonly text: string;
        readonly icon: React.ReactNode;
    };
    readonly chat: AiChatType[];
    readonly close: () => void;
    readonly editMessage: (messageId: string) => void;
    readonly validateAiResponse: (messageId: string) => void;
    readonly saveView: () => void;
    readonly undo: () => void;
}

export const AiChatWindow = ({
    header,
    chat,
    close,
    editMessage,
    validateAiResponse,
    saveView,
    undo,
}: AiChatWindowProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const lastUserPromptIndex = pipe(
        chat,
        A.filter((c) => c.type === "user"),
        last,
        (lastUserPrompt) =>
            lastUserPrompt ? chat.indexOf(lastUserPrompt) : undefined
    );

    return (
        <FlexScrollAreaContainer
            sx={{
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                width: 62,
                height: collapsed ? 24.5 : 80,
            }}
        >
            <Stack
                gap={4}
                sx={{
                    padding: 4,
                    paddingBottom: collapsed ? 2 : undefined,
                }}
            >
                <AiChatHeader
                    header={header}
                    close={close}
                    collapsed={collapsed}
                    collapse={() => setCollapsed(!collapsed)}
                />
            </Stack>
            {!collapsed && (
                <Box width="100%" sx={{ paddingX: 4 }}>
                    <Divider />
                </Box>
            )}
            <FlexScrollArea
                sx={{
                    flexDirection: "column-reverse",
                    overflowAnchor: "auto",
                }}
            >
                <Stack
                    gap={2}
                    sx={{
                        padding: 4,
                    }}
                >
                    {pipe(
                        chat,
                        A.mapWithIndex((index, c) => (
                            <AiChatMessage
                                key={c.messageId}
                                {...c}
                                isLast={
                                    isEqual(index, lastUserPromptIndex) &&
                                    c.type === "user"
                                }
                                editMessage={editMessage}
                                validateAiResponse={validateAiResponse}
                            />
                        ))
                    )}
                </Stack>
            </FlexScrollArea>
            <Stack
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    padding: 4,
                }}
            >
                {/* <IconButton variant="minimal" radius="full" onClick={undo}>
                    <UndoOutline size="xs" />
                </IconButton> */}
                <Tooltip title="Coming soon">
                    <div>
                        <Button
                            variant="minimal"
                            color="primary"
                            disabled
                            onClick={saveView}
                        >
                            Save View
                        </Button>
                    </div>
                </Tooltip>
            </Stack>
        </FlexScrollAreaContainer>
    );
};
