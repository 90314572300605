import {
    Button,
    IconAvatar,
    Stack,
    Typography,
    UserAvatar,
} from "@biggeo/bg-ui/lab";
import { Ai } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { useState } from "react";

export type AiChatType = {
    readonly type: "ai" | "user";
    readonly messageId: string;
    readonly avatar?: string;
    readonly sender: string;
    readonly message: string;
    readonly validated?: boolean; // True means the user already clicked on `Looks Good`.
    readonly isLast?: boolean;
};

export interface AiChatMessageProps extends AiChatType {
    readonly editMessage: (messageId: string) => void;
    readonly validateAiResponse: (messageId: string) => void;
}

export const AiChatMessage = ({
    type,
    isLast,
    messageId,
    avatar,
    sender,
    message,
    validated,

    editMessage,
    validateAiResponse,
}: AiChatMessageProps) => {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <Stack gap={1}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
                {type === "user" ? (
                    <UserAvatar src={avatar} alt={sender} size="xs" />
                ) : (
                    <IconAvatar size="xs" color="primary">
                        <Ai size="xs" color="primary" />
                    </IconAvatar>
                )}
                <Typography variant="body3" fontWeight="bold">
                    {sender}
                </Typography>
            </Stack>
            <Stack
                onMouseEnter={() => setHover(!hover)}
                onMouseLeave={() => setHover(false)}
            >
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="body3">{message}</Typography>
                    {/* {hover && isLast && (
                                    <IconButton
                                        variant="minimal"
                                        density="dense"
                                        radius="full"
                                        onClick={() => editMessage(messageId)}
                                        {...slotProps?.editButton}
                                    >
                                        <EditOutline size="xs" />
                                    </IconButton>
                                )} */}
                </Stack>
                {isEqual(validated, false) && (
                    <Stack
                        sx={{
                            paddingTop: 3,
                        }}
                    >
                        <Button
                            variant="minimal"
                            color="primary"
                            onClick={() => validateAiResponse(messageId)}
                            slotProps={{
                                label: {
                                    fontWeight: "semibold",
                                },
                            }}
                        >
                            Looks Good
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
