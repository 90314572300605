import { createContext, useContext } from "react";
import { Location } from "react-router-dom";

export type NavigationContextType = {
    readonly location?: Location;
    readonly dispatch?: React.Dispatch<NavigationAction>;
};

export const NavigationContext = createContext<NavigationContextType>({
    location: undefined,
    dispatch: undefined,
});

export type NavigationContextState = {
    readonly location?: Location;
};

export const useNavigation = (): NavigationContextType =>
    useContext(NavigationContext);

export type NavigationAction = {
    readonly type: "SET_LOCATION";
    readonly values: Partial<NavigationContextState>;
};

export const NavigationReducer = (
    state: NavigationContextState,
    action: NavigationAction
): NavigationContextState => {
    switch (action.type) {
        case "SET_LOCATION": {
            return {
                ...state,
                ...action.values,
            };
        }
        default:
            return state;
    }
};
