import { Box } from "@biggeo/bg-ui/lab";

export type MapTooltipProps = {
    tooltipId: string;
    children?: React.ReactNode;
};

export const MapTooltip = ({ tooltipId, children }: MapTooltipProps) => (
    <Box
        id={tooltipId}
        sx={{
            position: "relative",
            width: "fit-content",
            display: "none",
            background: (theme) => theme.palette.background.main,
            color: (theme) => theme.palette.background.onMain,
            boxShadow: (theme) => theme.shadows.sm,
            borderRadius: (theme) => theme.radius.default,
            border: 1,
            borderColor: (theme) => theme.palette.stroke[100],
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-12px",
                left: "50%",
                transform: "translateX(-50%)",
                width: 0,
                height: 0,
                borderLeft: "12px solid transparent",
                borderRight: "12px solid transparent",
                borderTop: (theme) =>
                    `12px solid ${theme.palette.background.main}`,
            },

            /**
             * Fixes the marker offset positioning issue
             * https://github.com/mapbox/mapbox-gl-js/issues/10749#issuecomment-1739940929
             */
            "&.mapboxgl-marker": {
                position: "absolute",
                top: 0,
                left: 0,
                willChange: "transform",
                opacity: 1,
                transition: "opacity .2s",
            },
        }}
    >
        {children}
    </Box>
);
