import {
    useFetchDataSourcesLazyQuery,
    useRemoveDataSetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { ErrorPage } from "../../common/components/ErrorPage";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { useMap } from "../../map/mapbox/context";
import {
    SetDatasetContextType,
    getDefaultDatasetConfiguration,
} from "../../map/mapbox/context/context-utils";
import { ExploreDatasetsView } from "../views/ExploreDatasetsView";

export const ExploreDatasetsViewContainer = ({
    exploreDataset,
    onClickPreviewInfo,
    refetchDatasourcePreview,
    setRefetchDatasourcePreview,
    updateDataset,
    addDatasets,
    isSavedViewPage,
    reorderDatasets,
}: {
    readonly exploreDataset: () => void;
    readonly onClickPreviewInfo: (
        input: E.Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly setRefetchDatasourcePreview: (value: boolean) => void;
    readonly refetchDatasourcePreview: boolean;
    readonly isSavedViewPage: boolean;
} & Pick<
    SetDatasetContextType,
    "updateDataset" | "addDatasets" | "reorderDatasets"
>) => {
    const isRunningOnSF = isAppRunningOnSF();
    const { datasets, map, isLoaded } = useMap();
    const { executeQuery, remote } = useFetchDataSourcesLazyQuery();
    const { executeMutation: removeDataset } = useRemoveDataSetMutation();

    const onRemove = (id: string) => {
        const found = datasets.find((c) => c.dataSource.id === id);
        if (found)
            removeDataset({
                variables: { collectionName: found.dataSource.collectionName },
            });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (refetchDatasourcePreview) {
            executeQuery({
                variables: {
                    input: {
                        isPreview: true,
                    },
                },
                onCompleted: (d) => {
                    if (!isSavedViewPage) {
                        setRefetchDatasourcePreview(false);

                        datasets.map((dataset) => {
                            const found = d.fetchDataSources.dataSources.find(
                                (i) => i.id === dataset.dataSource.id
                            );

                            if (found) {
                                updateDataset({
                                    dataSourceId: found.id,
                                    dataset: {
                                        dataSource: found,
                                        isVisible:
                                            found.compute === true &&
                                            found.isConnected === true,
                                        isSelected:
                                            found.compute === true &&
                                            found.isConnected === true,
                                        isGettingStyled: false,
                                        isLegendOpen: false,
                                        isTableViewed: false,
                                        configuration:
                                            getDefaultDatasetConfiguration(
                                                isRunningOnSF
                                            ),
                                    },
                                });
                            }
                        });

                        d.fetchDataSources.dataSources.map((dataSource) => {
                            addDatasets({
                                dataSource,
                                mapTemplateDataset: undefined,
                                isVisible:
                                    dataSource.compute === true &&
                                    dataSource.isConnected === true,
                                isSelected:
                                    dataSource.compute === true &&
                                    dataSource.isConnected === true,
                                isGettingStyled: false,
                                isLegendOpen: false,
                                isTableViewed: false,
                                configuration:
                                    getDefaultDatasetConfiguration(
                                        isRunningOnSF
                                    ),
                            });
                        });
                    }
                },
            });
        }
    }, [refetchDatasourcePreview, isRunningOnSF]);

    return match(remote, {
        _: () => <LoadingBar />,
        Success: () => {
            return (
                // TODO: add onEdit, onView, and onRemove function
                <ExploreDatasetsView
                    datasets={pipe(
                        datasets,
                        A.filter((d) => isEqual(d.dataSource.isPreview, true))
                    )}
                    onClickPreviewInfo={onClickPreviewInfo}
                    exploreDataset={exploreDataset}
                    onRemove={onRemove}
                    updateDataset={updateDataset}
                    reorderDatasets={reorderDatasets}
                    map={map}
                    isLoaded={isLoaded}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};
