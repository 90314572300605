import {
    DataSource,
    InputSwitchCompute,
    useFetchDataSourceQuery,
    useSwitchComputeMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    CircularLoading,
    Grid,
    IconButton,
    MemoizedField,
    Severity,
    Typography,
} from "@biggeo/bg-ui/lab";
import { PlayArrowOutline, StopCircle } from "@biggeo/bg-ui/lab/icons";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../toaster/containers/redux/model";

export interface ComputeViewProps {
    readonly id: string;
    readonly collectionName: string;
    readonly setLocalDataSources?: React.Dispatch<
        React.SetStateAction<readonly DataSource[]>
    >;
    readonly currentComputedId?: string;
    readonly setCurrentComputedId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
}

export const ComputeView = ({
    id,
    collectionName,
    setLocalDataSources,
    currentComputedId,
    setCurrentComputedId,
}: ComputeViewProps) => {
    const dispatch = useDispatch();

    const {
        queryReturn: { data: dataSource, loading: dataSourceLoading, refetch },
    } = useFetchDataSourceQuery({ variables: { id } });

    const {
        executeMutation: switchCompute,
        mutationReturn: [_c, { loading }],
    } = useSwitchComputeMutation();

    const handleSwitchCompute = (input: InputSwitchCompute) => {
        // const location = `${collectionStorageDirectory}/${input.collectionName}.cereal`;

        setCurrentComputedId(input.id);

        switchCompute({
            variables: {
                input: { ...input },
            },
            onCompleted: (data) => {
                refetch();
                const compute = Boolean(data.switchCompute.compute);
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.success,
                        title: `Compute ${compute ? "enabled" : "disabled"} successfully`,
                        autoHideDuration: 5000,
                    })
                );

                setLocalDataSources?.((prev) =>
                    prev.map((c) =>
                        c.id === data.switchCompute.id
                            ? { ...c, ...data.switchCompute }
                            : c
                    )
                );
                setCurrentComputedId(undefined);
            },
            onError: (e) => {
                const errorMsg = JSON.parse(e.message) as {
                    code: string;
                    details: string;
                };
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.error,
                        title: errorMsg.details,
                        autoHideDuration: 5000,
                    })
                );
                setCurrentComputedId(undefined);
            },
        });
    };

    const isLoading = loading || dataSourceLoading;
    const collectionLoading = Boolean(dataSource?.fetchDataSource.isLoading);
    const isOn = Boolean(dataSource?.fetchDataSource.compute);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const intervalId = setInterval(() => {
            collectionLoading && refetch();
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [collectionLoading]);

    return (
        <MemoizedField disable={!!currentComputedId}>
            <Grid container alignItems="center" gap={1}>
                {isLoading ? (
                    <IconButton
                        rounded
                        variant="tonal"
                        density="dense"
                        disabled={isLoading}
                    >
                        <CircularLoading size="md" />
                    </IconButton>
                ) : dataSource?.fetchDataSource.compute ? (
                    <>
                        <IconButton
                            variant="tonal"
                            density="dense"
                            disabled={!!currentComputedId}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSwitchCompute({
                                    collectionName,
                                    compute: false,
                                    id,
                                });
                            }}
                            sx={{
                                borderRadius: (theme) => theme.radius.full,
                            }}
                        >
                            <StopCircle
                                color={loading ? "disabled" : "primary"}
                            />
                        </IconButton>
                        <Typography variant="body3" fontWeight="semibold">
                            On
                        </Typography>
                    </>
                ) : (
                    <>
                        <IconButton
                            variant="tonal"
                            density="dense"
                            disabled={!!currentComputedId}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSwitchCompute({
                                    compute: true,
                                    collectionName,
                                    id,
                                });
                            }}
                            sx={{
                                borderRadius: (theme) => theme.radius.full,
                            }}
                        >
                            <PlayArrowOutline
                                color={!isOn ? "disabled" : "primary"}
                                invertColors
                                colorSet="container"
                            />
                        </IconButton>
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            textColor={
                                !isOn ? "disabled.onContainer" : undefined
                            }
                        >
                            Off
                        </Typography>
                    </>
                )}
            </Grid>
        </MemoizedField>
    );
};
