import { Box, Button, Divider, Stack, TextField } from "@biggeo/bg-ui/lab";
import isEqual from "lodash/isEqual";
import { useState } from "react";

interface IMarketplaceChipPanel {
    readonly item: {
        label: string;
        field: string;
    };
    readonly updateFilter: ({
        field,
        value,
    }: {
        field: string;
        value: string | number | boolean;
    }) => void;
    readonly handleApplyFilter: (field: string) => void;
    readonly defaultValue: string | number | boolean;
    readonly isSubChip?: boolean;
}

const MarketplaceChipPanel = ({
    item,
    updateFilter,
    handleApplyFilter,
    defaultValue,
    isSubChip,
}: IMarketplaceChipPanel) => {
    const [currentValue, setCurrentValue] = useState<string | number | boolean>(
        ""
    );
    return (
        <Stack width={52.5}>
            <Stack
                gap={1}
                width={"100%"}
                sx={{
                    padding: 2,
                }}
            >
                <TextField
                    fullWidth
                    type={
                        isEqual(item.field, "price") ||
                        isEqual(item.field, "size")
                            ? "number"
                            : "text"
                    }
                    onChange={(e) => {
                        setCurrentValue(e?.currentTarget.value || "");
                    }}
                    defaultValue={defaultValue as string}
                />
            </Stack>
            <Divider />
            <Box
                sx={{
                    padding: 2,
                }}
            >
                <Button
                    fullWidth
                    onClick={() => {
                        handleApplyFilter(
                            isSubChip ? `${item.field}-1` : item.field
                        );
                        updateFilter({
                            field: item.field,
                            value: currentValue,
                        });
                    }}
                >
                    Apply
                </Button>
            </Box>
        </Stack>
    );
};

export default MarketplaceChipPanel;
