import { useSelector } from "react-redux";

import { RootState } from "../../redux/reducer";

export const useModal = () => {
    return useSelector((state: RootState) => state.modal);
};
export const useSelectedModal = () => {
    return useSelector((state: RootState) => state.modal.content);
};

export const useModalType = () => {
    return useSelector((state: RootState) => state.modal.modalType);
};
export const useModalDirection = () => {
    return useSelector((state: RootState) => state.modal.direction);
};
