import {
    Box,
    Button,
    OverflowingTypography,
    Stack,
    StackProps,
    useTheme,
} from "@biggeo/bg-ui/lab";
import React, { ComponentProps } from "react";

import { Link, To } from "react-router-dom";
import { useMapPopup } from "../../../toaster/containers/redux/hooks";

export const MAP_TOOLTIP_WIDTH = 114;
export const MAP_TOOLTIP_HEIGHT = 32;
export const MAP_TOOLTIP_ARROW_HEIGHT = 10;

export type MapPopupType = {
    readonly message?: string;
    readonly tooltipArrow?: boolean;
    readonly additionalMessage?: string;
    readonly button?: ComponentProps<typeof Button> & { to?: To };
} & Pick<StackProps, "sx">;

export const MapPromptPopup = React.memo(() => {
    const popup = useMapPopup();

    return <MapPopup {...popup} />;
});

export const MapPopup = React.forwardRef<HTMLDivElement, MapPopupType>(
    ({ message, additionalMessage, sx, tooltipArrow = false, button }, ref) => {
        const theme = useTheme();

        return (
            <Stack
                ref={ref}
                id="map-popup"
                alignItems="center"
                justifyContent="center"
                gap={2}
                sx={{
                    display: "none",
                    minWidth: (theme) => theme.spacing(32.75),
                    width: tooltipArrow
                        ? (theme) => theme.spacing(MAP_TOOLTIP_WIDTH / 4)
                        : undefined,
                    height: (theme) => theme.spacing(MAP_TOOLTIP_HEIGHT / 4),
                    cursor: tooltipArrow ? undefined : "crosshair",
                    textAlign: tooltipArrow ? "center" : undefined,
                    position: "absolute",
                    zIndex: 9999,
                    ...sx,
                }}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={button ? 4 : 2}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.background.main,
                        color: (theme) => theme.palette.surface.main,
                        boxShadow: (theme) => theme.shadows.md,
                        borderRadius: tooltipArrow
                            ? (theme) => theme.radius.xs3
                            : (theme) => theme.radius.xs5,
                        padding: 2,
                        ...sx,
                    }}
                >
                    <OverflowingTypography variant="body4" lineClamp={4}>
                        {additionalMessage ? (
                            <>
                                {message},{" "}
                                <span
                                    style={{
                                        color: theme.palette.error.main,
                                    }}
                                >
                                    {additionalMessage}
                                </span>
                            </>
                        ) : (
                            message
                        )}
                    </OverflowingTypography>
                    {button && (
                        <Stack
                            sx={{
                                paddingTop: 1,
                            }}
                        >
                            {button.to ? (
                                <Link to={button.to} target={"_blank"}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        {...button}
                                    />
                                </Link>
                            ) : (
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    {...button}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
                {tooltipArrow && (
                    <Box
                        sx={{
                            width: 0,
                            height: 0,
                            borderLeft: "8px solid transparent",
                            borderRight: "8px solid transparent",
                            borderTop: (theme) =>
                                `${MAP_TOOLTIP_ARROW_HEIGHT}px solid ${theme.palette.background.main}`,
                            left: 0,
                            right: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    />
                )}
            </Stack>
        );
    }
);
