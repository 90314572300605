import { IconButton, Stack } from "@biggeo/bg-ui/lab";

import { CheckCircle, CircleOutline } from "@biggeo/bg-ui/lab/icons";

export type ImageWithCheckboxProps<T> = {
    readonly id: T;
    readonly image: string;
    readonly selected: boolean;
    readonly onClick: (id: T) => void;
    readonly invertColors?: boolean;
};

export const ImageWithCheckbox = <T extends string | number | symbol>({
    id,
    image,
    selected,
    onClick,
    invertColors = false,
}: ImageWithCheckboxProps<T>) => {
    return (
        <Stack
            sx={{
                padding: 1,
                alignItems: "flex-end",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                width: (theme) => theme.spacing(28.5),
                height: (theme) => theme.spacing(16),
                borderRadius: (theme) => theme.radius.xs5,
            }}
        >
            <IconButton variant="minimal" onClick={() => onClick(id)}>
                {selected ? (
                    <CheckCircle
                        size="xs"
                        color={invertColors ? "background" : undefined}
                    />
                ) : (
                    <CircleOutline
                        size="xs"
                        color={invertColors ? "background" : undefined}
                    />
                )}
            </IconButton>
        </Stack>
    );
};
