/* eslint-disable react-refresh/only-export-components */
import { Svg } from "@biggeo/bg-ui/lab";
import { ComponentProps, FC, LazyExoticComponent, lazy } from "react";

const Airfield = lazy(() => import("../../common/icons/mapbox-icons/Airfield"));
const Airport = lazy(() => import("../../common/icons/mapbox-icons/Airport"));
const AlcoholShop = lazy(
    () => import("../../common/icons/mapbox-icons/AlcoholShop")
);
const AmericanFootball = lazy(
    () => import("../../common/icons/mapbox-icons/AmericanFootball")
);
const AmusementPark = lazy(
    () => import("../../common/icons/mapbox-icons/AmusementPark")
);
const Aquarium = lazy(() => import("../../common/icons/mapbox-icons/Aquarium"));
const Attraction = lazy(
    () => import("../../common/icons/mapbox-icons/Attraction")
);
const Bank = lazy(() => import("../../common/icons/mapbox-icons/Bank"));
const Bar = lazy(() => import("../../common/icons/mapbox-icons/Bar"));
const Baseball = lazy(() => import("../../common/icons/mapbox-icons/Baseball"));
const Basketball = lazy(
    () => import("../../common/icons/mapbox-icons/Basketball")
);
const Beach = lazy(() => import("../../common/icons/mapbox-icons/Beach"));
const Beer = lazy(() => import("../../common/icons/mapbox-icons/Beer"));
const Bicycle = lazy(() => import("../../common/icons/mapbox-icons/Bicycle"));
const BicycleShare = lazy(
    () => import("../../common/icons/mapbox-icons/BicycleShare")
);
const BowlingAlley = lazy(
    () => import("../../common/icons/mapbox-icons/BowlingAlley")
);
const Bridge = lazy(() => import("../../common/icons/mapbox-icons/Bridge"));
const Bus = lazy(() => import("../../common/icons/mapbox-icons/Bus"));
const Cafe = lazy(() => import("../../common/icons/mapbox-icons/Cafe"));
const Campsite = lazy(() => import("../../common/icons/mapbox-icons/Campsite"));
const Car = lazy(() => import("../../common/icons/mapbox-icons/Car"));
const CarRental = lazy(
    () => import("../../common/icons/mapbox-icons/CarRental")
);
const CarRepair = lazy(
    () => import("../../common/icons/mapbox-icons/CarRepair")
);
const Casino = lazy(() => import("../../common/icons/mapbox-icons/Casino"));
const Castle = lazy(() => import("../../common/icons/mapbox-icons/Castle"));
const Cemetery = lazy(() => import("../../common/icons/mapbox-icons/Cemetery"));
const ChargingStation = lazy(
    () => import("../../common/icons/mapbox-icons/ChargingStation")
);
const Cinema = lazy(() => import("../../common/icons/mapbox-icons/Cinema"));
const Circle = lazy(() => import("../../common/icons/mapbox-icons/Circle"));
const CircleStroked = lazy(
    () => import("../../common/icons/mapbox-icons/CircleStroked")
);
const ClothingStore = lazy(
    () => import("../../common/icons/mapbox-icons/ClothingStore")
);
const College = lazy(() => import("../../common/icons/mapbox-icons/College"));
const CommunicationsTower = lazy(
    () => import("../../common/icons/mapbox-icons/CommunicationsTower")
);
const Confectionery = lazy(
    () => import("../../common/icons/mapbox-icons/Confectionery")
);
const Convenience = lazy(
    () => import("../../common/icons/mapbox-icons/Convenience")
);
const Dentist = lazy(() => import("../../common/icons/mapbox-icons/Dentist"));
const Doctor = lazy(() => import("../../common/icons/mapbox-icons/Doctor"));
const DogPark = lazy(() => import("../../common/icons/mapbox-icons/DogPark"));
const DrinkingWater = lazy(
    () => import("../../common/icons/mapbox-icons/DrinkingWater")
);
const Embassy = lazy(() => import("../../common/icons/mapbox-icons/Embassy"));
const Entrance = lazy(() => import("../../common/icons/mapbox-icons/Entrance"));
const Farm = lazy(() => import("../../common/icons/mapbox-icons/Farm"));
const FastFood = lazy(() => import("../../common/icons/mapbox-icons/FastFood"));
const Ferry = lazy(() => import("../../common/icons/mapbox-icons/Ferry"));
const FireStation = lazy(
    () => import("../../common/icons/mapbox-icons/FireStation")
);
const FitnessCentre = lazy(
    () => import("../../common/icons/mapbox-icons/FitnessCentre")
);
const Fuel = lazy(() => import("../../common/icons/mapbox-icons/Fuel"));
const Furniture = lazy(
    () => import("../../common/icons/mapbox-icons/Furniture")
);
const Garden = lazy(() => import("../../common/icons/mapbox-icons/Garden"));
const Gate = lazy(() => import("../../common/icons/mapbox-icons/Gate"));
const Globe = lazy(() => import("../../common/icons/mapbox-icons/Globe"));
const Golf = lazy(() => import("../../common/icons/mapbox-icons/Golf"));
const Grocery = lazy(() => import("../../common/icons/mapbox-icons/Grocery"));
const Harbor = lazy(() => import("../../common/icons/mapbox-icons/Harbor"));
const Hardware = lazy(() => import("../../common/icons/mapbox-icons/Hardware"));
const Heliport = lazy(() => import("../../common/icons/mapbox-icons/Heliport"));
const HighwayRestArea = lazy(
    () => import("../../common/icons/mapbox-icons/HighwayRestArea")
);
const Historic = lazy(() => import("../../common/icons/mapbox-icons/Historic"));
const HorseRiding = lazy(
    () => import("../../common/icons/mapbox-icons/HorseRiding")
);
const Hospital = lazy(() => import("../../common/icons/mapbox-icons/Hospital"));
const HotSpring = lazy(
    () => import("../../common/icons/mapbox-icons/HotSpring")
);
const IceCream = lazy(() => import("../../common/icons/mapbox-icons/IceCream"));
const Industry = lazy(() => import("../../common/icons/mapbox-icons/Industry"));
const Information = lazy(
    () => import("../../common/icons/mapbox-icons/Information")
);
const JewelryStore = lazy(
    () => import("../../common/icons/mapbox-icons/JewelryStore")
);
const Landmark = lazy(() => import("../../common/icons/mapbox-icons/Landmark"));
const Laundry = lazy(() => import("../../common/icons/mapbox-icons/Laundry"));
const Library = lazy(() => import("../../common/icons/mapbox-icons/Library"));
const LiftGate = lazy(() => import("../../common/icons/mapbox-icons/LiftGate"));
const Lighthouse = lazy(
    () => import("../../common/icons/mapbox-icons/Lighthouse")
);
const Lodging = lazy(() => import("../../common/icons/mapbox-icons/Lodging"));
const Marker = lazy(() => import("../../common/icons/mapbox-icons/Marker"));
const MarkerStroked = lazy(
    () => import("../../common/icons/mapbox-icons/MarkerStroked")
);
const MobilePhone = lazy(
    () => import("../../common/icons/mapbox-icons/MobilePhone")
);
const Monument = lazy(() => import("../../common/icons/mapbox-icons/Monument"));
const Mountain = lazy(() => import("../../common/icons/mapbox-icons/Mountain"));
const Museum = lazy(() => import("../../common/icons/mapbox-icons/Museum"));
const Music = lazy(() => import("../../common/icons/mapbox-icons/Music"));
const ObservationTower = lazy(
    () => import("../../common/icons/mapbox-icons/ObservationTower")
);
const Optician = lazy(() => import("../../common/icons/mapbox-icons/Optician"));
const Park = lazy(() => import("../../common/icons/mapbox-icons/Park"));
const Parking = lazy(() => import("../../common/icons/mapbox-icons/Parking"));
const ParkingGarage = lazy(
    () => import("../../common/icons/mapbox-icons/ParkingGarage")
);
const Pharmacy = lazy(() => import("../../common/icons/mapbox-icons/Pharmacy"));
const PicnicSite = lazy(
    () => import("../../common/icons/mapbox-icons/PicnicSite")
);
const Pitch = lazy(() => import("../../common/icons/mapbox-icons/Pitch"));
const PlaceOfWorship = lazy(
    () => import("../../common/icons/mapbox-icons/PlaceOfWorship")
);
const Playground = lazy(
    () => import("../../common/icons/mapbox-icons/Playground")
);
const Police = lazy(() => import("../../common/icons/mapbox-icons/Police"));
const Post = lazy(() => import("../../common/icons/mapbox-icons/Post"));
const Prison = lazy(() => import("../../common/icons/mapbox-icons/Prison"));
const Racetrack = lazy(
    () => import("../../common/icons/mapbox-icons/Racetrack")
);
const RacetrackBoat = lazy(
    () => import("../../common/icons/mapbox-icons/RacetrackBoat")
);
const RacetrackCycling = lazy(
    () => import("../../common/icons/mapbox-icons/RacetrackCycling")
);
const RacetrackHorse = lazy(
    () => import("../../common/icons/mapbox-icons/RacetrackHorse")
);
const Rail = lazy(() => import("../../common/icons/mapbox-icons/Rail"));
const RailLight = lazy(
    () => import("../../common/icons/mapbox-icons/RailLight")
);
const RailMetro = lazy(
    () => import("../../common/icons/mapbox-icons/RailMetro")
);
const RangerStation = lazy(
    () => import("../../common/icons/mapbox-icons/RangerStation")
);
const ReligiousBuddhist = lazy(
    () => import("../../common/icons/mapbox-icons/ReligiousBuddhist")
);
const ReligiousChristian = lazy(
    () => import("../../common/icons/mapbox-icons/ReligiousChristian")
);
const ReligiousJewish = lazy(
    () => import("../../common/icons/mapbox-icons/ReligiousJewish")
);
const ReligiousMuslim = lazy(
    () => import("../../common/icons/mapbox-icons/ReligiousMuslim")
);
const ReligiousShinto = lazy(
    () => import("../../common/icons/mapbox-icons/ReligiousShinto")
);
const ResidentialCommunity = lazy(
    () => import("../../common/icons/mapbox-icons/ResidentialCommunity")
);
const Restaurant = lazy(
    () => import("../../common/icons/mapbox-icons/Restaurant")
);
const RestaurantBbq = lazy(
    () => import("../../common/icons/mapbox-icons/RestaurantBbq")
);
const RestaurantNoodle = lazy(
    () => import("../../common/icons/mapbox-icons/RestaurantNoodle")
);
const RestaurantPizza = lazy(
    () => import("../../common/icons/mapbox-icons/RestaurantPizza")
);
const RestaurantSeafood = lazy(
    () => import("../../common/icons/mapbox-icons/RestaurantSeafood")
);
const RestaurantSushi = lazy(
    () => import("../../common/icons/mapbox-icons/RestaurantSushi")
);
const Rocket = lazy(() => import("../../common/icons/mapbox-icons/Rocket"));
const School = lazy(() => import("../../common/icons/mapbox-icons/School"));
const Shoe = lazy(() => import("../../common/icons/mapbox-icons/Shoe"));
const Shop = lazy(() => import("../../common/icons/mapbox-icons/Shop"));
const Skateboard = lazy(
    () => import("../../common/icons/mapbox-icons/Skateboard")
);
const Skiing = lazy(() => import("../../common/icons/mapbox-icons/Skiing"));
const Slipway = lazy(() => import("../../common/icons/mapbox-icons/Slipway"));
const Stadium = lazy(() => import("../../common/icons/mapbox-icons/Stadium"));
const Star = lazy(() => import("../../common/icons/mapbox-icons/Star"));
const Suitcase = lazy(() => import("../../common/icons/mapbox-icons/Suitcase"));
const Swimming = lazy(() => import("../../common/icons/mapbox-icons/Swimming"));
const TableTennis = lazy(
    () => import("../../common/icons/mapbox-icons/TableTennis")
);
const Tennis = lazy(() => import("../../common/icons/mapbox-icons/Tennis"));
const Theatre = lazy(() => import("../../common/icons/mapbox-icons/Theatre"));
const Toilet = lazy(() => import("../../common/icons/mapbox-icons/Toilet"));
const Toll = lazy(() => import("../../common/icons/mapbox-icons/Toll"));
const Town = lazy(() => import("../../common/icons/mapbox-icons/Town"));
const TownHall = lazy(() => import("../../common/icons/mapbox-icons/TownHall"));
const Triangle = lazy(() => import("../../common/icons/mapbox-icons/Triangle"));
const TriangleStroked = lazy(
    () => import("../../common/icons/mapbox-icons/TriangleStroked")
);
const Tunnel = lazy(() => import("../../common/icons/mapbox-icons/Tunnel"));
const Veterinary = lazy(
    () => import("../../common/icons/mapbox-icons/Veterinary")
);
const Viewpoint = lazy(
    () => import("../../common/icons/mapbox-icons/Viewpoint")
);
const Volcano = lazy(() => import("../../common/icons/mapbox-icons/Volcano"));
const Volleyball = lazy(
    () => import("../../common/icons/mapbox-icons/Volleyball")
);
const Watch = lazy(() => import("../../common/icons/mapbox-icons/Watch"));
const Waterfall = lazy(
    () => import("../../common/icons/mapbox-icons/Waterfall")
);
const Watermill = lazy(
    () => import("../../common/icons/mapbox-icons/Watermill")
);
const Windmill = lazy(() => import("../../common/icons/mapbox-icons/Windmill"));
const Zoo = lazy(() => import("../../common/icons/mapbox-icons/Zoo"));

export const mapboxIcons: Record<
    string,
    LazyExoticComponent<FC<ComponentProps<typeof Svg>>>
> = {
    airfield: Airfield,
    airport: Airport,
    "alcohol-shop": AlcoholShop,
    "american-football": AmericanFootball,
    "amusement-park": AmusementPark,
    aquarium: Aquarium,
    attraction: Attraction,
    bank: Bank,
    bar: Bar,
    baseball: Baseball,
    basketball: Basketball,
    beach: Beach,
    beer: Beer,
    "bicycle-share": BicycleShare,
    bicycle: Bicycle,
    "bowling-alley": BowlingAlley,
    bridge: Bridge,
    bus: Bus,
    cafe: Cafe,
    campsite: Campsite,
    "car-rental": CarRental,
    "car-repair": CarRepair,
    car: Car,
    casino: Casino,
    castle: Castle,
    cemetery: Cemetery,
    "charging-station": ChargingStation,
    cinema: Cinema,
    "circle-stroked": CircleStroked,
    circle: Circle,
    "clothing-store": ClothingStore,
    college: College,
    "communications-tower": CommunicationsTower,
    confectionery: Confectionery,
    convenience: Convenience,
    dentist: Dentist,
    doctor: Doctor,
    "dog-park": DogPark,
    "drinking-water": DrinkingWater,
    embassy: Embassy,
    entrance: Entrance,
    farm: Farm,
    "fast-food": FastFood,
    ferry: Ferry,
    "fire-station": FireStation,
    "fitness-centre": FitnessCentre,
    fuel: Fuel,
    furniture: Furniture,
    garden: Garden,
    gate: Gate,
    globe: Globe,
    golf: Golf,
    grocery: Grocery,
    harbor: Harbor,
    hardware: Hardware,
    heliport: Heliport,
    "highway-rest-area": HighwayRestArea,
    historic: Historic,
    "horse-riding": HorseRiding,
    hospital: Hospital,
    "hot-spring": HotSpring,
    "ice-cream": IceCream,
    industry: Industry,
    information: Information,
    "jewelry-store": JewelryStore,
    landmark: Landmark,
    laundry: Laundry,
    library: Library,
    "lift-gate": LiftGate,
    lighthouse: Lighthouse,
    lodging: Lodging,
    "marker-stroked": MarkerStroked,
    marker: Marker,
    "mobile-phone": MobilePhone,
    monument: Monument,
    mountain: Mountain,
    museum: Museum,
    music: Music,
    "observation-tower": ObservationTower,
    optician: Optician,
    park: Park,
    "parking-garage": ParkingGarage,
    parking: Parking,
    pharmacy: Pharmacy,
    "picnic-site": PicnicSite,
    pitch: Pitch,
    "place-of-worship": PlaceOfWorship,
    playground: Playground,
    police: Police,
    post: Post,
    prison: Prison,
    "racetrack-boat": RacetrackBoat,
    "racetrack-cycling": RacetrackCycling,
    "racetrack-horse": RacetrackHorse,
    racetrack: Racetrack,
    "rail-light": RailLight,
    "rail-metro": RailMetro,
    rail: Rail,
    "ranger-station": RangerStation,
    "religious-buddhist": ReligiousBuddhist,
    "religious-christian": ReligiousChristian,
    "religious-jewish": ReligiousJewish,
    "religious-muslim": ReligiousMuslim,
    "religious-shinto": ReligiousShinto,
    "residential-community": ResidentialCommunity,
    "restaurant-bbq": RestaurantBbq,
    "restaurant-noodle": RestaurantNoodle,
    "restaurant-pizza": RestaurantPizza,
    "restaurant-seafood": RestaurantSeafood,
    "restaurant-sushi": RestaurantSushi,
    restaurant: Restaurant,
    rocket: Rocket,
    school: School,
    shoe: Shoe,
    shop: Shop,
    skateboard: Skateboard,
    skiing: Skiing,
    slipway: Slipway,
    stadium: Stadium,
    star: Star,
    suitcase: Suitcase,
    swimming: Swimming,
    "table-tennis": TableTennis,
    tennis: Tennis,
    theatre: Theatre,
    toilet: Toilet,
    toll: Toll,
    "town-hall": TownHall,
    town: Town,
    "triangle-stroked": TriangleStroked,
    triangle: Triangle,
    tunnel: Tunnel,
    veterinary: Veterinary,
    viewpoint: Viewpoint,
    volcano: Volcano,
    volleyball: Volleyball,
    watch: Watch,
    waterfall: Waterfall,
    watermill: Watermill,
    windmill: Windmill,
    zoo: Zoo,
};

export type MapboxIcon = keyof typeof mapboxIcons;
export const mapboxIconList = Object.keys(mapboxIcons) as MapboxIcon[];
