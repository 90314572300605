import type { ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import isString from "lodash/isString";
import { useEffect } from "react";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { DrawEventType } from "../../utils/draw-modes-types";
import {
    FunctionType,
    SetSavedViewPolygonsType,
    getInputPolygon,
} from "../../utils/utils";
import { SavedPolygonType } from "./saved-polygon-hooks";

export const usePolygon = ({
    functionType,
    map,
    handleMultiPolygons,
    deleteShape,
    updateSavedPolygon,
    deleteSavedPolygon,
    savedPolygons,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    handleMultiPolygons: ({ polygon }: { polygon: InputPolygonWithId }) => void;
    deleteShape: (id: string) => void;
    savedPolygons: SavedPolygonType;
} & SetSavedViewPolygonsType) => {
    const current = map.current;

    const handlePolygon = (e: DrawEventType) => {
        const feature = e?.features[0];

        const isSquare: boolean = feature.properties?.isSquare || false;
        const isCircle: boolean = feature.properties?.isCircle || false;

        if (!isSquare && !isCircle) {
            const id = feature?.id;

            if (e.type === "draw.delete" && isString(id)) {
                // Delete shape by keyboard
                deleteShape(id);
                deleteSavedPolygon(id, savedPolygons);
            } else {
                const localPolygon: InputPolygonWithId =
                    getInputPolygon(feature);

                onPolygonChange(localPolygon);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (current) {
            current.on("draw.create", handlePolygon);
            current.on("draw.delete", handlePolygon);
            current.on("draw.update", handlePolygon);
        }

        return () => {
            if (current) {
                current.off("draw.create", handlePolygon);
                current.off("draw.delete", handlePolygon);
                current.off("draw.update", handlePolygon);
            }
        };
    }, [functionType, current]);

    const onPolygonChange = (p: InputPolygonWithId) => {
        handleMultiPolygons({ polygon: p });

        updateSavedPolygon(p, savedPolygons);
    };

    return { handlePolygon };
};
