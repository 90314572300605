import { CircularLoading, Stack, Typography } from "@biggeo/bg-ui/lab";

type MapLoadingViewProps = {
    content?: string;
};

const MapLoadingView = ({
    content = "Loading your results.",
}: MapLoadingViewProps) => {
    return (
        <Stack
            flexDirection="row"
            alignItems="center"
            gap={2}
            sx={{
                padding: 3,
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                position: "absolute",
                left: 4,
                bottom: 4,
            }}
        >
            <CircularLoading size="sm" />
            <Typography variant="body3" textColor="disabled.onContainer">
                {content}
            </Typography>
        </Stack>
    );
};

export default MapLoadingView;
