import { reduceNumber } from "@biggeo/bg-common";
import { useFetchMapTemplateWithSavedViewsByIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Grid,
    LoadingBar,
    Stack,
} from "@biggeo/bg-ui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Routes } from "../../navigation/redux/model";
import SavedViewList from "../../saved-views/views/SavedViewList";

const ViewAllMapTemplateSavedViewsPage = () => {
    const { id } = useParams();
    const toPage = useNavigate();
    const mapTemplateId = reduceNumber(id);
    if (!mapTemplateId) return <ErrorPage />;

    const {
        queryReturn: { data, loading },
    } = useFetchMapTemplateWithSavedViewsByIdQuery({
        variables: { mapTemplateId: mapTemplateId },
    });

    return (
        <Stack height={"100%"} width={"100%"}>
            <BreadcrumbsGroup
                value={"index-data-source"}
                sx={{
                    padding: 4,
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <BreadcrumbsButton
                    value={"saved-views"}
                    onClick={() => toPage(Routes.mapTemplateSavedViews)}
                >
                    Saved Views
                </BreadcrumbsButton>
                <BreadcrumbsButton value={"index-data-source"} hideSeparator>
                    {data?.fetchMapTemplateWithSavedViewsById.name}
                </BreadcrumbsButton>
            </BreadcrumbsGroup>
            {loading ? (
                <Grid item width={"100%"}>
                    <LoadingBar />
                </Grid>
            ) : data ? (
                <SavedViewList
                    data={
                        data.fetchMapTemplateWithSavedViewsById
                            .savedViewExtended.savedViews || []
                    }
                    mapTemplateId={data.fetchMapTemplateWithSavedViewsById.id}
                    title={data.fetchMapTemplateWithSavedViewsById.name}
                    image={
                        data.fetchMapTemplateWithSavedViewsById.thumbnail ||
                        undefined
                    }
                />
            ) : (
                <ErrorPage />
            )}
        </Stack>
    );
};

export default ViewAllMapTemplateSavedViewsPage;
