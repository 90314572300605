import isEmpty from "lodash/isEmpty";
import { GeoJSONSource, Layer } from "mapbox-gl";
import { useEffect } from "react";
import { DEFAULT_MAP_STYLE } from "../../views/MapStyles";
import { isLayerPresent } from "../utils/data-layers-utils";

export const DEFAULT_SHAPE_COLOR = "#2F5AF0";
export const SELECTED_SHAPE_COLOR = "#1E1E1E";
export const CONFLICT_SHAPE_COLOR = "#F04438";

export const DEFAULT_SHAPE_OPACITY = 0;
export const SELECTED_SHAPE_OPACITY = 0.5;

export const DEFAULT_SYMBOL_COLOR = "#14161D";

export enum CustomShapeSource {
    select = "select",
    customization = "customization",
    filtering = "filtering",
}

export type ShapeStyleHookReturnType = {
    readonly styles: Layer[];
    readonly onShapeStyleLoad: (i: {
        map: mapboxgl.Map;
        features?: GeoJSON.FeatureCollection<
            GeoJSON.Geometry,
            GeoJSON.GeoJsonProperties
        >;
    }) => GeoJSONSource;
    readonly loadCustomShapeSources: (map: mapboxgl.Map) => void;
};

export const useShapeStyle = ({
    map,
    isLoaded,
}: {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
}): ShapeStyleHookReturnType => {
    const style = isLoaded && map.current ? map.current.getStyle() : undefined;

    // Change the map's label colors to black so they show when datasets are on.
    useEffect(() => {
        if (map.current && isLoaded && style) {
            const layers = style.layers.filter((l) => l.id.includes("label"));
            const styleUrl = style.sprite;

            if (!isEmpty(layers) && styleUrl?.includes(DEFAULT_MAP_STYLE.key)) {
                layers.map((layer) => {
                    map.current?.setPaintProperty(
                        layer.id,
                        "text-color",
                        "black"
                    );
                });
            }
        }
    }, [isLoaded, map.current, style]);

    const loadCustomShapeSources = (map: mapboxgl.Map) => {
        map.addSource(CustomShapeSource.select, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
            promoteId: "featureId", // Necessary to get the id from properties object when you setData.
        });

        map.addSource(CustomShapeSource.customization, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
    };

    const onShapeStyleLoad = (input: {
        map: mapboxgl.Map;
        features?: GeoJSON.FeatureCollection<
            GeoJSON.Geometry,
            GeoJSON.GeoJsonProperties
        >;
    }): GeoJSONSource => {
        const { map, features } = input;
        const source = CustomShapeSource.select;

        const selectSource = map.getSource(source) as GeoJSONSource;

        const customizationSource = map.getSource(
            CustomShapeSource.customization
        ) as GeoJSONSource;

        // Set the data to all the shapes that are currently on the map
        if (selectSource && features) {
            selectSource.setData(features);
        }

        if (customizationSource && features) {
            customizationSource.setData(features);
        }

        if (
            !isLayerPresent({
                layerId: `map-shapes-fill-${source}`,
                map,
                isLoaded,
            })
        ) {
            map.addLayer({
                id: `map-shapes-fill-${source}`,
                type: "fill",
                source,
                paint: {
                    "fill-color": [
                        "case",
                        ["==", ["get", "selected"], true],
                        SELECTED_SHAPE_COLOR,
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "fill-opacity": [
                        "case",
                        ["==", ["get", "selected"], true],
                        SELECTED_SHAPE_OPACITY,
                        DEFAULT_SHAPE_OPACITY,
                    ],
                },
                filter: ["==", "$type", "Polygon"],
            });
        }

        if (
            !isLayerPresent({
                layerId: `map-shapes-line-${source}`,
                map,
                isLoaded,
            })
        ) {
            map.addLayer({
                id: `map-shapes-line-${source}`,
                type: "line",
                source,
                paint: {
                    "line-color": [
                        "case",
                        ["==", ["get", "selected"], true],
                        SELECTED_SHAPE_COLOR,
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "line-width": ["coalesce", ["get", "line-width"], 2],
                    "line-opacity": [
                        "case",
                        ["==", ["get", "selected"], true],
                        SELECTED_SHAPE_OPACITY,
                        DEFAULT_SHAPE_OPACITY,
                    ],
                },
                filter: ["==", "$type", "Polygon"],
            });
        }

        return selectSource;
    };

    return {
        onShapeStyleLoad,
        loadCustomShapeSources,
        // Not linked to any source. On draw create.
        styles: [
            {
                id: "gl-map-shapes-fill",
                type: "fill",
                paint: {
                    "fill-color": DEFAULT_SHAPE_COLOR,
                    "fill-opacity": DEFAULT_SHAPE_OPACITY,
                },
                filter: ["all", ["==", "$type", "Polygon"]],
            },
            {
                id: "gl-map-shapes-line",
                type: "line",
                paint: {
                    "line-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "line-width": ["coalesce", ["get", "user_stroke-width"], 2],
                    "line-opacity": [
                        "coalesce",
                        ["get", "user_stroke-opacity"],
                        1,
                    ],
                },
                filter: ["all", ["==", "$type", "Polygon"]],
            },
            // Polygon outline when they draw.
            {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                filter: [
                    "all",
                    ["==", "active", "true"],
                    ["==", "$type", "Polygon"],
                ],
                layout: {
                    "line-cap": "round",
                    "line-join": "round",
                },
                paint: {
                    "line-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "line-dasharray": [0.2, 2],
                    "line-width": 2,
                },
            },
            {
                id: "gl-draw-line-active",
                type: "line",
                filter: [
                    "all",
                    ["==", "$type", "LineString"],
                    ["==", "active", "true"],
                ],
                layout: {
                    "line-cap": "round",
                    "line-join": "round",
                },
                paint: {
                    "line-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "line-dasharray": [0.2, 2],
                    "line-width": 2,
                },
            },
            {
                id: "gl-draw-polygon-and-line-vertex-inactive",
                type: "circle",
                filter: [
                    "all",
                    ["==", "meta", "vertex"],
                    ["==", "$type", "Point"],
                    ["!=", "mode", "static"],
                ],
                paint: {
                    "circle-radius": 3,
                    "circle-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                },
            },
            {
                id: "gl-draw-point-active",
                type: "circle",
                filter: [
                    "all",
                    ["==", "$type", "Point"],
                    ["!=", "meta", "midpoint"],
                    ["==", "active", "true"],
                ],
                paint: {
                    "circle-radius": 5,
                    "circle-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                },
            },
            // Polygon fill after they draw
            {
                id: "gl-draw-polygon-fill",
                type: "fill",
                filter: [
                    "all",
                    ["==", "$type", "Polygon"],
                    ["!=", "mode", "static"],
                ],
                paint: {
                    "fill-color": [
                        "coalesce",
                        ["get", "user_fill-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "fill-outline-color": [
                        "coalesce",
                        ["get", "user_fill-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "fill-opacity": DEFAULT_SHAPE_OPACITY,
                },
            },
            // Vertex points
            {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                filter: [
                    "all",
                    ["==", "meta", "vertex"],
                    ["==", "$type", "Point"],
                    ["!=", "mode", "static"],
                ],
                paint: {
                    "circle-radius": 3,
                    "circle-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                },
            },
            {
                id: "gl-draw-polygon-midpoint",
                type: "circle",
                filter: [
                    "all",
                    ["==", "$type", "Point"],
                    ["==", "meta", "midpoint"],
                ],
                paint: {
                    "circle-radius": 3,
                    "circle-color": [
                        "coalesce",
                        ["get", "user_stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                },
            },
            // This sets the UI for that radius info prompt that shows when you draw a circle.
            {
                id: "gl-draw-symbol",
                type: "symbol",
                layout: {
                    "text-line-height": 1.1,
                    "text-size": 15,
                    "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"], // Couldn't override it with '"Outfit", sans-serif'.
                    "text-anchor": "left",
                    "text-justify": "left",
                    "text-offset": [0.8, 0.8],
                    "text-field": ["get", "radius"],
                    "text-max-width": 7,
                },
                paint: {
                    "text-color": DEFAULT_SYMBOL_COLOR,
                },
                filter: ["==", "meta", "currentCursorPosition"],
            },
        ],
    };
};
