import { BorderedAccordion, Stack, Typography } from "@biggeo/bg-ui/lab";
import { Circle } from "@biggeo/bg-ui/lab/icons";
import startCase from "lodash/startCase";
import { MapFilterCriteriaSection } from "../utils/utils";

type IFilterCriteriaAccordion = React.PropsWithChildren<{
    readonly label: MapFilterCriteriaSection;
    readonly filled?: boolean;
    readonly hideDot?: boolean;
}>;

const FilterCriteriaAccordion = ({
    label,
    children,
    filled = false,
    hideDot = false,
}: IFilterCriteriaAccordion) => {
    return (
        <BorderedAccordion
            expanded
            label={
                <Stack flexDirection="row" alignItems="center" gap={2}>
                    {!filled && !hideDot && (
                        <Circle
                            color="error"
                            sx={{
                                height: (theme) => theme.spacing(2),
                                width: (theme) => theme.spacing(2),
                            }}
                        />
                    )}
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(label)}
                    </Typography>
                </Stack>
            }
        >
            {children}
        </BorderedAccordion>
    );
};

export default FilterCriteriaAccordion;
