import { Dialog } from "@biggeo/bg-ui/lab";
import { ComponentProps } from "react";
import { ActionType, action } from "typesafe-actions";

interface IModel {
    readonly content?: JSX.Element;
    readonly openModal: boolean;
    readonly modalType: "drawer" | "dialog" | "new-dialog";
    readonly direction?: "left" | "right";
    readonly dialogProps?: ComponentProps<typeof Dialog>;
}

const initialState: IModel = {
    content: undefined,
    openModal: false,
    modalType: "drawer",
    direction: "right",
    dialogProps: undefined,
};

export const modalActions = {
    closeModal: () => action("CLOSE_MODAL"),
    openModal: (p?: {
        component: JSX.Element;
        modalType: IModel["modalType"];
        direction?: IModel["direction"];
        dialogProps?: ComponentProps<typeof Dialog>;
    }) => action("OPEN_MODAL", p),
};

export type ModalActionType = ActionType<typeof modalActions>;

type CombinedActions = ModalActionType;

export const modalReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: CombinedActions
): IModel => {
    switch (action.type) {
        case "OPEN_MODAL":
            return {
                ...state,
                content: action.payload?.component,
                openModal: true,
                modalType: action.payload?.modalType || "drawer",
                direction: action.payload?.direction ?? "right",
                dialogProps: action.payload?.dialogProps,
            };
        case "CLOSE_MODAL":
            return { ...state, openModal: false };

        default:
            return state;
    }
};
