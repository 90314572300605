import { AiResponse } from "@biggeo/bg-server-lib/datascape-ai";
import concat from "lodash/concat";
import { ActionType as ActionT, action } from "typesafe-actions";

interface IModel {
    readonly prompts: AiResponse[];
}

const initialState: IModel = {
    prompts: [],
};

export const aiActions = {
    addPrompt: (p: AiResponse) => action("ADD_PROMPT", p),
};

export type CombinedActions = ActionT<typeof aiActions>;

export const aiReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: CombinedActions
): IModel => {
    if (!action) return state;
    switch (action.type) {
        case "ADD_PROMPT":
            return {
                ...state,
                prompts: concat(state.prompts, action.payload),
            };
        default:
            return state;
    }
};
