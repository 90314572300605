import { FilterObject } from "@biggeo/bg-server-lib/datascape-ai";
import { InputViewBox } from "@biggeo/bg-server-lib/datascape-ai";
import debounce from "lodash/debounce";
import { useEffect } from "react";
import { FunctionType, getBounds, matchFunctionType } from "../../utils/utils";
import { MapAction } from "../context";

export const useViewport = ({
    functionType,
    map,
    multiFilters,
    handleViewportChange,
    viewport,
    dispatch,
}: {
    dispatch: React.Dispatch<MapAction> | undefined;
    functionType: FunctionType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    multiFilters: FilterObject[];
    handleViewportChange: ({ viewport }: { viewport: InputViewBox }) => void;
    viewport: InputViewBox;
}) => {
    const onViewportChange = (viewport: InputViewBox) => {
        dispatch?.({ type: "SET_VIEWPORT", values: viewport });
        matchFunctionType(functionType, {
            viewport: () => {
                handleViewportChange({ viewport });
            },
        });
    };

    const viewportFunction = () => {
        const bounds = map.current?.getBounds();

        if (bounds) {
            const fittedBounds = getBounds(bounds);

            onViewportChange(fittedBounds);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        handleMove();
    }, [multiFilters]);

    const handleMove = debounce(() => {
        matchFunctionType(functionType, {
            viewport: () => viewportFunction(),
        });
    }, 250);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const current = map.current;

        if (current) {
            current.on("moveend", handleMove);
            current.on("movestart", () => {
                handleMove.cancel();
            });
        }
        return () => {
            if (current) {
                current.off("moveend", handleMove);
                current.off("movestart", () => {
                    handleMove.cancel();
                });
            }
        };
    }, [map.current, functionType, handleMove]);

    const onStyleLoad = () => {
        viewportFunction();
    };

    return { onStyleLoad, viewport, bounds: map.current?.getBounds() };
};
