import { Grid, useDataGridOptions } from "@biggeo/bg-ui/lab";
import { useEffect } from "react";
import { Routes } from "../../navigation/redux/model";
import MapDatasetsConfigView from "../mapbox/views/MapDatasetsConfigView";
import { IMapConfiguration } from "./MapConfiguration";

interface IMapTemplateDatasets extends IMapConfiguration {
    readonly route?: "data" | "configuration";
    readonly hideBanner?: boolean;
}

const MapTemplateDatasets = ({
    mapTemplateId,
    route = "configuration",
    toPage,
    hideBanner,
}: IMapTemplateDatasets) => {
    const { filterSearchPaginateProps } = useDataGridOptions(20);

    const manageData = (id: number) => {
        toPage(`${Routes.mapTemplateData}/${id}`);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(
        () => filterSearchPaginateProps.setSearchText(undefined),
        [mapTemplateId]
    );

    return (
        <Grid item xs minHeight={0}>
            <MapDatasetsConfigView
                route={route}
                mapTemplateId={mapTemplateId}
                manageData={manageData}
                hideBanner={hideBanner}
            />
        </Grid>
    );
};

export default MapTemplateDatasets;
