import {
    Button,
    Grid,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";

interface IMapTemplateManageCard {
    readonly id: number;
    readonly thumbnail?: string;
    readonly name: string;
    readonly manage: (id: number) => void;
}

const MapTemplateManageCard = ({
    id,
    thumbnail,
    name,
    manage,
}: IMapTemplateManageCard) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            sx={{
                padding: 4,
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                borderRadius: (theme) => theme.radius.xs4,
            }}
        >
            <Grid item>
                <ThumbnailAvatar src={thumbnail} square alt={name} size="md" />
            </Grid>
            <Grid item xs>
                <Stack
                    sx={{
                        breakpoints: { xs: { justifyContent: "center" } },
                    }}
                >
                    <Typography variant="title2" fontWeight="bold">
                        {name}
                    </Typography>
                    <Typography
                        variant="body3"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                            breakpoints: {
                                xs: {
                                    display: "none",
                                },
                                sm: {
                                    display: "block",
                                },
                            },
                        }}
                    >
                        These are the datasets you have enabled for use in this
                        map template. Go to your management if you want to
                        enable more datasets on this map template.
                    </Typography>
                </Stack>
            </Grid>
            <Button
                color="surface"
                density="dense"
                onClick={() => manage(id)}
                sx={{ flexShrink: 0 }}
            >
                Manage Data
            </Button>
        </Grid>
    );
};

export default MapTemplateManageCard;
