import { useLazyQuery } from "@apollo/client";

import {
    DataSourcesExtended,
    FetchDataSourcesDocument,
    InputDataSource,
} from "@biggeo/bg-server-lib/datascape-ai";

export const useDataSourcesQuery = (input: InputDataSource) => {
    const [execute] = useLazyQuery<
        { fetchDataSources: DataSourcesExtended },
        { input: InputDataSource }
    >(FetchDataSourcesDocument, {
        variables: {
            input,
        },
    });

    return execute;
};
