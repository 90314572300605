import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";

export const useMapData = () => {
    const mapDataRemoteData = useSelector(
        (state: RootState) => state.mapData.mapDataRemoteData
    );

    return mapDataRemoteData;
};
