import { Ring } from "@biggeo/bg-server-lib/datascape-ai";

export const server_url = import.meta.env.VITE_SERVER_URL;

// TODO: This is a kind of awkward place for this to live, it should probably be in a general settings files
export const levelSetPercentiles = [0.0, 20.0, 40.0, 60.0, 80.0];

export const defaultLevelSets: {
    percentile: number;
    rings: Ring[];
}[] = [
    { percentile: 0.0, rings: [] },
    { percentile: 20.0, rings: [] },
    { percentile: 40.0, rings: [] },
    { percentile: 60.0, rings: [] },
    { percentile: 80.0, rings: [] },
];

export const collectionStorageDirectory = "/storage";
