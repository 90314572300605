import moment from "moment";

/**
 * This function converts a date to this format `6 sec/min/hour/days/months/year ago`.
 * @param date - Date
 */

export const formatDateToTimePeriod = (date: Date, increment?: number) => {
    // Snowflake saves the date as 1 hour earlier, so added this increment to account for that.
    if (increment) {
        const updatedDate = new Date(
            new Date(date).setHours(new Date(date).getHours() + increment)
        );

        return moment(updatedDate).fromNow();
    }

    return moment(date).fromNow();
};

/**
 * This function converts a date to this format: MM/DD/YYYY. E.g. `01/16/2024`.
 * @param date - Date
 */

export const formatDateMMDDYYYY = (date: Date) =>
    moment(date).format("MM/DD/YYYY");

/**
 * This function converts a date to this format: MMM D, YYYY. E.g. `Jan 16, 2024`.
 * @param date - Date
 */

export const formatDateMMMDYYYY = (date: Date) =>
    moment(date).format("MMM D, YYYY");
