import {
    CreateEditDeleteMapTemplate,
    MapTemplateExtended,
    MapTemplateStatus,
    useCreateEditDeleteMapTemplateMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model.ts";
import { CallBacksType } from "../../utils/types";
import ManageMapTemplateContainer from "../containers/ManageMapTemplateContainer";
import MapTemplateFormContainer from "../containers/MapTemplateFormContainer.tsx";

export type AvailableDataset = {
    readonly id: string;
    readonly title: string;
    readonly image?: string;
    readonly select?: boolean;
};

const CreateOrManageMapTemplatePage = () => {
    const toPage = useNavigate();
    const dispatch = useDispatch();
    const { id, dataSourceId } = useParams();

    const navigate = (to?: string) =>
        to ? toPage(`${to}`) : toPage(Routes.mapTemplates);

    const {
        executeMutation: createEditMapTemplate,
        mutationReturn: [_, { loading }],
    } = useCreateEditDeleteMapTemplateMutation();

    const create = (
        input: Omit<CreateEditDeleteMapTemplate, "id">,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => {
        createEditMapTemplate({
            variables: {
                input: {
                    description: input.description,
                    fkDataSourceIds: input.fkDataSourceIds,
                    name: input.name,
                    status: MapTemplateStatus.active,
                    thumbnail: input.thumbnail,
                },
            },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Map template created successfully!",
                        autoHideDuration: 5000,
                    })
                );
                callbacks?.onSuccess?.(data.createEditDeleteMapTemplate);
            },
        });
    };

    const manage = (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => {
        createEditMapTemplate({
            variables: {
                input: {
                    id: input.id,
                    description: input.description || "",
                    fkDataSourceIds: input.fkDataSourceIds,
                    name: input.name,
                    status: MapTemplateStatus.active,
                    thumbnail: input.thumbnail,
                    isMarketplaceMapTemplate:
                        input.isMarketplaceMapTemplate || false,
                },
            },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Map template updated successfully!",
                        autoHideDuration: 5000,
                    })
                );

                callbacks?.onSuccess?.(data.createEditDeleteMapTemplate);
            },
        });
    };

    const save = (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => {
        if (id) {
            manage(input, callbacks);
        }

        if (!id && input.name) {
            create(
                {
                    ...omit(input, ["id"]),
                },
                callbacks
            );
        }
    };

    return pipe(
        id,
        O.fromPredicate((x) => !isUndefined(x)),
        O.fold(
            () => (
                <MapTemplateFormContainer
                    navigate={navigate}
                    save={save}
                    loading={loading}
                    dataSourceId={dataSourceId}
                />
            ),
            (mapId) => (
                <ManageMapTemplateContainer
                    id={Number(mapId)}
                    navigate={navigate}
                    save={save}
                    loading={loading}
                />
            )
        )
    );
};

export default CreateOrManageMapTemplatePage;
