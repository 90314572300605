import { pipe } from "fp-ts/lib/function";
import tinycolor2, { ColorInput } from "tinycolor2";

export const generateColorFormats = (currentColor: ColorInput) =>
    pipe(currentColor, tinycolor2, (color) => ({
        hsl: color.toHsl(),
        // Used in @uiw/react-color
        hsv: {
            ...color.toHsv(),
            s: color.toHsv().s * 100,
            v: color.toHsv().v * 100,
        },
        rgb: color.toRgb(),
        hex: color.toHex8String(),
    }));

export const formatAmount = (
    initialUpdate: boolean,
    value?: string,
    parseInteger?: boolean
) => {
    const stringValue = value ? value.toString() : "";

    if (!stringValue) {
        return parseInteger ? "0" : "0.00";
    }
    if (containsPeriod(stringValue) && !initialUpdate) {
        return parseInteger
            ? thousandsSeparator(Number.parseInt(stringValue).toString())
            : thousandsSeparator(Number.parseFloat(stringValue).toFixed(2));
    }
    if (stringValue && !initialUpdate) {
        return parseInteger
            ? thousandsSeparator(stringValue)
            : thousandsSeparator(`${stringValue}.00`);
    }
    const integerPart =
        stringValue &&
        startsWithZeroAndLengthIsGreaterThanOne(stringValue.slice(0, -2))
            ? stringValue.slice(1, -2)
            : stringValue.slice(0, -2);

    const decimalPart = stringValue?.slice(-2);

    const formattedIntegerPart = integerPart && thousandsSeparator(integerPart);

    const amount = parseInteger
        ? stringValue
        : `${removePeriods(
              formattedIntegerPart === "." ? "0" : formattedIntegerPart
          )}.${decimalPart}`;

    return parseInteger
        ? thousandsSeparator(Number.parseInt(removePeriods(amount)).toString())
        : amount;
};

export const removeHashAndLimitCharacters = ({
    color,
    numOfChar = 7,
}: {
    readonly color: string;
    readonly numOfChar?: number;
}) =>
    (color.charAt(0) === "#" ? color.slice(1, numOfChar) : color).toUpperCase();

export const thousandsSeparator = (s: string) =>
    s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const containsPeriod = (s: string) => s.includes(".");

export const startsWithZeroAndLengthIsGreaterThanOne = (s: string) =>
    s.startsWith("0") && s.length > 1;

export const removePeriods = (s: string) => s.replace(/[.]/g, "");

export const removeNonDigits = (n: string) => n.replace(/\D/g, "");

export const greaterThanCheck = ({
    input,
    check,
}: {
    readonly input: number;
    readonly check: number;
}) => (input > check ? check : input);

export const convertToTwoDecimalNumber = (num: number): number =>
    Number(num.toFixed(2));

export const convertToHex8String = (hex: string, opacity?: number) => {
    return opacity
        ? tinycolor2(hex).setAlpha(opacity).toHex8String()
        : tinycolor2(hex).toHex8String();
};
