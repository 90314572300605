import {
    Box,
    CellItem,
    IconButton,
    Stack,
    Switch,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ArrowDownwardOutline,
    Circle,
    MoreVertOutline,
} from "@biggeo/bg-ui/lab/icons";

const FilterCriteriaDatasetsListEmptyView = () => {
    return (
        <Stack
            gap={1}
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            sx={{
                padding: 2,
                textAlign: "center",
                paddingTop: 8,
            }}
        >
            <Typography variant="body2" fontWeight="bold">
                No datasets available to filter
            </Typography>
            <Typography
                variant="body3"
                sx={{
                    color: (theme) => theme.palette.disabled.onContainer,
                }}
            >
                To filter on a dataset, enable the compute resources on the
                datasets panel.
            </Typography>
            <Stack width={"100%"} gap={1}>
                <EmptyDatasetItem />
                <ArrowDownwardOutline
                    size="xs"
                    color="disabled"
                    sx={{
                        paddingLeft: 2,
                    }}
                />
                <EmptyDatasetItem selected />
            </Stack>
        </Stack>
    );
};

export default FilterCriteriaDatasetsListEmptyView;

export const EmptyDatasetItem = ({
    selected = false,
}: { selected?: boolean }) => {
    return (
        <Stack
            width={"100%"}
            sx={{
                borderRadius: (theme) => theme.radius.default,
                overflow: "hidden",
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
            }}
        >
            <Box
                sx={{
                    background:
                        "linear-gradient(90deg, #60F3D0 0%, #23E5E5 38%, #4E93FC 63.99%, #1163E0 100%)",
                    height: 2,
                }}
            />
            <CellItem
                readonly
                disableHoverEffect
                density="none"
                title={
                    <Typography variant="body3" truncate>
                        Dataset Name
                    </Typography>
                }
                sx={{
                    "&.MinervaCellItem-root": {
                        padding: 1,
                    },
                }}
                startNode={
                    <Stack gap={2} flexDirection="row" alignItems="center">
                        <Switch readonly switched={selected} />
                        <Circle
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}
                            size={"xxs"}
                        />
                    </Stack>
                }
                endNode={
                    <IconButton readonly density="none" variant="minimal">
                        <MoreVertOutline size="sm" />
                    </IconButton>
                }
            />
        </Stack>
    );
};
