import { Box, ISlotable, SlotsBase, SlotsProvider } from "@biggeo/bg-ui/lab";
import compact from "lodash/compact";
import concat from "lodash/concat";
import isEqual from "lodash/isEqual";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export type GenericDraggableItemSlots = SlotsBase<{
    readonly container: React.ComponentProps<typeof Box>;
}>;

export const genericDraggableItemSlots: GenericDraggableItemSlots = {
    container: Box,
};

type IGenericDraggableItem<T> = React.PropsWithChildren<{
    readonly type: string;
    readonly index: number;
    readonly state: T[];
    readonly onDragAndDrop?: (state: T[]) => void;
    readonly onHoverAndDrop?: (state: T[]) => void;
    readonly dropArea?: JSX.Element;
}> &
    ISlotable<GenericDraggableItemSlots>;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const swapArrayIndexes = <T extends any[]>(
    currentIndex: number,
    desiredIndex: number,
    context: T
): T => {
    const length = context.length;

    if (
        length === 0 ||
        currentIndex === desiredIndex ||
        currentIndex < 0 ||
        currentIndex >= length
    ) {
        return context;
    }

    const targetIndex =
        desiredIndex >= length
            ? length - 1
            : desiredIndex < 0
              ? 0
              : desiredIndex;

    return context.reduce((acc, curr, index) => {
        if (isEqual(index, currentIndex)) {
            return acc;
        }

        if (isEqual(index, targetIndex)) {
            const field = context[currentIndex];
            return compact(concat(acc, field, curr));
        }

        return concat(acc, curr);
    }, []);
};

export const GenericDraggableItem = <T,>({
    children,
    type,
    index,
    state,
    onDragAndDrop,
    onHoverAndDrop,
    dropArea,
    slotProps,
    slots,
}: IGenericDraggableItem<T>) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type,
            item: { draggedIndex: index },
            collect: (monitor) => ({
                type: monitor.getItemType(),
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [state]
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: type,
            drop: ({
                draggedIndex,
            }: {
                draggedIndex: number;
            }) => {
                if (draggedIndex !== index) {
                    const newState = swapArrayIndexes(
                        draggedIndex,
                        index,
                        state
                    );

                    onDragAndDrop?.(newState);
                }
            },
            hover: ({
                draggedIndex,
            }: {
                draggedIndex: number;
            }) => {
                if (draggedIndex !== index) {
                    const newState = swapArrayIndexes(
                        draggedIndex,
                        index,
                        state
                    );

                    onHoverAndDrop?.(newState);
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [state]
    );

    drag(ref);
    drop(ref);

    return (
        <SlotsProvider baseSlots={genericDraggableItemSlots} slots={slots}>
            {({ container: Container }) => (
                <Container
                    ref={ref}
                    {...slotProps?.container}
                    sx={{
                        opacity: isDragging ? 0 : 1,
                        ...slotProps?.container?.sx,
                    }}
                >
                    {isOver && canDrop && dropArea}
                    {children}
                </Container>
            )}
        </SlotsProvider>
    );
};
