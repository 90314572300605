import {
    Area,
    useFetchAllAreasQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Dropdown, Grid, MenuItem } from "@biggeo/bg-ui/lab";
import { DoneOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useState } from "react";
import SavedAreasTableContainer from "../../available-areas/containers/SavedAreasTableContainer.tsx";
import SavedAreaForm from "../../map-templates/views/SavedAreaForm";
import { IMapConfiguration } from "./MapConfiguration";

interface IMapAvailableAreasConfig extends IMapConfiguration {}

const MapAvailableAreasConfig = ({
    isSavedAreaForm,
    mapTemplateId,
    toPage,
}: IMapAvailableAreasConfig) => {
    const [areas, setAreas] = useState<Area[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedArea, setSelectedArea] = useState<number>(0);

    useFetchAllAreasQuery({
        variables: {},
        onCompleted: (data) => {
            setAreas(data.fetchAllAreas);
            setSelectedArea(data.fetchAllAreas[0].id);
        },
    });
    const handleAreaChange = (areaId: number) => {
        setSelectedArea(areaId);
        setOpen(false);
    };

    const getSelectAreaName = ({
        areas,
        selectedArea,
    }: {
        areas: Area[];
        selectedArea: number;
    }): string | undefined => {
        const areaName = areas.find((item) =>
            isEqual(item.id, selectedArea)
        )?.name;
        return setAreaNameCase(areaName);
    };

    const setAreaNameCase = (areaName?: string) => {
        return isEqual(areaName?.toLowerCase(), "custom boundaries")
            ? startCase(areaName?.toLowerCase())
            : areaName;
    };

    return (
        <>
            {!isSavedAreaForm && (
                <>
                    <Dropdown
                        open={open}
                        onOpenChange={(o) => setOpen(o)}
                        slotProps={{
                            button: {
                                sx: {
                                    minWidth: 30,
                                    justifyContent: "space-between",
                                },
                            },
                        }}
                        placement="bottom-start"
                        label={
                            getSelectAreaName({ areas, selectedArea }) ||
                            "Custom Boundaries"
                        }
                        variant="button"
                        content={pipe(
                            areas,
                            A.mapWithIndex((index, item: Area) => (
                                <MenuItem
                                    key={index}
                                    label={setAreaNameCase(item.name)}
                                    density={"dense"}
                                    endNode={
                                        selectedArea === item.id && (
                                            <DoneOutline size={"xs"} />
                                        )
                                    }
                                    onClick={() => handleAreaChange(item.id)}
                                />
                            ))
                        )}
                    />
                    <Grid item xs minHeight={0}>
                        <SavedAreasTableContainer
                            areaId={selectedArea}
                            mapTemplateId={mapTemplateId}
                            selectedAreaName={getSelectAreaName({
                                areas,
                                selectedArea,
                            })}
                        />
                    </Grid>
                </>
            )}
            {isSavedAreaForm && <SavedAreaForm toPage={toPage} />}
        </>
    );
};

export default MapAvailableAreasConfig;
