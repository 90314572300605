import { AspectRatio, ButtonBase, Image, Typography } from "@biggeo/bg-ui/lab";

export type HeatMapClusterMapperCardProps = {
    selected?: boolean;
    img?: string;
    alt?: string;
    title?: string;
    onClick?: () => void;
};

export const HeatMapClusterMapperCard = ({
    selected,
    img,
    alt,
    title,
    onClick,
}: HeatMapClusterMapperCardProps) => (
    <ButtonBase
        onClick={onClick}
        sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            borderRadius: (theme) => theme.radius.default,
            border: selected ? 2 : 1,
            borderColor: (theme) =>
                selected
                    ? theme.palette.background.onMain
                    : theme.palette.stroke[100],
        }}
    >
        <AspectRatio
            variant="1"
            height={22}
            sx={{
                borderRadius: "inherit",
            }}
        >
            <Image
                src={img}
                alt={alt}
                sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            />
        </AspectRatio>
        <Typography variant="body3">{title}</Typography>
    </ButtonBase>
);
