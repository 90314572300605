import {
    PointDataInput,
    useFetchSnowflakePointDataQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    Grid,
    IconButton,
    LoadingBar,
    OverflowingTypography,
    ProgressBar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import startCase from "lodash/startCase";
import { useNavigate } from "react-router";
import { dataPointTooltipCloseIconId } from "./map-tooltip/redux/hooks";
import { useMap } from "./mapbox/context";

const ErrorView = ({
    title,
    message,
    ctaText,
    cta,
}: {
    title?: string;
    message?: string;
    ctaText?: string;
    cta?: () => void;
}) => {
    return (
        <Stack
            gap={4}
            sx={{
                padding: 2,
                borderRadius: (theme) => theme.radius.default,
                background: (theme) => theme.palette.info.container,
                color: (theme) => theme.palette.info.onContainer,
            }}
        >
            <Stack gap={2}>
                <Typography variant="title2" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="title3">{message}</Typography>
            </Stack>
            {ctaText && cta && (
                <Button
                    color="info"
                    onClick={cta}
                    sx={{ alignSelf: "flex-end" }}
                >
                    Re-index Dataset
                </Button>
            )}
        </Stack>
    );
};

const LoadingView = ({
    totalSize,
    progress,
}: { totalSize?: number; progress?: number }) => {
    return (
        <Stack>
            <Stack gap={1} sx={{ padding: 2 }}>
                <Grid container justifyContent="space-between" gap={1}>
                    <Typography variant="title4">
                        {progress && totalSize
                            ? `Processing ${progress}KB of ${totalSize}KB`
                            : "Processing"}
                    </Typography>
                    {progress && totalSize && (
                        <Typography variant="title2" fontWeight="bold">
                            {`${Math.round((progress / totalSize) * 100)}% Done`}
                        </Typography>
                    )}
                </Grid>
                {progress && totalSize ? (
                    <ProgressBar color="success" value={progress / totalSize} />
                ) : (
                    <LoadingBar color="success" />
                )}
            </Stack>
            <Stack
                gap={1}
                sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 4 }}
            >
                <Typography variant="title2" fontWeight="bold" align="center">
                    Nothing to see yet...
                </Typography>
                <Typography
                    variant="title4"
                    align="center"
                    textColor="disabled.onContainer"
                >
                    The dataset is currently processing, once complete you will
                    be able to see the point details here.
                </Typography>
            </Stack>
        </Stack>
    );
};

export const PointDataContainer = ({
    selectedPoint,
}: {
    selectedPoint: PointDataInput;
}) => {
    const toPage = useNavigate();

    const { datasets } = useMap();
    const table = datasets.find(
        (d) => d.dataSource.id === selectedPoint.databaseId
    );

    const hasTableId = pipe(
        table,
        O.fromNullable,
        O.fold(
            () => false,
            (data) => Boolean(data.dataSource.tableId)
        )
    );

    const {
        queryReturn: { data, error },
    } = useFetchSnowflakePointDataQuery({
        variables: {
            input: selectedPoint,
        },
        skip: !hasTableId,
    });

    const pointData = data?.fetchSnowflakePointData.data;

    return (
        <Stack
            sx={{
                maxHeight: 60.75,
                width: 56.25,
            }}
        >
            <Grid
                container
                gap={1}
                flexWrap="nowrap"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ padding: 2 }}
            >
                <Typography variant="title4" fontWeight="bold">
                    {pipe(
                        table,
                        O.fromNullable,
                        O.fold(
                            () => {
                                return startCase(selectedPoint.databaseId);
                            },
                            (data) => {
                                return (
                                    data.dataSource.label ||
                                    data.dataSource.tableName
                                );
                            }
                        )
                    ) || "No data found"}
                </Typography>
                <IconButton
                    id={dataPointTooltipCloseIconId}
                    variant="ghost"
                    sx={{ padding: 0.5 }}
                >
                    <CloseOutline size="xxs" />
                </IconButton>
            </Grid>

            <Stack sx={{ overflow: "auto", padding: 2 }}>
                {pipe(
                    pointData,
                    O.fromNullable,
                    O.fold(
                        () =>
                            error || !hasTableId ? (
                                <ErrorView
                                    title={
                                        error
                                            ? undefined
                                            : "We need to re-index your dataset"
                                    }
                                    message={
                                        error
                                            ? undefined
                                            : "The Dataset that you're trying to access is missing the table id. Please re-index the dataset by clicking the button below."
                                    }
                                    ctaText={
                                        error ? undefined : "Re-index Dataset"
                                    }
                                    cta={
                                        error
                                            ? undefined
                                            : () => toPage("/datasets")
                                    }
                                />
                            ) : (
                                <LoadingView />
                            ),
                        (pointData) => (
                            <Stack gap={1}>
                                {Object.entries(pointData).map(([k, v]) => {
                                    return (
                                        <Grid
                                            key={k}
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            flexWrap="nowrap"
                                        >
                                            <Grid item xs={6}>
                                                <OverflowingTypography variant="body3">
                                                    {k}
                                                </OverflowingTypography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <OverflowingTypography
                                                    variant="body4"
                                                    fontWeight="semibold"
                                                >
                                                    {typeof v === "string"
                                                        ? v
                                                        : JSON.stringify(v)}
                                                </OverflowingTypography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Stack>
                        )
                    )
                )}
            </Stack>
        </Stack>
    );
};
