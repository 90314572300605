import { MarketplaceDataset } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CompanyAvatar,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Hidden,
    Image,
    MapLayoutHeader,
    OverflowingTypography,
    Stack,
} from "@biggeo/bg-ui/lab";
import { MapOutline } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { AreaQualityTabs } from "../components/AreaQualityTabs";
import MarketplaceDatasetPreviewInfo from "./MarketplaceDatasetPreviewInfo";

interface IMarketplaceDatasetPreview {
    readonly marketplaceDataset: MarketplaceDataset;
    readonly onClickPreviewDataset: (marketplaceDatasetId: string) => void;
    readonly onClose: () => void;
    readonly isPreviewBtnDisabled: boolean;
}

const MarketplaceDatasetPreview = ({
    marketplaceDataset,
    onClickPreviewDataset,
    onClose,
    isPreviewBtnDisabled,
}: IMarketplaceDatasetPreview) => {
    return (
        <Stack height={"100%"}>
            <Hidden breakpoint="cmd" variant="up">
                <MapLayoutHeader
                    vertical
                    header="Dataset information"
                    onClick={() => onClose()}
                />
            </Hidden>
            <Hidden breakpoint="cmd" variant="down">
                <MapLayoutHeader
                    inverted
                    header="Dataset information"
                    onClick={() => onClose()}
                />
            </Hidden>
            <Divider orientation="horizontal" color={100} />
            <FlexScrollAreaContainer>
                <FlexScrollArea
                    gap={4}
                    flexDirection="column"
                    sx={{ padding: 4 }}
                >
                    <Image
                        src={
                            "https://biggeo.blob.core.windows.net/media/Basemap-image.png"
                        }
                        sx={{
                            width: "100%",
                            objectFit: "contain",
                            objectPosition: "top left",
                        }}
                    />
                    <Button
                        variant="filled"
                        color="background"
                        startNode={<MapOutline size="xs" />}
                        sx={{
                            position: "absolute",
                            right: "26px",
                            top: "72px",
                            borderColor: (theme) => theme.palette.outline,
                            border: "1px solid",
                            borderRadius: (theme) => theme.radius.default,
                            ...(isPreviewBtnDisabled && {
                                color: (theme) => theme.palette.disabled.main,
                            }),
                        }}
                        readonly={isPreviewBtnDisabled}
                        onClick={() => {
                            if (isEqual(isPreviewBtnDisabled, false)) {
                                onClickPreviewDataset(marketplaceDataset.id);
                            }
                        }}
                    >
                        Preview
                    </Button>
                    <Stack flexDirection="row" alignItems="center" gap={2}>
                        <CompanyAvatar
                            square
                            src={marketplaceDataset.img || undefined}
                            alt={marketplaceDataset.name}
                        />
                        <Stack spacing={0.5}>
                            <OverflowingTypography
                                variant="body3"
                                fontWeight="bold"
                            >
                                {marketplaceDataset.name}
                            </OverflowingTypography>
                            <OverflowingTypography
                                variant="body4"
                                textColor="disabled.onContainer"
                            >
                                By Dataplor
                            </OverflowingTypography>
                        </Stack>
                    </Stack>
                    <Divider color={100} />
                    <AreaQualityTabs
                        tableName={marketplaceDataset.tableName}
                        columns={marketplaceDataset.columns || []}
                    />
                    <Divider color={100} />
                    <MarketplaceDatasetPreviewInfo
                        price={marketplaceDataset.price}
                        size={marketplaceDataset.size}
                    />
                </FlexScrollArea>
            </FlexScrollAreaContainer>
        </Stack>
    );
};

export default MarketplaceDatasetPreview;
