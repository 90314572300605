import { ShapeStyle } from "@biggeo/bg-server-lib/datascape-ai";
import { BreadcrumbsButton, BreadcrumbsGroup, Stack } from "@biggeo/bg-ui/lab";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { MapColorSetterType } from "../../common/types/color-picker";
import { DatasetCardMapItem } from "../../components/DatasetCardMapItem";
import { MapContextDataset } from "../mapbox/context/map";

export interface IDatasetMenu {
    readonly dataset: MapContextDataset;
    readonly onBack: (dataSourceId: string) => void;
    readonly extraOptions?: ColorSwatchOption[];
    readonly updateHeatMapColor: (
        value: ColorSwatchOption,
        dataset: MapContextDataset
    ) => void;
    readonly updateShape: (input: {
        shape: ShapeStyle;
        dataset: MapContextDataset;
    }) => void;
    readonly updateColor: ({
        color,
        opacity,
        type,
    }: MapColorSetterType & {
        type: "fill" | "stroke";
        dataset: MapContextDataset;
    }) => void;
    readonly updateCustomMarker: (i: {
        marker: string | null;
        dataset: MapContextDataset;
    }) => void;
}

const DatasetMenu = ({ onBack, dataset, ...props }: IDatasetMenu) => {
    return (
        <Stack gap={4}>
            <BreadcrumbsGroup value="styles">
                <BreadcrumbsButton
                    variant="minimal"
                    value="datasets"
                    onClick={() => onBack(dataset.dataSource.id)}
                >
                    Datasets
                </BreadcrumbsButton>
                <BreadcrumbsButton
                    variant="minimal"
                    value="styles"
                    hideSeparator
                >
                    Styles
                </BreadcrumbsButton>
            </BreadcrumbsGroup>
            <DatasetCardMapItem {...props} dataset={dataset} />
        </Stack>
    );
};

export default DatasetMenu;
